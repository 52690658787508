import React, { Component } from 'react';
import Axios from 'axios';
import { read_cookie, delete_cookie } from 'sfcookies';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Loader from '../../loader';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import TableSearch from '../TableSearch';

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      filteredhistory: [],
      startDate: new Date(),
      endDate: new Date(),
      pdfUrl: '',
      bool: false,
      isOpen: false,
      imageUrl: '',
      searchedHistory: [],
    };
  }

  componentDidMount() {
    //console.log(this.props.values);
    if (this.props.values) {
      this.handleHistory();
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  UNSAFE_componentWillMount() {
    let d = new Date();
    d.setMonth(d.getMonth() - 6);
    this.setState({ startDate: d });
  }

  handleHistory = () => {
    const token = read_cookie('Bearer_Token');

    let stDate = null;
    if (this.state.startDate) {
      stDate = moment(this.state.startDate).format('yyyy-MM-DD');
    }
    const name = window.$name;
    let enDate = moment(this.state.endDate).format('yyyy-MM-DD');
    this.setState({ bool: true });
    if (typeof this.props.values !== 'undefined' && this.props.values !== '') {
      Axios.post(
        `${name}/api/Customer/GetTransactions`,
        {
          customerNumber: this.props.values,
          dateFrom: stDate,
          dateTo: enDate,
        },
        {
          headers: {
            Token: token,
          },
        }
      )
        .then((res) => {
          if (res.data.success) {
            const name = window.$name;
            this.setState({
              history: res.data.transactions,
              filteredhistory: res.data.transactions,
              pdfUrl: `${name}/` + res.data.pdfUrl,
              bool: false,
            });
          } else {
            this.setState({ bool: false });
            //alert(res.data.message);
          }
        })
        .catch((error) => {
          this.setState({ bool: false });
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.href = '/login';
          }
          //alert("ისტორიის წაკითხვა ვერ მოხერხდა");;
        });
    }
  };

  setStartDate = (date) => {
    if (date) {
      let a = moment(date).toDate();
      this.setState({ startDate: a });
    } else {
      this.setState({ startDate: null });
    }
  };

  setEndDate = (date) => {
    if (date) {
      this.setState({ endDate: date });
    } else {
      this.setState({ endDate: new Date() });
    }
  };

  getToPdf = () => {
    window.open(this.state.pdfUrl, '_self');
  };

  handleLightBox = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleImageUrl = (url) => {
    //console.log(url);
    this.setState({ imageUrl: url });
  };

  handleSearchedHistory = (data) => {
    this.setState({ filteredhistory: data });
  };

  _exporter;
  export = () => {
    this.save(this._exporter);
  };

  save = (component) => {
    const options = component.workbookOptions();
    const rows = options.sheets[0].rows;

    rows.forEach((row) => {
      if (row.type === 'header') {
        row.height = 35;
      }
    });

    component.save(options);
  };

  render() {
    //console.log(this.history);
    let history = this.state.filteredhistory;
    //console.log(history);
    //let array = this.state.history.map((element)=>({operDateString:element.operDateString,operationName:element.operationName,meterReading:element.meterReading,consumption:element.consumption,amount:element.amount,balance:element.balance}));
    const conditionalRowStyles = [
      {
        when: (row) => row.operationName.includes('გადახდა'),
        style: {
          color: 'black',
          backgroundColor: '#A4FFB6',
        },
      },
    ];
    //console.log(history)
    const images = [this.state.imageUrl];

    const name = window.$name;

    //history=this.state.filteredhistory;

    const columns = [
      {
        name: 'თარიღი',
        selector: 'operDateString',
        sortable: true,
        wrap: true,
        center: true,
        width: '10%',
        cell: (row) => moment(row.operDate).format('DD.MM.YYYY'),
      },
      {
        name: 'დასახელება',
        selector: 'operationName',
        sortable: true,
        wrap: true,
        center: true,
        width: '25%',
      },
      {
        name: 'ჩვენება',
        selector: 'meterReading',
        sortable: true,
        wrap: true,
        center: true,
        width: '13.75%',
      },
      {
        name: 'მოხმარება',
        selector: 'consumption',
        sortable: true,
        wrap: true,
        center: true,
        width: '13.75%',
      },
      {
        name: 'თანხა',
        selector: 'amount',
        sortable: true,
        wrap: true,
        center: true,
        width: '13.75%',
      },
      {
        name: 'ბალანსი',
        selector: 'balance',
        sortable: true,
        wrap: true,
        center: true,
        width: '13.75%',
      },
      {
        name: 'ქვითარი',
        selector: 'billDocPath',
        sortable: false,
        center: true,
        width: '5%',
        cell: (row) =>
          row.billDocPath ? (
            <a href={`https://b.te.ge/${row.billDocPath}`}>
              <i
                className="fas fa-file-invoice"
                style={{ color: '#262262', cursor: 'pointer' }}
              ></i>
            </a>
          ) : null,
      },
      {
        name: 'ფოტო',
        selector: 'meterPhotoPath',
        sortable: false,
        center: true,
        width: '5%',
        cell: (row) =>
          row.meterPhotoPath ? (
            <i
              className="fas fa-file-image"
              style={{ color: '#262262', cursor: 'pointer' }}
              onClick={() => {
                this.handleLightBox();
                this.handleImageUrl(`${name}/${row.meterPhotoPath}`);
              }}
            ></i>
          ) : null,
      },
      //'//10.0.0.131:8080/'+`${row.meterPhotoPath}`
    ];
    //console.log(this.state.filteredhistory);
    const actions = [
      // <i className="fas fa-print" key="Print" style={{color:"#262262"}}></i>,

      <i
        className="fas fa-file-pdf"
        key="pdfbutton"
        title="PDF-ში კონვერტირება"
        style={{ color: '#262262', cursor: 'pointer' }}
        onClick={this.getToPdf}
      ></i>,
      <i
        className="fas fa-file-excel"
        key="excelbutton"
        title="Excel-ში კონვერტირება"
        style={{ color: '#262262', cursor: 'pointer' }}
        onClick={this.export}
      ></i>,
    ];

    let dt = moment(new Date()).format('DD.MM.YYYY');
    const fileName = `${this.props.values + '_' + dt}.xlsx`;
    const headerCellOptions = {
      background: '#262262',
      textAlign: 'center',
      fontSize: 19,
      verticalAlign: 'center',
    };
    return (
      <React.Fragment>
        <ExcelExport
          data={history}
          fileName={fileName}
          headerPaddingCellOptions={{ background: '#ff0000' }}
          ref={(exporter) => {
            this._exporter = exporter;
          }}
        >
          <ExcelExportColumn
            field="operDateString"
            title="თარიღი"
            width={150}
            headerCellOptions={headerCellOptions}
          />
          <ExcelExportColumn
            field="operationName"
            title="დასახელება"
            width={350}
            headerCellOptions={headerCellOptions}
          />
          <ExcelExportColumn
            field="meterReading"
            title="ჩვენება"
            width={150}
            headerCellOptions={headerCellOptions}
          />
          <ExcelExportColumn
            field="consumption"
            title="მოხმარება"
            width={150}
            headerCellOptions={headerCellOptions}
          />
          <ExcelExportColumn
            field="amount"
            title="თანხა"
            width={150}
            headerCellOptions={headerCellOptions}
          />
          <ExcelExportColumn
            field="balance"
            title="ბალანსი"
            width={150}
            headerCellOptions={headerCellOptions}
          />
        </ExcelExport>
        <div
          className="row justify-content-center"
          style={{ position: 'relative', fontFamily: 'BPGCond' }}
        >
          {this.state.isOpen && (
            <div className="ReactModal__Overlay">
              <Lightbox
                mainSrc={images[0]}
                onCloseRequest={() => {
                  this.handleLightBox();
                  this.handleImageUrl('');
                }}
                closeLabel="Close lightbox"
                enableZoom
                clickOutsideToClose
                imageLoadErrorMessage="ფოტოს ჩატვირთვა ვერ მოხერხდა"
              ></Lightbox>
            </div>
          )}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-3 col-lg-2">
                <label style={{ whiteSpace: 'nowrap' }}>
                  <strong>საწყისი დრო</strong>
                </label>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.setStartDate}
                  isClearable
                  dateFormat="dd.MM.yyyy"
                  showMonthDropdown
                  showYearDropdown
                ></DatePicker>
              </div>
              <div className="col-md-3 col-lg-2">
                <label style={{ whiteSpace: 'nowrap' }}>
                  <strong>საბოლოო დრო</strong>
                </label>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.setEndDate}
                  isClearable
                  dateFormat="dd.MM.yyyy"
                  showMonthDropdown
                  showYearDropdown
                ></DatePicker>
              </div>
              <div className="col-md-3 col-lg-2">
                <div className="col-sm-12" style={{ top: '40%' }}>
                  <button
                    className="btn btn-primary"
                    style={{ backgroundColor: '#262262', borderColor: '#262262' }}
                    onClick={this.handleHistory}
                    type="button"
                  >
                    ნახვა
                  </button>
                </div>
              </div>
              <div className="col-md-3 col-lg-4"></div>
            </div>
            <br />
          </div>
          <div className="col-lg-12">
            <div className="card-group">
              {this.state.bool ? <Loader></Loader> : null}
              <DataTable
                title={
                  <div style={{ float: 'left' }}>
                    <TableSearch
                      data={this.state.history}
                      handleSearchedHistory={this.handleSearchedHistory}
                    />
                  </div>
                }
                actions={actions}
                columns={columns}
                data={history}
                noDataComponent="მონაცემები ვერ მოიძებნა"
                conditionalRowStyles={conditionalRowStyles}
                subHeader={false}
                subHeaderAlign="left"
                // subHeaderComponent={<TableSearch data={this.state.history} handleSearchedHistory={this.handleSearchedHistory}/>}
                pagination
                responsive
              ></DataTable>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
//style={{left:"45%",top:"45%",zIndex:"9999999",position:"absolute",width:"150px"}}
export default History;
