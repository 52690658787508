import React, { Component } from 'react';
import { Line , Pie, Bar} from 'react-chartjs-2';
import Axios from 'axios';
import {read_cookie,delete_cookie} from 'sfcookies';
import moment from 'moment';
import DatePicker from 'react-datepicker';
//import Loader from '../../loader';

class Charts extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            history:[],
            filteredhistory:[],
            years:[],
            data:[],
            value:0,
            startDate:null,
            endDate:new Date(),
            historyPie:[],
            filteredhistoryPie:[],
            pieStartDate:null,
            pieEndDate:new Date(),
            historyBar:[],
            filteredhistorybar:[],
            loader:false
        }
    }

    componentDidMount(){
        this.handleHistory();     
        this.handleBarHistory();  
    }

    UNSAFE_componentWillMount(){
        let d = new Date();
        d.setMonth(d.getMonth()-6);
        this.setState({startDate:d});
        this.setState({pieStartDate:d});  
    }

    handleHistory=()=>{
   
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        let stDate = null;
        if(this.state.startDate){
            stDate=moment(this.state.startDate).format('YYYY-MM-DD');
        }
        let enDate = moment(this.state.endDate).format('YYYY-MM-DD');

        if(typeof(this.props.values)!=="undefined"){
            Axios.post(`${name}/api/Customer/GetTransactions`,
        {
            customerNumber : this.props.values,
            dateFrom:stDate,
            dateTo:enDate
        },
        {
            headers:{
                "Token" : token
            }
        })
        .then(res=>{
            if(res.data.success){
                //console.log(res.data);
                this.setState({history:res.data.transactions,filteredhistory:res.data.transactions,historyPie:res.data.transactions,filteredhistoryPie:res.data.transactions});
                // this.setState({filteredhistory:res.data.transactions});  
                // this.setState({historyPie:res.data.transactions});
                // this.setState({filteredhistoryPie:res.data.transactions}); 
                console.log(1)                    
            }
            else{
                alert("???");
            }
        })
        .catch(error=>{
            if(error.response.status===401){
                delete_cookie("Bearer_Token");
                window.location.href = '/login';
              }
            //alert("ისტორიის წაკითხვა ვერ მოხერხდა");;
        })
        }
    }

    handleBarHistory=()=>{
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        if(typeof(this.props.values)!=="undefined"){
            Axios.post(`${name}/api/Customer/GetTransactions`,
        {
            customerNumber : this.props.values,
            // dateFrom:"",
            // dateTo:""
        },
        {
            headers:{
                "Token" : token
            }
        })
        .then(res=>{
            if(res.data.success){
                //console.log(res.data);
                this.setState({historyBar:res.data.transactions,filteredhistorybar:res.data.transactions});
                //this.setState({filteredhistoryPie:res.data.transactions});                     
            }
            else{
                alert("???");
            }
        })
        .catch(error=>{
            if(error.response.status===401){
                delete_cookie("Bearer_Token");
                window.location.href = '/login';
              }
            alert("ისტორიის წაკითხვა ვერ მოხერხდა");;
        })
        }
    }

    renderLabels=()=>{
        // let lbs=['იანვარი','თებერვალი','მარტი','აპრილი','მაისი','ივნისი','ივლისი','აგვისტო','სექტემბერი','ოქტომბერი','ნოემბერი','დეკემბერი'];
        let labels = [];
        this.state.history.map((history,index)=>{
            if(history.operId===125){
                let dt = history.operDate;
                dt = moment(dt).format('yyyy-MM-DD');
                labels.push(dt);
            }
        })
        return labels;
    }

    renderData(year){
        let data=[];
        //console.log(this.state.data);
        this.state.history.map((history,index)=>{
            if(history.operId===125){
                data.push(history.consumption);
            }
        })
        // this.state.data.map((history,index)=>{
        //     history.map((hs,ind)=>{
        //         if(index===year){
        //             //data.push(hs.length);
        //             for(var a=0;a<hs.length;a++){
        //                 hs[a].map((h,i)=>{
        //                     data.push(h.consumption);
        //                 })
        //             }
        //         }
        //     })
        // })
        // this.state.filteredhistory.map((history,index)=>{
        //     if(history.OPER){
        //         data.push(history.CONSUMPTION);
        //     }
        // });
        //console.log(data);
        return data;
    }

    filter(){
      
    }

    setStartDate=(date)=>{
        if(date){
            let a=moment(date).toDate()
            this.setState({startDate:a},function() {
                this.handleHistory();
              });
 
        }
        else{
            this.setState({startDate:null});
        }     
        
    }

    setEndDate=(date)=>{
        this.setState({endDate:date});
    }

    setPiestartDate=(date)=>{
        if(date){
            let a=moment(date).toDate()
            this.setState({pieStartDate:a});
        }
        else{
            this.setState({pieStartDate:null});
        }
    }

    setPieEndDate=(date)=>{
        this.setState({pieEndDate:date});
    }

    handleChange = (event) => {
        this.setState({ value:event.value });
    };

    renderOptions(){
        let options = [];

        this.state.years.map((year,index)=>{
            let item = {};
            item.value=index;
            item.label=year;
            options.push(item);
        });
        return options;
    }

    renderPieLabels(){
        let labels = ['გაზაფხული','ზაფხული','შემოდგომა','ზამთარი'];

        return labels;
    }

    renderPieData(){
        let data = [];

        let spring=0;
        let summer=0;
        let fall=0;
        let winter=0;

        this.state.history.map((h,i)=>{
            let dt=h.operDate;
            let month = moment(dt).format("MM");
            if(month==="03"||month==="04"||month==="05"){
                spring+=h.consumption;
            }
            else if(month==="06"||month==="07"||month==="08"){
                summer+=h.consumption;
            }
            else if(month==="09"||month==="10"||month==="11"){
                fall+=h.consumption;
            }
            else{
                winter+=h.consumption;
            }
        })
        data.push(spring);
        data.push(summer);
        data.push(fall);
        data.push(winter);
        return data;
    }

    renderBarLabels(){
        let labels=[];

        this.state.historyBar.map((h,i)=>{
            let dt=h.operDate;
            let year=moment(dt).format("YYYY");
            //console.log(year);
            labels.push(year);
        });
        
        labels = Array.from(new Set(labels));
        labels = labels.reverse();

        return labels;
    }

    renderBarData(){
        let data=[];

        let years = this.renderBarLabels();
        //console.log(years);
        let historybar = this.state.historyBar;
        let history = [];
        historybar.map((h,i)=>{
            if(h.operId===125){
                history.push(h);
            }
            //console.log(h);
        });
        //console.log(history);
        //console.log(typeof(this.state.historyBar));
        let historyLength = history.length;
        for(var j=0;j<years.length;j++){
            let cons = 0;
            let cnt=0;
            for(var i=0;i<historyLength;i++){
                let dt=history[i].operDate;
                let year=moment(dt).format("YYYY");
                if(year===years[j]){
                    cons+=history[i].consumption;
                    cnt++;
                }
            }
            //console.log([years[j],cnt,cons]);
            var n=cons/cnt;
            n=Math.round(n);
            data.push(n);
            //console.log(data);
        }

        return data;
    }

    render() { 
        console.log([this.state.startDate,this.state.endDate],this.state.history)
        const chartData = {
            labels:this.renderLabels(),
            datasets:[{
                data:this.renderData(this.state.value),
                label:"მოხმარება",
                pointBackgroundColor:"rgba(38, 34, 98)",
                borderColor:"rgba(179,181,198,1)",
                showLine:true,
            }],           
        }

        const pieData = {
            labels:this.renderPieLabels(),
            datasets:[{
                data:this.renderPieData(),
                backgroundColor:['#9da5b1','#768192','#4f5d73','#262262'],
                hoverBackgroundColor:['#9da5b1','#768192','#4f5d73','#262262']
            }]
        }

        const barData = {
            labels:this.renderBarLabels(),
            datasets:[{
                data:this.renderBarData(),
                label:"საშუალო წლიური მოხმარება",
                backgroundColor:"rgba(38, 34, 98, 1)"
            }]
        }
        //let options = this.renderOptions();
    //console.log(chartData);
    //console.log(pieData);
    //console.log(barData);
        return ( 
            <React.Fragment>
                <div className="row" style={{fontFamily:"BPGCond"}}>
                    <div className="col-lg-12">
                        <div className="row ">
                            <div className="col-md-3 col-lg-2">
                                <label style={{whiteSpace:"nowrap"}}><strong>საწყისი დრო</strong></label>
                                <DatePicker selected={this.state.startDate} onChange={this.setStartDate} isClearable dateFormat="dd.MM.yyyy" showMonthDropdown showYearDropdown></DatePicker>
                            </div>
                            <div className="col-md-3 col-lg-2">
                                <label style={{whiteSpace:"nowrap"}}><strong>საბოლოო დრო</strong></label>
                                <DatePicker className="datepicker100percent" selected={this.state.endDate} onChange={this.setEndDate} isClearable dateFormat="dd.MM.yyyy" showMonthDropdown showYearDropdown></DatePicker>
                            </div>                        
                            <div className="col-md-3 col-lg-2">
                                <div className="col-sm-12" style={{top:"40%"}}>
                                    <button className="btn btn-primary" onClick={this.handleHistory} type="button" style={{backgroundColor:"#262262",borderColor:"#262262"}}>ნახვა</button>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-4"></div>
                        </div>
                        <br></br>
                    </div>  
                    {/* <br/><br/><br/><br/><br/><br/> */}
                    <div className="col-lg-12 ">
                        <div className="row ">
                            <div className="col-md-12 col-sm-12 col-lg-6">
                                <div className="card">
                                    <div className="card-header">მოხმარება</div>
                                    <div className="card-body">
                                        <Line data={chartData}></Line> 
                                    </div>
                                </div>
                            </div> 
                            <div className="col-md-12 col-sm-12 col-lg-6">
                                <div className="card">
                                    <div className="card-header">ჯამური სეზონური მოხმარება</div>
                                    <div className="card-body">
                                        <Pie data={pieData} height={"347%"} options={{ maintainAspectRatio: false }}></Pie>
                                    </div>
                                </div>                               
                            </div>  
                        </div>
                    </div>                             
                </div>
                <br></br>
                <div className="row" style={{fontFamily:"BPGCond"}}>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-6">
                                <div className="card">
                                    <div className="card-header">თვის საშუალო მოხმარება წლების მიხედვით</div>
                                    <div className="card-body">
                                        <Bar data={barData}></Bar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
         );
    }
}
 
export default Charts;