import React, { Component } from "react";
import "../css/style.css";
import "../css/fontawesome-free-5.13.0-web/css/all.css";
import { Link } from "react-router-dom";
import Loader from "./loader";
import "../fonts/stylesheet.css";
import TextMask from "react-text-mask";
import emailMask from "text-mask-addons/src/emailMask";
import MaskedInput from "react-text-mask";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from "react-bootstrap";
import SignUpHelp from "./signupHelp";
import RegistrationTerms from "./RegistrationTermsModal";
// import { zIndex } from "material-ui/styles";
// import { BrowserRouter as Link } from "react-router-dom"; //Router, Switch, Route, Link, BrowserRouter

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      namefocus: false,
      surnamefocus: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.handleClick();
    }
  }

  handleHelpModal = () => {
    this.setState({ show: !this.state.show });
  };

  handleClick() {
    const formIsValid = this.props.formValidate(this.props, 1);

    if (formIsValid) {
      this.props.nextStep();
    } else {
      //console.log(this.props.values);
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
  }

  componentDidMount() {
    this.setState({ loading: false });
    // document.getElementById("fullname").style.fontFamily="BPGCond";
  }

  handleNameFocus = () => {
    this.setState({ namefocus: !this.state.namefocus });
  };

  handleSurnameFocus = () => {
    this.setState({ surnamefocus: !this.state.surnamefocus });
  };

  render() {
    const { values, handleChange, handleCheckChange } = this.props;
    //console.log(this.props);
    //console.log(values);
    let styleObj = {
      display: "flex",
      alignItems: "center",
      width: "0.75rem",
      height: "0.75rem",
      //fontSize: '1rem',
      flexDirection: "row",
      //textAlign: 'center',
      whiteSpace: "nowrap",
      color: "inherit",
      //verticalAlign:'middle',
      /* align-items: center; */
      fill: "currentColor",
      backgroundColor: "#ebedef",
    };

    let styleEmail = {
      display: "flex",
      //alignItems: 'center',
      width: "0.75rem",
      height: "0.75rem",
      fontSize: "0.75rem",
      flexDirection: "row",
      //textAlign: 'center',
      whiteSpace: "nowrap",
      color: "inherit",
      //verticalAlign:'middle',
      /* align-items: center; */
      fill: "currentColor",
      backgroundColor: "#ebedef",
    };

    var obj = document.getElementById('personalnumber');
        if(values.isCitizen){
          
          if(typeof(obj)!=="undefined"&&obj){
            if(obj.type!==null){
              obj.type='number';
            }
          }
        }
        else{
          if(typeof(obj)!=="undefined"){
            if(obj.type!==null){
              obj.type='text';
            }
          }
        }
    //console.log([values.agreeToTerms,values.recaptchaCode]);
    return (
      // values.loading?(
      <React.Fragment>
        <div
          className="c-app flex-row align-items-center"
          style={{ fontFamily: "BPGCond" }}
          onKeyDown={(event) => {
            this.handleKeyDown(event);
          }}
        >
          <div className="container">
            {/* <div style={{textAlign:"right"}}>
                    <button className="btn btn-outline-primary" type="button" onClick={this.handleHelpModal}><i className="fas fa-question"></i></button>
                  </div> */}
            <Modal
              centered
              show={this.state.show}
              onHide={this.handleHelpModal}
              dialogClassName="modal-90w"
            >
              <Modal.Header closeButton style={{ fontFamily: "BPGCaps" }}>
                <strong>დახმარება</strong>
              </Modal.Header>
              <Modal.Body>
                <SignUpHelp></SignUpHelp>
              </Modal.Body>
            </Modal>
            
            <Modal centered show={values.TermsModal} onHide={()=>handleCheckChange("TermsModal")} dialogClassName="modal-90w">
                <Modal.Header closeButton style={{ fontFamily: "BPGCaps" }}><strong>წესები და პირობები</strong></Modal.Header>
                  <Modal.Body>
                    <RegistrationTerms/>
                  </Modal.Body>
            </Modal>

            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card-group">
                  {values.loading ? <Loader/> : null}
                  <div className="card p-4">
                    <div
                      className="card-header"
                      style={{ textAlign: "center" }}
                    >
                      <span className="float-right">
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={this.handleHelpModal}
                        >
                          <i className="fas fa-question"></i>
                        </button>
                      </span>
                      <h1>რეგისტრაცია</h1>
                    </div>
                    <div className="card-body">
                      <div className="input-group mb-3">
                        <label><input type="radio" checked={values.isCitizen} onChange={()=>handleCheckChange("isCitizen")}></input>&nbsp;საქართველოს მოქალაქე</label>&nbsp;
                        <label><input type="radio" checked={!values.isCitizen} onChange={()=>handleCheckChange("isCitizen")}></input>&nbsp;არა საქართველოს მოქალაქე</label>
                      </div>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="far fa-user" />
                            </div>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          onFocus={() => this.handleNameFocus()}
                          onBlur={() => this.handleNameFocus()}
                          type="text"
                          id="fullname"
                          placeholder="სახელი ქართულად"
                          name="firstname"
                          onChange={handleChange("firstname")}
                          value={values.firstname}
                          autoComplete="off"
                        />
                        <div>
                          <span className="ast">*</span>
                        </div>
                      </div>
                      {this.state.namefocus ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          პირადობის მოწმობის მიხედვით
                        </label>
                      ) : null}
                      {values.firstnameerror ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          {values.firstnameerror}
                        </label>
                      ) : null}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="fas fa-user" />
                            </div>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          onFocus={() => this.handleSurnameFocus()}
                          onBlur={() => this.handleSurnameFocus()}
                          type="text"
                          placeholder="გვარი ქართულად"
                          name="lastname"
                          onChange={handleChange("lastname")}
                          value={values.lastname}
                          autoComplete="off"
                        />
                        <div>
                          <span className="ast">*</span>
                        </div>
                      </div>
                      {this.state.surnamefocus ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          პირადობის მოწმობის მიხედვით
                        </label>
                      ) : null}
                      {values.lastnameerror ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          {values.lastnameerror}
                        </label>
                      ) : null}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="fas fa-map-marker-alt"></i>
                            </div>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="საკონტაქტო მისამართი"
                          name="address"
                          onChange={handleChange("address")}
                          value={values.address}
                          autoComplete="off"
                        ></input>
                        <div>
                          <span className="ast">*</span>
                        </div>
                      </div>
                      {values.addresserror ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          {values.addresserror}
                        </label>
                      ) : null}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleEmail}>
                              <i className="far fa-envelope"></i>
                            </div>
                          </span>
                        </div>
                        <TextMask
                          className="form-control"
                          placeholder="ელ. ფოსტა"
                          name="email"
                          onChange={handleChange("email")}
                          value={values.email}
                          mask={emailMask.mask}
                          pipe={emailMask.pipe}
                        />
                      </div>
                      {values.emailerror ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          {values.emailerror}
                        </label>
                      ) : null}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="fas fa-user"></i>
                            </div>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="მომხმარებლის სახელი"
                          name="username"
                          onChange={handleChange("username")}
                          value={values.username}
                          autoComplete="off"
                        />
                        <div style={{ alignItems: "center" }}>
                          <span className="ast">*</span>
                        </div>
                      </div>
                      {values.usernameerror ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          {values.usernameerror}
                        </label>
                      ) : null}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="fas fa-phone-square-alt"></i>
                            </div>
                          </span>
                        </div>
                        <MaskedInput
                          mask={[
                            "(",
                            /[0-9]/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          guide={true}
                          className="form-control"
                          type="text"
                          placeholder="მობილურის ნომერი"
                          name="phone"
                          onChange={handleChange("phonenumber")}
                          value={values.phonenumber}
                          autoComplete="off"
                        ></MaskedInput>
                        <br></br>
                        {/* <input 
                              // mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]} guide={true}
                              className="form-control" type="text" placeholder="მობილურის ნომერი" name="phone" onChange={handleChange("phonenumber")} value={values.phonenumber}/> */}
                        <span className="ast">*</span>
                      </div>
                      {values.phonenumbererror ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          {values.phonenumbererror}
                        </label>
                      ) : null}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="fas fa-id-card"></i>
                            </div>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="number"
                          placeholder="პირადი ნომერი"
                          name="personalnumber"
                          id="personalnumber"
                          onChange={handleChange("personalnumber")}
                          value={values.personalnumber}
                        />
                        <span className="ast">*</span>
                      </div>
                      {values.personalnumbererror ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          {values.personalnumbererror}
                        </label>
                      ) : null}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="fas fa-lock"></i>
                            </div>
                          </span>
                        </div>

                        <input
                          className="form-control"
                          type="password"
                          placeholder="პაროლი"
                          onChange={handleChange("password")}
                          value={values.password}
                        />
                        <span className="ast">*</span>
                      </div>
                      {values.passworderror ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          {values.passworderror}
                        </label>
                      ) : null}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="fas fa-lock"></i>
                            </div>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="password"
                          placeholder="გაიმეორეთ პაროლი"
                          onChange={handleChange("repassword")}
                          value={values.repassword}
                        />
                        <span className="ast">*</span>
                      </div>
                      {values.repassworderror ? (
                        <label style={{ fontSize: 12, color: "#e55353" }}>
                          {values.repassworderror}
                        </label>
                      ) : null}
                      <div className="row justify-content-center">
                        <div className="col-md-8">
                          <label>
                            <input type="checkbox" style={{verticalAlign:"middle"}} checked={values.agreeToTerms} onChange={()=>handleCheckChange("agreeToTerms")} />
                              &nbsp;ვეთანხმები
                          </label>
                            <span style={{color:"#262262",cursor:"pointer"}} onClick={()=>handleCheckChange("TermsModal")}>&nbsp;<strong>წესებსა და პირობებს</strong></span>
                        </div>
                        <div className="col-md-8">
                          <ReCAPTCHA
                            sitekey={values.siteKey}
                            onChange={handleChange("recaptchaCode")}
                            hl="ka"
                          ></ReCAPTCHA>
                          {values.recaptchaCodeerror ? (
                            <label style={{ fontSize: 12, color: "#e55353" }}>
                              {values.recaptchaCodeerror}
                            </label>
                          ) : null}
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="col-md-12">
                          <button
                            className="btn btn-block btn-success"
                            type="button"
                            disabled={
                              values.recaptchaCode&&values.agreeToTerms? false : true
                            }
                            onClick={this.handleClick}
                            style={{
                              backgroundColor: "#262262",
                              borderColor: "#262262",
                            }}
                          >
                            რეგისტრაცია
                          </button>
                        </div>
                        <div
                          className="col-md-12"
                          style={{ textAlign: "center" }}
                        >
                          <br></br>
                          <Link
                            to={"/login"}
                            style={{ textDecoration: "none" }}
                          >
                            ავტორიზაცია
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
