import React, { Component } from 'react';
import '../../../css/customer.css';
import {Button,Modal} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { read_cookie,delete_cookie } from 'sfcookies';
import Axios from 'axios';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
// import MyLetters from '../MyLetters';
import swal from 'sweetalert2';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
//import { Redirect } from 'react-router-dom';

class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showAddPhone:false,
            showPopup:false,
            showNotify:false,
            beforechange:"",
            addPhone:"",
            addPhoneerror:"",
            smscode:"",
            smscodeerror:"",
            toVerify:"",
            showAddEmail:false,
            showEmailVerify:false,
            addEmail:"",
            addEmailerror:"",
            mailCode:"",
            mailCodeerror:"",
            emailToVerify:"",
            pdfUrl:"",
            disabled:false,
            duration:180,
            comment:"",
            key:0,
            notificationTitle:"",
            accidentTypeId:1
        }
        this.handleSwalPopup=props.handleSwalPopup;
    }

    renderTime = ({ remainingTime }) => {
      
        if (remainingTime === 0) {
          return <div ></div>;
        }
        
        var minutes = Math.floor(remainingTime/60);
        var secondes = remainingTime%60;
    
        return (
          <div className="timer">
            <div>{minutes}:{secondes}</div>
          </div>
        );
    };

    handlePhoneClick = () => {
        //console.log(1);
        this.setState({showAddPhone:!this.state.showAddPhone});
    }

    handleEmailClick=()=>{
        this.setState({showAddEmail:!this.state.showAddEmail});
    }

    handlePrepare(phone){
        this.setState({beforechange:phone});
    }

    handleNotify = (accidentType) => {
        //console.log(accidentType)
        this.setState({showNotify:!this.state.showNotify,accidentTypeId:accidentType,comment:""});
    }

    handlePhoneChange = (input) => (e) => {      
        //console.log(e.target.value);
        this.setState({ [input]: e.target.value });;
    };

    handlePopup = () => {
        this.setState({showPopup:!this.state.showPopup});
    }

    handleChange = (input) => (e) => {
        this.setState({ [input]: e.target.value });
    };

    handleEmailVerifyShow=()=>{
        this.setState({showEmailVerify:!this.state.showEmailVerify});
    }

    handlePhoneAdd = () => {
        const token = read_cookie("Bearer_Token");

        const isValid = this.phoneEditValidate();
        const name = window.$name;
        if(isValid){
            //this.handlePhoneClick();
            //this.handlePopup();
            const custN = this.props.values.custNumber+"-"+this.props.values.controlNumber;
            //console.log([custN,this.state.addPhone]);
            Axios.post(`${name}/api/Customer/AddPhone`,
            {
                customerNumber:custN,
                info:this.state.addPhone
            },
            {
                headers:{
                    "Token":token
                }
            })
            .then(res=>{
                if(res.data.success){
                    this.setState({toVerify:this.state.addPhone});
                    this.handlePhoneClick();
                    this.handlePopup();
                    this.handleSwalPopup(`ერთჯერადი კოდი გამოგზავნილია ${this.state.addPhone}-ზე`,"success",false,"","დახურვა",false,"#757575");
                }
                else{
                    this.handleSwalPopup("ნომრის დამატება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                }
            })
            .catch(error=>{
                //console.log(error);
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                }
                else{
                    this.handleSwalPopup("ნომრის დამატება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                }
                //alert(error);
            });
        }
    }

    handleResendtoPhone(phone){
        const token = read_cookie("Bearer_Token");
        //console.log(phone);
        const custN = this.props.values.custNumber+"-"+this.props.values.controlNumber;
        //this.setState({toVerify:phone});
        const name = window.$name;
        if(phone){
            Axios.post(`${name}/api/Customer/AddPhone`,
        {
            customerNumber:custN,
            info:phone
        },
        {
            headers:{
                "Token":token
            }
        })
        .then(res=>{
            //console.log(1);
            if(res.data.success){
                store.addNotification({
                    title: "Success",
                    message: "კოდი გამოგზავნილია",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                //this.setState({toVerify:this.state.addPhone});
                }
                else{
                    //console.log(res);
                    store.addNotification({
                        title: "შეცდომა",
                        message: res.data.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true
                        }
                      });
                }
            })
            .catch(error=>{
                //console.log(error);
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                  }
                store.addNotification({
                    title: "შეცდომა",
                    message: error.data.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
            })
        }
        
    }

    handleResendToEmail(email){
        //console.log(1);
        const token = read_cookie("Bearer_Token");
        //console.log(1);
        const custN = this.props.values.custNumber+"-"+this.props.values.controlNumber;
        //this.setState({emailToVerify:email});
        const name = window.$name;
        if(email){
            Axios.post(`${name}/api/Customer/AddEmail`,
        {
            customerNumber:custN,
            info:email
        },
        {
            headers:{
                "Token":token
            }
        })
        .then(res=>{
            if(res.data.success){
                this.setState({disabled:true,key:this.state.key+1,duration:180});
                store.addNotification({
                    title: "Success",
                    message: "კოდი გამოგზავნილია",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                //this.setState({toVerify:this.state.addPhone});
                }
                else{
                    //console.log(res);
                    store.addNotification({
                        title: "შეცდომა",
                        message: res.data.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true
                        }
                      });
                }
            })
            .catch(error=>{
                //console.log(error.response);
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                  }
                store.addNotification({
                    title: "შეცდომა",
                    message: error.response.statusText,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
            })
        }
    }

    handleEmailAdd = () => {
        const token = read_cookie("Bearer_Token");

        const isValid = this.emailFormValidate();
        //console.log(isValid);
        const name = window.$name;
        if(isValid){
            const custN = this.props.values.custNumber+"-"+this.props.values.controlNumber;
            Axios.post(`${name}/api/Customer/AddEmail`,
            {
                customerNumber:custN,
                info:this.state.addEmail
            },
            {
                headers:{
                    "Token":token
                }
            })
            .then(res=>{
                //console.log(res);
                if(res.data.success){
                    this.setState({emailToVerify:this.state.addEmail});
                    this.handleEmailClick();
                    this.handleEmailVerifyShow();
                    this.handleSwalPopup("ელ. ფოსტა დამატებული და კოდი გამოგზავნილია. ერთჯერადი კოდი ვალიდურია 5 წუთი","success",false,"","დახურვა",false,"#757575");
                    //window.location.reload();
                }
                else{
                    this.handleSwalPopup("ელ. ფოსტის დამატება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                }
            })
            .catch(error=>{
                if(error.response.code===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                }
                else{
                    this.handleSwalPopup("ელ. ფოსტის დამატება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                }
                
            });
        }
    }

    handleVerify = () => {
        const token = read_cookie("Bearer_Token");
        const isValid = this.smsFormValidate();
        const name = window.$name;
        if(isValid){
            //console.log([this.state.toVerify,this.state.smscode]);
            Axios.post(`${name}/api/Customer/VerifyPhone`,
            {
                "mobilePhone":this.state.toVerify,
                "smsCode":this.state.smscode
            },
            {
                headers:{
                    "Token":token
                }
            })
            .then(res=>{
                //console.log(res);
                if(res.data.success){
                   this.handleSwalPopup("ნომერი ვერიფიცირებულია","success",false,"","დახურვა",true,"#757575");
                }
                else{
                    this.handleSwalPopup("ნომრის ვერიფიცირება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                }
            })
            .catch(error=>{
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                }
                else{
                    this.handleSwalPopup("ნომრისს ვერიფიცირება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                }
            });
        }
    }

    handleEmailVerify=()=>{
        const token = read_cookie("Bearer_Token");
        const isValid = this.smsFormValidate();
        //console.log(2);
        const name = window.$name;
        if(isValid){
            Axios.post(`${name}/api/Customer/VerifyEmail`,
            {
                email:this.state.emailToVerify,
                smsCode:this.state.smscode
            },
            {
                headers:{
                    "Token":token
                }
            })
            .then(res=>{
                //console.log(res);
                if(res.data.success){
                    this.handleSwalPopup("ელ. ფოსტა ვერიფიცირებულია","success",false,"","დახურვა",true,"#757575");
                }
                else{
                    this.handleSwalPopup("ნომერი ვერიფიცირებულია","success",false,"","დახურვა",false,"#757575");
                }
            })
            .catch(error=>{
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                  }
                else{
                    this.handleSwalPopup("ნომერი ვერიფიცირებულია","success",false,"","დახურვა",false,"#757575");
                }
            })
        }
    }

    handlePhoneRemove=(phone)=>{
        const token = read_cookie("Bearer_Token");

        const custN = this.props.values.custNumber+"-"+this.props.values.controlNumber;
        //console.log(custN);
        //console.log(phone);
        const name = window.$name;
        Axios.post(`${name}/api/Customer/DeletePhone`,
        {
            customerNumber:custN,
            info:phone.info
        },
        {
            headers:{
                "Token":token
            }
        })
        .then(res=>{
            if(res.data.success){
                this.handleSwalPopup("ნომერი ამოშლილია","success",false,"","დახურვა",true,"#757575");
            }
            else{
                this.handleSwalPopup("ნომერის ამოშლა ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
            }
        })
        .catch(error=>{
            if(error.response.status===401){
                delete_cookie("Bearer_Token");
                window.location.href = '/login';
            }
            else{
                this.handleSwalPopup("ნომერის ამოშლა ვერ მოხერხდა","danger",false,"","დახურვა",false,"#757575");
            }
        })
    }

    handleEmailRemove=(email)=>{
        const token = read_cookie("Bearer_Token");

        const custN = this.props.values.custNumber+"-"+this.props.values.controlNumber;
        //console.log(email);
        //console.log(custN);
        const name = window.$name;
        Axios.post(`${name}/api/Customer/DeleteEmail`,
        {
            customerNumber:custN,
            info:email
        },
        {
            headers:{
                "Token":token
            }
        })
        .then(res=>{
            if(res.data.success){
                store.addNotification({
                    title: "Success",
                    message: "E-Mail ამოშლილია",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                window.location.reload();
            }
            else{
                //console.log(res);
                store.addNotification({
                    title: "შეცდომა",
                    message: "შეცდომა",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
            }
        })
        .catch(error=>{
            store.addNotification({
                title: "შეცდომა",
                message: error,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
              });
        })
    }

    phoneEditValidate = () => {
        let isValid = true;
        //console.log(1);
        if(this.state.addPhone.length!==9){
            isValid = false;
            this.setState({addPhoneerror:"კოდი უნდა იყოს ოთხნიშნა"});
          }

          if(this.state.addPhone.match(/\/[0-9]/)){
            isValid = false;
            this.setState({addPhoneerror:"შეიყვანეთ მხოლოდ ციფრები"});
          }

          if(this.state.addPhone===""){
              isValid = false;
              this.setState({addPhoneerror:"აუცილებელია კოდის შეყვანა"});
          }

        return isValid;
    }

    smsFormValidate = () => {
        
        let isValid = true;

        if(!this.state.toVerify&&!this.state.emailToVerify){
            isValid = false;
        }

        if(this.state.smscode.length!==4){
          isValid = false;
          this.setState({smscodeerror:"კოდი უნდა იყოს ოთხნიშნა"});
        }

        if(this.state.smscode.match(/\/[0-9]/)){
          isValid = false;
          this.setState({smscodeerror:"შეიყვანეთ მხოლოდ ციფრები"});
        }

        if(this.state.smscode===""){
            isValid = false;
            this.setState({smscodeerror:"აუცილებელია კოდის შეყვანა"});
        }

        return isValid;
    }

    emailFormValidate=()=>{
        let isValid = true;

        if(this.state.addEmail===""){
            isValid=false;
            this.setState({addEmailerror:"აუცილებელია E-Mail-ის  შეყვანა"});
            //console.log(this.state.addEmail);
        }
        //console.log(isValid);
        return isValid;
    }

    swalConfirm = () =>{
        if(this.state.comment===""){
            swal.fire({
                html:`<span style="font-family:BPGCond">შეავსეთ კომენტარის ველი</span>`,
                icon:"warning",
                confirmButtonText:'<span style="font-family:BPGCond">დახურვა</span>',
                confirmButtonColor:"#262262"
            })
        }
        else{
            swal.fire({
                html:`<span style="font-family:BPGCond">ვადასტურებ, რომ რეალურად არის პრობლემა</span>`,
                showCancelButton:true,
                cancelButtonText:'<span style="font-family:BPGCond">არა</span>',
                confirmButtonText:'<span style="font-family:BPGCond">დიახ</span>',
                confirmButtonColor:"#262262",
                icon:"warning"
            })
            .then((result)=>{
                if(result.isConfirmed){
                    //this.SendNotification();
                }
            })
        }
    }

    SendNotification = () => {
        const token = read_cookie("Bearer_Token");
        const custN = this.props.values.custNumber;
        const name = window.$name;
        const address = this.props.values.address;
        //console.log([custN,address,this.state.accidentTypeId,this.state.comment])
        Axios.post(`${name}/api/Customer/AccidentAdd`,
        {
            customerNumber:custN,
            address:address,
            accidentTypeId:this.state.accidentTypeId,
            comment:this.state.comment
        },
        {
            headers:{
                "token":token
            }
        })
        .then(res=>{
            //console.log(res);
            if(res.data.success){
                this.handleNotify();
                this.handleSwalPopup("შეტყობინება გაგზავნილია","success",false,"","დახურვა",false,"#757575");
            }
            else{
                this.handleSwalPopup("შეტყობინების გაგზავნა ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
            }
        })
        .catch(error=>{
            //console.log(1);
            this.handleSwalPopup("შეტყობინების გაგზავნა ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
        })
    }

    exportDebtReport=(url)=>{
        const name = window.$name;
        window.open(`${name}/`+url,'_self');
    }

    exportRegistrationInquiry=(url)=>{
        const name = window.$name;
        window.open(`${name}/`+url,'_self');
    }

    onValueChange=(event)=> {
        this.setState({
            accidentTypeId: event.target.value
        });
    }

    render() { 

        const values = this.props.values;
        //console.log(this.state.accidentTypeId);
        //const totalProps = values.emails.reduce((a, obj) => a + Object.keys(obj).length, 0);
        //console.log([this.state.emailToVerify,this.state.smscode]);

        return ( 
            <React.Fragment>     
                <Modal show={this.state.showPopup} onHide={this.handlePopup} centered>
                    <Modal.Header style={{fontFamily:"BPGCond"}}><strong>ნომრის ვერიფიკაცია</strong></Modal.Header>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">SMS Code</label>
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-11"><input className="form-control" id="smscode" name="smscode" value={this.state.smscode} onChange={this.handlePhoneChange("smscode")}></input></div>
                                        <div className="col-md-1"><i className="fas fa-undo-alt" title="თავიდან გამოგზავნა" style={{cursor:"pointer",position:"absolute",left:"10%",top:"25%"}} onClick={()=>this.handleResendtoPhone(this.state.toVerify)}></i></div>
                                        <br></br>
                                        {
                                            this.state.smscodeerror ?
                                            (
                                                <label style={{fontSize: 12,color:"#e55353"}}>{this.state.smscodeerror}</label>
                                            )
                                            :null
                                        }
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-success" onClick={this.handleVerify} style={{fontFamily:"BPGCond"}}>ვერიფიკაცია</Button>
                        {/* <Button className="btn btn-primary">თავიდან გამოგზავნა</Button> */}
                        <Button onClick={this.handlePopup} style={{fontFamily:"BPGCond"}}>დახურვა</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showEmailVerify} onHide={this.handleEmailVerifyShow} centered>
                    <Modal.Header style={{fontFamily:"BPGCond"}}><strong>E-Mail-ის ვერიფიკაცია</strong></Modal.Header>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label" style={{fontFamily:"BPGCond"}}>Code</label>
                                <div className="col-md-9">
                                    <input className="form-control" value={this.state.smscode} onChange={this.handlePhoneChange("smscode")}></input>                                    
                                    <br></br>
                                    {
                                        this.state.smscodeerror ?
                                        (
                                            <label style={{fontSize: 12,color:"#e55353"}}>{this.state.smscodeerror}</label>
                                        )
                                        :null
                                    }
                                </div>
                                <div className="col-md-12">
                                    <div className="row" style={{float:"right"}}>
                                    <button className="btn btn-link px-0" title="თავიდან გამოგზავნა" disabled={!this.state.disabled||this.state.key===0?false:true} style={{cursor:"pointer"}} onClick={()=>this.handleResendToEmail(this.state.emailToVerify)}>თავიდან გამოგზავნა</button>
                                  &nbsp;
                                    {/* <i className="fas fa-undo-alt" title="თავიდან გამოგზავნა" disabled={!this.state.disabled||this.state.key===0?false:true} style={{cursor:"pointer"}} onClick={()=>this.handleResendToEmail(this.state.emailToVerify)}></i> */}
                                    <div style={{visibility:!this.state.disabled?"hidden":"visible"}}>
                                    <CountdownCircleTimer isPlaying={this.state.disabled?true:false} duration={this.state.duration} size={35} strokeWidth={2.5}
                                      colors={[["#262262"]]}
                                      key={this.state.key}
                                      onComplete={() => this.setState({disabled:false})}
                                      
                                    >
                                      {this.renderTime}
                                    </CountdownCircleTimer>
                                  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-success" onClick={this.handleEmailVerify} style={{fontFamily:"BPGCond"}}>ვერიფიკაცია</Button>
                        {/* <Button className="btn btn-primary">თავიდან გამოგზავნა</Button> */}
                        <Button onClick={this.handleEmailVerifyShow} style={{fontFamily:"BPGCond"}}>დახურვა</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showAddPhone} onHide={this.handlePhoneClick} centered>
                    <Modal.Header style={{fontFamily:"BPGCond"}}><strong>მობილური ნომრის დამატება</strong></Modal.Header>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label" style={{fontFamily:"BPGCond"}}>ნომერი</label>
                                <div className="col-md-9">
                                    <input className="form-control" id="addPhone" name="addPhone" onChange={this.handlePhoneChange("addPhone")}></input>
                                    <br></br>
                                    {
                                        this.state.addPhoneerror ? 
                                        (
                                            <label style={{fontSize:12,color:"#e55353"}}>{this.state.addPhoneerror}</label>
                                        )
                                        :null
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary" onClick={this.handlePhoneAdd} style={{fontFamily:"BPGCond"}} type="button">დამატება</Button>
                        <Button className="btn btn-danger" onClick={this.handlePhoneClick} style={{fontFamily:"BPGCond"}} type="button">გაუქმება</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showAddEmail} onHide={this.handleEmailClick} centered>
                    <Modal.Header style={{fontFamily:"BPGCond"}}><strong>E-Mail-ის დამატება</strong></Modal.Header>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label" style={{fontFamily:"BPGCond"}}>E-Mail</label>
                                <div className="col-md-9">
                                    <input className="form-control" id="addEmail" name="addEmail" onChange={this.handlePhoneChange("addEmail")}></input>
                                    <br></br>
                            
                                    {
                                        this.state.addEmailerror?
                                        (
                                            <label style={{fontSize:12,color:"#e55353"}}>{this.state.addEmailerror}</label>
                                        )
                                        :null
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary" onClick={this.handleEmailAdd} style={{fontFamily:"BPGCond"}}>დამატება</Button>
                        <Button className="btn btn-danger" onClick={this.handleEmailClick} style={{fontFamily:"BPGCond"}}>გაუქმება</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showNotify} onHide={this.handleNotify} dialogClassName="modalClass" centered>
                    <Modal.Header style={{fontFamily:"BPGCond"}} closeButton><strong>{this.state.accidentTypeId===1?"შეტყობინება გაზის წყვეტაზე":"შეტყობინება წნევის პრობლემაზე"}</strong></Modal.Header>
                    <Modal.Body style={{fontFamily:"BPGCond"}}>
                        <div className="form-horizontal">
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-4 col-lg-4 col-form-label">აბონენტის ნომერი</label>
                                <div className="col-sm-12 col-md-8 col-lg-8">
                                    <input className="form-control" id="CustomerNumber" name="CustomerNumber" value={values.custNumber+'-'+values.controlNumber} readOnly></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-4 col-lg-4 col-form-label">მისამართი</label>
                                <div className="col-sm-12 col-md-8 col-lg-8">
                                    <input className="form-control" id="Address" name="Address" value={values.address} readOnly></input>
                                </div>
                            </div>
                            {
                                this.state.accidentTypeId!=1 && 
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 cl-lg-4 col-form-label">შემთხვევის ტიპი</label>
                                    <div className="col-sm-12 col-md-8 col-lg-8">
                                        {/* <input type="radio" id="accidentType1" name="accidentTypeId" value={1} onChange={(e)=>this.onValueChange(e)} defaultChecked></input>&nbsp;
                                        <label htmlFor="accidentType1">წყვეტის შეტყობინება</label>&nbsp; */}
                                        <input type="radio" id="accidentType2" name="accidentTypeId" value={2} onChange={(e)=>this.onValueChange(e)} defaultChecked></input>&nbsp;
                                        <label htmlFor="accidentType2">დაბალი წნევა</label>&nbsp;
                                        <input type="radio" id="accidentType3" name="accidentTypeId" value={3} onChange={(e)=>this.onValueChange(e)}></input>&nbsp;
                                        <label htmlFor="accidentType3">მაღალი წნევა</label>
                                    </div>
                                </div>
                            }
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-4 col-lg-4 col-form-label">კომენტარი</label>
                                <div className="col-sm-12 col-md-8 col-lg-8">
                                    <textarea className="form-control" id="comment" name="comment" value={this.state.comment} onChange={this.handleChange("comment")} rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary" onClick={this.swalConfirm} style={{fontFamily:"BPGCond"}}>გაგზავნა</Button>
                        <Button className="btn btn-danger" onClick={this.handleNotify} style={{fontFamily:"BPGCond"}}>გაუქმება</Button>
                    </Modal.Footer>
                </Modal>

                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-7 col-sm-12 col-lg-6">
                            <h4 style={{color:"#262262"}} >
                                <Link to="/dashboard/Customers" style={{textDecoration:"none"}}><i className="fas fa-arrow-circle-left" style={{cursor:"pointer",color:"#262262"}} title="უკან"></i>&nbsp;&nbsp;</Link>
                                <span className="middle" style={{fontFamily:"BPGCond"}}>#{values.custNumber+"-"+values.controlNumber}&nbsp;-&nbsp;{values.customerName}</span>
                                <span className="label label-purple arrowed-in-right">                        
                                </span>
                            </h4>

                        <div className="profile-user-info" style={{color:"#262262"}}>
                            <div className="profile-info-row col-md-7">
                                <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>მისამართი</strong></div>
                                <div className="profile-info-value" style={{fontFamily:"BPGCond"}}>
                                    <i className="fas fa-map-marker-alt" style={{color:"#262262"}} />
                                    &nbsp;
                                    <span style={{fontFamily:"BPGCond",wordBreak:"break-word"}}>{values.address}</span>
                                </div>
                            </div>

                            <div className="profile-info-row col-md-7">
                                <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>კატეგორია</strong></div>
                                <div className="profile-info-value" style={{fontFamily:"BPGCond"}}>
                                    &nbsp;
                                    <span style={{fontFamily:"BPGCond",wordBreak:"break-word"}}>{values.categoryName}</span>
                                </div>
                            </div>

                            <div className="profile-info-row col-md-7">
                                <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>საკადასტრო კოდი</strong></div>
                                <div className="profile-info-value" style={{fontFamily:"BPGCond"}}>
                                    &nbsp;                                   
                                    <span style={{fontFamily:"BPGCond",wordBreak:"break-word"}}>{values.realEstateCode}</span>
                                </div>
                            </div>

                            <div className="profile-info-row col-md-7">
                                <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>ტარიფი</strong></div>
                                <div className="profile-info-value" style={{fontFamily:"BPGCond"}}>
                                    &nbsp;
                                    <span style={{fontFamily:"BPGCond",wordBreak:"break-word"}}>{values.tariffValue}</span>
                                </div>
                            </div>
                              
                            <div className="profile-info-row col-md-7">
                                <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>პირადი #<br/>საიდენტიფიკაციო #</strong></div>
                                <div className="profile-info-value" style={{fontFamily:"BPGCond"}}>
                                    <span style={{fontFamily:"BPGCond",wordBreak:"break-word"}}>{values.personalN}</span>
                                </div>
                            </div>
                            
                            <div className="profile-info-row col-md-7">
                                <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>გეგმიური რევიზიის თვე</strong></div>
                                <div className="profile-info-value" style={{fontFamily:"BPGCond"}}>
                                    <span style={{fontFamily:"BPGCond",wordBreak:"break-word"}}>{values.scheduledRevisionDate}</span>
                                </div>
                            </div>
                            {
                                values.phones?
                                values.phones.map((phone,index)=>{
                                    return(
                                        index===0?
                                        <React.Fragment key={index}>
                                            <div className="profile-info-row col-md-7" >
                                                <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>მობილური (ძირითადი)</strong></div>
                                                <div className="profile-info-value" style={{fontFamily:"BPGCond"}}>
                                                    <span style={{fontFamily:"BPGCond",wordBreak:"break-word"}}>{phone.info}</span>
                                                    <div style={{float:"right"}}><i className="far fa-plus-square" title="ნომრის დამატება" style={{right:"10px",cursor:"pointer"}} onClick={this.handlePhoneClick}></i></div>   
                                                </div>                                                                           
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment key={index}>
                                            <div className="profile-info-row col-md-7" >
                                                <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>მობილური (ალტერნატიული)</strong></div>
                                                <div className="profile-info-value" style={{fontFamily:"BPGCond"}}>
                                                    <span style={{fontFamily:"BPGCond",wordBreak:"break-word"}}>{phone.info}</span>
                                                    <div style={{float:"right"}}><i className="fas fa-trash-alt" onClick={() => { 
                                                        swal.fire({title:"ნამდვილად გსურთ ნომრის ამოშლა?",showCancelButton:true,cancelButtonColor:"#757575",cancelButtonText:"არა",confirmButtonColor:"#262262"})
                                                        .then((value)=>{
                                                            if(value.isConfirmed){
                                                                this.handlePhoneRemove(phone) 
                                                            }
                                                        })
                                                        
                                                        }} style={{cursor:"pointer"}} title="ამოშლა"></i>
                                                    </div>
                                                </div>                                                         
                                            </div>      
                                        </React.Fragment> 
                                    )
                                })
                                :null
                            }
                            {                            
                                values.emails?
                                    (
                                        <React.Fragment>
                                            {
                                                values.emails.map((email,index)=>{
                                                    
                                                    return(
                                                        <div className="profile-info-row col-md-7" key={index}>
                                                            <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>ელ. ფოსტა</strong></div>
                                                            <div className="profile-info-value" >
                                                                <span style={{fontFamily:"BPGCond",wordBreak:"break-word"}}>{email.info}</span>
                                                                <div style={{float:"right"}}>
                                                                    <i className="fas fa-trash-alt" onClick={
                                                                        ()=>{
                                                                            swal.fire({html:`<span style="font-family:BPGCond;">ნამდვილად გსურთ ელ. ფოსტის ამოშლა?</span>`,icon:'question',confirmButtonText:`<span style="font-family:BPGCond;">დიახ</span>`,confirmButtonColor:"#262262",showCancelButton:true,cancelButtonText:`<span style="font-family:BPGCond;"ს>არა</span>`})
                                                                            .then((willdelete)=>{
                                                                                if(willdelete.isConfirmed)
                                                                                {this.handleEmailRemove(email.info)}
                                                                            })
                                                                        }
                                                                    } title="ამოშლა"></i>
                                                                </div>
                                                            </div>                                                                                                                                   
                                                        </div>                                      
                                                    )
                                                })   
                                            }
                                            <div className="profile-info-row col-md-7">
                                                <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>ელ. ფოსტა</strong></div>
                                                <div className="profile-info-value">
                                                    <span style={{float:"right"}}>
                                                    <i className="fas fa-plus-square" title="E-Mail დამატება" style={{cursor:"pointer"}} onClick={this.handleEmailClick}></i>
                                                    </span>
                                                </div>                                          
                                            </div> 
                                        </React.Fragment>
                                    )
                                    :
                                    <React.Fragment>
                                        <div className="profile-info-row col-md-7">
                                            <div className="profile-info-name" style={{fontFamily:"BPGCond",color:"#262262"}}><strong>ელ. ფოსტა</strong></div>
                                            <div className="profile-info-value">
                                                <span style={{float:"right"}}>
                                                <i className="fas fa-plus-square" title="E-Mail დამატება" style={{cursor:"pointer"}} onClick={this.handleEmailClick}></i>
                                                </span>
                                            </div>                                          
                                        </div> 
                                    </React.Fragment>
                                            
                            }    
                        
                        </div>

                                
                        </div> 
                        <div className="col-md-5 col-sm-12 col-lg-6">
                            <div className="btn-group-vertical" style={{float:"right"}}>
                                <Link to={{pathname:"/dashboard/WriteLetter",params:values}} style={{width:"100%"}}>
                                    <button className="btn btn-block btn-primary" type="button" style={{fontFamily:"BPGCond",textAlign:"left"}} ><span><i className="fas fa-plus-circle"></i></span>&nbsp;ახალი განაცხადი</button>
                                </Link>
                                <br></br>
                                <button className="btn btn-block btn-primary " style={{fontFamily:"BPGCond",textAlign:"left"}} type="button" onClick={()=>this.exportDebtReport(values.debtReportUrl)}><i className="fas fa-file-download"></i>&nbsp;ცნობა დავალიანების შესახებ</button>
                                <br></br>
                                <button className="btn btn-block btn-primary " style={{fontFamily:"BPGCond",textAlign:"left"}} type="button" onClick={()=>this.exportRegistrationInquiry(values.registrationReportUrl)}><i className="fas fa-file-download"></i>&nbsp;ცნობა აბონენტად რეგისტრაციის შესახებ</button>
                                <br/>
                                <button className="btn btn-block btn-primary" style={{fontFamily:"BPGCond",textAlign:"left",backgroundColor:"#662d90",borderColor:"#662d90"}} type="button" onClick={()=>this.handleNotify(1)}><i className="fas fa-bullhorn"></i>&nbsp;შეტყობინება გაზის წყვეტაზე</button>
                                <br/>
                                <button className="btn btn-block btn-primary" style={{fontFamily:"BPGCond",textAlign:"left",backgroundColor:"#662d90",borderColor:"#662d90"}} type="button" onClick={()=>this.handleNotify(2)}><i className="fas fa-bullhorn"></i>&nbsp;შეტყობინება წნევის პრობლემაზე</button>
                            </div>               
                        </div>   
                </div>       
                </div>
            </React.Fragment>
         );
    }
}

export default Customer;