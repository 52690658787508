import React, { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

class Letters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventKey: '',
      custN: '',
      hover: false,
      hoverId: '',
      popoverTarget: '',
      letterGroups: {},
      letterTypes: {},
      showWritePage: false,
    };
  }

  componentDidMount() {
    //console.log(this.props);
    //this.setState({letterTypes:this.props.values.lettertypes,letterGroups:this.props.values.letterGroups});
    //this.handleLoad();
  }
  UNSAFE_componentWillMount() {
    //console.log(this.props);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleSelect = (eventKey) => {
    this.setState({ eventKey: eventKey, showWritePage: !this.state.showWritePage });
  };

  handleMouseOver = (Id) => {
    this.setState({ hoverId: Id });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  handleHover = () => {
    if (this.state.hover !== true) {
      this.setState({ hover: true });
    }
  };

  togglePopover = (target) => {
    this.setState({
      ...this.state,
      [target]: !this.state[target],
    });
  };

  handleLoad = () => {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    Axios.post(
      `${name}/api/Letters/GetLetterTypes`,
      {
        customerNumber: '',
      },
      {
        headers: {
          Token: token,
        },
      }
    )
      .then((res) => {
        //console.log(res);
        if (res.data.success) {
          let data = [];
          for (var i = 0; i < res.data.letterGroups.length; i++) {
            let temp = res.data.letterGroups[i].refLetterTypes;
            //console.log(temp.length);
            for (var j = 0; j < temp.length; j++) {
              var tmp = temp[j];
              //console.log(tmp);
              data.push(tmp);
            }
          }
          this.setState({ letterGroups: res.data.letterGroups, letterTypes: data });
        } else {
          alert('მონაცემების ჩატვირთვა ვერ მოხერხდა');
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
      });
  };

  render() {
    const conStyle = {
      color: '#666',
      fontSize: '14px',
      textTransform: 'uppercase',
      //borderBottom: "1px solid #f0f0f0",
      lineHeight: '45px',
      textDecoration: 'none',
      //paddingBottom:"30px"
      //display:"inline-block"
    };

    const letters = this.props.values.letterGroups;
    //console.log(this.props);
    if (Object.keys(letters).length === 0) {
      return <label></label>;
    }
    //console.log(letters);
    let cnt = 0;
    return (
      <React.Fragment>
        <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
          {letters.map((letter, index) => {
            return (
              <ul key={index} className="col-lg-12" style={{ listStylePosition: 'outside' }}>
                <div className="card">
                  <div className="card-header">
                    <strong>{letter.name}</strong>
                  </div>
                  <div className="card-body">
                    <div style={{ padding: '0px 10px' }}>
                      {letter.refLetterTypes.map((l, i) => {
                        //let cmp = l.componentName;
                        let name = l.componentName;
                        cnt++;
                        //let id = l.id;
                        return (
                          <React.Fragment key={i}>
                            <li
                              style={{
                                borderBottom: '1px solid #f0f0f0',
                                padding: '0 5px',
                                listStyleType: 'none',
                              }}
                            >
                              <span style={{ padding: '0' }}>
                                <Link
                                  to={{
                                    pathname: `/dashboard/Write/${name}`,
                                    params: this.props.location.params,
                                  }}
                                  className="navhover"
                                  style={conStyle}
                                >
                                  {l.name}
                                </Link>
                                &nbsp;
                                <i
                                  className="far fa-question-circle "
                                  title="Click"
                                  id={name}
                                  style={{ cursor: 'pointer' }}
                                ></i>
                              </span>

                              <Popover
                                placement="right"
                                trigger="legacy"
                                isOpen={this.state[`${name}`]}
                                target={name}
                                toggle={() => this.togglePopover(`${name}`)}
                              >
                                <PopoverHeader style={{ fontFamily: 'BPGCaps' }}>
                                  {l.name}
                                </PopoverHeader>
                                <PopoverBody style={{ fontFamily: 'BPGCond' }}>
                                  {l.descCondition}
                                </PopoverBody>
                              </Popover>
                            </li>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </ul>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default Letters;
