import Axios from 'axios';
import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import Helmet from 'react-helmet';
import { read_cookie , delete_cookie} from 'sfcookies';
import ReactHtmlParser from 'react-html-parser';

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[]
         }
    }

    UNSAFE_componentWillMount(){
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        Axios.get(`${name}/api/HelpTopics/GetHelpTopics`,
        {
            headers:{
                "Token":token
            }
        })
        .then(res=>{
            if(res.data.success){
                this.setState({data:res.data.helpTopicsList});
            }
        })
        .catch(error=>{
            if(error.response.status===401){
                delete_cookie("Bearer_Token");
                window.location.href = '/login';
              }
        })
    }

    triggerCollapsible(id){
        const data = this.state.data;

        return(     
            <div className="card-header" style={{cursor:"pointer",color:"#262262",fontFamily:"BPGCaps",fontSize:"17.5px"}}>
                <br/>
                <div className="card-header-actions"><i className="fas fa-angle-down"></i></div>
                <span><strong>{data[id].name}</strong></span>  
                <br/><br/>
            </div> 
        )
    }

    render() { 
        const data = this.state.data;
        //console.log(data);
        if(!data.length){
            return(<label></label>)
        }
        return ( 
            <React.Fragment>
                <Helmet><title>დახმარება</title></Helmet>
                <div className="row justify-content-center">
                    {
                        data.map((value,index)=>{
                            return(
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{fontFamily:"BPGCond",wordWrap:"break-word"}} key={index}>
                                    <div className="card">
                                        <Collapsible trigger={this.triggerCollapsible(index)} >
                                            <div className="card-body">
                                                <div className="col-md-12 col-sm-12">
                                                    {ReactHtmlParser(value.text)}
                                                </div>
                                            </div>
                                        </Collapsible>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </React.Fragment>
         );
    }
}
 
export default Help;