import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { read_cookie } from 'sfcookies';
import renderHTML from 'react-render-html';
import NotFound from '../pages/NotFound';

class DocumentPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      page: '',
      letterDocument: [],
      letterDocumentLines: [],
      template: '',
      reference_devices: [],
      reference_districts: [],
      reference_streets: [],
      reference_activestreest: [],
      token: '',
      temporaryToken:
        'E3A3B0DAE539AA1197D78876F4CD7FB45C40459DB73E6FC96AAD43FF29826AAFCE612FF9D5D08B46D7BE2481E678F77E79B7A2488C104D80A10D5DDAD560F86B',
      activetoken: '',
      redirect: false,
      temp: `<h2><strong>&nbsp; &nbsp; </strong></h2>
            <h2><strong>&nbsp; &nbsp; განმცხადებლის ინფორმაცია</strong></h2>
            <ol>
            <li style="">განმცხადებლის სახელი და გვარი: <strong>{{fullname}}</strong>;</li>
            <li style="">განმცხადებლის პირადი ნომერი: <strong>{{personal}}</strong>;</li>
            <li style="">განმცხადებლის საკონტაქტო მისამართი: <strong>{{address}}</strong>;</li>
            <li style="">განმცხადებლის ძირითადი მობილურის ნომერი: <strong>{{phone_1}}</strong>;</li>
            <li style="">განმცხადებლის ალტერნატიული მობილურის ნომერი: <strong>{{phone_2}}</strong>;</li>
            <li style="">განმცხადებლის ძირითადი ელ. ფოსტა: <strong>{{email_1}}</strong>;</li>
            <li style="">განმცხადებლის ალტერნატიული ელ. ფოსტა: <strong>{{email_2}}</strong>;</li>
            </ol>
            <p>&nbsp;</p>
            <h2 style="text-align: left;"><strong>&nbsp; &nbsp; ინფორმაცია აბონენტის შესახე</strong>ბ</h2>
            <ol>
            <li style="text-align: left;">აბონენტის ნომერი(არსებობის შემთხვევაში): <strong>{{custN}}</strong>;</li>
            <li style="text-align: left;">აბონენტის სახელი და გვარი/დასახელება: <strong>{{customer_firstname}} {{customer_lastname}}</strong>;</li>
            <li style="text-align: left;">აბონენტის პირადი/საიდენტიფიკაციო ნომერი: <strong>{{customer_personal}}</strong>;</li>
            <li style="text-align: left;">აბონენტის ძირითადი მობილურის ნომერი: <strong>{{customer_phone_1}}</strong>;</li>
            <li style="text-align: left;">აბონენტის ალტერნატიული მობილურის ნომერი: <strong>{{customer_phone_2}}</strong>;</li>
            <li style="text-align: left;">აბონენტის ძირითადი ელ. ფოსტა: <strong>{{customer_email_1}}</strong>;</li>
            <li style="text-align: left;">აბონენტის ალტერნატიული ელ. ფოსტა: <strong>{{customer_email_2}}</strong>;</li>
            </ol>`,
      temp1: `<h2><strong>&nbsp; &nbsp; </strong></h2>
            <h2><strong>&nbsp; &nbsp; განმცხადებლის ინფორმაცია</strong></h2>
            <ol>
            <li style="">განმცხადებლის სახელი და გვარი: <strong>{{fullname}}</strong>;</li>
            <li style="">განმცხადებლის პირადი ნომერი: <strong>{{personal}}</strong>;</li>
            <li style="">განმცხადებლის საკონტაქტო მისამართი: <strong>{{address}}</strong>;</li>
            <li style="">განმცხადებლის ძირითადი მობილურის ნომერი: <strong>{{phone_1}}</strong>;</li>
            <li style="">განმცხადებლის ალტერნატიული მობილურის ნომერი: <strong>{{phone_2}}</strong>;</li>
            <li style="">განმცხადებლის ძირითადი ელ. ფოსტა: <strong>{{email_1}}</strong>;</li>
            <li style="">განმცხადებლის ალტერნატიული ელ. ფოსტა: <strong>{{email_2}}</strong>;</li>
            </ol>
            <p>&nbsp;</p>
            <h2 style="text-align: left;"><strong>&nbsp; &nbsp; ინფორმაცია აბონენტის შესახე</strong>ბ</h2>
            <ol>
            <li style="text-align: left;">აბონენტის ნომერი(არსებობის შემთხვევაში): <strong>{{custN}}</strong>;</li>
            <li style="text-align: left;">აბონენტის სახელი და გვარი/დასახელება: <strong>{{applicant_fullname}}</strong>;</li>
            <li style="text-align: left;">აბონენტის პირადი/საიდენტიფიკაციო ნომერი: <strong>{{applicant_personal}}</strong>;</li>
            <li style="text-align: left;">აბონენტის ძირითადი მობილურის ნომერი: <strong>{{applicant_phone_1}}</strong>;</li>
            <li style="text-align: left;">აბონენტის ალტერნატიული მობილურის ნომერი: <strong>{{applicant_phone_2}}</strong>;</li>
            <li style="text-align: left;">აბონენტის ძირითადი ელ. ფოსტა: <strong>{{applicant_email_1}}</strong>;</li>
            <li style="text-align: left;">აბონენტის ალტერნატიული ელ. ფოსტა: <strong>{{applicant_email_2}}</strong>;</li>
            </ol>`,
      temp2: `<h2><strong>&nbsp; &nbsp; </strong></h2>
        <h2><strong>&nbsp; &nbsp; განმცხადებლის ინფორმაცია</strong></h2>
        <ol>
        <li style="">განმცხადებლის სახელი და გვარი: <strong>{{fullname}}</strong>;</li>
        <li style="">განმცხადებლის პირადი ნომერი: <strong>{{personal}}</strong>;</li>
        <li style="">განმცხადებლის საკონტაქტო მისამართი: <strong>{{address}}</strong>;</li>
        <li style="">განმცხადებლის ძირითადი მობილურის ნომერი: <strong>{{phone_1}}</strong>;</li>
        <li style="">განმცხადებლის ალტერნატიული მობილურის ნომერი: <strong>{{phone_2}}</strong>;</li>
        <li style="">განმცხადებლის ძირითადი ელ. ფოსტა: <strong>{{email_1}}</strong>;</li>
        <li style="">განმცხადებლის ალტერნატიული ელ. ფოსტა: <strong>{{email_2}}</strong>;</li>
        </ol>`,
      paymenttext: `მოხმარებული გაზის საფასურის გადაუხდელობის შემთხვევაში, მიმწოდებელი უფლებამოსილია დავალიანების დაფარვამდე ყოველ მომდევნო საანგარიშო თვეში ერთხელ, მომხმარებელს დაარიცხოს პირგასამტეხლო, საანგარიშო თვეში მოხმარებული გაზის საფასურის გადაუხდელი თანხის 2,1% (ორი მთელი ერთი მეათედი) ოდენობით. 
 პირგასამტეხლოს გადახდა არ ათავისუფლებს მომხმარებელს მოხმარებული ბუნებრივი გაზის საფასურის გადახდის ვალდებულებისგან.
        მომხმარებლის მიერ პირგასამტეხლოს გადახდის ვალდებულების წარმოშობის შემთხვევაში, პირველად დაიფარება პირგასამტეხლო, ხოლო შემდგომ ძირითადი დავალიანება, ხოლო პირგასამტეხლოს სრული ოდენობის დაფარვის შემდეგ გადასახდელი თანხა წარიმართება ძირითადი დავალიანების დასაფარად, იმ პირობით, რომ პირველად დაიფარება უფრო ადრე წარმოშობილი დავალიანება.
        მიმწოდებლის მხრიდან გაზის მიუწოდებლობისას, თუ ეს გამოწვეულია მისი ბრალეული ქმედებით, მომხმარებელი უფლებამოსილია პირგასამტეხლოს სახით 
        მოითხოვოს ამ პერიოდში მიუწოდებელი გაზის საფასურის 0,07% (ნული მთელი შვიდი მეასედი) გაზის მიუწოდებლობის პერიოდის ყოველ დღეზე, ხოლო ყოველდღიური პირგასამტეხლო გამოითვლება წინა საანგარიშო თვის განმავლობაში მომხმარებლისათვის მიწოდებული გაზის საშუალო დღიური მოცულობის შესაბამისად. ამასთან, პირგასამტეხლოს გადახდა არ ათავისუფლებს მიმწოდებელს 
        ხელშეკრულებით ნაკისრი ვალდებულებების შესრულებისაგან.`,
    };
  }

  UNSAFE_componentWillMount() {
    //console.log(this.props.match.params.token);
    var temp1 = this.state.activetokenl;
    if (this.props.location.search === null || this.props.location.search === '') {
      var temp = read_cookie('Bearer_Token');
      temp1 = temp;
      this.setState({ activetoken: temp });
    } else {
      var s1 = this.props.location.search;
      var s2 = s1.substring(1);
      temp1 = s2;
      this.setState({ activetoken: s2 });
      //console.log(this.props.location);
      //console.log(2)
    }

    const token = temp1;
    //this.state.activetoken;
    //console.log(this.state.activetoken);
    //read_cookie("Bearer_Token");
    const name = window.$name;
    Axios.get(`${name}/api/Letters/GetLetterDocument?id=${this.props.match.params.id}`, {
      headers: { Token: token },
    })
      .then((response) => {
        //console.log(response)
        if (response.data.success) {
          document
            .querySelectorAll('style,link[rel="stylesheet"]')
            .forEach((item) => item.remove());
          this.setState({
            letterDocument: response.data.letterDocument,
            letterDocumentLines: response.data.letterDocumentLines,
            template: response.data.template,
          });
          this.switchLetterTypes(response.data.letterDocument.refLetterTypeId);
        } else if (!response.data.success) {
          if (response.data.code === -1) {
            this.setState({ redirect: true });
            //console.log(121212)
          }
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            //delete_cookie("Bearer_Token");
            window.location.replace('/dashboard');
          }
        }
      });
  }

  componentDidMount() {
    this.setState({ id: this.props.match.params.id });
    //console.log(this.props.match.params.id);

    const token = this.state.activetoken;
    //read_cookie("Bearer_Token");
    const name = window.$name;
    Axios.get(`${name}/api/Letters/GetReferences?letterTypeId=50`, { headers: { Token: token } })
      .then((res) => {
        if (res.data.success) {
          let data = res.data.references;
          let districts = [];
          let streets = [];
          let devices = [];
          let active_streets = [];
          //console.log(data);
          data.forEach((element) => {
            switch (element.key) {
              case 'RefDevices':
                devices.push(element);
                break;
              case 'RefDistricts':
                districts.push(element);
                break;
              case 'RefStreets':
                streets.push(element);
                break;
              default:
                break;
            }
          });
          let val = districts[0].id;
          streets.forEach((element) => {
            if (element.parentId === val) {
              active_streets.push(element);
            }
          });
          this.setState({
            reference_devices: devices,
            reference_streets: streets,
            reference_districts: districts,
            reference_activestreest: active_streets,
          });
        } else if (!res.data.success) {
          if (res.data.code === -1) {
            this.setState({ redirect: true });
            //console.log(121212)
          }
        }
        //console.log(active_streets);
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          //delete_cookie("Bearer_Token");
          window.location.replace('/dashboard');
        }
        //alert("შეცდომა ?");
      });
  }

  getDayMonthYear = (date, type) => {
    //type = 1 => დღე     type = 2 => თვე(სიტყვიერად)   type = 3 => თვე(რიცხვით)    type = 4 => წელი(ბოლო ორი)   type = 5 => წელი
    let returnedValue = '';
    const months = [
      'იანვარი',
      'თებერვალი',
      'მარტი',
      'აპრილი',
      'მაისი',
      'ივნისი',
      'ივლისი',
      'აგვისტო',
      'სექტემბერი',
      'ოქტომბერი',
      'ნოემბერი',
      'დეკემბერი',
    ];
    if (type === 1) returnedValue = date[0] + date[1];
    else if (type === 2) {
      let month = date[3] + date[4];
      month = parseInt(month);
      returnedValue = months[month - 1];
    } else if (type === 3) returnedValue = date[3] + date[4];
    else if (type === 4) returnedValue = date[date.length - 2] + date[date.length - 1];
    else if (type === 5)
      returnedValue =
        date[date.length - 4] +
        date[date.length - 3] +
        date[date.length - 2] +
        date[date.length - 1];

    return returnedValue;
  };

  ConnectNewCustomer = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;

    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const month_1 = this.getDayMonthYear(date, 3);
    const year = this.getDayMonthYear(date, 4);
    const year_1 = this.getDayMonthYear(date, 5);

    let docName = null;
    if (letterDocument.docName !== null) docName = letterDocument.docName;
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let street_Id = letterDocumentLines.filter((x) => x.key === 'street_Id');
    if (street_Id.length) street_Id = street_Id[0].value; //ადგილი (მისამართი), სადაც უნდა ჩატარდეს სამუშაოები
    let street_Name = letterDocumentLines.filter((x) => x.key === 'street_Name');
    if (street_Name.length) street_Name = street_Name[0].value;
    let addressto_building = letterDocumentLines.filter((x) => x.key === 'addressto_building');
    if (addressto_building.length) addressto_building = addressto_building[0].value;
    let addressto_entrance = letterDocumentLines.filter((x) => x.key === 'addressto_entrance');
    if (addressto_entrance.length) addressto_entrance = addressto_entrance[0].value;
    let addressto_floor = letterDocumentLines.filter((x) => x.key === 'addressto_floor');
    if (addressto_floor.length) addressto_floor = addressto_floor[0].value;
    let addressto_apartament = letterDocumentLines.filter((x) => x.key === 'addressto_apartament');
    if (addressto_apartament.length) addressto_apartament = addressto_apartament[0].value;
    let checkyes_13 = letterDocumentLines.filter((x) => x.key === 'checkyes_13');
    let checkyes_14 = letterDocumentLines.filter((x) => x.key === 'checkyes_14');
    let checkyes_14_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_14_1');
    let checkyes_15 = letterDocumentLines.filter((x) => x.key === 'checkyes_15');
    let checkyes_6 = letterDocumentLines.filter((x) => x.key === 'checkyes_6');
    let checkyes_20 = letterDocumentLines.filter((x) => x.key === 'checkyes_20');
    let checkyes_16 = letterDocumentLines.filter((x) => x.key === 'checkyes_16');
    let checkyes_18 = letterDocumentLines.filter((x) => x.key === 'checkyes_18');
    let smartmeter = letterDocumentLines.filter((x) => x.key === 'smartmeter');
    //let checkyes_14 = letterDocumentLines.filter(x=> x.key==="checkyes_14");
    let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
    let checkyes_23 = letterDocumentLines.filter((x) => x.key === 'checkyes_23');
    let checkyes_24 = letterDocumentLines.filter((x) => x.key === 'checkyes_24');
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let peakpower = letterDocumentLines.filter((x) => x.key === 'peakpower');
    if (peakpower.length) peakpower = peakpower[0].value;
    let lowpower = letterDocumentLines.filter((x) => x.key === 'lowpower');
    if (lowpower.length) lowpower = lowpower[0].value;
    let lowpowertext = letterDocumentLines.filter((x) => x.key === 'lowpowertext');
    if (lowpowertext.length) lowpowertext = lowpowertext[0].value;
    let amountN = letterDocumentLines.filter((x) => x.key === 'amountN');
    if (amountN.length) amountN = amountN[0].value;
    let bankdetails = letterDocumentLines.filter((x) => x.key === 'bankdetails');
    if (bankdetails.length) bankdetails = bankdetails[0].value;
    let telasi = letterDocumentLines.filter((x) => x.key === 'telasi');
    if (telasi.length) telasi = telasi[0].value;
    let smartmeterprice = letterDocumentLines.filter((x) => x.key === 'smartmeterprice');
    if (smartmeterprice.length) smartmeterprice = smartmeterprice[0].value;
    let gwp = letterDocumentLines.filter((x) => x.key === 'gwp');
    if (gwp.length) gwp = gwp[0].value;
    let amount = letterDocumentLines.filter((x) => x.key === 'amount');
    if (amount.length) amount = amount[0].value;
    let file_9 = letterDocumentLines.filter((x) => x.key === 'file_9');
    let smartmeterfile = letterDocumentLines.filter((x) => x.key === 'smartmeterfile');
    let smartmeterpaid = letterDocumentLines.filter((x) => x.key === 'smartmeterpaid');
    const checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');

    for (let i = 0; i < 9; i++) {
      let myRegExp = new RegExp(`{{phone_1_${i}}}`);
      let square = `<div>${phone_1[i]}</div>`;
      template = template.replace(myRegExp, typeof phone_1 !== 'undefined' ? square : '&#8414;');
    }
    if (phone_2.length > 0) {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div >${phone_2[i]}</div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    } else {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div></div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    }

    let responsible = '';
    if (typeof checked !== 'undefined') {
      if (typeof customer_firstname !== 'undefined') {
        if (typeof customer_lastname !== 'undefined') {
          if (checked) {
            responsible = customer_firstname + ' ' + customer_lastname;
          }
        }
      }
      if (typeof customer_personal !== 'undefined') responsible += ' (' + customer_personal + ')';
    }

    let addressto = '';
    //let streetName = this.state.reference_streets.filter(x=>x.id==street_Id); streetName=streetName[0].value;
    let buildingNum = addressto_building;
    let entrance = '';
    if (typeof addressto_entrance !== 'undefined') entrance += ', სად. ' + addressto_entrance;
    let floor = '';
    if (typeof addressto_floor !== 'undefined') floor += ', სართ. ' + addressto_floor;
    let apartament = '';
    if (typeof addressto_apartament !== 'undefined') apartament += ', ბინა ' + addressto_apartament;
    addressto = street_Name + ' ' + buildingNum + entrance + floor + apartament;
    if (checkyes_1.length) {
      if (checkyes_1[0].value === 'true') {
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'false') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
      }
      if (checkyes_1[0].value === 'yes') {
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'no') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'already') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
      }
      //page=page.replace("{{checkyes_1}}",checkyes_1.length&&checkyes_1[0].value!=="false"?"&#8864;":"&#8414;");
    }
    template = template.replace(/{{docName}}/g, docName !== null ? docName : '');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________@_____'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' && email_2.length > 0 ? email_2 : '__________________@_____'
    );
    template = template.replace(
      /{{day}}/g,
      typeof day !== 'undefined' ? day : '__________________'
    );
    template = template.replace(
      /{{month}}/g,
      typeof month !== 'undefined' ? month : '__________________'
    );
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '__________________'
    );
    template = template.replace(
      /{{year}}/g,
      typeof year !== 'undefined' ? year : '__________________'
    );
    template = template.replace(
      /{{year_1}}/g,
      typeof year_1 !== 'undefined' ? year_1 : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      '{{checkyes_13}}',
      checkyes_13.length && checkyes_13[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_13}}',
      checkyes_13.length && checkyes_13[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_14}}',
      checkyes_14.length && checkyes_14[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_14}}',
      checkyes_14.length && checkyes_14[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    try {
      if (checkyes_14.length) {
        if (checkyes_14_1[0].value === 'true') {
          template = template.replace('{{checkyes_20}}', '&#8414;');
          template = template.replace('{{checkyes_20}}', '&#8414;');
          template = template.replace('{{amountCheck}}', '&#8864;');
        } else {
          template = template.replace(
            '{{checkyes_20}}',
            checkyes_20.length && checkyes_20[0].value !== 'false' ? '&#8864;' : '&#8414;'
          );
          template = template.replace(
            '{{checkyes_20}}',
            checkyes_20.length && checkyes_20[0].value !== 'false' ? '&#8414;' : '&#8864;'
          );
          template = template.replace(
            '{{amountCheck}}',
            typeof checkyes_14_1 !== 'undefined' ? '&#8414;' : '&#8864;'
          );
        }
      }
    } catch (err) {
      template = template.replace(
        '{{checkyes_20}}',
        checkyes_20.length && checkyes_20[0].value !== 'false' ? '&#8864;' : '&#8414;'
      );
      template = template.replace(
        '{{checkyes_20}}',
        checkyes_20.length && checkyes_20[0].value !== 'false' ? '&#8414;' : '&#8864;'
      );
      template = template.replace(
        '{{amountCheck}}',
        typeof checkyes_14_1 !== 'undefined' ? '&#8414;' : '&#8864;'
      );
    }

    template = template.replace(
      '{{smartmeter}}',
      smartmeter.length && smartmeter[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{smartmeter}}',
      smartmeter.length && smartmeter[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{smartmeterpaid}}',
      smartmeterpaid.length && smartmeterpaid[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{smartmeterpaid}}',
      smartmeterpaid.length && smartmeterpaid[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );

    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_14}}',
      checkyes_14.length && checkyes_14[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_14}}',
      checkyes_14_1.length && checkyes_14_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_15}}',
      checkyes_15.length && checkyes_15[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_15}}',
      checkyes_15.length && checkyes_15[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_24}}',
      checkyes_24.length && checkyes_24[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_24}}',
      checkyes_24.length && checkyes_24[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_6}}',
      checkyes_6.length && checkyes_6[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_6}}',
      checkyes_6.length && checkyes_6[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    //template=template.replace("{{checkyes_19}}",checkyes_19.length&&checkyes_19[0].value!=="false"?"&#8864;":"&#8414;");
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{smartmeterprice}}/g,
      typeof smartmeterprice !== 'undefined' ? smartmeterprice : '__________________'
    );
    template = template.replace(
      /{{responsible}}/g,
      typeof responsible !== 'undefined' ? responsible : '_________________'
    );
    template = template.replace(
      /{{peakpower}}/g,
      typeof peakpower !== 'undefined' ? peakpower : '_____'
    );
    template = template.replace(
      /{{lowpower}}/g,
      typeof lowpowertext !== 'undefined' ? lowpowertext : '______'
    );
    template = template.replace(
      /{{amountN}}/g,
      typeof amountN !== 'undefined' ? amountN : '______'
    );
    template = template.replace(
      /{{bankdetails}}/g,
      typeof bankdetails !== 'undefined' ? bankdetails : '________________'
    );
    template = template.replace(
      /{{telasi}}/g,
      typeof telasi !== 'undefined' ? telasi : '_________________'
    );
    template = template.replace(/{{gwp}}/g, typeof gwp !== 'undefined' ? gwp : '_________________');
    template = template.replace('{{checkyes_19}}', file_9.length ? '&#8864;' : '&#8414;');
    template = template.replace(
      '{{smartmeterfile}}',
      smartmeterfile.length ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_23}}',
      checkyes_23.length && checkyes_23[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{amount}}/g,
      typeof amount !== 'undefined' ? amount : '_________________'
    );

    //console.log(file_9);
    this.setState({ template: template });
  };

  ConnectAbonentFromDivide = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;

    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const month_1 = this.getDayMonthYear(date, 3);
    const year = this.getDayMonthYear(date, 4);
    const year_1 = this.getDayMonthYear(date, 5);

    let docName = null;
    if (letterDocument.docName !== null) docName = letterDocument.docName;
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let street_Id = letterDocumentLines.filter((x) => x.key === 'street_Id');
    if (street_Id.length) street_Id = street_Id[0].value; //ადგილი (მისამართი), სადაც უნდა ჩატარდეს სამუშაოები
    let addressto_building = letterDocumentLines.filter((x) => x.key === 'addressto_building');
    if (addressto_building.length) addressto_building = addressto_building[0].value;
    let addressto_entrance = letterDocumentLines.filter((x) => x.key === 'addressto_entrance');
    if (addressto_entrance.length) addressto_entrance = addressto_entrance[0].value;
    let addressto_floor = letterDocumentLines.filter((x) => x.key === 'addressto_floor');
    if (addressto_floor.length) addressto_floor = addressto_floor[0].value;
    let addressto_apartament = letterDocumentLines.filter((x) => x.key === 'addressto_apartament');
    if (addressto_apartament.length) addressto_apartament = addressto_apartament[0].value;
    let addressto = '';
    let street_Name = letterDocumentLines.filter((x) => x.key === 'street_Name');
    if (street_Name.length) street_Name = street_Name[0].value;
    let buildingNum = addressto_building;
    let entrance = '';
    if (typeof addressto_entrance !== 'undefined' && addressto_entrance.length > 0)
      entrance += ', სად. ' + addressto_entrance;
    let floor = '';
    if (typeof addressto_floor !== 'undefined' && addressto_floor.length > 0)
      floor += ', სართ. ' + addressto_floor;
    let apartament = '';
    if (typeof addressto_apartament !== 'undefined' && addressto_apartament.length > 0)
      apartament += ', ბინა ' + addressto_apartament;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let requestedpower = letterDocumentLines.filter((x) => x.key === 'requestedpower');
    if (requestedpower.length) requestedpower = requestedpower[0].value;
    let requestedpowertext = letterDocumentLines.filter((x) => x.key === 'requestedpowertext');
    if (requestedpowertext.length) requestedpowertext = requestedpowertext[0].value;
    let bankdetails = letterDocumentLines.filter((x) => x.key === 'bankdetails');
    if (bankdetails.length) bankdetails = bankdetails[0].value;
    let telasi = letterDocumentLines.filter((x) => x.key === 'telasi');
    if (telasi.length) telasi = telasi[0].value;
    let gwp = letterDocumentLines.filter((x) => x.key === 'gwp');
    if (gwp.length) gwp = gwp[0].value;
    let responsible = letterDocumentLines.filter((x) => x.key === 'responsible');
    if (responsible.length) responsible = responsible[0].value;
    let peakpower = letterDocumentLines.filter((x) => x.key === 'peakpower');
    if (peakpower.length) peakpower = peakpower[0].value;
    let lowpower = letterDocumentLines.filter((x) => x.key === 'lowpower');
    if (lowpower.length) lowpower = lowpower[0].value;
    let amountN = letterDocumentLines.filter((x) => x.key === 'amountN');
    if (amountN.length) amountN = amountN[0].value;
    let smartmetercost = letterDocumentLines.filter((x) => x.key === 'smartmetercost');
    if (smartmetercost.length) smartmetercost = smartmetercost[0].value;
    let file_8 = letterDocumentLines.filter((x) => x.key === 'file_8');
    let smartmeterfile = letterDocumentLines.filter((x) => x.key === 'smartmeterfile');
    //let file_agree = letterDocumentLines.filter(x=> x.key==="file_agree");
    let amount = letterDocumentLines.filter((x) => x.key === 'amount');
    if (amount.length) amount = amount[0].value;

    template = template.replace(/{{responsible}}/g, responsible !== null ? responsible : '');
    template = template.replace(/{{peakpower}}/g, peakpower !== null ? peakpower : '');
    template = template.replace(/{{lowpower}}/g, lowpower !== null ? lowpower : '');
    template = template.replace(/{{amountN}}/g, amountN !== null ? amountN : '');
    template = template.replace(
      /{{smartmetercost}}/g,
      smartmetercost !== null ? smartmetercost : ''
    );

    const checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_6 = letterDocumentLines.filter((x) => x.key === 'checkyes_6');
    let checkyes_13 = letterDocumentLines.filter((x) => x.key === 'checkyes_13');
    let checkyes_14_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_14_1');
    let checkyes_17 = letterDocumentLines.filter((x) => x.key === 'checkyes_17');
    let checkyes_16 = letterDocumentLines.filter((x) => x.key === 'checkyes_16');
    let checkyes_18 = letterDocumentLines.filter((x) => x.key === 'checkyes_18');
    let checkyes_20 = letterDocumentLines.filter((x) => x.key === 'checkyes_20');
    let checkyes_21 = letterDocumentLines.filter((x) => x.key === 'checkyes_21');
    let checkyes_24 = letterDocumentLines.filter((x) => x.key === 'checkyes_24');
    let checkyes_26 = letterDocumentLines.filter((x) => x.key === 'checkyes_26');
    let checkyes_27 = letterDocumentLines.filter((x) => x.key === 'checkyes_27');
    let checkyes_28 = letterDocumentLines.filter((x) => x.key === 'checkyes_28');
    let checkyes_14 = letterDocumentLines.filter((x) => x.key === 'checkyes_14');
    let checkyes_15 = letterDocumentLines.filter((x) => x.key === 'checkyes_15');
    let smartmeter = letterDocumentLines.filter((x) => x.key === 'smartmeter');
    let smartmeterpaid = letterDocumentLines.filter((x) => x.key === 'smartmeterpaid');
    template = template.replace(
      '{{smartmeterfile}}',
      smartmeterfile.length && smartmeterfile[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    template = template.replace(
      '{{checkyes_14}}',
      checkyes_14.length && checkyes_14[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_14}}',
      checkyes_14.length && checkyes_14[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_14}}',
      checkyes_14_1.length && checkyes_14_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_14}}',
      checkyes_14_1.length && checkyes_14_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_15}}',
      checkyes_15.length && checkyes_15[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_15}}',
      checkyes_15.length && checkyes_15[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{smartmeter}}',
      smartmeter.length && smartmeter[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{smartmeter}}',
      smartmeter.length && smartmeter[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{smartmeterpaid}}',
      smartmeterpaid.length && smartmeterpaid[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{smartmeterpaid}}',
      smartmeterpaid.length && smartmeterpaid[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );

    //let checkyes_14 = letterDocumentLines.filter(x=> x.key==="checkyes_14");

    addressto = street_Name + ' ' + buildingNum + entrance + floor + apartament;
    for (let i = 0; i < 9; i++) {
      let myRegExp = new RegExp(`{{phone_1_${i}}}`);
      let square = `<div >${phone_1[i]}</div>`;
      template = template.replace(myRegExp, typeof phone_1 !== 'undefined' ? square : '&#8414;');
    }
    if (phone_2.length > 0) {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div >${phone_2[i]}</div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    } else {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div ></div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    }

    template = template.replace(/{{docName}}/g, docName !== null ? docName : '__________________');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' && email_1.length > 0
        ? email_1
        : '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ @ _ _ _ _ _'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' && email_2.length > 0
        ? email_2
        : '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ @ _ _ _ _ _'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '');
    template = template.replace(/{{month_1}}/g, typeof month_1 !== 'undefined' ? month_1 : '____');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '');
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '');
    template = template.replace(/{{address}}/g, typeof address !== 'undefined' ? address : '');
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : ''
    );
    template = template.replace(/{{custN}}/g, typeof custN !== 'undefined' ? custN : '');
    template = template.replace(
      /{{requestedpower}}/g,
      typeof requestedpowertext !== 'undefined' && requestedpowertext.length > 0
        ? requestedpowertext
        : ''
    );
    template = template.replace(
      /{{bankdetails}}/g,
      typeof bankdetails !== 'undefined' && bankdetails.length > 0 ? bankdetails : ''
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_6}}',
      checkyes_6.length && checkyes_6[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_6}}',
      checkyes_6.length && checkyes_6[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_13}}',
      checkyes_13.length && checkyes_13[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_13}}',
      checkyes_13.length && checkyes_13[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_17}}',
      checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_17}}',
      checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace('{{checkyes_19}}', file_8.length ? '&#8864;' : '&#8414;');
    //template=template.replace("{{checkyes_19}}",file_agree.length?"&#8864;":"&#8414;");
    template = template.replace(
      '{{checkyes_20}}',
      checkyes_20.length && checkyes_20[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{amountCheck}}',
      checkyes_20.length && checkyes_20[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_20}}',
      checkyes_20.length && checkyes_20[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_21}}',
      checkyes_21.length && checkyes_21[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_21}}',
      checkyes_21.length && checkyes_21[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_24}}',
      checkyes_24.length && checkyes_24[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_24}}',
      checkyes_24.length && checkyes_24[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_26}}',
      checkyes_26.length && checkyes_26[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_27}}',
      checkyes_27.length && checkyes_27[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_28}}',
      checkyes_28.length && checkyes_28[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : ''
    );
    template = template.replace(
      /{{telasi}}/g,
      typeof telasi !== 'undefined' && telasi.length > 0 ? telasi : ''
    );
    template = template.replace(
      /{{gwp}}/g,
      typeof gwp !== 'undefined' && gwp.length > 0 ? gwp : ''
    );
    template = template.replace(
      /{{amount}}/g,
      typeof amount !== 'undefined' && amount.length > 0 ? amount : ''
    );
    template = template.replace(
      '{{amountCheck}}',
      typeof amount !== 'undefined' && amount.length > 0 ? '&#8864;' : '&#8414;'
    );
    if (checkyes_1.length) {
      if (checkyes_1[0].value === 'true') {
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'false') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
      }
      if (checkyes_1[0].value === 'yes') {
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'no') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'already') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
      }
      //page=page.replace("{{checkyes_1}}",checkyes_1.length&&checkyes_1[0].value!=="false"?"&#8864;":"&#8414;");
    }

    this.setState({ template: template });
  };

  PowerIncrease = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const month_1 = this.getDayMonthYear(date, 3);
    const year = this.getDayMonthYear(date, 4);
    const year_1 = this.getDayMonthYear(date, 5);
    let docName = null;
    if (letterDocument.docName !== null) docName = letterDocument.docName;
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let street_Id = letterDocumentLines.filter((x) => x.key === 'street_Id');
    if (street_Id.length) street_Id = street_Id[0].value; //ადგილი (მისამართი), სადაც უნდა ჩატარდეს სამუშაოები
    let addressto_building = letterDocumentLines.filter((x) => x.key === 'addressto_building');
    if (addressto_building.length) addressto_building = addressto_building[0].value;
    let addressto_entrance = letterDocumentLines.filter((x) => x.key === 'addressto_entrance');
    if (addressto_entrance.length) addressto_entrance = addressto_entrance[0].value;
    let addressto_floor = letterDocumentLines.filter((x) => x.key === 'addressto_floor');
    if (addressto_floor.length) addressto_floor = addressto_floor[0].value;
    let addressto_apartament = letterDocumentLines.filter((x) => x.key === 'addressto_apartament');
    if (addressto_apartament.length) addressto_apartament = addressto_apartament[0].value;
    let addressto = '';
    let street_Name = letterDocumentLines.filter((x) => x.key === 'street_Name');
    if (street_Name.length) street_Name = street_Name[0].value;
    let buildingNum = addressto_building;
    let entrance = '';
    if (typeof addressto_entrance !== 'undefined' && addressto_entrance.length > 0)
      entrance += ', სად. ' + addressto_entrance;
    let floor = '';
    if (typeof addressto_floor !== 'undefined' && addressto_floor.length > 0)
      floor += ', სართ. ' + addressto_floor;
    let apartament = '';
    if (typeof addressto_apartament !== 'undefined' && addressto_apartament.length > 0)
      apartament += ', ბინა ' + addressto_apartament;
    let permisiblepower = letterDocumentLines.filter((x) => x.key === 'permisiblepower');
    if (permisiblepower.length) permisiblepower = permisiblepower[0].value;
    let requestedpower = letterDocumentLines.filter((x) => x.key === 'requestedpower');
    if (requestedpower.length) requestedpower = requestedpower[0].value;
    let requestedpowertext = letterDocumentLines.filter((x) => x.key === 'requestedpowertext');
    if (requestedpowertext.length) requestedpowertext = requestedpowertext[0].value;
    let totalfeeN = letterDocumentLines.filter((x) => x.key === 'totalfeeN');
    if (totalfeeN.length) totalfeeN = totalfeeN[0].value;
    let totalfeeT = letterDocumentLines.filter((x) => x.key === 'totalfeeT');
    if (totalfeeT.length) totalfeeT = totalfeeT[0].value;
    let maximum = letterDocumentLines.filter((x) => x.key === 'maximum');
    if (maximum.length) maximum = maximum[0].value;
    let minimal = letterDocumentLines.filter((x) => x.key === 'minimal');
    if (minimal.length) minimal = minimal[0].value;
    addressto = street_Name + ' ' + buildingNum + entrance + floor + apartament;
    let telasi = letterDocumentLines.filter((x) => x.key === 'telasi');
    if (telasi.length) telasi = telasi[0].value;
    let gwp = letterDocumentLines.filter((x) => x.key === 'gwp');
    if (gwp.length) gwp = gwp[0].value;
    let bankdetails = letterDocumentLines.filter((x) => x.key === 'bankdetails');
    if (bankdetails.length) bankdetails = bankdetails[0].value;
    let amount = letterDocumentLines.filter((x) => x.key === 'amount');
    if (amount.length) amount = amount[0].value;
    let file_8 = letterDocumentLines.filter((x) => x.key === 'file_8');
    if (file_8.length) file_8 = file_8[0].value;

    const checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
    let checkyes_13 = letterDocumentLines.filter((x) => x.key === 'checkyes_13');
    let checkyes_16 = letterDocumentLines.filter((x) => x.key === 'checkyes_16');
    let checkyes_18 = letterDocumentLines.filter((x) => x.key === 'checkyes_18');
    let checkyes_17 = letterDocumentLines.filter((x) => x.key === 'checkyes_17');
    let checkyes_19 = letterDocumentLines.filter((x) => x.key === 'checkyes_19');
    let checkyes_20 = letterDocumentLines.filter((x) => x.key === 'checkyes_20');
    let checkyes_24 = letterDocumentLines.filter((x) => x.key === 'checkyes_24');
    let checkyes_26 = letterDocumentLines.filter((x) => x.key === 'checkyes_26');
    let checkyes_45 = letterDocumentLines.filter((x) => x.key === 'checkyes_45');
    let checkyes_46 = letterDocumentLines.filter((x) => x.key === 'checkyes_46');
    for (let i = 0; i < 9; i++) {
      let myRegExp = new RegExp(`{{phone_1_${i}}}`);
      let square = `<div >${phone_1[i]}</div>`;
      template = template.replace(myRegExp, typeof phone_1 !== 'undefined' ? square : '&#8414;');
    }
    if (phone_2.length > 0) {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div >${phone_2[i]}</div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    } else {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div ></div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    }
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    //console.log(phone_1)

    //template=template.replace(/{{phone_2}}/g,typeof(phone_2)!=="undefined"?phone_2:"__________________");
    template = template.replace(/{{docName}}/g, docName !== null ? docName : '__________________');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' && email_1.length > 0
        ? email_1
        : '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ @ _ _ _ _ _'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' && email_2.length > 0
        ? email_2
        : '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ @ _ _ _ _ _'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{month_1}}/g, typeof month_1 !== 'undefined' ? month_1 : '____');
    template = template.replace(
      /{{year}}/g,
      typeof year !== 'undefined' ? year : '__________________'
    );
    template = template.replace(
      /{{year_1}}/g,
      typeof year_1 !== 'undefined' ? year_1 : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________'
    );
    template = template.replace(
      /{{bankdetails}}/g,
      typeof bankdetails !== 'undefined' && bankdetails.length > 0
        ? bankdetails
        : '__________________'
    );
    template = template.replace(
      /{{permisiblepower}}/g,
      typeof permisiblepower !== 'undefined' && permisiblepower.length > 0
        ? permisiblepower
        : '____'
    );
    template = template.replace(
      /{{requestedpower}}/g,
      typeof requestedpowertext !== 'undefined' && requestedpowertext.length > 0
        ? requestedpowertext
        : ''
    );
    template = template.replace(
      /{{totalfeeN}}/g,
      typeof totalfeeN !== 'undefined' && totalfeeN.length > 0 ? totalfeeN : '___'
    );
    template = template.replace(
      /{{totalfeeT}}/g,
      typeof totalfeeT !== 'undefined' && totalfeeT.length > 0 ? totalfeeT : '___'
    );
    template = template.replace(
      /{{maximum}}/g,
      typeof maximum !== 'undefined' && maximum.length > 0 ? maximum : '___'
    );
    template = template.replace(
      /{{minimal}}/g,
      typeof minimal !== 'undefined' && minimal.length > 0 ? minimal : '___'
    );
    template = template.replace(
      /{{amount}}/g,
      typeof amount !== 'undefined' && amount.length > 0 ? amount : '______'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(/{{phone_2}}/g, '');
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_13}}',
      checkyes_13.length && checkyes_13[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_13}}',
      checkyes_13.length && checkyes_13[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_17}}',
      checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_17}}',
      checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_19}}',
      file_8.length && file_8[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_20}}',
      checkyes_20.length && checkyes_20[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_20}}',
      checkyes_20.length && checkyes_20[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_24}}',
      checkyes_24.length && checkyes_24[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_24}}',
      checkyes_24.length && checkyes_24[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_26}}',
      checkyes_26.length && checkyes_26[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_45}}',
      checkyes_45.length && checkyes_45[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_45}}',
      checkyes_45.length && checkyes_45[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_46}}',
      checkyes_46.length && checkyes_46[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_46}}',
      checkyes_46.length && checkyes_46[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      /{{telasi}}/g,
      typeof telasi !== 'undefined' && telasi.length > 0 ? telasi : '_________________'
    );
    template = template.replace(
      /{{gwp}}/g,
      typeof gwp !== 'undefined' && gwp.length > 0 ? gwp : '_________________'
    );
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    if (checkyes_1.length) {
      if (checkyes_1[0].value === 'true') {
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'false') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
      }
      if (checkyes_1[0].value === 'yes') {
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'no') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'already') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
      }
      //page=page.replace("{{checkyes_1}}",checkyes_1.length&&checkyes_1[0].value!=="false"?"&#8864;":"&#8414;");
    }

    this.setState({ template: template });
  };

  ConnectNewCustomers = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;

    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const month_1 = this.getDayMonthYear(date, 3);
    const year = this.getDayMonthYear(date, 4);
    const year_1 = this.getDayMonthYear(date, 5);
    let docName = null;
    if (letterDocument.docName !== null) docName = letterDocument.docName;
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let street_Id = letterDocumentLines.filter((x) => x.key === 'street_Id');
    if (street_Id.length) street_Id = street_Id[0].value; //ადგილი (მისამართი), სადაც უნდა ჩატარდეს სამუშაოები
    let addressto_building = letterDocumentLines.filter((x) => x.key === 'addressto_building');
    if (addressto_building.length) addressto_building = addressto_building[0].value;
    let addressto_entrance = letterDocumentLines.filter((x) => x.key === 'addressto_entrance');
    if (addressto_entrance.length) addressto_entrance = addressto_entrance[0].value;
    let addressto_floor = letterDocumentLines.filter((x) => x.key === 'addressto_floor');
    if (addressto_floor.length) addressto_floor = addressto_floor[0].value;
    let addressto_apartament = letterDocumentLines.filter((x) => x.key === 'addressto_apartament');
    if (addressto_apartament.length) addressto_apartament = addressto_apartament[0].value;
    let addressto = '';

    let money = letterDocumentLines.filter((x) => x.key === 'money');
    if (money.length) money = money[0].value;

    let streetName = this.state.reference_streets.filter((x) => x.id == street_Id);
    if (streetName.length) streetName = streetName[0].value;

    let buildingNum = addressto_building;

    let entrance = '';
    if (typeof addressto_entrance !== 'undefined' && addressto_entrance.length > 0)
      entrance += ', სად. ' + addressto_entrance;
    let floor = '';
    if (typeof addressto_floor !== 'undefined' && addressto_floor.length > 0)
      floor += ', სართ. ' + addressto_floor;
    let apartament = '';
    if (typeof addressto_apartament !== 'undefined' && addressto_apartament.length > 0)
      apartament += ', ბინა ' + addressto_apartament;

    addressto = streetName + ' ' + buildingNum + entrance + floor + apartament;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;

    let telasi = letterDocumentLines.filter((x) => x.key === 'telasi');
    if (telasi.length) telasi = telasi[0].value;
    let gwp = letterDocumentLines.filter((x) => x.key === 'gwp');
    if (gwp.length) gwp = gwp[0].value;
    let bankdetails = letterDocumentLines.filter((x) => x.key === 'bankdetails');
    if (bankdetails.length) bankdetails = bankdetails[0].value;
    let amount = letterDocumentLines.filter((x) => x.key === 'amount');
    if (amount.length) amount = amount[0].value;
    let household = letterDocumentLines.filter((val) => val.key === 'household');
    if (household.length) household = household[0].value; // საყოფაცხოვრებო
    let nonhousehold = letterDocumentLines.filter((val) => val.key === 'nonhousehold');
    if (nonhousehold.length) nonhousehold = nonhousehold[0].value; // არასაყოფაცხოვრებო
    let otheramount = letterDocumentLines.filter((val) => val.key === 'otheramount');
    if (otheramount.length) otheramount = otheramount[0].value; // დამატებით განცალკევებული აღრიცხვა (საერთო მიზნებისთვის, გათბობის სისტემა და სხვა)
    let power = letterDocumentLines.filter((val) => val.key === 'power');
    if (power.length) power = power[0].value;
    let quantity = letterDocumentLines.filter((val) => val.key === 'quantity');
    if (quantity.length) quantity = quantity[0].value;
    let smartmetercount = letterDocumentLines.filter((val) => val.key === 'smartmetercount');
    if (smartmetercount.length) smartmetercount = smartmetercount[0].value;
    //let otheramount = letterDocumentLines.filter(val=> val.key==="otheramount"); if(otheramount.length) otheramount=otheramount[0].value;
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let cd_dvd = letterDocumentLines.filter((x) => x.key === 'cd_dvd');
    let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
    let checkyes_13 = letterDocumentLines.filter((x) => x.key === 'checkyes_13');
    let checkyes_16 = letterDocumentLines.filter((x) => x.key === 'checkyes_16');
    let checkyes_18 = letterDocumentLines.filter((x) => x.key === 'checkyes_18');
    let checkyes_19 = letterDocumentLines.filter((x) => x.key === 'checkyes_19');
    let checkyes_26 = letterDocumentLines.filter((x) => x.key === 'checkyes_26');
    let checkyes_47 = letterDocumentLines.filter((x) => x.key === 'checkyes_47');

    let customers = letterDocumentLines.filter((val) => val.key.includes('specified_'));

    if (customers.length) {
      //let info="";
      //let transform = customers;
      let transformed = `</br></br></br></br></br></br><table style=${'"border:1px solid; font-size:8px; transform:scale(0.9);margin-left:-30px;"'}>
            <tr>
            <th style=${'"border:1px solid;"'}>აბონენტის დაზუსტებული მისამართი</th><th  style=${'"border:1px solid;"'}>აბონენტის (მოხმარებული ბუნებრივი გაზის საფასურის გადახდაზე პასუხისმგებელი პირის) სახელი, გვარი ან იურიდიული პირის დასახელება</th>
            <th style=${'"border:1px solid;"'}>პირადი ნომერი ან იურიდიული პირის საიდენტიფიკაციო კოდი</th>
            <th style=${'"border:1px solid;"'}>საკადასტრო კოდი</th>
            <th colspan=${'"2"'} style=${'"border:1px solid;"'}>განამაწილებელ ქსელზე მისაერთებელი სიმძლავრე/ საფასური</th>
            <th style=${'"border:1px solid;"'}>ბუნებრივი გაზის მოხმარების მიზანი</th>
            <th style=${'"border:1px solid;"'}>შიდა ქსელის გაზმომარაგებისთვის მზადყოფნის დოკუმენტი</th>
            <th style=${'"border:1px solid;"'}>გსურთ თუ არა ბუნებრივი გაზის მოწოდება განაწილების ლიცენზიატისგან</th>
            <th style=${'"border:1px solid;"'}>აბონენტის (მოხმარებული ბუნებრივი გაზის საფასურის გადახდაზე პასუხისმგებელი პირის) ხელმოწერა</th>
            </tr>`;
      //info = JSON.parse(transformed[0].value);
      for (var i = 0; i < customers.length; i++) {
        //console.log(customers[i])
        let info = JSON.parse(customers[i].value);
        //console.log(info);
        transformed += `<tr>
                <td style=${'"border:1px solid;"'}>${info[0].address}</td>
                <td style=${'"border:1px solid;"'}>${info[0].fullname}</td>
                <td style=${'"border:1px solid;"'}>${info[0].personal}</td>
                <td style=${'"border:1px solid;"'}>${info[0].cadastral}</td>
                <td style=${'"border:1px solid;"'}>${
          info[0].power
        }</td><td style=${'"border:1px solid;"'}>${info[0].amount}  ${info[0].amount}</td>
                <td style=${'"border:1px solid;"'}>${
          info[0].prepared
            ? '&#8864;საყოფაცხოვრებო; ან</br>&#8414;არასაყოფაცხოვრებო'
            : '&#8414;საყოფაცხოვრებო; ან</br>&#8864;არასაყოფაცხოვრებო'
        }</td>
                <td style=${'"border:1px solid;"'}>${
          info[0].preparedfile ? '&#8864;დიახ; ან</br>&#8414;არა' : '&#8414;დიახ; ან</br>&#8864;არა'
        }</td>
                <td style=${'"border:1px solid;"'}>${
          info[0].will ? '&#8864;დიახ; ან</br>&#8414;არა' : '&#8414;დიახ; ან</br>&#8864;არა'
        }</td>
                <td style=${'"border:1px solid;"'}>${info[0].fullname}</td>
                </tr>`;
      }
      transformed += '</table>';
      //console.log(transformed);
      template = template.replace('{{customers}}', customers.length ? transformed : '');
    }
    for (let i = 0; i < 9; i++) {
      let myRegExp = new RegExp(`{{phone_1_${i}}}`);
      let square = `<div>${phone_1[i]}</div>`;
      template = template.replace(myRegExp, typeof phone_1 !== 'undefined' ? square : '&#8414;');
    }
    if (phone_2.length > 0) {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div>${phone_2[i]}</div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    } else {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div></div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    }
    template = template.replace(
      /{{smartmetercount}}/g,
      smartmetercount !== null ? smartmetercount : ''
    );
    template = template.replace(/{{docName}}/g, docName !== null ? docName : '__________________');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' && email_1.length > 0
        ? email_1
        : '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ @ _ _ _ _ _'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' && email_2.length > 0
        ? email_2
        : '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ @ _ _ _ _ _'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{month_1}}/g, typeof month_1 !== 'undefined' ? month_1 : '____');
    template = template.replace(
      /{{year}}/g,
      typeof year !== 'undefined' ? year : '__________________'
    );
    template = template.replace(
      /{{year_1}}/g,
      typeof year_1 !== 'undefined' ? year_1 : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________'
    );
    template = template.replace(
      /{{bankdetails}}/g,
      typeof bankdetails !== 'undefined' && bankdetails.length > 0
        ? bankdetails
        : '__________________'
    );
    template = template.replace(
      /{{telasi}}/g,
      typeof telasi !== 'undefined' && telasi.length > 0 ? telasi : '_________________'
    );
    template = template.replace(
      /{{gwp}}/g,
      typeof gwp !== 'undefined' && gwp.length > 0 ? gwp : '_________________'
    );
    template = template.replace(
      /{{amount}}/g,
      typeof amount !== 'undefined' && amount.length > 0 ? amount : '______'
    );
    template = template.replace(
      /{{money}}/g,
      typeof money !== 'undefined' && money.length > 0 ? money : '______'
    );
    template = template.replace(
      /{{power}}/g,
      typeof power !== 'undefined' && power.length > 0 ? power : '______'
    );
    template = template.replace(
      /{{quantity}}/g,
      typeof quantity !== 'undefined' && quantity.length > 0 ? quantity : '______'
    );
    template = template.replace(
      /{{household}}/g,
      typeof household !== 'undefined' && household.length > 0 ? household : '___'
    );
    template = template.replace(
      /{{nonhousehold}}/g,
      typeof nonhousehold !== 'undefined' && nonhousehold.length > 0 ? nonhousehold : '________'
    );
    template = template.replace(
      /{{otheramount}}/g,
      typeof otheramount !== 'undefined' && otheramount.length > 0 ? otheramount : '____'
    );
    template = template.replace(
      '{{cd_dvd}}',
      cd_dvd.length && cd_dvd[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_19}}',
      checkyes_19.length && checkyes_19[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_47}}',
      checkyes_47.length && checkyes_47[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_26}}',
      checkyes_26.length && checkyes_26[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_13}}',
      checkyes_13.length && checkyes_13[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_13}}',
      checkyes_13.length && checkyes_13[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    if (checkyes_1.length) {
      if (checkyes_1[0].value === 'true') {
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'false') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
      }
      if (checkyes_1[0].value === 'yes') {
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'no') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
      }
      if (checkyes_1[0].value === 'already') {
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8414;');
        template = template.replace('{{checkyes_1}}', '&#8864;');
      }
      //page=page.replace("{{checkyes_1}}",checkyes_1.length&&checkyes_1[0].value!=="false"?"&#8864;":"&#8414;");
    }

    this.setState({ template: template });
  };

  TemporaryCustomer = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 3);
    const year_1 = this.getDayMonthYear(date, 5);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let power = letterDocumentLines.filter((x) => x.key === 'power');
    if (power.length) power = power[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let powerText = letterDocumentLines.filter((x) => x.key === 'powerText');
    if (powerText.length) powerText = powerText[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    //console.log(checked);

    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(
      /{{customer_phone_2}}/g,
      typeof customer_phone_2 !== 'undefined' ? customer_phone_2 : '__________________'
    );
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' && email_1.length > 0 ? email_1 : '_______'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' && email_2.length > 0 ? email_2 : '________'
    );
    template = template.replace(
      /{{power}}/g,
      typeof powerText !== 'undefined' ? powerText : '__________________'
    );
    template = template.replace(
      /{{address}}/,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{address}}/,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{day}}/g,
      typeof day !== 'undefined' ? day : '__________________'
    );
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '__________________'
    );
    template = template.replace(
      /{{year_1}}/g,
      typeof year_1 !== 'undefined' ? year_1 : '__________________'
    );

    this.setState({ template: template });
  };

  ConnectDistributionNetwork = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    // let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    // if (addressto.length) addressto = addressto[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let street_Id = letterDocumentLines.filter((x) => x.key === 'street_Id');
    if (street_Id.length) street_Id = street_Id[0].value; //ადგილი (მისამართი), სადაც უნდა ჩატარდეს სამუშაოები
    let street_Name = letterDocumentLines.filter((x) => x.key === 'street_Name');
    if (street_Name.length) street_Name = street_Name[0].value;
    let addressto_building = letterDocumentLines.filter((x) => x.key === 'addressto_building');
    if (addressto_building.length) addressto_building = addressto_building[0].value;
    let addressto_entrance = letterDocumentLines.filter((x) => x.key === 'addressto_entrance');
    if (addressto_entrance.length) addressto_entrance = addressto_entrance[0].value;
    let addressto_floor = letterDocumentLines.filter((x) => x.key === 'addressto_floor');
    if (addressto_floor.length) addressto_floor = addressto_floor[0].value;
    let addressto_apartament = letterDocumentLines.filter((x) => x.key === 'addressto_apartament');
    if (addressto_apartament.length) addressto_apartament = addressto_apartament[0].value;
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
    const checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    const check_sixMonth = letterDocumentLines.filter((x) => x.key === 'check_sixMonth');
    let checkyes_6 = letterDocumentLines.filter((x) => x.key === 'checkyes_6');
    const checkyes_7 = letterDocumentLines.filter((x) => x.key === 'checkyes_7');
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
    const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
    const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
    const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
    const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
    const file_7 = letterDocumentLines.filter((val) => val.key === 'file_7'); // სხვა
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let addressto = '';
    //let streetName = this.state.reference_streets.filter(x=>x.id==street_Id); streetName=streetName[0].value;
    let buildingNum = addressto_building;
    let entrance = '';
    if (typeof addressto_entrance !== 'undefined') entrance += ', სად. ' + addressto_entrance;
    let floor = '';
    if (typeof addressto_floor !== 'undefined') floor += ', სართ. ' + addressto_floor;
    let apartament = '';
    if (typeof addressto_apartament !== 'undefined') apartament += ', ბინა ' + addressto_apartament;
    addressto = street_Name + ' ' + buildingNum + entrance + floor + apartament;

    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' && phone_1.length > 0 ? phone_1 : '_________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' && phone_2.length > 0 ? phone_2 : '_________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' && email_1.length > 0 ? email_1 : '_________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' && email_2.length > 0 ? email_2 : '______'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________'
    );
    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_2}}/g,
      file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_3}}/g,
      file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_7}}/g, file_7.length ? file_7[0].value : '__________');

    if (checkyes_6[0].value === 'false') {
      template = template.replace(/{{paymenttext}}/g, this.state.paymenttext);
      template = template.replace(/{{comment_label}}/g, 'შენიშვნის ველი:');
      template = template.replace(/{{comment}}/g, comment);
    } else {
      template = template.replace(/{{paymenttext}}/g, '');
      template = template.replace(/{{comment_label}}/g, '');
      template = template.replace(/{{comment}}/g, '');
    }

    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_sixMonth}}',
      check_sixMonth.length && check_sixMonth[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_sixMonth}}',
      check_sixMonth.length && check_sixMonth[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_6}}',
      checkyes_6.length && checkyes_6[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_6}}',
      checkyes_6.length && checkyes_6[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_7}}',
      checkyes_7.length && checkyes_7[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_7}}',
      checkyes_7.length && checkyes_7[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );

    this.setState({ template: template });
  };

  TechTerms = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 3);
    const year_1 = this.getDayMonthYear(date, 5);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let power = letterDocumentLines.filter((x) => x.key === 'power');
    if (power.length) power = power[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;

    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{power}}/g,
      typeof power !== 'undefined' ? power : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');

    this.setState({ template: template });
  };

  AbonentRegister = () => {
    try {
      const letterDocument = this.state.letterDocument;
      const letterDocumentLines = this.state.letterDocumentLines;
      let template = this.state.template;
      template = template.replace(
        /{{something}}/g,
        this.state.temp1.length > 0 ? this.state.temp1 : ''
      );
      const date = moment(letterDocument.rd).format('DD/MM/yyy');
      const day = this.getDayMonthYear(date, 1);
      const month = this.getDayMonthYear(date, 2);
      const year = this.getDayMonthYear(date, 4);
      let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
      if (fullname.length) fullname = fullname[0].value;
      let custN = letterDocumentLines.filter((x) => x.key === 'custN');
      if (custN.length) custN = custN[0].value;
      let personal = letterDocumentLines.filter((x) => x.key === 'personal');
      if (personal.length) personal = personal[0].value;
      let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
      if (phone_1.length) phone_1 = phone_1[0].value;
      let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
      if (phone_2.length) phone_2 = phone_2[0].value;
      let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
      if (email_1.length) email_1 = email_1[0].value;
      let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
      if (email_2.length) email_2 = email_2[0].value;
      let address = letterDocumentLines.filter((x) => x.key === 'address');
      if (address.length) address = address[0].value;
      let comment = letterDocumentLines.filter((x) => x.key === 'comment');
      if (comment.length) comment = comment[0].value;
      let applicant_fullname = letterDocumentLines.filter((x) => x.key === 'applicant_fullname');
      if (applicant_fullname.length) applicant_fullname = applicant_fullname[0].value;
      //let custN = letterDocumentLines.filter(x=>x.key==="custN"); if(custN.length) custN=custN[0].value;
      let applicant_personal = letterDocumentLines.filter((x) => x.key === 'applicant_personal');
      if (applicant_personal.length) applicant_personal = applicant_personal[0].value;
      let applicant_phone_1 = letterDocumentLines.filter((x) => x.key === 'applicant_phone_1');
      if (applicant_phone_1.length) applicant_phone_1 = applicant_phone_1[0].value;
      let applicant_phone_2 = letterDocumentLines.filter((x) => x.key === 'applicant_phone_2');
      if (applicant_phone_2.length) applicant_phone_2 = applicant_phone_2[0].value;
      let applicant_email_1 = letterDocumentLines.filter((x) => x.key === 'applicant_email_1');
      if (applicant_email_1.length) applicant_email_1 = applicant_email_1[0].value;
      let applicant_email_2 = letterDocumentLines.filter((x) => x.key === 'applicant_email_2');
      if (applicant_email_2.length) applicant_email_2 = applicant_email_2[0].value;
      let applicant_address = letterDocumentLines.filter((x) => x.key === 'applicantaddress');
      if (applicant_address.length) applicant_address = applicant_address[0].value;
      let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
      if (cadastral.length) cadastral = cadastral[0].value;
      let telasi = letterDocumentLines.filter((x) => x.key === 'telasi');
      if (telasi.length) telasi = telasi[0].value;
      let gwp = letterDocumentLines.filter((x) => x.key === 'gwp');
      if (gwp.length) gwp = gwp[0].value;
      let bank_name = letterDocumentLines.filter((x) => x.key === 'bank_name');
      if (bank_name.length) bank_name = bank_name[0].value;
      let bank_code = letterDocumentLines.filter((x) => x.key === 'bank_code');
      if (bank_code.length) bank_code = bank_code[0].value;
      let account_number = letterDocumentLines.filter((x) => x.key === 'account_number');
      if (account_number.length) account_number = account_number[0].value;
      let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
      let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
      let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
      let checkyes_7 = letterDocumentLines.filter((x) => x.key === 'checkyes_7');
      let checkyes_17 = letterDocumentLines.filter((x) => x.key === 'checkyes_17');
      let checkyes_18 = letterDocumentLines.filter((x) => x.key === 'checkyes_18');
      const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
      const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
      const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
      const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
      const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
      const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
      const file_7 = letterDocumentLines.filter((val) => val.key === 'file_7'); // სხვა
      let tools = letterDocumentLines.filter((val) => val.key === 'tools');

      tools = JSON.parse(tools[0].value);
      try {
        if (tools.length) {
          let machines = ``;
          tools.forEach((element) => {
            let cnt = 1;
            let temp = this.state.reference_devices.filter((x) => x.id === element.tool_Id);
            if (temp.length) {
              machines +=
                '<br/> 6.' +
                cnt +
                '. ' +
                'დანადგარის დასახელება:' +
                temp[0].value +
                '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                'რაოდენობა :' +
                element.quantity +
                '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                element.mcube +
                'მ&sup3;';
              machines += '<br/>';
            }
            cnt++;
          });

          template = template.replace('{{tools}}', machines !== `` ? machines : '');
        }
      } catch (err) {
        template = template.replace('{{tools}}', '___');
      }
      template = template.replace(
        /{{phone_1}}/g,
        typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
      );
      template = template.replace(
        /{{address}}/g,
        typeof address !== 'undefined' ? address : '__________________'
      );
      template = template.replace(
        /{{phone_2}}/g,
        typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
      );
      template = template.replace(
        /{{fullname}}/g,
        typeof fullname !== 'undefined' ? fullname : '__________________'
      );
      template = template.replace(
        /{{personal}}/g,
        typeof personal !== 'undefined' ? personal : '__________________'
      );
      template = template.replace(
        /{{email_1}}/g,
        typeof email_1 !== 'undefined' && email_1.length > 0 ? email_1 : '_______'
      );
      template = template.replace(
        /{{email_2}}/g,
        typeof email_2 !== 'undefined' && email_2.length > 0 ? email_2 : '________'
      );
      template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
      template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
      template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
      template = template.replace(
        /{{applicant_fullname}}/g,
        typeof applicant_fullname !== 'undefined' ? applicant_fullname : '__________________'
      );
      template = template.replace(
        /{{applicant_personal}}/g,
        typeof applicant_personal !== 'undefined' ? applicant_personal : '__________________'
      );
      template = template.replace(
        /{{applicant_phone_1}}/g,
        typeof applicant_phone_1 !== 'undefined' ? applicant_phone_1 : '__________________'
      );
      template = template.replace(
        /{{applicant_phone_2}}/g,
        typeof applicant_phone_2 !== 'undefined' ? applicant_phone_2 : '__________________'
      );
      template = template.replace(
        /{{applicant_email_1}}/g,
        typeof applicant_email_1 !== 'undefined' ? applicant_email_1 : '__________________'
      );
      template = template.replace(
        /{{applicant_email_2}}/g,
        typeof applicant_email_2 !== 'undefined' ? applicant_email_2 : '__________________'
      );
      template = template.replace(
        /{{applicant_address}}/g,
        typeof applicant_address !== 'undefined' ? applicant_address : '__________________'
      );
      template = template.replace(
        /{{telasi}}/g,
        typeof telasi !== 'undefined' ? telasi : '__________________'
      );
      template = template.replace(
        /{{gwp}}/g,
        typeof gwp !== 'undefined' ? gwp : '__________________'
      );
      template = template.replace(
        /{{bank_name}}/g,
        typeof bank_name !== 'undefined' ? bank_name : '_____'
      );
      template = template.replace(
        /{{bank_code}}/g,
        typeof bank_code !== 'undefined' ? bank_code : '_____'
      );
      template = template.replace(
        /{{account_number}}/g,
        typeof account_number !== 'undefined' ? account_number : '__________________'
      );
      template = template.replace(
        /{{cadastral}}/g,
        typeof cadastral !== 'undefined' ? cadastral : '__________________'
      );
      template = template.replace(
        /{{custN}}/g,
        typeof custN !== 'undefined' ? custN : '__________________'
      );
      template = template.replace(
        /{{file_1}}/g,
        file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
      );
      template = template.replace(
        /{{file_2}}/g,
        file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
      );
      template = template.replace(
        /{{file_3}}/g,
        file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
      );
      // template = template.replace(
      //   /{{paymenttext}}/g,
      //   checkyes_17[0].value === 'false' ? this.state.paymenttext : ''
      // );
      if (checkyes_17[0].value === 'false') {
        template = template.replace(/{{paymenttext}}/g, this.state.paymenttext);
        template = template.replace(/{{comment_label}}/g, 'შენიშვნის ველი:');
        template = template.replace(/{{comment}}/g, comment);
      } else {
        template = template.replace(/{{paymenttext}}/g, '');
        template = template.replace(/{{comment_label}}/g, '');
        template = template.replace(/{{comment}}/g, '');
      }

      template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
      template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
      template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
      template = template.replace(/{{file_7}}/g, file_7.length ? file_7[0].value : '__________');
      template = template.replace(
        '{{checkyes_1}}',
        checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
      );
      template = template.replace(
        '{{checkyes_1}}',
        checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
      );
      template = template.replace(
        '{{checkyes_4}}',
        checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
      );
      template = template.replace(
        '{{checkyes_4}}',
        checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
      );
      template = template.replace(
        '{{checkyes_5}}',
        checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
      );
      template = template.replace(
        '{{checkyes_5}}',
        checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
      );
      template = template.replace(
        '{{checkyes_7}}',
        checkyes_7.length && checkyes_7[0].value !== 'false' ? '&#8864;' : '&#8414;'
      );
      template = template.replace(
        '{{checkyes_7}}',
        checkyes_7.length && checkyes_7[0].value !== 'false' ? '&#8414;' : '&#8864;'
      );
      template = template.replace(
        '{{checkyes_17}}',
        checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8864;' : '&#8414;'
      );
      template = template.replace(
        '{{checkyes_17}}',
        checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8414;' : '&#8864;'
      );
      template = template.replace(
        '{{checkyes_18}}',
        checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8864;' : '&#8414;'
      );
      template = template.replace(
        '{{checkyes_18}}',
        checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8414;' : '&#8864;'
      );

      this.setState({ template: template });
    } catch (err) {
      alert('მონაცემების ჩატვირთვა ვერ მოხერხდა');
      //console.log(err);
    }
  };

  AbonentInfo = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let date1 = letterDocumentLines.filter((x) => x.key === 'date1');
    if (date1.length) date1 = date1[0].value;
    let date2 = letterDocumentLines.filter((x) => x.key === 'date2');
    if (date2.length) date2 = date2[0].value;
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
    const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
    const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
    const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
    const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
    const file_7 = letterDocumentLines.filter((val) => val.key === 'file_7'); // სხვა
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_8 = letterDocumentLines.filter((x) => x.key === 'checkyes_8');
    let checkyes_9 = letterDocumentLines.filter((x) => x.key === 'checkyes_9');
    let checkyes_10 = letterDocumentLines.filter((x) => x.key === 'checkyes_10');
    let check_customers = letterDocumentLines.filter((x) => x.key === 'check_customers');
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    //console.log(customer_phone_2);

    try {
      let cs = letterDocumentLines.filter((val) => val.key === 'customersList');
      //console.log(cs);
      cs = JSON.parse(cs[0].value);
      //console.log(cs);
      if (cs.length) {
        let list = ``;
        cs.forEach((element) => {
          //console.log(element);
          let cnt = 1;
          list +=
            '<br/> 6.' +
            cnt +
            '. ' +
            'აბონენტის №:' +
            element.customerNumber +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
            'მისამართი  :' +
            element.customerAddress +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
            'ს/კ:' +
            element.customerCadastral +
            ';';
          list += '<br/>';

          cnt++;
        });

        template = template.replace('{{customerslist}}', list !== `` ? list : '');
      }
    } catch (err) {
      template = template.replace('{{customerslist}}', '');
    }

    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(
      /{{customer_phone_2}}/g,
      typeof customer_phone_2 !== 'undefined' ? customer_phone_2 : '__________________'
    );
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      customer_email_2.length > 0 ? customer_email_2 : '__________________'
    );
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' && phone_1.length > 0 ? phone_1 : '_________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' && phone_2.length > 0 ? phone_2 : '_________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' && email_1.length > 0 ? email_1 : '_________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' && email_2.length > 0 ? email_2 : '______'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{date1}}/g,
      typeof date1 !== 'undefined' && date1.length > 0 ? date1 : '______'
    );
    template = template.replace(
      /{{date2}}/g,
      typeof date2 !== 'undefined' && date2.length > 0 ? date2 : '______'
    );

    template = template.replace(
      /{{check_customers}}/g,
      check_customers.length && check_customers[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_2}}/g,
      file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_3}}/g,
      file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_7}}/g, file_7.length ? file_7[0].value : '__________');
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_8}}',
      checkyes_8.length && checkyes_8[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_9}}',
      checkyes_9.length && checkyes_9[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_10}}',
      checkyes_10.length && checkyes_10[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    this.setState({ template: template });
  };

  OldAbonentRegister = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_fullname = ' ' + customer_firstname + ' ' + customer_lastname;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let customer_address = letterDocumentLines.filter((x) => x.key === 'customeraddress');
    if (customer_address.length) customer_address = customer_address[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let telasi = letterDocumentLines.filter((x) => x.key === 'telasi');
    if (telasi.length) telasi = telasi[0].value;
    let gwp = letterDocumentLines.filter((x) => x.key === 'gwp');
    if (gwp.length) gwp = gwp[0].value;
    let bank_name = letterDocumentLines.filter((x) => x.key === 'bank_name');
    if (bank_name.length) bank_name = bank_name[0].value;
    let bank_code = letterDocumentLines.filter((x) => x.key === 'bank_code');
    if (bank_code.length) bank_code = bank_code[0].value;
    let account_number = letterDocumentLines.filter((x) => x.key === 'account_number');
    if (account_number.length) account_number = account_number[0].value;
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
    const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
    const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
    const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
    const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
    const file_7 = letterDocumentLines.filter((val) => val.key === 'file_7'); // სხვა
    const file_cancelDoc = letterDocumentLines.filter((val) => val.key === 'file_cancelDoc');
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_7 = letterDocumentLines.filter((x) => x.key === 'checkyes_7');
    let checkyes_17 = letterDocumentLines.filter((x) => x.key === 'checkyes_17');
    let checkyes_18 = letterDocumentLines.filter((x) => x.key === 'checkyes_18');
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let tools = letterDocumentLines.filter((val) => val.key === 'tools');
    tools = JSON.parse(tools[0].value);
    try {
      if (tools.length) {
        let machines = ``;
        tools.forEach((element) => {
          let cnt = 1;
          let temp = this.state.reference_devices.filter((x) => x.id === element.tool_Id);
          machines +=
            '<br/> 5.' +
            cnt +
            '. ' +
            'დანადგარის დასახელება:' +
            temp[0].value +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
            'რაოდენობა :' +
            element.quantity +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
            element.mcube +
            'მ&sup3;';
          machines += '<br/>';
          cnt++;
        });
        //machines+="<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>";
        template = template.replace('{{tools}}', machines !== `` ? machines : '');
      }
    } catch (err) {
      template = template.replace('{{tools}}', '__');
    }
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' && phone_1.length > 0 ? phone_1 : '_________'
    );
    template = template.replace(/{{phone_2}}/g, '');
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' && email_1.length > 0 ? email_1 : '_________'
    );
    template = template.replace(/{{email_2}}/g, '');
    template = template.replace(
      /{{customer_address}}/g,
      typeof addressto !== 'undefined' && addressto.length > 0 ? addressto : '_________'
    );
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{customer_fullname}}/g,
      typeof customer_fullname !== 'undefined' ? customer_fullname : '__________________'
    );
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(
      /{{customer_phone_2}}/g,
      typeof customer_phone_2 !== 'undefined' ? customer_phone_2 : '__________________'
    );
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(
      /{{customer_address}}/g,
      typeof customer_address !== 'undefined' ? customer_address : '__________________'
    );
    template = template.replace(
      /{{telasi}}/g,
      typeof telasi !== 'undefined' ? telasi : '__________________'
    );
    template = template.replace(
      /{{gwp}}/g,
      typeof gwp !== 'undefined' ? gwp : '__________________'
    );
    template = template.replace(
      /{{bank_name}}/g,
      typeof bank_name !== 'undefined' ? bank_name : '_____'
    );
    template = template.replace(
      /{{bank_code}}/g,
      typeof bank_code !== 'undefined' ? bank_code : '_____'
    );
    template = template.replace(
      /{{account_number}}/g,
      typeof account_number !== 'undefined' ? account_number : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_2}}/g,
      file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_3}}/g,
      file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
    template = template.replace(
      /{{file_cancelDoc}}/g,
      file_cancelDoc.length ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_7}}/g, file_7.length ? file_7[0].value : '__________');
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_7}}',
      checkyes_7.length && checkyes_7[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_7}}',
      checkyes_7.length && checkyes_7[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_17}}',
      checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_17}}',
      checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    this.setState({ template: template });
  };

  ProjectCorrecting = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const month_1 = this.getDayMonthYear(date, 3);
    const year = this.getDayMonthYear(date, 4);
    const year_1 = this.getDayMonthYear(date, 5);
    let docName = null;
    if (letterDocument.docName !== null) docName = letterDocument.docName;
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_6 = letterDocumentLines.filter((x) => x.key === 'checkyes_6');
    let checkyes_30 = letterDocumentLines.filter((x) => x.key === 'checkyes_30');
    let checkyes_31 = letterDocumentLines.filter((x) => x.key === 'checkyes_31');
    let checkyes_32 = letterDocumentLines.filter((x) => x.key === 'checkyes_32');
    let checkyes_33 = letterDocumentLines.filter((x) => x.key === 'checkyes_33');
    let checkyes_34 = letterDocumentLines.filter((x) => x.key === 'checkyes_34');
    let checkyes_35 = letterDocumentLines.filter((x) => x.key === 'checkyes_35');
    let checkother = letterDocumentLines.filter((x) => x.key === 'checkother');
    let otherTask = letterDocumentLines.filter((x) => x.key === 'otherTask');
    if (otherTask.length) otherTask = otherTask[0].value;
    let documents = letterDocumentLines.filter((x) => x.isFile);
    //let customer_fullname = customer_firstname+" "+customer_lastname;
    //template=template.replace(/{{customer_fullname}}/g,customer_fullname);
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(
      /{{docName}}/g,
      typeof docName !== 'undefined' ? docName : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' ? customer_lastname : '__________________'
    );
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_6}}',
      checkyes_6.length && checkyes_6[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_6}}',
      checkyes_6.length && checkyes_6[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_30}}',
      checkyes_30.length && checkyes_30[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_31}}',
      checkyes_31.length && checkyes_31[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_32}}',
      checkyes_32.length && checkyes_32[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_33}}',
      checkyes_33.length && checkyes_33[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_34}}',
      checkyes_34.length && checkyes_34[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_35}}',
      checkyes_35.length && checkyes_35[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkother}}',
      checkother.length && checkother[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{otherTask}}/g,
      typeof otherTask !== 'undefined' ? otherTask : '__________________'
    );
    try {
      if (documents.length > 0) {
        let docs = ``;
        documents.forEach((element) => {
          if (!element.isPrimary) {
            let cnt = 1;
            if (element.key === 'file_1') {
              docs += '<br/>' + cnt + '. ' + 'პირადობა';
              docs += '<br/>';
            } else {
              let filename = element.value.replace(/^.*[\\\/]/, '');
              docs += '<br/>' + cnt + '. ' + filename;
              docs += '<br/>';
            }

            cnt++;
          }
        });
        template = template.replace('{{documents}}', docs !== `` ? docs : '');
      }
    } catch (err) {
      template = template.replace('{{documents}}', '___');
    }
    this.setState({ template: template });
  };

  InternalNetworkInstalatlion = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 3);
    const year_1 = this.getDayMonthYear(date, 5);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let tools = letterDocumentLines.filter((val) => val.key === 'tools');
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    tools = JSON.parse(tools[0].value);
    try {
      if (tools.length) {
        let machines = ``;
        tools.forEach((element) => {
          let cnt = 1;
          let temp = this.state.reference_devices.filter((x) => x.id === element.tool_Id);
          if (temp.length) {
            machines += '<br/>' + cnt + '. ' + temp[0].value;
            machines += '<br/>';
          }
          cnt++;
        });

        template = template.replace('{{tools}}', machines !== `` ? machines : '');
      }
    } catch (err) {
      template = template.replace('{{tools}}', '__');
    }
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(/{{email_1}}/g, email_1.length ? email_1 : '__________________');
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(/{{personal}}/g, personal.length ? personal : '__________________');
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );

    this.setState({ template: template });
  };

  InternalNetworkUpdate = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 2);
    const year_1 = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_35 = letterDocumentLines.filter((x) => x.key === 'checkyes_35');
    let checkyes_36 = letterDocumentLines.filter((x) => x.key === 'checkyes_36');
    let tools = letterDocumentLines.filter((val) => val.key === 'tools');
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;

    tools = JSON.parse(tools[0].value);
    try {
      if (tools.length) {
        let machines = ``;
        tools.forEach((element) => {
          //console.log(22);
          let cnt = 1;
          let temp = this.state.reference_devices.filter((x) => x.id === element.tool_Id);
          //console.log(temp)
          if (temp.length > 0) {
            machines += '<br/>' + cnt + '. ' + temp[0].value + '&nbsp;&nbsp;';
            machines += '<br/>';
            cnt++;
          }
          // machines+="<br/>"+cnt+". "+temp[0].value+'&nbsp;&nbsp;';
          // machines+="<br/>";
          // cnt++;
        });

        template = template.replace('{{tools}}', machines !== `` ? machines : '');
      }
    } catch (err) {
      template = template.replace('{{tools}}', '___');
    }
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(/{{email_1}}/g, email_1.length ? email_1 : '__________________');
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(/{{personal}}/g, personal.length ? personal : '__________________');
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_35}}',
      checkyes_35.length && checkyes_35[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_36}}',
      checkyes_36.length && checkyes_36[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    this.setState({ template: template });
  };

  DetectorMontage = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 3);
    const year_1 = this.getDayMonthYear(date, 5);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let account_number = letterDocumentLines.filter((x) => x.key === 'account_number');
    if (account_number.length) account_number = account_number[0].value;
    let p150amount = letterDocumentLines.filter((x) => x.key === 'p150amount');
    if (p150amount.length) p150amount = p150amount[0].value;
    let p150text = letterDocumentLines.filter((x) => x.key === 'p150text');
    if (p150text.length) p150text = p150text[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let documents = letterDocumentLines.filter((x) => x.isFile);
    let p150 = letterDocumentLines.filter((x) => x.key === 'p150');
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;

    try {
      if (documents.length > 0) {
        let docs = ``;
        let cnt = 1;
        documents.forEach((element) => {
          if (!element.isPrimary) {
            if (element.key === 'file_1') {
              docs += '<br/>' + cnt + '. ' + 'პირადობა';
              docs += '<br/>';
            } else if (element.key === 'file_2') {
              docs += '<br/>' + cnt + '. ' + 'ამონაწერი საჯარო რეესტრიდან';
              docs += '<br/>';
            } else {
              let filename = element.value.replace(/^.*[\\\/]/, '');
              docs += '<br/>' + cnt + '. ' + filename;
              docs += '<br/>';
            }
            cnt++;
          }
        });
        template = template.replace('{{documents}}', docs !== `` ? docs : '');
      }
    } catch (err) {
      template = template.replace('{{documents}}', '___');
    }
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(/{{email_1}}/g, email_1.length ? email_1 : '__________________');
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(/{{personal}}/g, personal.length ? personal : '__________________');
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{account_number}}/g,
      typeof account_number !== 'undefined' ? account_number : '__________________'
    );
    template = template.replace(
      /{{p150text}}/g,
      typeof p150text !== 'undefined' ? p150text : '__________________'
    );
    template = template.replace(
      /{{p150amount}}/g,
      typeof p150amount !== 'undefined' ? p150amount : '__________________'
    );
    template = template.replace(
      '{{p150}}',
      p150.length && p150[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{p150}}',
      p150.length && p150[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );

    this.setState({ template: template });
  };

  MoveCounterTube = () => {
    //console.log(1);
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 2);
    const year_1 = this.getDayMonthYear(date, 4);
    let documents = letterDocumentLines.filter((x) => x.isFile);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let checkyes_ground = letterDocumentLines.filter((x) => x.key === 'checkyes_ground');
    let checkyes_reconstruction = letterDocumentLines.filter(
      (x) => x.key === 'checkyes_reconstruction'
    );
    let checkyes_vault = letterDocumentLines.filter((x) => x.key === 'checkyes_vault');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_35 = letterDocumentLines.filter((x) => x.key === 'checkyes_35');
    let checkyes_36 = letterDocumentLines.filter((x) => x.key === 'checkyes_36');
    let tools = letterDocumentLines.filter((val) => val.key === 'tools');
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;

    try {
      if (documents.length > 0) {
        let docs = ``;
        documents.forEach((element) => {
          if (!element.isPrimary) {
            let cnt = 1;
            if (element.key === 'file_1') {
              docs += '<br/>' + cnt + '. ' + 'პირადობა';
              docs += '<br/>';
            } else {
              let filename = element.value.replace(/^.*[\\\/]/, '');
              docs += '<br/>' + cnt + '. ' + filename;
              docs += '<br/>';
            }

            cnt++;
          }
        });
        template = template.replace('{{documents}}', docs !== `` ? docs : '');
      }
    } catch (err) {
      template = template.replace('{{documents}}', '___');
    }
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(/{{email_1}}/g, email_1.length ? email_1 : '__________________');
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(/{{personal}}/g, personal.length ? personal : '__________________');
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : ''
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : ''
    );
    template = template.replace(/{{phone_2}}/g, typeof phone_2 !== 'undefined' ? phone_2 : '');
    template = template.replace(/{{email_1}}/g, typeof email_1 !== 'undefined' ? email_1 : '');
    template = template.replace(/{{email_2}}/g, typeof email_2 !== 'undefined' ? email_2 : '');
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_35}}',
      checkyes_35.length && checkyes_35[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_36}}',
      checkyes_36.length && checkyes_36[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_ground}}',
      checkyes_ground.length && checkyes_ground[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_reconstruction}}',
      checkyes_reconstruction.length && checkyes_reconstruction[0].value !== 'false'
        ? '&#8864;'
        : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_vault}}',
      checkyes_vault.length && checkyes_vault[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    this.setState({ template: template });
  };

  AccountingUpdate = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let other = letterDocumentLines.filter((x) => x.key === 'other');
    if (other.length) other = other[0].value;
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
    const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
    const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
    const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
    const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
    const file_7 = letterDocumentLines.filter((val) => val.key === 'file_7'); // სხვა
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_39 = letterDocumentLines.filter((x) => x.key === 'checkyes_39');
    let checkyes_40 = letterDocumentLines.filter((x) => x.key === 'checkyes_40');
    let checkyes_41 = letterDocumentLines.filter((x) => x.key === 'checkyes_41');
    let checkyes_42 = letterDocumentLines.filter((x) => x.key === 'checkyes_42');
    let checkyes_43 = letterDocumentLines.filter((x) => x.key === 'checkyes_43');
    let checkyes_44 = letterDocumentLines.filter((x) => x.key === 'checkyes_44');
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;

    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_2}}/g,
      file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_3}}/g,
      file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_7}}/g, file_7.length ? file_7[0].value : '__________');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{other}}/g,
      typeof other !== 'undefined' ? other : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_39}}',
      checkyes_39.length && checkyes_39[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_40}}',
      checkyes_40.length && checkyes_40[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_41}}',
      checkyes_41.length && checkyes_41[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_42}}',
      checkyes_42.length && checkyes_42[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_43}}',
      checkyes_43.length && checkyes_43[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_44}}',
      checkyes_44.length && checkyes_44[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    this.setState({ template: template });
  };

  CheckingCounter = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
    const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
    const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
    const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
    const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
    const file_7 = letterDocumentLines.filter((val) => val.key === 'file_7'); // სხვა
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_2 = letterDocumentLines.filter((x) => x.key === 'checkyes_2');
    let checkyes_3 = letterDocumentLines.filter((x) => x.key === 'checkyes_3');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;

    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_2}}/g,
      file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_3}}/g,
      file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_7}}/g, file_7.length ? file_7[0].value : '__________');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_2}}',
      checkyes_2.length && checkyes_2[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_3}}',
      checkyes_3.length && checkyes_3[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    this.setState({ template: template });
  };

  TermiantionRecovery = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let date1 = letterDocumentLines.filter((x) => x.key === 'date1');
    if (date1.length) date1 = date1[0].value;
    let date2 = letterDocumentLines.filter((x) => x.key === 'date2');
    if (date2.length) date2 = date2[0].value;
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
    const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
    const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
    const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
    const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
    const file_7 = letterDocumentLines.filter((val) => val.key === 'file_7'); // სხვა
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_29 = letterDocumentLines.filter((x) => x.key === 'checkyes_29');
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;

    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_2}}/g,
      file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_3}}/g,
      file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_7}}/g, file_7.length ? file_7[0].value : '__________');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      '{{date1}}',
      checkyes_29.length && checkyes_29[0].value !== 'true' ? date1 : '______;'
    );
    template = template.replace(
      '{{date1}}',
      checkyes_29.length && checkyes_29[0].value === 'true' ? date1 : '______;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );

    template = template.replace(
      '{{checkyes_29}}',
      checkyes_29.length && checkyes_29[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );

    template = template.replace(
      '{{checkyes_29}}',
      checkyes_29.length && checkyes_29[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    template = template.replace(/{{otherdocuments}}/g, '__________________');

    this.setState({ template: template });
  };

  MovingAmount = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const month_1 = this.getDayMonthYear(date, 3);
    const year = this.getDayMonthYear(date, 4);
    const year_1 = this.getDayMonthYear(date, 5);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let amount1 = letterDocumentLines.filter((x) => x.key === 'amount1');
    if (amount1.length) amount1 = amount1[0].value;
    let contract_n = letterDocumentLines.filter((x) => x.key === 'contract_n');
    if (contract_n.length) contract_n = contract_n[0].value;

    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(/{{month_1}}/g, typeof month_1 !== 'undefined' ? month_1 : '___');
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{amount1}}/g,
      typeof amount1 !== 'undefined' ? amount1 : '__________________'
    );
    template = template.replace(
      /{{contract_n}}/g,
      typeof contract_n !== 'undefined' ? contract_n : '__________________'
    );
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(
      /{{customer_phone_2}}/g,
      typeof customer_phone_2 !== 'undefined' ? customer_phone_2 : '__________________'
    );
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' && email_1.length > 0 ? email_1 : '_______'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' && email_2.length > 0 ? email_2 : '________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );

    this.setState({ template: template });
  };

  AmountCorrecting = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let custN1 = letterDocumentLines.filter((x) => x.key === 'custN1');
    if (custN1.length) custN1 = custN1[0].value;
    let account_number = letterDocumentLines.filter((x) => x.key === 'account_number');
    if (account_number.length) account_number = account_number[0].value;
    let contract_n = letterDocumentLines.filter((x) => x.key === 'contract_n');
    if (contract_n.length) contract_n = contract_n[0].value;
    let date1 = letterDocumentLines.filter((x) => x.key === 'date1');
    if (date1.length) date1 = date1[0].value;
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_11 = letterDocumentLines.filter((x) => x.key === 'checkyes_11');
    let check_air = letterDocumentLines.filter((x) => x.key === 'check_air');
    let check_deposit = letterDocumentLines.filter((x) => x.key === 'check_deposit');
    let check_fine = letterDocumentLines.filter((x) => x.key === 'check_fine');
    let check_renewal = letterDocumentLines.filter((x) => x.key === 'check_renewal');
    let check_nonlegal = letterDocumentLines.filter((x) => x.key === 'check_nonlegal');
    let check_counter = letterDocumentLines.filter((x) => x.key === 'check_counter');
    let check_agreement = letterDocumentLines.filter((x) => x.key === 'check_agreement');
    let check_power = letterDocumentLines.filter((x) => x.key === 'check_power');
    let check_newcust = letterDocumentLines.filter((x) => x.key === 'check_newcust');
    let check_difference = letterDocumentLines.filter((x) => x.key === 'check_difference');
    let checkyes_correct = letterDocumentLines.filter((x) => x.key === 'checkyes_correct');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
    const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
    const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
    const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
    const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;

    template = template.replace(
      '{{check_air}}',
      check_air.length && check_air[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_deposit}}',
      check_deposit.length && check_deposit[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_fine}}',
      check_fine.length && check_fine[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_renewal}}',
      check_renewal.length && check_renewal[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_nonlegal}}',
      check_nonlegal.length && check_nonlegal[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_counter}}',
      check_counter.length && check_counter[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_agreement}}',
      check_agreement.length && check_agreement[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_power}}',
      check_power.length && check_power[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_newcust}}',
      check_newcust.length && check_newcust[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_difference}}',
      check_difference.length && check_difference[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{check_newcust}}',
      check_newcust.length && check_newcust[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_correct}}',
      checkyes_correct.length && checkyes_correct[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_correct}}',
      checkyes_correct.length && checkyes_correct[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    template = template.replace(
      '{{checkyes_11}}',
      checkyes_11.length && checkyes_11[0].value !== 'yes' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_11}}',
      checkyes_11.length && checkyes_11[0].value !== 'no' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_11}}',
      checkyes_11.length && checkyes_11[0].value !== 'already' ? '&#8414;' : '&#8864;'
    );
    console.log(custN1);
    template = template.replace(/{{custN1}}/g, typeof custN1 !== 'undefined' ? custN1 : '______');
    template = template.replace(
      /{{account_number}}/g,
      typeof account_number !== 'undefined' ? account_number : '_______'
    );
    template = template.replace(
      /{{contract_n}}/g,
      typeof contract_n !== 'undefined' ? contract_n : '___________'
    );
    template = template.replace(/{{date1}}/g, typeof date1 !== 'undefined' ? date1 : '________');

    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_2}}/g,
      file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_3}}/g,
      file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );

    this.setState({ template: template });
  };

  AmountBack = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let account_number = letterDocumentLines.filter((x) => x.key === 'account_number');
    if (account_number.length) account_number = account_number[0].value;
    let date1 = letterDocumentLines.filter((x) => x.key === 'date1');
    if (date1.length) date1 = date1[0].value;
    let custN1 = letterDocumentLines.filter((x) => x.key === 'custN1');
    if (custN1.length) custN1 = custN1[0].value;
    let contract_n = letterDocumentLines.filter((x) => x.key === 'contract_n');
    if (contract_n.length) contract_n = contract_n[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_11 = letterDocumentLines.filter((x) => x.key === 'checkyes_11');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
    const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
    const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
    const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
    const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    //console.log(address);
    template = template.replace(/{{custN1}}/g, typeof custN1 !== 'undefined' ? custN1 : '______');
    template = template.replace(
      /{{contract_n}}/g,
      typeof contract_n !== 'undefined' ? contract_n : '__________________'
    );
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{date1}}/g, typeof date1 !== 'undefined' ? date1 : '_____');
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_11}}',
      checkyes_11.length && checkyes_11[0].value !== 'yes' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_11}}',
      checkyes_11.length && checkyes_11[0].value !== 'no' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_11}}',
      checkyes_11.length && checkyes_11[0].value !== 'already' ? '&#8414;' : '&#8864;'
    );
    // template = template.replace(
    //   '{{checkyes_11}}',
    //   checkyes_11.length && checkyes_11[0].value !== 'false' ? '&#8864;' : '&#8414;'
    // );
    // template = template.replace(
    //   '{{checkyes_11}}',
    //   checkyes_11.length && checkyes_11[0].value !== 'false' ? '&#8414;' : '&#8864;'
    // );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_2}}/g,
      file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_3}}/g,
      file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{address}}/,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{address}}/,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{account_number}}/g,
      typeof account_number !== 'undefined' ? account_number : '__________________'
    );

    this.setState({ template: template });
  };

  AmountDeWrite = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 3);
    const year_1 = this.getDayMonthYear(date, 5);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let head = letterDocumentLines.filter((x) => x.key === 'head');
    if (head.length) head = head[0].value;
    let debt_amount = letterDocumentLines.filter((x) => x.key === 'debt_amount');
    if (debt_amount.length) debt_amount = debt_amount[0].value;
    let debt_amounttext = letterDocumentLines.filter((x) => x.key === 'debt_amounttext');
    if (debt_amounttext.length) debt_amounttext = debt_amounttext[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;

    //console.log(checked)

    try {
      template = template.replace(
        /{{physical}}/g,
        typeof checked !== 'undefined' && checked === 'true' ? 'ფიზიკური პირი ' : 'იურიდიული პირი'
      );
    } catch (err) {
      template = template.replace(/{{physical}}/g, 'ფიზიკური პირი ');
    }

    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(/{{email_1}}/g, email_1.length ? email_1 : '__________________');
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');

    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    if (typeof checked !== 'undefined' && checked === 'false') {
      template = template.replace(
        /{{head}}/g,
        typeof fullname !== 'undefined' ? fullname : '__________________'
      );
      template = template.replace(
        /{{fullname}}/,
        typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
      );
    } else {
      template = template.replace(/{{head}}/g, '__________________');
      template = template.replace(
        /{{fullname}}/g,
        typeof customer_firstname !== 'undefined'
          ? customer_firstname + ' ' + customer_lastname
          : '__________________'
      );
    }
    template = template.replace(
      /{{fullname}}/,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{debt_amount}}/g,
      typeof debt_amount !== 'undefined' ? debt_amount : '__________________'
    );
    template = template.replace(
      /{{debt_amounttext}}/g,
      typeof debt_amounttext !== 'undefined' ? debt_amounttext : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );

    this.setState({ template: template });
  };

  MovingAmountToNumber = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 3);
    const year_1 = this.getDayMonthYear(date, 5);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let date1 = letterDocumentLines.filter((x) => x.key === 'date1');
    if (date1.length) date1 = date1[0].value;
    let amount1 = letterDocumentLines.filter((x) => x.key === 'amount1');
    if (amount1.length) amount1 = amount1[0].value;
    let amount2 = letterDocumentLines.filter((x) => x.key === 'amount2');
    if (amount2.length) amount2 = amount2[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');

    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(/{{email_1}}/g, email_1.length ? email_1 : '__________________');
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{address}}/,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{address}}/,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(/{{date1}}/g, typeof date1 !== 'undefined' ? date1 : '__');
    template = template.replace(/{{amount1}}/g, typeof amount1 !== 'undefined' ? amount1 : '__');
    template = template.replace(/{{amount2}}/g, typeof amount2 !== 'undefined' ? amount2 : '__');

    this.setState({ template: template });
  };

  AmountBackFromInstall = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 3);
    const year_1 = this.getDayMonthYear(date, 5);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let date1 = letterDocumentLines.filter((x) => x.key === 'date1');
    if (date1.length) date1 = date1[0].value;
    let amount1 = letterDocumentLines.filter((x) => x.key === 'amount1');
    if (amount1.length) amount1 = amount1[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let account_number = letterDocumentLines.filter((x) => x.key === 'account_number');
    if (account_number.length) account_number = account_number[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');

    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(/{{email_1}}/g, email_1.length ? email_1 : '__________________');
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(/{{date1}}/g, typeof date1 !== 'undefined' ? date1 : '__');
    template = template.replace(/{{amount1}}/g, typeof amount1 !== 'undefined' ? amount1 : '__');
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{account_number}}/g,
      typeof account_number !== 'undefined' ? account_number : '__________________'
    );

    this.setState({ template: template });
  };

  DebtRedistribution = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let debt_amount = letterDocumentLines.filter((x) => x.key === 'debt_amount');
    if (debt_amount.length) debt_amount = debt_amount[0].value;
    let debt_months = letterDocumentLines.filter((x) => x.key === 'debt_months');
    if (debt_months.length) debt_months = debt_months[0].value;
    let debt_before_amount = letterDocumentLines.filter((x) => x.key === 'debt_before_amount');
    if (debt_before_amount.length) debt_before_amount = debt_before_amount[0].value;
    let checkyes_1 = letterDocumentLines.filter((x) => x.key === 'checkyes_1');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_2 = letterDocumentLines.filter((val) => val.key === 'file_2'); // ამონაწერი საჯარო რესტრიდან
    const file_3 = letterDocumentLines.filter((val) => val.key === 'file_3'); // ამონაწერი სამეწარმეო რეესტრიდან
    const file_4 = letterDocumentLines.filter((val) => val.key === 'file_4'); // უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის შემთხვევაში)
    const file_5 = letterDocumentLines.filter((val) => val.key === 'file_5'); // გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
    const file_6 = letterDocumentLines.filter((val) => val.key === 'file_6'); // საბანკო რეკვიზიტები
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;

    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_1}}',
      checkyes_1.length && checkyes_1[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_2}}/g,
      file_2.length && file_2[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_3}}/g,
      file_3.length && file_3[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(/{{file_4}}/g, file_4.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_5}}/g, file_5.length ? '&#8864;' : '&#8414;');
    template = template.replace(/{{file_6}}/g, file_6.length ? '&#8864;' : '&#8414;');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{debt_amount}}/g,
      typeof debt_amount !== 'undefined' ? debt_amount : '__________________'
    );
    template = template.replace(
      /{{debt_months}}/g,
      typeof debt_months !== 'undefined' ? debt_months : '__________________'
    );
    template = template.replace(
      /{{debt_before_amount}}/g,
      typeof debt_before_amount !== 'undefined' ? debt_before_amount : '__________________'
    );

    this.setState({ template: template });
  };

  CancelApplication = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp2.length > 0 ? this.state.temp2 : ''
    );
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const year = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let date2 = letterDocumentLines.filter((x) => x.key === 'date2');
    if (date2.length) date2 = date2[0].value;
    // const date2_day = this.getDayMonthYear(date2,1);
    // const date2_month = this.getDayMonthYear(date2,2);
    // const date2_year = this.getDayMonthYear(date,4);
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let applicationName = letterDocumentLines.filter((x) => x.key === 'applicationName');
    if (applicationName.length) applicationName = applicationName[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;

    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');

    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '_________');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '__');
    template = template.replace(/{{date}}/g, typeof date2 !== 'undefined' ? date2 : '______');
    // template=template.replace(/{{date2_month}}/g,typeof(date2_month)!=="undefined"?date2_month:"_________");
    // template=template.replace(/{{date2_year}}/g,typeof(date2_year)!=="undefined"?date2_year:"__");

    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{applicationName}}/g,
      typeof applicationName !== 'undefined' ? applicationName : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );

    this.setState({ template: template });
  };

  CancelAbonentInfo = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    // const date = moment(letterDocument.rd).format('DD/MM/yyy');
    // const day = this.getDayMonthYear(date, 1);
    // const month_1 = this.getDayMonthYear(date, 2);
    // const year_1 = this.getDayMonthYear(date, 4);
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const month_1 = this.getDayMonthYear(date, 3);
    const year = this.getDayMonthYear(date, 4);
    const year_1 = this.getDayMonthYear(date, 5);
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '');
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checked = letterDocumentLines.filter((x) => x.key === 'checked');
    if (checked.length) checked = checked[0].value;
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    const file_1 = letterDocumentLines.filter((val) => val.key === 'file_1'); // პირადობა/პასპორტი (ასლი)
    const file_20 = letterDocumentLines.filter((val) => val.key === 'file_20');

    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(
      /{{email_1}}/g,
      typeof email_1 !== 'undefined' ? email_1 : '__________________'
    );
    template = template.replace(
      /{{email_2}}/g,
      typeof email_2 !== 'undefined' ? email_2 : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{customer_address}}/g,
      typeof addressto !== 'undefined' ? addressto : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : '__________________'
    );

    template = template.replace(
      /{{customer_fullname}}/g,
      typeof customer_firstname !== 'undefined' &&
        typeof customer_lastname !== 'undefined' &&
        checked === 'true'
        ? customer_firstname + ' ' + customer_lastname
        : '__________________'
    );
    if (checked === 'false') {
      template.template.replace(
        /{{customer_fullname}}/g,
        typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
      );
    }

    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    );
    // template = template.replace(
    //   /{{customer_lastname}}/g,
    //   typeof customer_lastname !== 'undefined' && checked === 'true' ? customer_lastname : ''
    // );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );

    template = template.replace(
      /{{file_1}}/g,
      file_1.length && file_1[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      /{{file_20}}/g,
      file_20.length && file_20[0].value !== '' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );

    this.setState({ template: template });
  };

  SmartMeter = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;

    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const month_1 = this.getDayMonthYear(date, 3);
    const year = this.getDayMonthYear(date, 4);
    const year_1 = this.getDayMonthYear(date, 5);

    let docName = null;
    if (letterDocument.docName !== null) docName = letterDocument.docName;
    else {
      docName = '';
    }
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    //let email_2 = letterDocumentLines.filter(x=>x.key==="email_2"); if(email_2.length) email_2=email_2[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let bankdetails = letterDocumentLines.filter((x) => x.key === 'bankdetails');
    if (bankdetails.length) bankdetails = bankdetails[0].value;
    // let addressto = letterDocumentLines.filter(x=>x.key==="addressto"); if(addressto.length) addressto=addressto[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let amountN = letterDocumentLines.filter((x) => x.key === 'amountN');
    if (amountN.length) amountN = amountN[0].value;
    let telasi = letterDocumentLines.filter((x) => x.key === 'telasi');
    if (telasi.length) telasi = telasi[0].value;
    let gwp = letterDocumentLines.filter((x) => x.key === 'gwp');
    if (gwp.length) gwp = gwp[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let street_Id = letterDocumentLines.filter((x) => x.key === 'street_Id');
    if (street_Id.length) street_Id = street_Id[0].value; //ადგილი (მისამართი), სადაც უნდა ჩატარდეს სამუშაოები
    let street_Name = letterDocumentLines.filter((x) => x.key === 'street_Name');
    if (street_Name.length) street_Name = street_Name[0].value;
    let addressto_building = letterDocumentLines.filter((x) => x.key === 'addressto_building');
    if (addressto_building.length) addressto_building = addressto_building[0].value;
    let addressto_entrance = letterDocumentLines.filter((x) => x.key === 'addressto_entrance');
    if (addressto_entrance.length) addressto_entrance = addressto_entrance[0].value;
    let addressto_floor = letterDocumentLines.filter((x) => x.key === 'addressto_floor');
    if (addressto_floor.length) addressto_floor = addressto_floor[0].value;
    let addressto_apartament = letterDocumentLines.filter((x) => x.key === 'addressto_apartament');
    if (addressto_apartament.length) addressto_apartament = addressto_apartament[0].value;
    let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_16 = letterDocumentLines.filter((x) => x.key === 'checkyes_16');
    let checkyes_18 = letterDocumentLines.filter((x) => x.key === 'checkyes_18');
    let checkyes_221 = letterDocumentLines.filter((x) => x.key === 'checkyes_221');
    let addressto = '';
    //let streetName = this.state.reference_streets.filter(x=>x.id==street_Id); streetName=streetName[0].value;
    let buildingNum = addressto_building;
    let entrance = '';
    if (typeof addressto_entrance !== 'undefined') entrance += ', სად. ' + addressto_entrance;
    let floor = '';
    if (typeof addressto_floor !== 'undefined') floor += ', სართ. ' + addressto_floor;
    let apartament = '';
    if (typeof addressto_apartament !== 'undefined') apartament += ', ბინა ' + addressto_apartament;
    addressto = street_Name + ' ' + buildingNum + entrance + floor + apartament;

    for (let i = 0; i < 9; i++) {
      let myRegExp = new RegExp(`{{phone_1_${i}}}`);
      let square = `<div>${phone_1[i]}</div>`;
      template = template.replace(myRegExp, typeof phone_1 !== 'undefined' ? square : '&#8414;');
    }
    if (phone_2.length > 0) {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div >${phone_2[i]}</div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    } else {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div></div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    }

    template = template.replace(/{{docName}}/g, typeof docName !== 'undefined' ? docName : '');
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '');
    template = template.replace(/{{month_1}}/g, typeof month_1 !== 'undefined' ? month_1 : '');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '');
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '');
    template = template.replace(/{{fullname}}/g, typeof fullname !== 'undefined' ? fullname : '');
    template = template.replace(/{{custN}}/g, typeof custN !== 'undefined' ? custN : '');
    template = template.replace(/{{personal}}/g, typeof personal !== 'undefined' ? personal : '');
    template = template.replace(/{{phone_1}}/g, typeof phone_1 !== 'undefined' ? phone_1 : '');
    template = template.replace(/{{address}}/g, typeof address !== 'undefined' ? address : '');
    template = template.replace(/{{email_1}}/g, typeof email_1 !== 'undefined' ? email_1 : '');
    template = template.replace(
      /{{bankdetails}}/g,
      typeof bankdetails !== 'undefined' ? bankdetails : ''
    );
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : ''
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : ''
    );
    template = template.replace(/{{amountN}}/g, typeof amountN !== 'undefined' ? amountN : '');
    template = template.replace(/{{telasi}}/g, typeof telasi !== 'undefined' ? telasi : '');
    template = template.replace(/{{gwp}}/g, typeof gwp !== 'undefined' ? gwp : '');
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_221}}',
      checkyes_221.length && checkyes_221[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_221}}',
      checkyes_221.length && checkyes_221[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(/{{pages}}/g, '');
    template = template.replace(/{{otherdocuments}}/g, '');

    this.setState({ template: template });
  };

  OtherLetter = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    console.log(template);
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 2);
    const year_1 = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    // let comment = letterDocumentLines.filter(x=>x.key==="comment"); if(comment.length) comment=comment[0].value;
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(/{{email_1}}/g, email_1.length ? email_1 : '__________________');
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');

    this.setState({ template: template });
  };

  ClarificationOfCommunications = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;
    template = template.replace(
      /{{something}}/g,
      this.state.temp.length > 0 ? this.state.temp : ''
    );
    // console.log(template);
    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month_1 = this.getDayMonthYear(date, 2);
    const year_1 = this.getDayMonthYear(date, 4);
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let comment = letterDocumentLines.filter((x) => x.key === 'comment');
    if (comment.length) comment = comment[0].value;
    let custN = letterDocumentLines.filter((x) => x.key === 'custN');
    if (custN.length) custN = custN[0].value;
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let customer_firstname = letterDocumentLines.filter((x) => x.key === 'customer_firstname');
    if (customer_firstname.length) customer_firstname = customer_firstname[0].value;
    let customer_lastname = letterDocumentLines.filter((x) => x.key === 'customer_lastname');
    if (customer_lastname.length) customer_lastname = customer_lastname[0].value;
    let customer_personal = letterDocumentLines.filter((x) => x.key === 'customer_personal');
    if (customer_personal.length) customer_personal = customer_personal[0].value;
    let customer_phone_1 = letterDocumentLines.filter((x) => x.key === 'customer_phone_1');
    if (customer_phone_1.length) customer_phone_1 = customer_phone_1[0].value;
    let customer_phone_2 = letterDocumentLines.filter((x) => x.key === 'customer_phone_2');
    if (customer_phone_2.length) customer_phone_2 = customer_phone_2[0].value;
    let customer_email_1 = letterDocumentLines.filter((x) => x.key === 'customer_email_1');
    if (customer_email_1.length) customer_email_1 = customer_email_1[0].value;
    let customer_email_2 = letterDocumentLines.filter((x) => x.key === 'customer_email_2');
    if (customer_email_2.length) customer_email_2 = customer_email_2[0].value;
    // let comment = letterDocumentLines.filter(x=>x.key==="comment"); if(comment.length) comment=comment[0].value;
    console.log(email_2);
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{phone_2}}/g,
      typeof phone_2 !== 'undefined' ? phone_2 : '__________________'
    );
    template = template.replace(/{{email_1}}/g, email_1.length ? email_1 : '__________________');
    template = template.replace(/{{email_2}}/g, email_2.length ? email_2 : '__________________');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      /{{custN}}/g,
      typeof custN !== 'undefined' ? custN : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    template = template.replace(
      /{{comment}}/g,
      typeof comment !== 'undefined' ? comment : '__________________'
    );
    template = template.replace(
      /{{customer_firstname}}/g,
      typeof customer_firstname !== 'undefined' ? customer_firstname : '__________________'
    );
    template = template.replace(
      /{{customer_lastname}}/g,
      typeof customer_lastname !== 'undefined' ? customer_lastname : ''
    );
    template = template.replace(
      /{{customer_personal}}/g,
      typeof customer_personal !== 'undefined' ? customer_personal : '__________________'
    );
    template = template.replace(
      /{{customer_phone_1}}/g,
      typeof customer_phone_1 !== 'undefined' ? customer_phone_1 : '__________________'
    );
    template = template.replace(/{{customer_phone_2}}/g, '');
    template = template.replace(
      /{{customer_email_1}}/g,
      typeof customer_email_1 !== 'undefined' ? customer_email_1 : '__________________'
    );
    template = template.replace(
      /{{customer_email_2}}/g,
      typeof customer_email_2 !== 'undefined' ? customer_email_2 : '__________________'
    );
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '______');
    template = template.replace(
      /{{month_1}}/g,
      typeof month_1 !== 'undefined' ? month_1 : '_________'
    );
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '__');

    this.setState({ template: template });
  };

  ConnectToNetwork = () => {
    const letterDocument = this.state.letterDocument;
    const letterDocumentLines = this.state.letterDocumentLines;
    let template = this.state.template;

    const date = moment(letterDocument.rd).format('DD/MM/yyy');
    const day = this.getDayMonthYear(date, 1);
    const month = this.getDayMonthYear(date, 2);
    const month_1 = this.getDayMonthYear(date, 3);
    const year = this.getDayMonthYear(date, 4);
    const year_1 = this.getDayMonthYear(date, 5);
    let docName = null;
    if (letterDocument.docName !== null) docName = letterDocument.docName;
    let fullname = letterDocumentLines.filter((x) => x.key === 'fullname');
    if (fullname.length) fullname = fullname[0].value;
    let personal = letterDocumentLines.filter((x) => x.key === 'personal');
    if (personal.length) personal = personal[0].value;
    let phone_1 = letterDocumentLines.filter((x) => x.key === 'phone_1');
    if (phone_1.length) phone_1 = phone_1[0].value;
    let phone_2 = letterDocumentLines.filter((x) => x.key === 'phone_2');
    if (phone_2.length) phone_2 = phone_2[0].value;
    let email_1 = letterDocumentLines.filter((x) => x.key === 'email_1');
    if (email_1.length) email_1 = email_1[0].value;
    let email_2 = letterDocumentLines.filter((x) => x.key === 'email_2');
    if (email_2.length) email_2 = email_2[0].value;
    let address = letterDocumentLines.filter((x) => x.key === 'address');
    if (address.length) address = address[0].value;
    let bankdetails = letterDocumentLines.filter((x) => x.key === 'bankdetails');
    if (bankdetails.length) bankdetails = bankdetails[0].value;
    let techtermsNumber = letterDocumentLines.filter((x) => x.key === 'techtermsNumber');
    if (techtermsNumber.length) techtermsNumber = techtermsNumber[0].value;
    let date1 = letterDocumentLines.filter((x) => x.key === 'date1');
    if (date1.length) date1 = date1[0].value;
    let date2 = letterDocumentLines.filter((x) => x.key === 'date2');
    if (date2.length) date2 = date2[0].value;
    let addressto = letterDocumentLines.filter((x) => x.key === 'addressto');
    if (addressto.length) addressto = addressto[0].value;
    let responsible = letterDocumentLines.filter((x) => x.key === 'responsible');
    if (responsible.length) responsible = responsible[0].value;
    let cadastral = letterDocumentLines.filter((x) => x.key === 'cadastral');
    if (cadastral.length) cadastral = cadastral[0].value;
    let responsible_personal = letterDocumentLines.filter((x) => x.key === 'responsible_personal');
    if (responsible_personal.length) responsible_personal = responsible_personal[0].value;
    let requestedpower = letterDocumentLines.filter((x) => x.key === 'requestedpower');
    if (requestedpower.length) requestedpower = requestedpower[0].value;
    let amountN = letterDocumentLines.filter((x) => x.key === 'amountN');
    if (amountN.length) amountN = amountN[0].value;
    let telasi = letterDocumentLines.filter((x) => x.key === 'telasi');
    if (telasi.length) telasi = telasi[0].value;
    let gwp = letterDocumentLines.filter((x) => x.key === 'gwp');
    if (gwp.length) gwp = gwp[0].value;
    let count = letterDocumentLines.filter((x) => x.key === 'count');
    if (count.length) count = count[0].value;
    let customerscount = letterDocumentLines.filter((x) => x.key === 'customerscount');
    if (customerscount.length) customerscount = customerscount[0].value;
    let smartmeterscount = letterDocumentLines.filter((x) => x.key === 'smartmeterscount');
    if (smartmeterscount.length) smartmeterscount = smartmeterscount[0].value;
    let household = letterDocumentLines.filter((x) => x.key === 'household');
    if (household.length) household = household[0].value;
    let nonhousehold = letterDocumentLines.filter((x) => x.key === 'nonhousehold');
    if (nonhousehold.length) nonhousehold = nonhousehold[0].value;
    let otherhousehold = letterDocumentLines.filter((x) => x.key === 'otherhousehold');
    if (otherhousehold.length) otherhousehold = otherhousehold[0].value;
    let sumpower = letterDocumentLines.filter((x) => x.key === 'sumpower');
    if (sumpower.length) sumpower = sumpower[0].value;
    let tablescount = letterDocumentLines.filter((x) => x.key === 'tablescount');
    if (tablescount.length) tablescount = tablescount[0].value;
    let pagescount = letterDocumentLines.filter((x) => x.key === 'pagescount');
    if (pagescount.length) pagescount = pagescount[0].value;
    let pages = letterDocumentLines.filter((x) => x.key === 'pages');
    if (pages.length) pages = pages[0].value;
    let otherdocuments = letterDocumentLines.filter((x) => x.key === 'otherdocuments');
    if (otherdocuments.length) otherdocuments = otherdocuments[0].value;
    let checkyes_4 = letterDocumentLines.filter((x) => x.key === 'checkyes_4');
    let checkyes_5 = letterDocumentLines.filter((x) => x.key === 'checkyes_5');
    let checkyes_17 = letterDocumentLines.filter((x) => x.key === 'checkyes_17');
    let checkyes_21 = letterDocumentLines.filter((x) => x.key === 'checkyes_21');
    let smartmeter = letterDocumentLines.filter((x) => x.key === 'smartmeter');
    let checkyes_16 = letterDocumentLines.filter((x) => x.key === 'checkyes_16');
    let checkyes_18 = letterDocumentLines.filter((x) => x.key === 'checkyes_18');
    let checkyes_24 = letterDocumentLines.filter((x) => x.key === 'checkyes_24');
    let checkyes_221 = letterDocumentLines.filter((x) => x.key === 'checkyes_221');
    let checktables = letterDocumentLines.filter((x) => x.key === 'checktables');
    let techterms = letterDocumentLines.filter((x) => x.key === 'techterms');
    let techtermscomp = letterDocumentLines.filter((x) => x.key === 'techtermscomp');
    let checkyes_25 = letterDocumentLines.filter((x) => x.key === 'checkyes_25');
    let onetomulti = letterDocumentLines.filter((x) => x.key === 'onetomulti');

    if (onetomulti.length) {
      if (onetomulti[0].value === 'true') {
        template = template.replace('{{check_onecustomer}}', '&#8864;');
        template = template.replace('{{check_multicustomer}}', '&#8414;');
      } else {
        template = template.replace('{{check_onecustomer}}', '&#8414;');
        template = template.replace('{{check_multicustomer}}', '&#8864;');
      }
    }

    template = template.replace(/{{docName}}/g, docName !== null ? docName : '');
    template = template.replace(
      '{{checkyes_24}}',
      checkyes_24.length && checkyes_24[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_4}}',
      checkyes_4.length && checkyes_4[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_5}}',
      checkyes_5.length && checkyes_5[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_17}}',
      checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_17}}',
      checkyes_17.length && checkyes_17[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_21}}',
      checkyes_21.length && checkyes_21[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_21}}',
      checkyes_21.length && checkyes_21[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{smartmeter}}',
      smartmeter.length && smartmeter[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{smartmeter}}',
      smartmeter.length && smartmeter[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_221}}',
      checkyes_221.length && checkyes_221[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_221}}',
      checkyes_221.length && checkyes_221[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_16}}',
      checkyes_16.length && checkyes_16[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{checkyes_18}}',
      checkyes_18.length && checkyes_18[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checktables}}',
      checktables.length && checktables[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{techtermscomp}}',
      techtermscomp.length && techtermscomp[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_25}}',
      checkyes_25.length && checkyes_25[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      '{{checkyes_25}}',
      checkyes_25.length && checkyes_25[0].value !== 'false' ? '&#8864;' : '&#8414;'
    );
    template = template.replace(
      '{{techterms}}',
      techterms.length && techterms[0].value !== 'false' ? '&#8414;' : '&#8864;'
    );
    template = template.replace(
      /{{otherdocuments}}/g,
      typeof otherdocuments !== 'undefined' ? otherdocuments : ''
    );
    template = template.replace(
      /{{responsible}}/g,
      typeof responsible !== 'undefined' ? responsible : ''
    );
    template = template.replace(
      /{{cadastral}}/g,
      typeof cadastral !== 'undefined' ? cadastral : ''
    );
    template = template.replace(
      /{{responsible_personal}}/g,
      typeof responsible_personal !== 'undefined' ? responsible_personal : ''
    );
    template = template.replace(
      /{{requestedpower}}/g,
      typeof requestedpower !== 'undefined' ? requestedpower : ''
    );
    template = template.replace(/{{amountN}}/g, typeof amountN !== 'undefined' ? amountN : '');
    template = template.replace(/{{telasi}}/g, typeof telasi !== 'undefined' ? telasi : '');
    template = template.replace(/{{gwp}}/g, typeof gwp !== 'undefined' ? gwp : '');
    template = template.replace(/{{count}}/g, typeof count !== 'undefined' ? count : '');
    template = template.replace(
      /{{customerscount}}/g,
      typeof customerscount !== 'undefined' ? customerscount : ''
    );
    template = template.replace(
      /{{smartmeterscount}}/g,
      typeof smartmeterscount !== 'undefined' ? smartmeterscount : ''
    );
    template = template.replace(
      /{{household}}/g,
      typeof household !== 'undefined' ? household : ''
    );
    template = template.replace(
      /{{nonhousehold}}/g,
      typeof nonhousehold !== 'undefined' ? nonhousehold : ''
    );
    template = template.replace(
      /{{otherhousehold}}/g,
      typeof otherhousehold !== 'undefined' ? otherhousehold : ''
    );
    template = template.replace(/{{sumpower}}/g, typeof sumpower !== 'undefined' ? sumpower : '');
    template = template.replace(
      /{{tablescount}}/g,
      typeof tablescount !== 'undefined' ? tablescount : ''
    );
    template = template.replace(
      /{{pagescount}}/g,
      typeof pagescount !== 'undefined' ? pagescount : ''
    );
    template = template.replace(/{{pages}}/g, typeof pages !== 'undefined' ? pages : '');
    template = template.replace(
      /{{addressto}}/g,
      typeof addressto !== 'undefined' ? addressto : ''
    );
    template = template.replace(
      /{{bankdetails}}/g,
      typeof bankdetails !== 'undefined' ? bankdetails : ''
    );
    template = template.replace(
      /{{techtermsNumber}}/g,
      typeof techtermsNumber !== 'undefined' ? techtermsNumber : ''
    );
    template = template.replace(/{{date1}}/g, typeof date1 !== 'undefined' ? date1 : '');
    template = template.replace(/{{date2}}/g, typeof date2 !== 'undefined' ? date2 : '');
    template = template.replace(/{{day}}/g, typeof day !== 'undefined' ? day : '');
    template = template.replace(/{{email_1}}/g, typeof email_1 !== 'undefined' ? email_1 : '');
    template = template.replace(/{{month_1}}/g, typeof month_1 !== 'undefined' ? month_1 : '');
    template = template.replace(/{{year_1}}/g, typeof year_1 !== 'undefined' ? year_1 : '');
    template = template.replace(/{{month}}/g, typeof month !== 'undefined' ? month : '');
    template = template.replace(/{{year}}/g, typeof year !== 'undefined' ? year : '');
    template = template.replace(
      /{{fullname}}/g,
      typeof fullname !== 'undefined' ? fullname : '__________________'
    );
    template = template.replace(
      /{{personal}}/g,
      typeof personal !== 'undefined' ? personal : '__________________'
    );
    template = template.replace(
      /{{phone_1}}/g,
      typeof phone_1 !== 'undefined' ? phone_1 : '__________________'
    );
    template = template.replace(
      /{{address}}/g,
      typeof address !== 'undefined' ? address : '__________________'
    );
    for (let i = 0; i < 9; i++) {
      let myRegExp = new RegExp(`{{phone_1_${i}}}`);
      let square = `<div >${phone_1[i]}</div>`;
      template = template.replace(myRegExp, typeof phone_1 !== 'undefined' ? square : '&#8414;');
    }
    if (phone_2.length > 0) {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div >${phone_2[i]}</div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    } else {
      for (let i = 0; i < 9; i++) {
        let myRegExp = new RegExp(`{{phone_2_${i}}}`);
        let square = `<div ></div>`;
        template = template.replace(myRegExp, typeof phone_2 !== 'undefined' ? square : '&#8414;');
      }
    }

    this.setState({ template: template });
  };

  switchLetterTypes = (letterTypeId) => {
    switch (letterTypeId) {
      case 39:
        this.AbonentRegister();
        break;
      case 40:
        this.ConnectDistributionNetwork();
        break;
      case 41:
        this.ConnectNewCustomer();
        break;
      case 42:
        this.AbonentInfo();
        break;
      case 43:
        this.AccountingUpdate();
        break;
      case 44:
        this.ConnectAbonentFromDivide();
        break;
      case 45:
        this.DebtRedistribution();
        break;
      case 46:
        this.InternalNetworkInstalatlion();
        break;
      case 47:
        this.InternalNetworkUpdate();
        break;
      case 48:
        this.MoveCounterTube();
        break;
      case 49:
        this.MovingAmount();
        break;
      case 50:
        this.PowerIncrease();
        break;
      case 51:
        this.TemporaryCustomer();
        break;
      case 52:
        this.TermiantionRecovery();
        break;
      case 53:
        this.AmountBack();
        break;
      case 54:
        this.AmountBackFromInstall();
        break;
      case 55:
        this.AmountCorrecting();
        break;
      case 56:
        this.MovingAmountToNumber();
        break;
      case 57:
        this.AmountDeWrite();
        break;
      case 58:
        this.ProjectCorrecting();
        break;
      case 59:
        this.CheckingCounter();
        break;
      case 60:
        this.ConnectNewCustomers();
        break;
      case 61:
        this.DetectorMontage();
        break;
      case 62:
        this.OldAbonentRegister();
        break;
      case 70:
        this.CancelApplication();
        break;
      case 71:
        this.TechTerms();
        break;
      case 72:
        this.CancelAbonentInfo();
        break;
      case 73:
        this.SmartMeter();
        break;
      case 74:
        this.ConnectToNetwork();
        break;
      case 75:
        this.OtherLetter();
        break;
      case 76:
        this.ClarificationOfCommunications();
        break;
      default:
        break;
    }
  };

  pdfExportComponent;
  render() {
    //console.log(this.props.location.search);

    return !this.state.redirect ? (
      <React.Fragment>{renderHTML(this.state.template)}</React.Fragment>
    ) : (
      <NotFound />
    );
  }
}

export default DocumentPrint;
