import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from './loader';
import '../css/style.css';
import '../css/fontawesome-free-5.13.0-web/css/all.css';
import '../fonts/stylesheet.css';
import ReCAPTCHA from 'react-google-recaptcha';
// import GoogleLogin from 'react-google-login';
// import { BrowserRouter as Link } from "react-router-dom";

export default class Login extends Component {
  constructor(props) {
    super(props);
    //console.log(this);
    this.state = {
      clientId: '335705365419-54d5dec9j5t2ds212370i8rilsqkdkc7.apps.googleusercontent.com',
    };
    this.handleClick = this.handleClick.bind(this);
  }

  responseGoogle = (response) => {
    //console.log([1,response.getAuthResponse().id_token])
    //bake_cookie("test",response.accessToken);
    // if(response.accessToken){
    //     this.setState({
    //       isLogined: true,
    //       accessToken: response.accessToken
    //     });
    // console.log(response.accessToken);
    // }
    //console.log(response);
  };

  nextPath(path) {
    this.props.history.push(path);
  }

  handlePassClick() {
    //console.log("sup");
  }

  handleClick() {
    //e.preventDefault();
    //console.log(this.props);
    let formIsValid = this.props.formValidate(this.props, 1);
    if (formIsValid) {
      this.props.nextStep();
      //this.props.submithandler();
    } else {
      //console.log(false);
    }
    //this.props.nextStep();
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.handleClick();
    }
  }

  render() {
    const { values, handleChange } = this.props;

    let styleObj = {
      display: 'flex',
      alignItems: 'center',
      width: '0.75rem',
      height: '0.75rem',
      //fontSize: '1rem',
      flexDirection: 'row',
      //textAlign: 'center',
      whiteSpace: 'nowrap',
      color: 'inherit',
      //verticalAlign:'middle',
      /* align-items: center; */
      fill: 'currentColor',
      backgroundColor: '#ebedef',
    };

    //console.log(1)
    return (
      <React.Fragment>
        {/* <div className="App" >
                <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                  <div className="container">                    
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                      <ul className="navbar-nav ml-auto">                 
                        <li className="nav-item">
                          <a className="nav-link" href={"/sign-up"}>Sign up</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div> */}
        {/* {
                values.bool?
                <div>
                 <Loader1 ></Loader1>
                </div>
               :
               null
              } */}

        <div
          className="c-app flex-row align-items-center"
          style={{ fontFamily: 'BPGCond' }}
          onKeyDown={(event) => {
            this.handleKeyDown(event);
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-sm-10 col-lg-6">
                {/* <div className="col-md-12"> */}
                <div className="card-group">
                  {values.bool ? <Loader></Loader> : null}
                  <div className="card">
                    <div className="card-header">
                      <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <img src="../logo.png" style={{ width: '300px' }} alt="თბილისი ენერჯი" />
                      </div>
                    </div>
                    <div className="card-body">
                      {/* <h3 style={{ textAlign: 'center', fontFamily: 'BPGCaps' }}>ავტორიზაცია</h3> */}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="far fa-user-circle"></i>
                            </div>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="მომხმარებლის სახელი ან პირადი ნომერი"
                          name="username"
                          onChange={handleChange('username')}
                          value={values.username}
                        />
                      </div>
                      {values.usernameerror ? (
                        <label style={{ fontSize: 12, color: '#e55353' }}>
                          {values.usernameerror}
                        </label>
                      ) : null}
                      <div className="input-group mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <div style={styleObj}>
                              <i className="fas fa-lock"></i>
                            </div>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="password"
                          placeholder="პაროლი"
                          name="password"
                          onChange={handleChange('password')}
                          value={values.password}
                        />
                      </div>
                      {values.passworderror ? (
                        <label style={{ fontSize: 12, color: '#e55353' }}>
                          {values.passworderror}
                        </label>
                      ) : null}
                      <div className="row justify-content-center">
                        {values.recaptchaRequested ? (
                          <React.Fragment>
                            <div className="col-md-8">
                              <ReCAPTCHA
                                sitekey={values.siteKey}
                                onChange={handleChange('recaptchaCode')}
                                hl="ka"
                              ></ReCAPTCHA>
                            </div>
                          </React.Fragment>
                        ) : null}
                        <div className="col-md-12">
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            disabled={
                              values.recaptchaRequested && !values.recaptchaCode ? true : false
                            }
                            onClick={this.handleClick}
                            style={{ backgroundColor: '#262262', borderColor: '#262262' }}
                          >
                            ავტორიზაცია
                          </button>
                        </div>
                        {/* <div className="col-md-12">
                            <div className="col-md-6">
                              <GoogleLogin 
                              clientId={this.state.clientId}
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            
                            cookiePolicy={'single_host_origin'}
                        >
                        </GoogleLogin>
                            </div>
                            <div className="col-md-6">

                            </div>
                          </div> */}
                        <div className="col-md-12" style={{ textAlign: 'center' }}>
                          <br></br>დაგავიწყდა&nbsp;
                          <Link to={'/passwordresetform'}>პაროლი?</Link>
                        </div>
                        <div className="col-md-12" style={{ textAlign: 'center' }}>
                          <br></br>
                          <Link to={'/sign-up'} style={{ textDecoration: 'none' }}>
                            რეგისტრაცია
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
