import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { BrowserRouter as Switch, Route, Link } from 'react-router-dom';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Loader from '../loader';
import { Modal } from 'react-bootstrap';
import Helmet from 'react-helmet';
import TableSearch from './TableSearch';

class MyLetters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date1: Date.now(),
      letters: [],
      filteredletters: [],
      showWriteForm: false,
      startDate: new Date(),
      endDate: new Date(),
      loader: false,
      page: '',
      redirect: false,
      showResult: false,
      currentMessage: '',
      currentFile: '',
      currentdocName: '',
      tempToken:
        'E3A3B0DAE539AA1197D78876F4CD7FB45C40459DB73E6FC96AAD43FF29826AAFCE612FF9D5D08B46D7BE2481E678F77E79B7A2488C104D80A10D5DDAD560F86B',
    };
  }

  componentDidMount() {
    this.handleLoad();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  UNSAFE_componentWillMount() {
    let d = new Date();
    d.setMonth(d.getMonth() - 6);
    this.setState({ startDate: d });
  }

  handleLoad = () => {
    const token = read_cookie('Bearer_Token');
    let stDate = null;
    if (this.state.startDate) {
      stDate = moment(this.state.startDate).format('yyyy-MM-DD');
    }
    const name = window.$name;
    let enDate = moment(this.state.endDate).format('yyyy-MM-DD');
    this.setState({ loader: true });
    Axios.post(
      `${name}/api/Letters/GetLetterDocuments`,
      {
        dateFrom: stDate,
        dateTo: enDate,
      },
      {
        headers: {
          Token: token,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          this.setState({
            letters: res.data.letterDocuments,
            filteredletters: res.data.letterDocuments,
            loader: false,
          });
        } else {
          this.setState({ loader: false });
          //alert("მონაცემების ჩამოტვირთვა ვერ მოხერხდა");
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.href = '/login';
        }
      });
  };

  setStartDate = (date) => {
    if (date) {
      let a = moment(date).toDate();
      this.setState({ startDate: a });
    } else {
      this.setState({ startDate: null });
    }
  };

  setEndDate = (date) => {
    if (date) {
      this.setState({ endDate: date });
    } else {
      this.setState({ endDate: new Date() });
    }
  };

  handleWriteForm = () => {
    this.setState({ showWriteForm: !this.state.showWriteForm });
  };

  renderSwitch(row) {
    switch (row.refLetterStatusId) {
      case 0:
        return (
          <span
            className="badge col-md-12"
            style={{
              height: '17.5px',
              width: '165px',
              fontSize: '11px',
              backgroundColor: '#ebedef',
            }}
          >
            {row.letterStatusName}
          </span>
        );
      case 1:
        return (
          <span
            className="badge col-md-12"
            style={{
              height: '17.5px',
              width: '165px',
              fontSize: '11px',
              backgroundColor: '#d8dbe0',
            }}
          >
            {row.letterStatusName}
          </span>
        );
      case 2:
        return (
          <span
            className="badge col-md-12"
            style={{
              height: '17.5px',
              width: '165px',
              fontSize: '11px',
              backgroundColor: '#c4c9d0',
            }}
          >
            {row.letterStatusName}
          </span>
        );
      case 3:
        return (
          <span
            className="badge col-md-12"
            style={{
              height: '17.5px',
              width: '165px',
              fontSize: '11px',
              backgroundColor: '#262262',
              color: 'white',
            }}
          >
            {row.letterStatusName}
          </span>
        );
      case -1:
        return (
          <span
            className="badge col-md-12"
            style={{
              height: '17.5px',
              width: '165px',
              fontSize: '11px',
              backgroundColor: '#b1b7c1',
            }}
          >
            {row.letterStatusName}
          </span>
        );
      case -2:
        return (
          <span
            className="badge col-md-12"
            style={{
              height: '17.5px',
              width: '165px',
              fontSize: '11px',
              backgroundColor: '#9da5b1',
            }}
          >
            {row.letterStatusName}
          </span>
        );
      default:
        return (
          <span
            className="badge col-md-12"
            style={{ height: '17.5px', width: '165px', fontSize: '11px' }}
          >
            {row.letterStatusName}
          </span>
        );
    }
  }

  handlePreview = (id) => {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    Axios.get(`${name}/api/Letters/GetLetterDocument?id=${id}`, { headers: { Token: token } })
      .then((response) => {
        let page = response.data.template;
        var data = response.data.letterDocumentLines;
        data.map((item, index) => {
          if (item.key === 'fullname') page = page.replace('{{fullname}}', data[index].value);
          if (item.key === 'address') page = page.replace('{{address}}', data[index].value);
          if (item.key === 'custN') page = page.replace('{{custN}}', data[index].value);
          if (item.key === 'phone_1') page = page.replace('{{phone_1}}', data[index].value);
          if (item.key === 'address_1') page = page.replace('{{address_1}}', data[index].value);
          if (item.key === 'fullname1') page = page.replace('{{fullname1}}', data[index].value);
          var date = moment(response.data.letterDocument.rd).format('DD/MM/yyyy');
          page = page.replace('{{Date}}', date);
        });
        this.setState({ page: page, redirect: true });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.href = '/login';
        }
      });
  };

  renderResult = (row) => {
    if (row.positiveResult)
      return (
        <span className="col-md-12" style={{ height: '17.5px', width: '165px', fontSize: '11px' }}>
          {row.resultMessage}
        </span>
      );
    else
      return (
        <span className="col-md-12" style={{ height: '17.5px', width: '165px', fontSize: '11px' }}>
          {row.resultMessage}
        </span>
      );
  };

  handleResultPopUp = (row) => {
    //console.log(row);
    if (typeof row !== 'undefined') {
      this.setState({
        showResult: !this.state.showResult,
        currentMessage: row.resultMessage,
        currentFile: row.resultDocumentUrl,
        currentdocName: row.docName,
      });
    } else {
      this.setState({
        showResult: !this.state.showResult,
        currentMessage: '',
        currentFile: '',
        currentdocName: '',
      });
    }
  };

  handleSearchedHistory = (data) => {
    this.setState({ filteredletters: data });
  };

  render() {
    //const token = read_cookie("Bearer_Token");
    //const tempToken="E3A3B0DAE539AA1197D78876F4CD7FB45C40459DB73E6FC96AAD43FF29826AAFCE612FF9D5D08B46D7BE2481E678F77E79B7A2488C104D80A10D5DDAD560F86B";
    //var temp=token?token:this.state.tempToken;
    const letters = this.state.filteredletters;

    //console.log(this.state.filteredletters);

    const columns = [
      {
        name: 'განაცხადის #',
        selector: 'docName',
        //compact:true
        sortable: true,
        wrap: true,
        center: true,
        minWidth: '98pt',
        //maxWidth:"50pt",
        //width:"120pt",
        cell: (row) => <span style={{ textAlign: 'left' }}>{row.docName}</span>,
      },
      {
        name: 'აბონენტის N',
        selector: 'customerNumber',
        sortable: true,
        wrap: true,
        center: true,
        minWidth: '78pt',
        //width:"115pt",
        cell: (row) => <span style={{ textAlign: 'left' }}>{row.customerNumber}</span>,
      },
      {
        name: 'თარიღი',
        selector: 'rd',
        sortable: true,
        wrap: true,
        center: true,
        minWidth: '73pt',
        //width:"100pt",
        cell: (row) => (
          <span style={{ textAlign: 'left' }}>{moment(row.rd).format('DD.MM.YYYY')}</span>
        ),
      },
      {
        name: 'ვადა',
        selector: 'dueDate',
        sortable: true,
        wrap: true,
        center: true,
        minWidth: '73pt',
        //width:"100pt",
        cell: (row) => (
          <span style={{ textAlign: 'left' }}>
            {row.dueDate ? moment(row.dueDate).format('DD.MM.YYYY') : ''}
          </span>
        ),
      },
      {
        name: 'განცხადების ტიპი',
        selector: 'letterTypeName',
        sortable: true,
        wrap: true,
        left: true,
        // minWidth:"150pt",
        // maxWidthL:"450pt",
        width: '280pt',
        cell: (row) => <span style={{ textAlign: 'left' }}>{row.letterTypeName}</span>,
      },
      {
        name: 'სემეკის ID',
        selector: 'gnercId',
        sortable: true,
        wrap: true,
        center: true,
        minWidth: '78pt',
        //width:"100pt",
        cell: (row) => <span style={{ textAlign: 'left' }}>{row.gnercId}</span>,
      },
      {
        name: 'სტატუსი',
        selector: 'letterStatusName',
        sortable: true,
        wrap: true,
        center: true,
        minWidth: '78pt',
        //width:"100pt",
        cell: (row) => <span style={{ textAlign: 'left' }}>{this.renderSwitch(row)}</span>,
      },
      {
        name: 'შედეგი',
        selector: 'positiveResult',
        sortable: true,
        wrap: true,
        center: true,
        minWidth: '20pt',
        //width:"40pt",
        cell: (row) =>
          row.resultMessage || row.resultDocumentUrl ? (
            <i
              className="far fa-eye"
              title="დააკლიკეთ და იხილეთ შედეგი"
              style={{ color: '#262262', cursor: 'pointer' }}
              onClick={() => this.handleResultPopUp(row)}
            ></i>
          ) : null,
      },
      {
        name: 'ბეჭდვა',
        wrap: true,
        minWidth: '20pt',
        //width:"40pt",
        center: true,
        cell: (row) => (
          <Link to={`/ShowDocument/${row.id}`} target="_blank">
            <i className="fas fa-print" style={{ color: '#262262', cursor: 'pointer' }}></i>
          </Link>
        ),
      },
    ];
    //console.log(this.props.location)
    return (
      <React.Fragment>
        <Helmet>
          <title>ჩემი განცხადებები</title>
        </Helmet>
        <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
          <Modal
            show={this.state.showResult}
            onHide={this.handleResultPopUp}
            style={{ fontFamily: 'BPGCond' }}
            centered
          >
            <Modal.Header closeButton>
              <strong style={{ fontFamily: 'BPGCaps' }}>{this.state.currentdocName}</strong>
            </Modal.Header>
            <Modal.Body>
              <label>{this.state.currentMessage}</label>
              <br />
              {this.state.currentFile ? (
                <React.Fragment>
                  <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <label>იხილეთ შედეგის ფაილი </label>
                  </div>
                  <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <a
                      href={'https://my.te.ge/uploads' + `${this.state.currentFile}`}
                      target="_blank"
                    >
                      <i
                        className="fas fa-file-pdf fa-3x"
                        style={{
                          color: '#262262',
                          cursor: 'pointer',
                          height: '25px',
                          width: '25px',
                        }}
                      ></i>
                    </a>
                    <br />
                    <br />
                  </div>
                </React.Fragment>
              ) : null}
            </Modal.Body>
          </Modal>
          <div className="col-lg-6 col-md-8 col-sm-12" style={{ float: 'left' }}>
            <Link className="card" style={{ textDecoration: 'none' }} to={'/dashboard/WriteLetter'}>
              <div className="card-header">
                <span>
                  <i className="fas fa-plus-circle"></i>&nbsp;განცხადების დაწერა
                </span>
              </div>
            </Link>
          </div>
          <Switch>
            <Route path="/dashboard/MyLetters">
              <div className="col-lg-6 col-md-4 col-sm-6" />
              <div className="col-lg-9">
                <br />
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <label>
                      <strong>საწყისი დრო</strong>
                    </label>
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.setStartDate}
                      isClearable
                      dateFormat="dd.MM.yyyy"
                      showMonthDropdown
                      showYearDropdown
                    ></DatePicker>
                  </div>
                  <div className="col-lg-2 col-md-3">
                    <label style={{ whiteSpace: 'nowrap' }}>
                      <strong>საბოლოო დრო</strong>
                    </label>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={this.setEndDate}
                      isClearable
                      dateFormat="dd.MM.yyyy"
                      showMonthDropdown
                      showYearDropdown
                    ></DatePicker>
                  </div>
                  <div className="col-lg-2 col-md-3">
                    <div className="col-sm-12" style={{ top: '45%' }}>
                      <button
                        className="btn btn-primary"
                        style={{ backgroundColor: '#262262', borderColor: '#262262' }}
                        onClick={this.handleLoad}
                        type="button"
                      >
                        ნახვა
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-3" />
                </div>
                <br />
              </div>
              <div className="col-lg-12">
                <div className="card-group">
                  {this.state.loader ? <Loader></Loader> : null}
                  <DataTable
                    title={
                      <div style={{ float: 'left' }}>
                        <TableSearch
                          data={this.state.letters}
                          handleSearchedHistory={this.handleSearchedHistory}
                        />
                      </div>
                    }
                    columns={columns}
                    data={letters}
                    responsive
                    pagination
                    noDataComponent="მონაცემები ცარიელია"
                  ></DataTable>
                </div>
              </div>
            </Route>
          </Switch>
        </div>
        <br></br>
      </React.Fragment>
    );
  }
}

export default MyLetters;
