import React, { Component } from 'react';

class BookVisit extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            centers:[{name:"ვაკე-წყნეთის მომსახურების ცენტრი"},
                    {name:"საბურთალო - ნუცუბიძის მომსახურების ცენტრი"},
                    {name:"წყნეთის მომსახურების ცენტრი"},
                    {name:"ჩუღურეთი-ნაძალადევის მომსახურების ცენტრი"},
                    {name:"მთაწმინდა–კრწანისის მომსახურების ცენტრი"},
                    {name:"ისანი-სამგორი-ვაზისუბნის მომსახურების ცენტრი"},
                    {name:"დიდუბე-დიღმის მომსახურების ცენტრი"},
                    {name:"გლდანი-სანზონა-თემქის მომსახურების ცენტრი"},],
            purposes:[{name:"აბონენტად რეგისტრაცია, მონაცემების განახლება"},
                    {name:"გამანაწილებელ ქსელზე მიერთება"},
                    {name:"დავალიანების გადახდა"},
                    {name:"ინფორმაციის მოთხოვნა"},
                    {name:"ტექნიკური სამუშაოები"},
                    {name:"ფინანსური კორექტირება"},
                    {name:"ხელშეკრულებები"},]
            
        }
    }

    render() { 

        const centers = this.state.centers;
        const purposes = this.state.purposes;
        return ( 
            <React.Fragment>
                <div className="row justify-content-center">
                    {/* <h4 style={{fontFamily:"BPGCaps"}}>ვიზიტის დაჯავშნა</h4> */}
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header" style={{textAlign:"left",fontFamily:"BPGCaps",fontSize:"16px"}}>
                                <span><strong>ვიზიტის დაჯავშნა</strong></span>
                            </div>
                            <div className="card-body" style={{textAlign:"left",fontFamily:"BPGCond",color:"#262262"}}>
                                <form className="form-horizontal">
                                    <div className="row">
                                        <div className="col-md-6" style={{float:"left"}}>
                                            <div className="col-md-12" style={{textAlign:"left",margin:"auto"}}>
                                                <div className="form-group row" style={{textAlign:"left"}}>
                                                    <label className="col-md-5 col-form-label"><strong>მომსახურეობის ცენტრი: </strong></label>
                                                    <select className="col-md-7 form-control">
                                                        {
                                                            centers.map((value,index)=>{
                                                                return(
                                                                    <option key={index} value={index}>{value.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{textAlign:"center",margin:"auto"}}>
                                                <div className="form-group row" style={{textAlign:"left"}}>
                                                    <label className="col-md-5 col-form-label"><strong>ვიზიტის მიზანი: </strong></label>
                                                    <select className="col-md-7 form-control">
                                                        {
                                                            purposes.map((value,index)=>{
                                                                return(
                                                                    <option key={index} value={index}>{value.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{textAlign:"center",margin:"auto"}}>
                                                <div className="form-group row" style={{textAlign:"left"}}>
                                                    <label className="col-md-5 col-form-label"><strong>თარიღი: </strong></label>
                                                    <input className="col-md-7 form-control" type="date"></input>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{textAlign:"center",margin:"auto"}}>
                                                <div className="form-group row" style={{textAlign:"left"}}>
                                                    <label className="col-md-5 col-form-label"><strong>დრო: </strong></label>
                                                    <input type="time" className="col-md-7 form-control"></input>
                                                </div>
                                            </div>
                                            <div className="col-md-12">                                                              
                                                <button className="btn btn-primary btn-md" type="button" style={{backgroundColor:"#262262",borderColor:"#262262",float:"right"}} >დაჯავშნა</button>                                                             
                                            </div>                                           
                                        </div>
                                    </div>              
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default BookVisit;