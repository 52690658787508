import React, { Component } from 'react';
import DataTable from 'react-data-table-component';

class CheckHistory extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            checkhistory:[]
         }
    }
    render() { 
        const checkhistory = this.state.checkhistory;
        
        const columns = [
            {
                name:'თარიღი',
                selector:'SEND_TIME',
                wrap:true,
                sortable:true,        
                center:true
            },
            {
                name:'Email',
                selector:'email',
                wrap:true,
                sortable:true,        
                center:true
            },
            {
                name:'გაგზავნილი ტექსტი',
                selector:'SEND_TEXT',
                wrap:true,
                sortable:true,        
                center:true
            }
        ]

        return ( 
            <React.Fragment>
                <div style={{fontFamily:"BPGCond"}}>
                    <DataTable columns={columns} data={checkhistory} pagination responsive striped noDataComponent="მონაცემები ცარიელია" style={{fontFamily:"BPGCond"}}></DataTable>
                </div>
            </React.Fragment>
         );
    }
}
 
export default CheckHistory;