import React, { Component } from 'react';
import { read_cookie,delete_cookie } from 'sfcookies';
import Axios from 'axios';
import moment from 'moment';
import '../../css/tabs.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ReactHtmlParser from 'react-html-parser';
import Helmet from 'react-helmet';

class Notifications extends Component {

    state = {
        notifications: {},
        currentNotificationText:"",
        currentNotification:{customerNumber:""},
        currentControlNumber:""
    }

    componentDidMount(){
        this.handleLoad();
    }

    handleLoad = () =>{
        const token = read_cookie("Bearer_Token");
        const url = window.$name;
        Axios.post(`${url}/api/Notifications/GetNotifications`,
        {
        },
        {
            headers:{
                Token:token
            }
        })
        .then(res=>{
            this.setState({notifications:res.data.notifications});
        })
        .catch(error=>{
            if(error.response.status===401){
                delete_cookie("Bearer_Token");
                window.location.replace("/login");
            }
        });
    }

    renderTable = (data, index) => {
        return(
            <tr key={index}>
               <td>{moment(data.created_at).format("yyyy-MM-DD hh:mm:ss")}</td>
               <td>{data.TEXT}</td>
           </tr>
        )
    }

    handletabChange=(e,id,controlNumber)=>{
        if(this.state.notifications[e.target.id].messageIsOpened===false){
            this.props.handleCount();
        }
        const name = window.$name;
        this.state.notifications[e.target.id].messageIsOpened=true;
        const token = read_cookie("Bearer_Token");
        Axios.get(`${name}/api/Notifications/GetNotification?id=${id}`,{'headers':{'Token':token}})
        .then(response=>{
            if(response.data.success){
                this.setState({currentNotificationText:response.data.notification.text,currentNotification:response.data.notification,currentControlNumber:controlNumber});
            }
        })
        .catch(error=>{
            if(typeof(error.response.status)!=="undefined"){
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.replace("/login");
                }
            }
            
        });
    }

    render() {

        const data = this.state.notifications;
        //console.log(this.state.currentNotification)
        if(Object.keys(data).length===0){
            return (
                <div className="row justify-content-center">
                    <Helmet><title>შეტყობინებები</title></Helmet>
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="inbox-container">
                                <div className="email-list"></div>
                                <div className="email-content">
                                <div className="email-content__header">
                                        <h3 className="email-content__subject" style={{fontFamily:"BPGCaps"}}><strong></strong></h3>
                                        <span className="delete-btn fa fa-trash-o"></span>
                                        <div className="email-content__time"></div>
                                        <div className="email-content__from"></div>
                                    </div>
                                    <div className="email-content__message" style={{fontFamily:"BPGCaps",textAlign:"center"}}><span><strong style={{fontSize:"25px"}}>თქვენ არ გაქვთ შეტყობინებები</strong></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                
                <div className="row justify-content-center" style={{fontFamily:"BPGCond"}}>
                    <div className="col-lg-12" >
                        <div className="card">
                            <div className="inbox-container" >
                                <div className="email-list">
                                    <PerfectScrollbar speed={0.8} className="area" horizontal="false" contentclassname="content">
                                    {
                                        data.map((data,index) => {
                                    //console.log(data)
                                        let date = moment(data.scheduleDate).format('DD.MM.YYYY');
                                        return (                                       
                                            <div className={this.state.currentNotification.id===data.id?"email-item selected":"email-item"} id={index} key={index} onClick={e=>this.handletabChange(e,data.id,data.controlNumber)} style={{backgroundColor:data.messageIsOpened?"":"#ced2d8"}}>
                                                <div className="email-item__unread-dot" id={index} data-read={data.messageIsOpened}></div>
                                                <div className="email-item__subject truncate" id={index} style={{fontFamily:"BPGCaps"}}><strong id={index}>{data.name}</strong></div>   
                                                <div className="email-item__details" id={index}>
                                                    <span className="email-item__from truncate" id={index}>{data.customerNumber+"-"+data.controlNumber}</span>
                                                    <span className="email-item__time truncate" id={index}>
                                                        {date}
                                                    </span>
                                                </div>  
                                            </div>                                                        
                                        );
                                    })
                                    }
                                    </PerfectScrollbar>
                                </div>
                                <div className="email-content">
                                    <div className="email-content__header">
                                        <h3 className="email-content__subject" style={{fontFamily:"BPGCaps"}}><strong>{this.state.currentNotification.name}</strong></h3>
                                        <span className="delete-btn fa fa-trash-o"></span>
                                        <div className="email-content__time">{this.state.currentNotification.scheduleDate?moment(this.state.currentNotification.scheduleDate).format("DD.MM.YYYY hh:mm"):""}</div>
                                        <div className="email-content__from">{this.state.currentNotification.customerNumber!==""?this.state.currentNotification.customerNumber+"-"+this.state.currentControlNumber:""}</div>
                                    </div>
                                    <div className="email-content__message">
                                        <div style={{wordWrap:"break-word"}}>
                                            {ReactHtmlParser(this.state.currentNotification.text)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}

export default Notifications;