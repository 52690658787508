import React, { Component } from 'react';
import { read_cookie, delete_cookie } from '../../node_modules/sfcookies';
import axios from '../../node_modules/axios';
import '../css/style.css';
import '../css/fontawesome-free-5.13.0-web/css/all.css';
import Navrbar from './pages/Navbar';
import Mobileverif from './mobileverif';
import Loader from '../components/loader';
import swal from 'sweetalert2';

class UserPanel extends Component {
  state = {
    email: '',
    username: '',
    firstname: '',
    lastname: '',
    personalnumber: '',
    registrationAddress: '',
    costomernumber: '',
    controlnumber: '',
    phonenumber: '',
    verification: '',
    smscode: '',
    created_at: '',
    readonly: true,
    page: '',
    usernameedit: '',
    usernameediterror: '',
    firstnameedit: '',
    firstnameediterror: '',
    registrationAddressedit: '',
    registrationAddresserror: '',
    lastnameedit: '',
    lastnameediterror: '',
    emailedit: '',
    emailediterror: '',
    personalnumberedit: '',
    personalnumberediterror: '',
    passold: '',
    passolderror: '',
    passnew: '',
    passnewerror: '',
    passnewre: '',
    passnewreerror: '',
    phones: [],
    user: {},
    customers: [],
    didmount: false,
    code: '',
    codeerror: '',
    phonenumber1: '',
    mobileLoading: false,
    letterGroups: {},
    lettertypes: {},
    notificationCount: 0,
    siteKey: '6LeRKt0ZAAAAAK4UK8qKKhj8kUgy0Bc13izENa5g',
    recaptchaCode: '',
    recaptchaCodeerror: '',
    duration: 5,
  };

  tokenChecker() {
    let token = read_cookie('Bearer_Token');
    if (token.length < 1) {
      window.location.replace('/login');
    }
  }

  stepAgain = () => {
    this.submitSmsAgain();
  };

  submitSmsAgain() {
    //console.log("შემოვიდა");
    let cockie_key = 'Bearer_Token';
    // const validation ={
    //   token:this.state.token,
    //   code:this.state.code
    // }
    const name = window.$name;
    let token = read_cookie(cockie_key);

    //console.log("Bearer " + validation.token);
    //console.log(this.state.user);
    var t = this.state.user.phones.find((obj) => obj.verified === false && obj.typeKey === 1);
    var phone = t.info;
    //console.log(t.info);
    axios
      .post(
        `${name}/api/Users/ReSendSmsCode`,
        {
          mobilePhone: phone,
        },
        {
          headers: {
            Token: token,
          },
        }
      )
      .then((res) => {
        //set token
        //bake_cookie(cockie_key,res.data.token);
        //alert("კოდი გამოგზავნილია");
        swal.fire({
          html: `<span style="font-family:BPGCond;">ერთჯერადი კოდი გამოგზავნილია. კოდი ვალიდურია 3 წუთის განმავლობაში.</span>`,
          icon: 'success',
          showCancelButton: false,
          cancelButtonText: '',
          confirmButtonText: `<span style="font-family:BPGCond;">დახურვა</span>`,
          confirmButtonColor: '#262262',
        });
      })
      .catch((error) => {
        //console.log(this.state.code);
        //alert("შეცდომა");
        //console.log(error);
      });
  }

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  userNameValidate = (username) => {
    let formIsValid = true;

    return formIsValid;
  };

  profileFormValidate() {
    let formIsValid = true;
    if (!this.state.firstnameedit) {
      formIsValid = false;
      this.setState({ firstnameediterror: 'სახელის ველი არ უნად იყოს ცარიელი' });
    }

    if (!this.state.lastnameedit) {
      formIsValid = false;
      this.setState({ lastnameediterror: 'გვარის ველი არ უნდა იყოს ცარიელი' });
    }

    if (!this.state.registrationAddressedit) {
      formIsValid = false;
      this.setState({ registrationAddresserror: 'მისამართის ველი არ უნდა იყოს ცარიელი' });
    }

    if (!this.state.usernameedit) {
      formIsValid = false;
      this.setState({ usernameediterror: 'მომხმარებლის სახელის ველი არ უნდა იყოს ცარიელი' });
    }

    // if(!this.state.emailedit){
    //   formIsValid=false;
    //   this.setState({emailediterror:"E-Mail ველი აუცილებელია"});
    // }

    // if(this.state.emailedit){
    //   if(typeof this.state.emailedit!=="undefined"){
    //     if(!this.state.emailedit.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
    //       formIsValid=false;
    //       this.setState({emailediterror:"შეიყვანეთ ვალიდური EMail"});
    //     }
    //   }
    // }

    if (!this.state.personalnumberedit) {
      formIsValid = false;
      this.setState({ personalnumberediterror: 'პირადი ნომერის ველი არ უნდა იყოს ცარიელი' });
    }

    if (typeof this.state.personalnumberedit !== 'undefined') {
      if (!this.state.personalnumberedit.match(/^[0-9]+$/)) {
        formIsValid = false;
        this.setState({ personalnumberediterror: 'პირადი ნომრის ველში დასაშვებია მხოლოდ ციფრები' });
      }
    }

    return formIsValid;
  }

  passFormValidate() {
    let isValid = true;

    if (!this.state.passold) {
      isValid = false;
      this.setState({ passolderror: 'აუცილებელია ამჟამინდელი პაროლის შეყვანა' });
    }

    if (!this.state.passnew) {
      isValid = false;
      this.setState({ passnewerror: 'აუცილებელია ახალი პაროლის შეყვანა' });
    }

    if (!this.state.passnewre) {
      isValid = false;
      this.setState({ passnewreerror: 'აუცილებელია განმეორებითი პაროლის შეყვანა' });
    }

    if (this.state.passnew && this.state.passnewre && this.state.passnew !== this.state.passnewre) {
      isValid = false;
      this.setState({ passnewerror: 'პაროლები ერთმანეთს არ ემთხვევა' });
    }
    if (this.state.passnew) {
      if (
        !this.state.passnew.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/) |
        (this.state.passnew.length < 6)
      ) {
        isValid = false;
        this.setState({
          passnewerror:
            'პაროლი უნდა შეიცავდეს ლათინური ანბანის პატარა და დიდ სიმბოლოებს, ციფრს და უნდა იყოს მინიმუმ 6 სიმბოლო',
        });
      }
    }
    return isValid;
  }

  logouthandler(e) {
    //e.preventDefault();
    let token = read_cookie('Bearer_Token');
    const name = window.$name;
    axios
      .post(
        `${name}/api/Users/LogOut`,
        {},
        {
          headers: {
            Token: token,
          },
        }
      )
      .then((res) => {
        delete_cookie('Bearer_Token');
        window.location.replace('/login');
      })
      .catch((error) => {
        delete_cookie('Bearer_Token');
        window.location.replace('/login');
      });
  }

  UNSAFE_componentWillMount() {
    this.handleLoad1();
    this.handleLoad();
  }

  handleLoad1 = () => {
    this.tokenChecker();
    //delete_cookie("Bearer_Token");
    const name = window.$name;
    let token = read_cookie('Bearer_Token');
    //alert(read_cookie("Bearer_Token"));
    axios
      .post(
        `${name}/api/Letters/GetLetterTypes`,
        {
          customerNumber: '',
        },
        {
          headers: {
            Token: token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let data = [];
          //console.log(res.data);
          for (var i = 0; i < res.data.letterGroups.length; i++) {
            let temp = res.data.letterGroups[i].refLetterTypes;
            //console.log(temp.length);
            for (var j = 0; j < temp.length; j++) {
              var tmp = temp[j];
              //console.log(tmp);
              data.push(tmp);
            }
          }
          //console.log(data);
          this.setState({ letterGroups: res.data.letterGroups, lettertypes: data });
        } else {
          alert('მონაცემების ჩატვირთვა ვერ მოხერხდა');
          window.location.replace('/login');
        }
      })
      .catch((error) => {
        if (typeof error.response.status != -'undefined') {
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.replace('/login');
          }
        }
      });
  };

  handleLoad = () => {
    this.tokenChecker();
    //delete_cookie("Bearer_Token");
    const name = window.$name;
    let token = read_cookie('Bearer_Token');
    //console.log(token);
    //alert(read_cookie("Bearer_Token"));
    axios
      .post(
        `${name}/api/Home`,
        {},
        {
          headers: {
            Token: token,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        if (res.data.success) {
          let data = res.data.customers;
          for (var i = 0; i < data.length; i++) {
            var cs = data.find((c) => c.custNumber === data[i].custNumber);
            cs.SortOrder = i;
          }
          this.setState({
            user: res.data.user,
            usernameedit: res.data.user.userName,
            firstnameedit: res.data.user.name,
            lastnameedit: res.data.user.lastName,
            personalnumberedit: res.data.user.personalId,
            registrationAddressedit: res.data.user.registrationAddress,
            customers: data,
            notificationCount: res.data.notificationCount,
            //lettertypes: res.data.letterTypes,
          });
        } else {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
      })
      .catch((error) => {
        //console.log(error.response.status);
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
      });
  };

  handleDataUpdate = () => {
    let token = read_cookie('Bearer_Token');
    const name = window.$name;
    //console.log(this.state.firstnameedit,this.state.lastnameedit,this.state.emailedit,this.state.personalnumberedit);
    let formIsValid = this.profileFormValidate();
    //console.log(formIsValid)
    if (formIsValid) {
      axios
        .post(
          `${name}/api/Users/ChangeProfile`,
          {
            fullName: this.state.firstnameedit + ' ' + this.state.lastnameedit,
            name: this.state.firstnameedit,
            lastName: this.state.lastnameedit,
            personalId: this.state.personalnumberedit,
            registrationAddress: this.state.registrationAddressedit,
          },
          {
            headers: {
              Token: token,
            },
          }
        )
        .then((res) => {
          //console.log(res);
          if (res.data.success) {
            this.handleSwalPopup(
              'მონაცემები დამახსოვრებულია',
              'success',
              false,
              '',
              'დახურვა',
              true,
              '#757575'
            );
          } else if (res.data.code === -5) {
            this.handleSwalPopup(
              `${this.state.personalnumberedit} პირადი ნომრით მომხმარებელი არსებობს`,
              'warning',
              false,
              '',
              'დახურვა',
              false,
              '#757575'
            );
          } else if (res.data.code === -6) {
            this.handleSwalPopup(
              `აუცილებელია მისამართის შეყვანა`,
              'warning',
              false,
              '',
              'დახურვა',
              false,
              '#757575'
            );
          } else {
            this.handleSwalPopup(
              'მონაცემების დამახსოვრება ვერ მოხერხდა',
              'warning',
              false,
              '',
              'დახურვა',
              false,
              '#757575'
            );
          }
          //window.location.reload();
        })
        .catch((error) => {
          //console.log(error);
          //alert("დამახსოვრება ვერ მოხერხდა");
          if (typeof error.response.status !== 'undefined') {
            if (error.response.status === 401) {
              delete_cookie('Bearer_Token');
              window.location.href = '/login';
            } else {
              this.handleSwalPopup(
                'მონაცემების დამახსოვრება ვერ მოხერხდა',
                'danger',
                false,
                '',
                'დახურვა',
                false,
                '#757575'
              );
            }
          } else {
            this.handleSwalPopup(
              'მონაცემების დამახსოვრება ვერ მოხერხდა',
              'danger',
              false,
              '',
              'დახურვა',
              false,
              '#757575'
            );
          }
        });
    }

    // console.log(token);
  };

  handleDataReset = () => {
    this.setState({
      firstnameedit: this.state.firstname,
      lastnameedit: this.setState.lastname,
      emailedit: this.setState.email,
      personalnumberedit: this.state.personalnumber,
    });
    //window.location.reload();
  };

  handlePasswordReset = () => {
    const token = read_cookie('Bearer_Token');
    //console.log(this.state.email);
    const name = window.$name;
    let formIsValid = this.passFormValidate();
    if (formIsValid) {
      axios
        .post(
          `${name}/api/Users/ChangePassword`,
          {
            oldPassword: this.state.passold,
            newPassword: this.state.passnew,
          },
          {
            headers: {
              Token: token,
            },
          }
        )
        .then((res) => {
          //console.log(res);
          if (res.data.success) {
            this.handleSwalPopup(
              'პაროლი შეცვლილია',
              'success',
              false,
              '',
              'დახურვა',
              true,
              '#757575'
            );
          } else {
            this.handleSwalPopup(
              'პაროლის შეცვლა ვერ მოხერხდა',
              'warning',
              false,
              '',
              'დახურვა',
              false,
              '#757575'
            );
          }
        })
        .catch((error) => {
          //console.log(error);
          if (typeof error.response.status !== 'undefined') {
            if (error.response.status === 401) {
              delete_cookie('Bearer_Token');
              window.location.href = '/login';
            } else {
              this.handleSwalPopup(
                'პაროლის შეცვლა ვერ მოხერხდა',
                'danger',
                false,
                '',
                'დახურვა',
                false,
                '#757575'
              );
            }
          } else {
            this.handleSwalPopup(
              'პაროლის შეცვლა ვერ მოხერხდა',
              'warning',
              false,
              '',
              'დახურვა',
              false,
              '#757575'
            );
          }

          //alert("პაროლის შეცვლა ვერ მოხერხდა");
        });
    }
  };

  handlePassUndo = () => {
    this.setState({
      passold: '',
      passnew: '',
      passnewre: '',
      passolderror: '',
      passnewerror: '',
      passnewreerror: '',
    });
    //window.location.reload();
  };

  handleReload = () => {
    window.location.reload();
  };

  formValidate = (props, step) => {
    //console.log([props,step]);
    let values = props.values;
    //let errors={};
    let formIsValid = true;

    this.setState({ usernameerror: '' });
    this.setState({ passworderror: '' });

    // if(!values["username"]){
    //   formIsValid=false;
    //   this.setState({usernameerror:"მომხმარებლის სახელი აუცილებელია"});
    //   //errors["email"]="არ უნდა იყოს ცარიელი";
    //   //console.log("formIsValid");
    // }

    // // if(typeof values["personalnumber"]!=="undefined"){
    // //   if(!values["personalnumber"].match(/^[0-9]+$/)){
    // //     formIsValid=false;
    // //     errors["personalnumber"]="მხოლოდ ციფრები";
    // //     alert("დასაშვებია მხოლოდ ციფრები")
    // //   }
    // // }

    // if(!values["password"]){
    //   formIsValid=false;
    //   this.setState({passworderror:"პაროლის შეყვანა აუცილებელია"});
    //   //errors["password"]="შეიყვანეთ პაროლი";
    // }

    // if(typeof values["password"]!=="undefined"){
    //   //აქ ჩაჯდება პაროლის დამთხვევა როცა აპი დაიწერება
    // }

    if (step === 2 && !values['code']) {
      formIsValid = false;
      this.setState({ codeerror: 'აუცილებელია კოდის შეყვანა' });
    }

    //this.setState({errors: errors});
    return formIsValid;
  };

  nextStep_1 = () => {
    this.submitsmshandler();
  };

  submitsmshandler() {
    let Btoken = read_cookie('Bearer_Token');
    //console.log(1);
    const name = window.$name;
    this.setState({ mobileLoading: true });
    axios
      .post(
        `${name}/api/Users/VerifyPhone`,
        {
          mobilePhone: this.state.user.phones[0].info,
          smsCode: this.state.code,
        },
        {
          headers: {
            Token: Btoken,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        if (res.data.success) {
          this.setState({ mobileLoading: false });
          window.location.reload();
        } else {
          this.setState({ mobileLoading: false });
          switch (res.data.code) {
            case -3:
              this.setState({ codeerror: 'ნომერი ვერ მოიძებნა ან არასწორი კოდი' });
              break;
            case -4:
              this.setState({ codeerror: 'ერთჯერად კოდს ვადა გაუვიდა(5 წუთი)' });
              break;
            case -5:
              this.setState({ codeerror: 'მომხმარებელი ვერ მოძებნა' });
              break;
            case -9:
              this.setState({ codeerror: 'დამახსოვრების პრობლემა' });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        this.setState({ mobileLoading: false });
        //console.log(error);
        alert('შეცდომა');
      });
  }

  handleCount = () => {
    this.setState({ notificationCount: this.state.notificationCount - 1 });
  };

  handleSwalPopup = (
    title,
    icon,
    showCancelButton,
    cancelButtonText,
    confirmButtonText,
    needReload,
    confirmButtonColor
  ) => {
    swal
      .fire({
        title: title,
        icon: icon,
        showCancelButton: showCancelButton,
        cancelButtonText: cancelButtonText,
        confirmButtonText: confirmButtonText,
        confirmButtonColor: confirmButtonColor,
      })
      .then(() => {
        if (needReload) {
          window.location.reload();
        }
      });
  };

  render() {
    //console.log(document.cookie)
    const {
      usernameedit,
      usernameediterror,
      duration,
      firstname,
      lastname,
      email,
      personalnumber,
      registrationAddress,
      registrationAddressedit,
      registrationAddresserror,
      phonenumber,
      costomernumber,
      controlnumber,
      emailedit,
      emailediterror,
      passold,
      passolderror,
      passnew,
      passnewerror,
      passnewre,
      passnewreerror,
      phones,
      user,
      customers,
      firstnameedit,
      firstnameediterror,
      lastnameedit,
      lastnameediterror,
      personalnumberedit,
      personalnumberediterror,
      code,
      codeerror,
      mobileLoading,
      letterGroups,
      lettertypes,
      notificationCount,
      handleCount,
      siteKey,
    } = this.state;
    const values = {
      usernameedit,
      usernameediterror,
      duration,
      firstname,
      lastname,
      email,
      personalnumber,
      registrationAddress,
      registrationAddressedit,
      phonenumber,
      registrationAddresserror,
      costomernumber,
      controlnumber,
      emailedit,
      emailediterror,
      passold,
      passolderror,
      passnew,
      passnewerror,
      passnewre,
      passnewreerror,
      phones,
      user,
      customers,
      firstnameedit,
      firstnameediterror,
      lastnameedit,
      lastnameediterror,
      personalnumberedit,
      personalnumberediterror,
      code,
      codeerror,
      mobileLoading,
      letterGroups,
      lettertypes,
      notificationCount,
      handleCount,
      siteKey,
    };
    //console.log(values.user);
    //console.log(this.props);
    if (Object.keys(values.user).length === 0) {
      return (
        <div className="card-group">
          <Loader></Loader>
        </div>
      );
    }
    //console.log(this.props.match);
    return !values.user.mainPhoneVerified ? (
      <React.Fragment>
        <Mobileverif
          nextStep_1={this.nextStep_1}
          formValidate={this.formValidate}
          stepAgain={this.stepAgain}
          values={values}
          handleChange={this.handleChange}
        ></Mobileverif>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Navrbar
          values={values}
          logouthandler={this.logouthandler}
          tokenChecker={this.tokenChecker}
          handleChange={this.handleChange}
          handleDataReset={this.handleDataReset}
          handleDataUpdate={this.handleDataUpdate}
          handleReload={this.handleReload}
          handlePasswordReset={this.handlePasswordReset}
          handlePassUndo={this.handlePassUndo}
          handleCount={this.handleCount}
        ></Navrbar>
      </React.Fragment>
    );
  }
}

export default UserPanel;
