import React, { Component } from 'react';
import { read_cookie ,delete_cookie} from 'sfcookies';
import Axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../../fonts/sylfaen-normal';
import '../../fonts/acadndu0-normal';
import '../../fonts/BPG_Square_37_Cond-normal';
import '../../fonts/BPG_Square_37_Cond_Caps-normal';
import Loader from '../../loader';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import TableSearch from '../TableSearch';
// import '../../../fonts/stylesheet.css';

class Sms extends Component {

    constructor(props){
        super(props);

        this.state = {
            sms:[],
            filteredsms:[],
            firstdate:"",
            lastdate:"",
            custN:"",
            phone:"",
            loader:false
        };
    }

    componentDidMount(){
        this.handleSms(this.props.values);
    }

    handleChange = (input) => (e) => {   
        this.setState({ [input]: e.target.value });
    };

    onChangefirst = (date) => this.setState({ firstdate : date })

    onChangelast = (date) => this.setState({ lastdate : date })

    handleSms = (custN) =>{
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        if(typeof(custN)!=="undefined"){
            this.setState({loader:true});
            Axios.post(`${name}/api/Customer/GetSmsLog`,{
                customerNumber: custN
            },
            {
                headers:{
                    "Token" : token
                }
            })
            .then(res=>{
                if(res.data.success){
                    this.setState({sms:res.data.smsLogs,filteredsms:res.data.smsLogs});
                    this.setState({loader:false});
                }
                else{
                    this.setState({loader:false});
                    //alert("SMS-ების ჩატვირთვა ვერ მოხერხდა")
                }
            })
            .catch(error=>{
                this.setState({loader:false});
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.replace("/login");
                  }
                //alert(error);
            });
            //this.setState({loader:false});
        }
    }

    getToPdf=()=>{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFont('BPG_Square_37_Cond');
        doc.setFontSize(15);

        const title = "SMS ისტორია";
        const headers = [["აბონენტის ნომერი", "მობილურის ნომერი","გაგზავნის დრო","გაგზავნილი ტექსტი"]];
        //console.log(headers.styles);
        headers.fillColor="#262262";

        const data = this.state.sms.map(element=>[element.customerNumber,element.phoneNumber,element.sendDateString,element.sendText]);

        let content = {
            startY: 50,
            headStyles:{fillColor: [38,34,98], fontStyle: 'normal',font:'BPG_Square_37_Cond_Caps'},
            bodyStyles:{font:'BPG_Square_37_Cond'},
            //columnStyles:{0:{font:'BPG_Square_37_Cond'},1:{font:'BPG_Square_37_Cond'}},
            head: headers,
            body: data,
        };
      
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    getToExcel=()=>{
        let dt = moment(new Date()).format("DD.MM.YYYY");
        let array = this.state.sms.map((element)=>({'აბონენტის ნომერი':element.customerNumber,'მობილურის ნომერი':element.phoneNumber,'გაგზავნის დრო':element.sendDateString,'გაგზავნილი ტექსტი':element.sendText}));
        //console.log(array);
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName= `${this.props.values+"_"+dt}`;
        let ws = XLSX.utils.json_to_sheet(array);
        //console.log(ws);
        ws['A1'].s={fill:{patternType: "none",fgColor: {rgb: "FF000000"},bgColor: {rgb: "00000000"}}}
        // ws['A1'].s={fill:{patternType: "none", // none / solid
		// fgColor: {rgb: "FF000000"},
		// bgColor: {rgb: "FFFFFFFF"}},border: {
        //     top: {style: "thin", color: {auto: 1}},
        //     right: {style: "thin", color: {auto: 1}},
        //     bottom: {style: "thin", color: {auto: 1}},
        //     left: {style: "thin", color: {auto: 1}}
        //     }}
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    handleSearchedHistory=(data)=>{
        this.setState({filteredsms:data});
    }

    _exporter;
    export = () => {
        this.save(this._exporter);
    }

    save = (component) => {
        const options = component.workbookOptions();
        const rows = options.sheets[0].rows;

        rows.forEach((row) => {
            if (row.type === 'header') {
                row.height=35;
            }
        });

        component.save(options);
    };

    render() { 

        const smshistory = this.state.filteredsms;
        //console.log(smshistory);
        const columns = [
            {
                name:'აბონენტის ნომერი',
                selector:'customerNumber',
                sortable:true,
                width:"15%",
                center:true
            },
            {
                name:'მობილურის ნომერი',
                selector:'phoneNumber',
                sortable:true,
                width:"15%",
                center:true
            },
            {
                name:'გაგზავნის დრო',
                selector:'sendDateString',
                width:"15%",
                sortable:true,
                center:true,
                cell:row=>moment(row.sendDateString).format('DD.MM.YYYY')
            },
            {
                name:'გაგზავნილი ტექსტი',
                selector:'sendText',
                width:"55%",
                wrap:true,
                sortable:true,        
                center:true
            }
        ]

        const actions = [
            <i className="fas fa-file-pdf" key="pdfbutton" title="Pdf-ში კონვერტირება" style={{color:"#262262",cursor:"pointer"}} onClick={this.getToPdf}></i>,
            <i className="fas fa-file-excel" key="excelbutton" title="Excel-ში კონვერტირება" style={{color:"#262262",cursor:"pointer"}} onClick={this.export}></i>
        ]

        let dt = moment(new Date()).format("DD.MM.YYYY");
        const fileName= `${this.props.values+"_"+dt}.xlsx`;
        const headerCellOptions={background: '#262262',textAlign:'center',fontSize:15};

        return ( 
            <React.Fragment>   
                <div className="row justify-content-center">
                    <ExcelExport
                        data={smshistory}                  
                        fileName={fileName}
                        headerPaddingCellOptions={{ background: '#ff0000' }}
                        ref={(exporter) => { this._exporter = exporter; }}                  
                        >
                        <ExcelExportColumn field="customerNumber" title="აბონენტის ნომერი" width={150} height="10em" headerCellOptions={headerCellOptions} />
                        <ExcelExportColumn field="phoneNumber" title="მობილურის ნომერი" width={150} headerCellOptions={headerCellOptions}/>
                        <ExcelExportColumn field="sendDateString" title="გაგზავნის დრო" width={150} headerCellOptions={headerCellOptions}/>
                        <ExcelExportColumn field="sendText" title="გაგზავნილი ტექსტი" width={750} headerCellOptions={headerCellOptions}/>                   
                    </ExcelExport>                                                                        
                    <div className="col-lg-12" style={{fontFamily:"BPGCond"}}>
                        <div className="card-group">
                            {
                                this.state.loader?
                                <Loader></Loader>
                                :null
                            }
                            <DataTable title={<div style={{float:"left"}}><TableSearch data={this.state.sms} handleSearchedHistory={this.handleSearchedHistory}/></div>}
                            actions={actions} columns={columns} data={smshistory} pagination responsive striped style={{fontFamily:"BPGCond"}} noDataComponent="მონაცემები ცარიელია"></DataTable>
                        </div>
                    </div>            
                </div>
            </React.Fragment>
         );
    }
}
 
export default Sms;
