import React, { Component } from 'react';
import '../css/style.css';
import '../css/fontawesome-free-5.13.0-web/css/all.css';
import '../fonts/stylesheet.css';
import Loader from './loader';
import ReCAPTCHA from 'react-google-recaptcha';

class PassResetStep2 extends Component {
  constructor(props) {
    super(props);
    //console.log(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      isValid: true,
    };
  }

  handleClick() {
    let formIsValid = this.props.formValidate(this.props, 2);
    this.setState({ isValid: formIsValid });
    if (formIsValid) {
      this.props.passReset();
    } else {
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.handleClick();
    }
  }

  render() {
    const { values, handleChange } = this.props;

    let styleObj = {
      display: 'flex',
      alignItems: 'center',
      width: '0.75rem',
      height: '0.75rem',
      //fontSize: '1rem',
      flexDirection: 'row',
      //textAlign: 'center',
      whiteSpace: 'nowrap',
      color: 'inherit',
      //verticalAlign:'middle',
      /* align-items: center; */
      fill: 'currentColor',
      backgroundColor: '#ebedef',
    };

    return (
      <React.Fragment>
        <div
          className="c-app flex-row align-items-center"
          onKeyDown={(event) => {
            this.handleKeyDown(event);
          }}
          style={{ fontFamily: 'BPGCond' }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card-group" style={{ textAlign: 'center' }}>
                  {values.bool2 ? <Loader></Loader> : null}
                  <div className="card p-4">
                    <div className="card-header">
                      <h1 style={{ fontFamily: 'BPGCaps' }}>პაროლის აღდგენა</h1>
                    </div>
                    <div className="card-body">
                      <form autoComplete="off">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <div style={styleObj}>
                                <i className="fas fa-phone-square-alt"></i>
                              </div>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            name="code"
                            id="codeRes"
                            type="text"
                            role="presentation"
                            placeholder="ერთჯერადი კოდი"
                            onChange={handleChange('code')}
                            value={values.code}
                            autoComplete="off"
                          ></input>
                        </div>
                        {values.codeerror ? (
                          <label style={{ fontSize: 12, color: '#e55353' }}>
                            {values.codeerror}
                          </label>
                        ) : null}
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <div style={styleObj}>
                                <i className="fas fa-lock"></i>
                              </div>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type="password"
                            placeholder="პაროლი"
                            id="pwdResNew"
                            name="passwordResNew"
                            onChange={handleChange('password')}
                            value={values.password}
                            autoComplete="new-password"
                          />
                        </div>
                        {values.passworderror ? (
                          <label style={{ fontSize: 12, color: '#e55353' }}>
                            {values.passworderror}
                          </label>
                        ) : null}
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <div style={styleObj}>
                                <i className="fas fa-lock"></i>
                              </div>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type="password"
                            autoComplete="off"
                            name="passwordResRep"
                            placeholder="გაიმეორეთ პაროლი"
                            onChange={handleChange('repassword')}
                            value={values.repassword}
                          />
                          {/* <div className="invalid-feedback">{values.repassworderror?values.repassworderror:null}</div> */}
                        </div>
                        {values.repassworderror ? (
                          <label style={{ fontSize: 12, color: '#e55353' }}>
                            {values.repassworderror}
                          </label>
                        ) : null}
                        <div className="row justify-content-center">
                          <div className="col-md-8">
                            <ReCAPTCHA
                              sitekey={values.siteKey}
                              onChange={handleChange('recaptchaCode_1')}
                              hl="ka"
                            ></ReCAPTCHA>
                          </div>{' '}
                        </div>
                        <div>
                          <button
                            className="btn btn-primary px-4"
                            type="button"
                            onClick={this.handleClick}
                            disabled={values.recaptchaCode_1 ? false : true}
                            style={{
                              backgroundColor: '#262262',
                              borderColor: '#262262',
                              fontFamily: 'BPGCond',
                            }}
                          >
                            შემდეგი
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PassResetStep2;
