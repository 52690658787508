import React, { Component } from 'react';
//import ReactLoading from 'react-loading';
import '../css/style.css';
import Logo from '../mainlogo.png';
import '../fonts/stylesheet.css';

class Loader extends Component {

    // constructor(props){
    //     super(props);
    // }
     
    render() { 

        return (      
            <div className="WaitLoader">
                <div className="loaderLogoDiv">
                    <img className="loaderLogo" src={Logo} alt="logo"></img>
                </div>
                <br/><br/>
                <h4 style={{fontFamily:"BPGCaps"}}>იტვირთება</h4>
            </div>     
            // <div className="c-app flex-row align-items-center">
            //     <div className="container">
            //         <div className="row justify-content-center">
            //             <div className="loader-container">
            //                 <div className="loader">
            //                     
            //                     {/* <ReactLoading type={'balls'} color={'#FFFFF'} height={'10%'} width={'10%'} className="col-md-1"/> */}
            //                     {/* <img src={Logo} alt="loader.gif"></img> */}
            //                 </div>
            //             </div>    
            //         </div>
            //     </div>
            // </div>          
         );
    }
}
 
export default Loader;