import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
import ToLetters from '../../ToLetters';
import axios from 'axios';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import MaskedInput1 from 'react-text-mask';
import TextMask from 'react-text-mask';
import emailMask from 'text-mask-addons/src/emailMask';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import InputInfo from '../InputInfo';

class AbonentRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agree: false,
      checkyes_1: true,
      checkyes_2: false,
      checkyes_3: false,
      checkyes_4: false,
      checkyes_5: true,
      checkyes_7: true,
      checkyes_17: true,
      checkyes_18: false,
      fullname: '',
      personal: '',
      phone_1: '',
      phone_2: '',
      email_1: '',
      email_2: '',
      applicant_fullname: '',
      applicant_personal: '',
      applicant_phone_1: '',
      applicant_phone_2: '',
      applicant_email_1: '',
      applicant_email_2: '',
      applicantaddress: '',
      telasi: '',
      gwp: '',
      custN: '',
      custNerror: '',
      address: '',
      cadastral: '',
      cadastralerror: '',
      bank_name: '',
      bank_code: '',
      account_number: '',
      comment: '',
      file_1: null,
      file_2: null,
      file_3: null,
      file_4: null,
      file_5: null,
      file_6: null,
      file_7: null,
      tools: [{ tool_Id: '', quantity: '', mcube: '' }],
      toolserror: '',
      recaptchaCode: '',
      recaptchaCodeerror: '',
      reference_tools: [],
      reference_FieldsDescriptions: [],
      errors: [],
    };
    this.handleLetterTermsModal = this.props.handleLetterTermsModal;
    this.letterFormValidator = this.props.letterFormValidator;
    this.renderCustomersOptions = this.props.renderCustomersOptions;
    this.SendLetter = this.props.SendLetter;
    this.handleSwalPopup = this.props.handleSwalPopup;
    this.checkGeorgianLetters = this.props.checkGeorgianLetters;
  }

  UNSAFE_componentWillMount() {
    const token = read_cookie('Bearer_Token');
    const url = window.$name;

    axios
      .get(`${url}/api/Letters/GetLetterFieldDescriptions/${this.props.letterTypeId}`, {
        headers: {
          Token: token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ reference_FieldsDescriptions: res.data.descriptions });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'udnefined') {
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.replace('/login');
          }
        }
      });

    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      //console.log(data);
      let phone = data.phones.filter((x) => x.typeKey === 1);
      let email = data.emails[0];
      //console.log(phone)
      this.setState({
        fullname: data.name + ' ' + data.lastName,
        personal: data.personalId,
        phone_1: phone ? phone[0].info : '',
        email_1: email ? email.info : '',
        address: data.registrationAddress,
      });
    }
  }

  componentDidMount() {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;

    if (typeof this.props.location.search !== 'undefined') {
      let s = this.props.location.search;
      let cust = s.substring(1);
      this.setState({ custN: cust });
      this.handleRepeat();
    }
    Axios.get(`${name}/api/Letters/GetReferences?letterTypeId=50`, { headers: { Token: token } })
      .then((res) => {
        let data = res.data.references;
        //console.log(data);
        let tools = [];
        data.forEach((element) => {
          switch (element.key) {
            case 'RefDevices':
              let obj1 = { value: element.id, label: element.value, m3: element.parentId };
              tools.push(obj1);
              break;
            default:
              break;
          }
        });

        this.setState({ reference_tools: tools });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
      });
  }

  handleToolChange = (index, e) => {
    const { value, m3 } = e;
    //console.log([value,label,m3]);
    const list = [...this.state.tools];
    list[index]['mcube'] = m3;
    list[index]['tool_Id'] = value;
    this.setState({ tools: list });
    //console.log(list);
  };

  handleCheck(name) {
    this.setState({ [name]: !this.state[name] });
  }

  handleChange = (input) => (e) => {
    let ph = '';
    if (input.includes('phone')) {
      ph = e.target.value.replace(/\D/g, '');
      if (input === 'phone_1') this.setState({ phone_1: ph });
      else if (input === 'phone_2') this.setState({ phone_2: ph });
      else if (input === 'applicant_phone_1') this.setState({ applicant_phone_1: ph });
      else this.setState({ applicant_phone_2: ph });
    } else if (input === 'recaptchaCode') {
      this.setState({ recaptchaCode: e });
    } else if (input === 'cadastral') {
      let val = e.target.value;
      const regex = /(\b\d{2,3}\b)|[0-9]{2,3}/g;
      let validationResult = true;
      if (val) {
        let t1 = val.replaceAll(',', '.');
        let t2 = t1.replaceAll('/', '.');

        let res = t2.match(regex);

        let splited = t2.split('.');

        if (splited.length < 3) {
          this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
          validationResult = false;
        }

        if (res && splited && splited[splited.length - 1].length < 5) {
          if (res.length != splited.length) {
            this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
            validationResult = false;
          }
        }

        if (splited.length > 3) {
          const response = this.checkGeorgianLetters(t2);
          if (splited[splited.length - 1].length > 6) {
            this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
            validationResult = false;
          }
          if (!response) {
            this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
            validationResult = false;
          }
        }

        if (validationResult) {
          this.setState({ [input]: t2, cadastralerror: '' });
        }
      } else {
        this.setState({ [input]: e.target.value });
      }
    } else {
      this.setState({ [input]: e.target.value, cadastralerror: '' });
      //console.log(this.state.comment);
    }
  };

  handleFileChange = (input) => (event) => {
    this.setState({ [input]: event.target.files[0] });
  };

  handleAddClick = () => {
    this.setState({ tools: [...this.state.tools, { tool_Id: '', quantity: '', mcube: '' }] });
  };

  handleRemoveClick = (index) => {
    let list = this.state.tools.splice(index, 1);
    this.setState({ tools: list });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.tools];
    list[index][name] = value;
    this.setState({ tools: list });
  };

  handleSubmit = () => {
    let isValid = this.formValidate();

    let data = [];

    if (isValid) {
      for (const [key, value] of Object.entries(this.state)) {
        let obj = {};
        if (!key.includes('error') && !key.includes('refe') && !key.includes('recap')) {
          if (value !== '' && value !== null) {
            obj.key = key;
            obj.value = value;
            data.push(obj);
          }
        }
      }
      let s = false;
      if (typeof this.props.location.search !== 'undefined') s = true;

      var cstn = this.state.custN;
      if (cstn === '') cstn = '0';

      this.SendLetter(cstn, this.props.letterTypeId, this.state.recaptchaCode, data, s);
    } else {
      //var filesError = this.state.errors.filter(x=>x.key==="filesSize"); if(filesError.length) filesError=filesError[0].value;
      if (this.state.errorFileSize) {
        this.handleSwalPopup(
          'ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      } else {
        this.handleSwalPopup(
          'შესავსებია სავალდებულო ველები',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      }
    }
  };

  handleRepeat = () => {
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      this.setState({
        applicant_fullname: this.state.fullname,
        applicant_personal: this.state.personal,
        applicantaddress: this.state.address,
        applicant_phone_1: this.state.phone_1,
        applicant_phone_2: this.state.phone_2,
        applicant_email_1: this.state.email_1,
        applicant_email_2: this.state.email_2,
      });
    }
  };

  formValidate() {
    const values = this.state;

    let data = [];

    for (const [key, value] of Object.entries(values)) {
      if (!key.includes('recap') && !key.includes('refe') && !key.includes('error')) {
        let obj = {};
        obj.name = key;
        obj.value = value;
        if (
          key === 'fullname' ||
          key === 'personal' ||
          key === 'phone_1' ||
          key === 'address' ||
          key === 'cadastral' ||
          key === 'file_1' ||
          key === 'applicant_fullname' ||
          key === 'applicantaddress' ||
          key === 'applicant_phone_1' ||
          key === 'applicant_personal'
        )
          obj.Required = true;
        // else if(key==="gwp"||key==="telasi"){
        //     if(values.checkyes_18) obj.Required=true;
        // }
        else obj.Required = false;

        data.push(obj);
      }
    }

    let result = this.letterFormValidator(this.props.letterTypeId, data);
    const found = result.errors.find((x) => x.key === 'filesSize');

    if (typeof found !== 'undefined') {
      //console.log(1234);
      this.state.errorFileSize = true;
      this.setState({ errors: result.errors });
      //this.handleSwalPopup("ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს","warning",false,"","დახურვა",false,"#262262");
    } else {
      this.state.errorFileSize = false;
      this.setState({ errors: result.errors });
    }

    return result.isValid;
  }

  errorExsist = (key) => {
    let bool = this.state.errors.some((obj) => obj.key == key);
    return bool;
  };

  renderErrorText = (key) => {
    let bool = this.state.errors.find((obj) => obj.key.includes(key) === true);
    return bool.value;
  };

  render() {
    const values = this.state;
    let phone, email;
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      phone = data.phones.filter((x) => x.typeKey === 1);
      email = data.emails[0];
    }
    let l = true;
    if (values.reference_FieldsDescriptions.length === 0) l = false;
    //console.log(values.checkyes_5);
    return (
      <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div style={{ float: 'left' }}>
                <ToLetters></ToLetters>
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>
                  <strong>{this.props.letterTitle}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>განმცხადებელი</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სახელი და გვარი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    {/* <MaskedInput1 className='form-control'
                                        mask={[ /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                         /> */}
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      readOnly={this.props.params.user.fullName ? true : false}
                      placeholder="სახელი გვარი/ორგანიზაციის დასახელება"
                      onChange={this.handleChange('fullname')}
                      value={values.fullname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'fullname'}
                      />
                    ) : null}
                    {this.errorExsist('fullname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('fullname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-4 col-form-label">
                    <strong>პირადი #/საიდენტიფიკაციო #:</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="personal"
                      placeholder="პირადი ნომერი"
                      readOnly={this.props.params.user.personalId ? true : false}
                      onChange={this.handleChange('personal')}
                      value={values.personal}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'personal'}
                      />
                    ) : null}
                    {this.errorExsist('personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მისამართი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      placeholder="მისამართი"
                      readOnly={this.props.params.user.registrationAddress ? true : false}
                      onChange={this.handleChange('address')}
                      value={values.address}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'address'}
                      />
                    ) : null}
                    {this.errorExsist('address') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('address')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      readOnly={
                        typeof phone[0].info !== 'undefined' && phone[0].info ? true : false
                      }
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_1"
                      onChange={this.handleChange('phone_1')}
                      value={values.phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_2"
                      onChange={this.handleChange('phone_2')}
                      value={values.phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_2'}
                      ></InputInfo>
                    ) : null}
                    {this.errorExsist('phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      readOnly={typeof email !== 'undefined' ? true : false}
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_1"
                      onChange={this.handleChange('email_1')}
                      value={values.email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_1'}
                      ></InputInfo>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_2"
                      onChange={this.handleChange('email_2')}
                      value={values.email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_2'}
                      ></InputInfo>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>რეგისტრაციის მსურველი</strong>
              <div className="card-header-actions">
                <button className="btn btn-primary btn-sm" onClick={this.handleRepeat}>
                  განმცხადებლის მონაცემების გადმოტანა
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>აბონენტის ნომერი</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput
                      className="form-control"
                      mask="111111-111"
                      list="customers"
                      placeholder="XXXXXX-XXX"
                      name="custN"
                      onChange={this.handleChange('custN')}
                      value={values.custN}
                    ></MaskedInput>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'custN'}
                      ></InputInfo>
                    ) : null}
                    <datalist id="customers">{this.renderCustomersOptions()}</datalist>
                    {this.errorExsist('custN') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('custN')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>რეგისტრაციის მსურველი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="applicant_fullname"
                      placeholder="სახელი გვარი/ორგანიზაციის დასახელება"
                      onChange={this.handleChange('applicant_fullname')}
                      value={values.applicant_fullname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'applicant_fullname'}
                      />
                    ) : null}
                    {this.errorExsist('applicant_fullname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('applicant_fullname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-4 col-form-label">
                    <strong>პირადი #/საიდენტიფიკაციო #:</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="applicant_personal"
                      placeholder="პირადი ნომერი"
                      onChange={this.handleChange('applicant_personal')}
                      value={values.applicant_personal}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'applicant_personal'}
                      />
                    ) : null}
                    {this.errorExsist('applicant_personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('applicant_personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მისამართი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="applicantaddress"
                      placeholder="მისამართი"
                      onChange={this.handleChange('applicantaddress')}
                      value={values.applicantaddress}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'applicantaddress'}
                      />
                    ) : null}
                    {this.errorExsist('applicantaddress') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('applicantaddress')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="applicant_phone_1"
                      onChange={this.handleChange('applicant_phone_1')}
                      value={values.applicant_phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'applicant_phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('applicant_phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('applicant_phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="applicant_phone_2"
                      onChange={this.handleChange('applicant_phone_2')}
                      value={values.applicant_phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'applicant_phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('applicant_phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('applicant_phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ძირითადი"
                      name="applicant_email_1"
                      onChange={this.handleChange('applicant_email_1')}
                      value={values.applicant_email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'applicant_email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ალტერნატიული"
                      name="applicant_email_2"
                      onChange={this.handleChange('applicant_email_2')}
                      value={values.applicant_email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'applicant_email_2'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>სხვა პირობები</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      გსურთ თუ არა ისარგებლოთ ქვითრის ელექტრონული სახით მიწოდების სერვისით
                    </strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_1');
                        }}
                        checked={this.state.checkyes_1}
                      ></input>
                      &nbsp;დიახ
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_1');
                        }}
                        checked={!this.state.checkyes_1}
                      ></input>
                      &nbsp;არა
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      მიეწოდოს ელექტროენერგეტიკისა და წყალმომარაგების სექტორში მომსახურე შემდეგ
                      საწარმოებს, სადაც, განაცხადში მოცემულ მისამართზე, რეგისტრირებული ვარ
                      მომხმარებლად და გამაჩნია აბონენტის ნომერი
                    </strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_18');
                        }}
                        checked={this.state.checkyes_18}
                      ></input>
                      &nbsp;დიახ
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_18');
                        }}
                        checked={!this.state.checkyes_18}
                      ></input>
                      &nbsp;არა
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_18'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      უძრავი ქონების საკადასტრო კოდი <span style={{ color: '#e55353' }}>*</span>
                    </strong>
                    <br />
                    დასაშვებია მხოლოდ ციფრები, წერტილი და ერთი ქართული ასო
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="cadastral"
                      placeholder="საკადასტრო კოდი"
                      onChange={this.handleChange('cadastral')}
                      value={values.cadastral}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'cadastral'}
                      />
                    ) : null}
                    <label style={{ fontSize: 14, color: '#262262' }}></label>
                    {values.cadastralerror ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {values.cadastralerror}
                      </label>
                    ) : null}
                    {this.errorExsist('cadastral') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('cadastral')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-control">
                    <strong>უძრავი ქონების ფლობის ფორმა</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_7');
                        }}
                        checked={this.state.checkyes_7}
                      ></input>
                      &nbsp;საკუთრება
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_7');
                        }}
                        checked={!this.state.checkyes_7}
                      ></input>
                      &nbsp;დროებით ფლობა
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_7'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ბუნებრივი გაზის მოხმარების მიზანი</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_17');
                        }}
                        checked={this.state.checkyes_17}
                      ></input>
                      &nbsp;საყოფაცხოვრებო
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_17');
                        }}
                        checked={!this.state.checkyes_17}
                      ></input>
                      &nbsp;არასაყოფაცხოვრებო
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_17'}
                      />
                    ) : null}
                  </div>
                </div>
                {!values.checkyes_17 && (
                  <>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        მოხმარებული გაზის საფასურის გადაუხდელობის შემთხვევაში, მიმწოდებელი
                        უფლებამოსილია დავალიანების დაფარვამდე ყოველ მომდევნო საანგარიშო თვეში
                        ერთხელ, მომხმარებელს დაარიცხოს პირგასამტეხლო, საანგარიშო თვეში მოხმარებული
                        გაზის საფასურის გადაუხდელი თანხის 2,1% (ორი მთელი ერთი მეათედი) ოდენობით.
                        პირგასამტეხლოს გადახდა არ ათავისუფლებს მომხმარებელს მოხმარებული ბუნებრივი
                        გაზის საფასურის გადახდის ვალდებულებისგან. მომხმარებლის მიერ პირგასამტეხლოს
                        გადახდის ვალდებულების წარმოშობის შემთხვევაში, პირველად დაიფარება
                        პირგასამტეხლო, ხოლო შემდგომ ძირითადი დავალიანება, ხოლო პირგასამტეხლოს სრული
                        ოდენობის დაფარვის შემდეგ გადასახდელი თანხა წარიმართება ძირითადი დავალიანების
                        დასაფარად, იმ პირობით, რომ პირველად დაიფარება უფრო ადრე წარმოშობილი
                        დავალიანება. მიმწოდებლის მხრიდან გაზის მიუწოდებლობისას, თუ ეს გამოწვეულია
                        მისი ბრალეული ქმედებით, მომხმარებელი უფლებამოსილია პირგასამტეხლოს სახით
                        მოითხოვოს ამ პერიოდში მიუწოდებელი გაზის საფასურის 0,07% (ნული მთელი შვიდი
                        მეასედი) გაზის მიუწოდებლობის პერიოდის ყოველ დღეზე, ხოლო ყოველდღიური
                        პირგასამტეხლო გამოითვლება წინა საანგარიშო თვის განმავლობაში
                        მომხმარებლისათვის მიწოდებული გაზის საშუალო დღიური მოცულობის შესაბამისად.
                        ამასთან, პირგასამტეხლოს გადახდა არ ათავისუფლებს მიმწოდებელს ხელშეკრულებით
                        ნაკისრი ვალდებულებების შესრულებისაგან.
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        <strong>შენიშვნის ველი</strong>
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          id="comment"
                          placeholder="შენიშვნის ველი"
                          onChange={this.handleChange('comment')}
                          value={values.comment}
                        ></input>
                      </div>
                    </div>
                  </>
                )}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>გესაჭიროებათ თუ არა საგადასახადო ანგარიშ-ფაქტურის გამოწერა</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_4');
                        }}
                        checked={this.state.checkyes_4}
                      ></input>
                      &nbsp;დიახ
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_4');
                        }}
                        checked={!this.state.checkyes_4}
                      ></input>
                      &nbsp;არა
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_4'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>განაწილების ლიცენზიატის მიერ შეტყობინების გაგზავნის ფორმა:</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_5');
                        }}
                        checked={this.state.checkyes_5}
                      ></input>
                      &nbsp;ელექტრონული
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_5');
                        }}
                        checked={!this.state.checkyes_5}
                      ></input>
                      &nbsp;წერილობითი
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_5'}
                      />
                    ) : null}
                    <br></br>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ბანკის დასახელება</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="bank_name"
                      placeholder="ბანკის დასახელება"
                      onChange={this.handleChange('bank_name')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'bank_name'}
                      />
                    ) : null}
                    {this.errorExsist('bank_name') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('bank_name')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ბანკის კოდი</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="bank_code"
                      placeholder="ბანკის კოდი"
                      onChange={this.handleChange('bank_code')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'bank_code'}
                      />
                    ) : null}
                    {this.errorExsist('bank_code') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('bank_code')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ანგარიშის ნომერი</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="account_number"
                      placeholder="ანგარიშის ნომერი"
                      onChange={this.handleChange('account_number')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'account_number'}
                      />
                    ) : null}
                    {this.errorExsist('account_number') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('account_number')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label"><strong>გაზის დანადგარების და ხელსაწყოების ჩამონათვალი</strong></label>
                                </div>  */}
                {this.state.tools.map((tool, index) => {
                  //console.log(tool);
                  return (
                    <React.Fragment key={index}>
                      <div className="form-group row">
                        <br></br>
                        <label className="col-md-4 col-form-label">
                          {index === 0 ? (
                            <strong>გაზის დანადგარების და ხელსაწყოების ჩამონათვალი </strong>
                          ) : null}
                        </label>

                        <div className="col-md-4">
                          {/* <input className="form-control" name="Tname" placeholder="დანადგარის დასახელება" value={tool.Tname} onChange={e => this.handleInputChange(e, index)}></input> */}
                          <Select
                            isSearchable
                            options={values.reference_tools}
                            onChange={(e) => this.handleToolChange(index, e)}
                            placeholder="აირჩიეთ"
                          />
                        </div>
                        <div className="col-md-2">
                          <input
                            className="form-control"
                            name="quantity"
                            type="number"
                            placeholder="რაოდ."
                            value={tool.quantity}
                            onChange={(e) => this.handleInputChange(e, index)}
                          ></input>
                        </div>
                        <div className="col-md-2">
                          <input
                            className="form-control"
                            name="mcube"
                            type="number"
                            placeholder="მ&sup3;"
                            value={tool.mcube}
                            onChange={(e) => this.handleInputChange(e, index)}
                          ></input>
                          {l && index === 0 ? (
                            <InputInfo
                              descriptions={values.reference_FieldsDescriptions}
                              letterTypeId={this.props.letterTypeId}
                              inputName={'tools'}
                            />
                          ) : null}
                        </div>
                        {/* <div className="col-md-1"/> */}
                      </div>
                      <div className="form-group row">
                        <div className="col-md-4" />
                        <div className="col-md-6" />
                        <div className="btn-box col-md-2">
                          {this.state.tools.length !== 1 && (
                            <button className="" type="button" onClick={this.handleRemoveClick}>
                              წაშლა
                            </button>
                          )}
                          &nbsp;
                          {this.state.tools.length - 1 === index && (
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={this.handleAddClick}
                            >
                              დამატება
                            </button>
                          )}
                        </div>
                        {/* <div className="col-md-1"/> */}
                      </div>
                    </React.Fragment>
                  );
                })}

                {values.toolserror ? (
                  <div className="form-group row">
                    <div className="col-md-4"></div>
                    <label style={{ fontSize: 10, color: '#e55353' }}>{values.toolserror}</label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>თანდართული დოკუმენტაცია (მხოლოდ pdf/jpg ფაილები, ჯამში მაქსიმუმ 14MB)</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      პირადობის მოწმობის ან პასპორტის ასლი
                      <span style={{ color: '#e55353' }}> *</span>
                    </strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_1')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_1'}
                      />
                    ) : null}
                    <br />
                    {this.errorExsist('file_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label">
                                        <strong>ამონაწერი საჯარო რესტრიდან<span style={{color:"#e55353"}}> *</span></strong>
                                    </label>
                                    <div className="col-md-4">
                                        <input type="file" accept=".pdf, .jpg, .jpeg" onChange={this.handleFileChange("file_2")}></input>
                                        <br/>
                                        {
                                            this.errorExsist("file_2") ?(
                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("file_2")}</label>
                                                ):null
                                        }
                                    </div>
                                </div> */}
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label">
                                        <strong>ამონაწერი სამეწარმეო რეესტრიდან</strong>
                                    </label>
                                    <div className="col-md-8">
                                        <input type="file" accept=".pdf, .jpg, .jpeg" onChange={this.handleFileChange("file_3")}></input>
                                        {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"file_3"} />:null}
                                        <br/>
                                        {
                                            this.errorExsist("file_3") ?(
                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("file_3")}</label>
                                                ):null
                                        }
                                    </div>
                                </div> */}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>დროებითი რეგისტრაციის მსურველის თანხმობა</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_4')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_4'}
                      />
                    ) : null}
                    <br />
                    {this.errorExsist('file_4') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_4')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label">
                                        <strong>გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)</strong>
                                    </label>
                                    <div className="col-md-8">
                                        <input type="file" accept=".pdf, .jpg, .jpeg" onChange={this.handleFileChange("file_5")}></input>
                                        {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"file_5"} />:null}
                                        <br/>
                                        {
                                            this.errorExsist("file_5") ?(
                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("file_5")}</label>
                                                ):null
                                        }
                                    </div>
                                </div> */}
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label">
                                        <strong>საბანკო რეკვიზიტები</strong>
                                    </label>
                                    <div className="col-md-8">
                                        <input type="file" accept=".pdf, .jpg, .jpeg" onChange={this.handleFileChange("file_6")}></input>
                                        {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"file_6"} />:null}
                                        <br/>
                                        {
                                            this.errorExsist("file_6") ?(
                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("file_6")}</label>
                                                ):null
                                        }
                                    </div>
                                </div> */}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სხვა დოკუმენტები</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_7')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_7'}
                      />
                    ) : null}
                    <br />
                    {this.errorExsist('file_7') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_7')}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-md-12" style={{ textAlign: 'right' }}>
                  <label>
                    <input
                      type="checkbox"
                      style={{ verticalAlign: 'middle' }}
                      checked={values.agree}
                      onChange={() => this.handleCheck('agree')}
                    ></input>
                    &nbsp;ვეთანხმები
                    <span
                      style={{ color: '#262262', cursor: 'pointer' }}
                      onClick={this.handleLetterTermsModal}
                    >
                      &nbsp;<strong>წესებსა და პირობებს</strong>
                    </span>
                  </label>
                </div>
                <br />
                <br />
                <div className="col-md-12">
                  <ReCAPTCHA
                    style={{ float: 'right' }}
                    sitekey={this.props.params.siteKey}
                    onChange={this.handleChange('recaptchaCode')}
                    hl="ka"
                  ></ReCAPTCHA>
                </div>
                <br />
                <br />
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary"
                    style={{ float: 'right', backgroundColor: '#262262', color: 'white' }}
                    disabled={this.state.recaptchaCode && this.state.agree ? false : true}
                    onClick={this.handleSubmit}
                  >
                    გაგზავნა
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AbonentRegister;
