import React, { Component } from 'react';
import SignUp from './signup';
import Mobileverif from './mobileverif';
import axios from '../../node_modules/axios';
import { bake_cookie, read_cookie } from '../../node_modules/sfcookies';
import { Redirect } from 'react-router-dom';
// import TemporaryPin from '../components/pages/TemporaryPin';

class UserForm extends Component {

    state = {
        step: 1,
        firstname: "",
        firstnameerror:"",
        lastname: "",
        lastnameerror:"",
        address:"",
        addresserror:"",
        username: "",
        usernameerror:"",
        email: "",
        emailerror:"",
        phonenumber: "",
        phonenumbererror:"",
        isCitizen:true,
        personalnumber: "",
        personalnumbererror:"",
        password: "",
        passworderror:"",
        repassword: "",
        repassworderror:"",
        code: "",
        codeerror:"",
        token: "",
        loading:false,
        disabled:false,
        siteKey:"6LeRKt0ZAAAAAK4UK8qKKhj8kUgy0Bc13izENa5g",
        recaptchaCode:"",
        recaptchaCodeerror:"",
        toSignUp:false,
        pin:"",
        pintohandle:"my2020",
        agreeToTerms:false,
        TermsModal:false
      };
      
      UNSAFE_componentWillMount(){
        // var querystring = window.location.search;
        // var params = new URLSearchParams(querystring);
        // let platform = params.get("platform");
        // let app = params.get("app");
        // if(params!==null&&app!==null){
        //   bake_cookie("platform",platform);
        //   bake_cookie("app",app);
        // }
      }

      passwordInputValidate(password){
        let formIsValid = true;
        this.state.passworderror="";
        if(!password){
          formIsValid=false;
          this.setState({passworderror:"აუცილებელია პაროლის შეყვანა"});
          this.setState({disabled:true});
        }

        if(password){
          if(!password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/)||password.length<6){   
            formIsValid=false;       
            this.setState({disabled:true,passworderror:"პაროლი უნდა შეიცავდეს ლათინური ანბანის პატარა და დიდ სიმბოლოებს, ციფრს და უნდა იყოს მინიმუმ 6 სიმბოლო"})
            // this.state.disabled=true;
            // this.state.passworderror="პაროლი უნდა შეიცავდეს ლათინური ანბანის პატარა და დიდ სიმბოლოებს, ციფრს და უნდა იყოს მინიმუმ 6 სიმბოლო";
          }
          else{
            this.state.disabled=false;
          }
        }
        return formIsValid;
      }

      usernameInputValidate(username){
        let formIsValid = true;
        this.state.usernameerror="";
        if(!username){
          formIsValid=false;
          this.setState({usernameerror:"username არ უნდა იყოს ცარიელი"});
        }
        const name = window.$name;
        if(username){
          axios.post(`${name}/api/Users/CheckUserName`,
          {
            info:username
          })
          .then(res=>{
            //console.log(res);
            if(!res.data.success){
              formIsValid=false;
              this.setState({usernameerror:"მომხმარებელი ამ სახელით არსებობს"});
              this.setState({disabled:true});
            }
            else{
              this.setState({disabled:false});
            }
          })
          .catch(error=>{
            //console.log(error);
            formIsValid=false;
            this.setState({disabled:true});
            this.setState({usernameerror:"შეცდომა"});
          })
        }     

        return formIsValid;
      }

      phoneInputValidate(phone){
        let formIsValid = true;
        this.state.phonenumbererror="";
        if(!phone){
          formIsValid=false;
          this.setState({phonenumbererror:"მობილურის ნომერის ველი არ უნდა იყოს ცარიელი"});
        }

        if(phone){

          if(phone.length!==9){
            formIsValid=false;
            this.setState({disabled:true,phonenumbererror:"მობილურის ნომერი უნდა იყოს 9 ნიშნა"})
            // this.state.phonenumbererror="მობილურის ნომერი უნდა იყოს 9 ნიშნა";
            // this.state.disabled=true;
          }

          if(!phone.match(/^[0-9]+$/)){
            formIsValid=false;
            this.state.phonenumbererror="მობილურის ნომრის ველში დასაშვებია მხოლოდ ციფრები";        
          }
        }
        const name = window.$name;
        // if(phone&&phone.length===9){
        //   axios.post(`${name}/api/Users/CheckMobilePhone`,
        //   {
        //     info:phone
        //   })
        //   .then(res=>{   
        //     //console.log([phone,res]);        
        //     if(!res.data.success){
        //       formIsValid=false;
        //       if(res.data.code!==-3){
        //         this.setState({phonenumbererror:"მობილურის ნომრით მომხმარებელი არსებობს"});
        //       }
        //       else{
        //         this.setState({phonenumbererror:"შეიყვანეთ ვალიდური ნომერი"});
        //       }
        //       this.setState({disabled:true})
        //     }
        //     else{
        //       this.setState({disabled:false});
        //     }
        //   })
        //   .catch(error=>{
        //     formIsValid=false;
        //     this.state.phonenumbererror="შეცდომა";
        //   })
        // }
        return formIsValid;
      }

      personalInputValidate(personal){
        let formIsValid = true;
        this.state.personalnumbererror="";
        //console.log(personal);
        

        if(this.state.isCitizen){
          if(personal.length!==11){
            //console.log(this.state.personalnumber);
            formIsValid=false;
            this.setState({personalnumbererror:"პირადი ნომერი უნდა იყოს 11 ნიშნა"});
            this.state.disabled=true;
          }
          else{
            if(typeof(personal)!=="undefined"){
              if(!personal.match(/^[0-9]+$/)){
                formIsValid=false;
                this.state.disabled=true;
                this.setState({personalnumbererror:"პირადი ნომრის ველში დასაშვებია მხოლოდ ციფრები"});
              }
              else{
                this.setState({disabled:false})
              }
            }
          }
        }
        else{
          if(!personal){
            formIsValid=false;
            this.setState({personalnumbererror:"პირადი ნომერის ველი არ უნდა იყოს ცარიელი"})
            this.state.disabled=true;
          }
        }

        //console.log(this.state.personalnumber);
        const name = window.$name;

        if(personal){
          axios.post(`${name}/api/Users/CheckPersonalId`,
          {
            info:personal
          })
          .then(res=>{
           
            if(!res.data.success){
              //console.log(res);
              formIsValid=false;
              this.setState({personalnumbererror:"პირადი ნომრით მომხმარებელი არსებობს"});
              this.setState({disabled:true});
            }
            else{
              this.setState({disabled:false});
            }
          })
          .catch(error=>{
            formIsValid=false;
            this.setState({personalnumbererror:"შეცდომა"});
            this.setState({disabled:true});
          })
        }

        return formIsValid;
      }

      formValidate=(props,step)=>{
        let values = props.values;
        // this.passwordInputValidate();
        // this.personalInputValidate();
        // this.usernameInputValidate();
        // this.phoneInputValidate();
        let formIsValid = true;
        this.setState({firstnameerror:"",
                      lastnameerror:"",
                      addresserror:"",
                      usernameerror:"",
                      emailerror:"",
                      phonenumbererror:"",
                      personalnumbererror:"",
                      passworderror:"",
                      repassworderror:"",
                      codeerror:""});
        //this.setState({lastnameerror:""});
        //this.setState({usernameerror:""});
        //this.setState({emailerror:""});
        //this.setState({phonenumbererror:""});
        //this.setState({personalnumbererror:""});
        // this.setState({passworderror:""});
        // this.setState({repassworderror:""});
        // this.setState({codeerror:""});  
        let ph="";
       
        ph = values.phonenumber.replace(/\D/g, "");
        //values.phonenumber=ph; 
        //console.log(ph);
         // else this.setState({phone_2:ph});
          

        let passIsValid = this.passwordInputValidate(values.password);
        //console.log(passIsValid)
        if(!passIsValid){
          formIsValid=false;
        }
        let usernameIsValid=this.usernameInputValidate(values.username);
        if(!usernameIsValid){
          formIsValid=false;
        }
        let phoneInputValid=this.phoneInputValidate(ph);
        if(!phoneInputValid){
          formIsValid=false;
        }
        let personalInputValid=this.personalInputValidate(values.personalnumber);
        if(!personalInputValid){
          formIsValid=false;
        }

          

        if(!values["firstname"]){
          formIsValid=false;
          this.setState({firstnameerror:"სახელის ველი არ უნდა იყოს ცარიელი"});
        }

        if(!values["lastname"]){
          formIsValid=false;
          this.setState({lastnameerror:"გვარის ველი არ უნდა იყოს ცარიელი"});   
        }

        if(!values["address"]){
          formIsValid=false;
          this.setState({addresserror:"მისამართის შეყვანა აუცილებელია"});
        }

        if(values["email"]){
          if(typeof values["email"]!=="undefined"){
            if(!values["email"].match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
              formIsValid=false;
              this.setState({emailerror:"შეიყვანეთ ვალიდური EMail"});
            }
          }
        }

        if(!values["repassword"]){
          formIsValid=false;
          this.setState({repassworderror:"აუციელებელია განმეორებითი pაროლის შეყვანა"})   ; 
        }

        if(typeof values["repassword"]!=="undefined"){
          if(values["repassword"]!==values["password"]){
            formIsValid=false;
            this.setState({repassworderror:"პაროლები არ ემთხვევა ერთმანეთს"});
          }
        }
        
        if(step===2&&!values["code"]){         

        }

        if(step===2&&typeof values["code"]!=="undefined"){
          if(!values["code"].match(/^([0-9]{4})$/)){
            formIsValid=false;
            this.setState({codeerror:"არასწორი კოდი"});
          }
        }
       //console.log(this.state)
        return formIsValid;
      }
      
      submithandler(){
        //console.log(1);
        //e.preventDefault;
       // let bool = true;
        //let errors={};
        let ph = this.state.phonenumber.replace(/\D/g, "");
        const name = window.$name;
        const user = {
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          address: this.state.address,
          username:this.state.username,
          isCitizen:this.state.isCitizen,
          email: this.state.email,
          phonenumber: ph,
          personalnumber: this.state.personalnumber,
          password: this.state.password,
          recaptchaCode: this.state.recaptchaCode
          //token:""
        }
        let cockie_key = "Bearer_Token";

        this.setState({loading:true});

        axios.post(`${name}/api/Users/Register`,
        {
          userName:user.username,
          password:user.password,
          name:user.firstname,
          lastname:user.lastname,
          isCitizen:user.isCitizen,
          RegistrationAddress:user.address,
          personalId:user.personalnumber,
          email:user.email,
          mobilePhone:user.phonenumber,
          recaptchaCode:user.recaptchaCode
        })
        .then(res=>{
          //console.log(res);
          if(res.data.success){
            bake_cookie(cockie_key,res.data.token);
            //this.setState({token:res.data.token});
            const {step}  = this.state;
            this.setState({
              step: step + 1,
            });
          }
          else{            
            switch(res.data.code){
              case -50:
                this.setState({recaptchaCodeerror:"შეცდომა"})
                break;
              default:
                break;
            }
            window.grecaptcha.reset();
          }
          this.setState({loading:false});
        })
        .catch(error=>{
          this.setState({loading:false});
          //console.log(error);
        })
        //this.setState({loading:false});
       // return bool;
      };

      submitsmshandler(){      
        let Btoken = read_cookie("Bearer_Token");
        const name = window.$name;
        let ph = this.state.phonenumber.replace(/\D/g, "");
        axios.post(`${name}/api/Users/VerifyPhone`,{
          mobilePhone:ph,
          smsCode:this.state.code
        },
        {
          headers:{
            "Token":Btoken
          }
        })
        .then(res=>{
          //console.log(res);
          const {step}  = this.state;
          if(res.data.success){
            this.setState({
              step: step + 1,
            })
          }
        })
        .catch(error=>{
          //console.log(error);
          alert("არასწორი კოდი");
        })
       
      };

      submitSmsAgain(){
        let cockie_key = "Bearer_Token";
        const name = window.$name;
        let Btoken = read_cookie(cockie_key);
        var phone = "";
        let ph = this.state.phonenumber.replace(/\D/g, "");
        axios.post(`${name}/api/Home`,
      {

      },
      {
          headers:{
            "Token" : Btoken
          }
      }).then(res =>{
        var t = res.data.phones.find(obj=>obj.verified===false&&obj.typeKey===1);
        phone = t.info;
      })
      .catch(error=>{

      })
      
        axios.post(`${name}/api/Users/ReSendSmsCode`,
        {
          mobilePhone:phone!==""?phone:ph
        },
        {headers:
            {
              "Token":Btoken
            }    
        }
      ).then(res=>{
        //set token
        //bake_cookie(cockie_key,res.data.token);
        if(res.data.success){
          //console.log(res);
          alert("კოდი გამოგზავნილია");
        }
        else{
          if(res.data.code===-3){
            alert("წინა კოდი ჯერ კიდევ ვალიდურია");
          }
          
          else{
            alert("კოდის გამოგზავნა ვერ მოხერხდა");
          }
        }
          
      })
        .catch(error=>{
          
          alert("არასწორი კოდი");
          
          //console.log(error);
        })
      }

      nextStep=()=>  {
          this.submithandler();
      };

      nextStep_1=()=>{
        this.submitsmshandler();

      }

      stepAgain=()=>{
        this.submitSmsAgain();
      }

      prevStep = () => {
        const { step } = this.state;
        this.setState({
          step: step - 1,
        });
      };

      handleChange = (input) => (e) => {
        //console.log([input,e.target.value]); 
        let ph="";
        if(input==="phonenumber"){
          ph = e.target.value.replace(/\D/g, "");
          this.setState({phonenumber:ph});
          //console.log(ph);
        }
        if(input==="recaptchaCode"){
          //console.log(e)
          this.setState({recaptchaCode:e});
        }
        else{
          this.setState({ [input]: e.target.value });
        }
        
        //console.log(input)
        if(input==="password"){
          this.passwordInputValidate(e.target.value);
        }
        if(input==="username"){
          this.usernameInputValidate(e.target.value);
        }
        if(input==="phonenumber"){
          this.phoneInputValidate(ph);
        }
        if(input==="personalnumber"){
          var temp = e.target.value;
          this.personalInputValidate(temp);
        }
      };

      continue = (e) => {
        e.preventDefault();
        this.props.nextStep();
      };

      handleTermsModal = () => {
        this.setState({ TermsModal: !this.state.TermsModal });
      };

      handleCheckChange=(input)=>{
        this.setState({[input]:!this.state[input]}); 
        //this.personalInputValidate(this.state.personalnumber);
      }

      handleNext=()=>{
        if(this.state.pin==="my2020"||this.state.pin==="My2020"){
          this.setState({toSignUp:!this.state.toSignUp})
        }
      }

      render(){
        const { step } = this.state;
        const { firstname, lastname, address, username, email, phonenumber, personalnumber, password,repassword,code,token,firstnameerror,lastnameerror,addresserror,emailerror,usernameerror,phonenumbererror,personalnumbererror,passworderror,repassworderror,codeerror,loading,disabled,siteKey,recaptchaCode,recaptchaCodeerror,isCitizen,agreeToTerms,TermsModal } = this.state;
        const values = { firstname, lastname, address, username, email, phonenumber, personalnumber, password ,repassword,code,token,firstnameerror,lastnameerror,addresserror,emailerror,usernameerror,phonenumbererror,personalnumbererror,passworderror,repassworderror,codeerror,loading,disabled,siteKey,recaptchaCode,recaptchaCodeerror,isCitizen,agreeToTerms,TermsModal};
//console.log(step);
        //console.log(this.state.toSignUp);
        switch(step){
            case 1: 
            return(
              // !this.state.toSignUp?
              // <TemporaryPin
              //   handleChange={this.handleChange}
              //   values={values}
              //   handleNext={this.handleNext}
              // ></TemporaryPin>
              // :
              <SignUp
                 //aaa="dwadaw"
                   nextStep={this.nextStep}
                   handleChange={this.handleChange}
                   handleCheckChange={this.handleCheckChange}
                   formValidate={this.formValidate}
                   submithandler={this.submithandler}
                   passwordInputValidate={this.passwordInputValidate}
                   values={values}
                   handleTermsModal={this.handleTermsModal}>
                </SignUp>
            
            )
            case 2:
                return(
                    <Mobileverif
                    //bll={values.firstname}
                       nextStep_1={this.nextStep_1}
                       //prevStep={this.prevStep}
                       stepAgain={this.stepAgain}
                       handleChange={this.handleChange}
                       formValidate={this.formValidate}
                       values={values}
                       submitsmshandler={this.submitsmshandler}
                      >
                    </Mobileverif>
                )
                default:
                  
                    //this.submithandler();
                    return(
                    <Redirect to={"/dashboard"} ></Redirect>
                    )
                    // return(<UserPanel
                    // values={values}
                    // handleChange={this.handleChange}>

                    // </UserPanel>)
        }
      }
}

export default UserForm;