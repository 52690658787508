import React, { Component } from 'react';

class TeChat extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <iframe
              id="mobilechat"
              width="100%"
              height="480"
              title="TE Chat"
              src="https://chat.te.ge/?76118c78-b05a-11ee-b87b-0050568e27d7"
              // src="https://chat.te.ge/client.html?ref=https%3A//te.ge/ka/27/static/distanciuri-momsaxureba.html&hash=4fcb029a4265f214818a29be75dd256e703bb187&username=%E1%83%A1%E1%83%A2%E1%83%A3%E1%83%9B%E1%83%90%E1%83%A0%E1%83%98"
            ></iframe>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TeChat;
