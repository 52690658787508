import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import Loader from '../../loader';
import moment from 'moment';
import TableSearch from '../TableSearch';

class Emails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      filteredemails: [],
      loader: false,
    };
  }

  componentDidMount() {
    this.handleLoad(this.props.values);
  }

  handleLoad(custN) {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    if (typeof custN !== 'undefined') {
      this.setState({ loader: true });
      Axios.post(
        `${name}/api/Customer/GetEmailLog`,
        {
          customerNumber: custN,
        },
        {
          headers: {
            "Token": token,
          },
        }
      )
        .then((res) => {
          //console.log(res.data);
          if (res.data.success) {
            this.setState({ emails: res.data.emailLogs, filteredemails: res.data.emailLogs });
            this.setState({ loader: false });
          } else {
            this.setState({ loader: false });
            //alert("Email-ების წაკითხვა ვერ მოხერხდა");
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.href = '/login';
          }
          //alert("შეცდომა");
        });
    }
  }

  handleSearchedHistory = (data) => {
    this.setState({ filteredemails: data });
  };

  render() {
    const emails = this.state.filteredemails;
    //console.log(emails);
    const columns = [
      {
        name: 'თარიღი',
        selector: 'sendDateString',
        wrap: true,
        sortable: true,
        //center:true,
        cell: (row) => moment(row.sendDateString).format('DD.MM.YYYY'),
      },
      {
        name: 'აბონენტის ნომერი',
        selector: 'customerNumber',
        wrap: true,
        sortable: true,
        //center:true
      },
      {
        name: 'Email',
        selector: 'email',
        wrap: true,
        sortable: true,
        //center:true
      },
      // {
      //     name:'გაგზავნილი ტექსტი',
      //     selector:'sendText',
      //     wrap:true,
      //     sortable:true,
      //     center:true
      // }
    ];

    // const actions = [
    //     <i className="fas fa-file-pdf" title="Convert To Pdf" style={{color:"#262262",cursor:"pointer"}}></i>
    // ]

    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-lg-12" style={{ fontFamily: 'BPGCond' }}>
            <div className="card-group">
              {this.state.loader ? <Loader></Loader> : null}
              <DataTable
                title={
                  <div style={{ float: 'left' }}>
                    <TableSearch
                      data={this.state.emails}
                      handleSearchedHistory={this.handleSearchedHistory}
                    />
                  </div>
                }
                columns={columns}
                data={emails}
                pagination
                responsive
                striped
                noDataComponent="მონაცემები ცარიელია"
                style={{ fontFamily: 'BPGCond' }}
              ></DataTable>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Emails;
