import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import mobileverif from './components/mobileverif';
import UserForm from './components/UserForm';
import UserLogin from './components/userlogin';
import UserPanel from './components/userpanel';
import PasswordReset from './components/passwordreset';
import PasswordResetForm from './components/passresetform';
import PassResetStep2 from './components/passresetstep2';
import NotFound from './components/pages/NotFound';
// import TemporaryPin from './components/pages/TemporaryPin';
import DocumentPrint from './components/pages/DocumentPrint';
import GoogleAuth from './components/googlelogin';
import TermsAndConditions from './components/TermsAndConditions';
import ScrollToTop from './components/ScrollToTop';
import './css/snow.css';
//import { delete_cookie } from 'sfcookies';

function App() {
  // const token = read_cookie("Bearer_Token");
  // const tempToken="E3A3B0DAE539AA1197D78876F4CD7FB45C40459DB73E6FC96AAD43FF29826AAFCE612FF9D5D08B46D7BE2481E678F77E79B7A2488C104D80A10D5DDAD560F86B";
  // var temp=token?token:tempToken;
  // var path = `/ShowDocument/:id/${temp}`;
  //const name = window.$name;
  //console.log(path);
  //delete_cookie("Bearer_Token");
  return (
    <Router>
      <ScrollToTop />
      {/* <div class="snowflakes" aria-hidden="true"> 
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄ს
  </div>
</div>*/}
      {/* <div className="auth-wrapper">
        <div className="auth-inner"> */}
      <Switch>
        <Route exact path="/" component={UserLogin} />
        {/* <Route path="/Pin" component={TemporaryPin}/>  */}
        <Route path="/googleAuth" component={GoogleAuth}></Route>
        <Route path="/login" component={UserLogin} />
        <Route path="/sign-up" component={UserForm} />
        <Route path="/verif" component={mobileverif} />
        <Route path="/passwordresetform" component={PasswordResetForm} />
        <Route path="/passwordreset" component={PasswordReset} />
        <Route path="/PassResetStep2" component={PassResetStep2} />
        <Route path="/dashboard" component={UserPanel}></Route>
        <Route path="/ShowDocument/:id" component={(props) => <DocumentPrint {...props} />}></Route>
        <Route path="/TermsAndConditions" component={TermsAndConditions}></Route>
        <Route component={NotFound}></Route>
      </Switch>
      {/* </div>
      </div> */}
    </Router>
  );
}

export default App;
