import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
import ToLetters from '../../ToLetters';
import MaskedInput1 from 'react-text-mask';
import TextMask from 'react-text-mask';
import emailMask from 'text-mask-addons/src/emailMask';
import ReCAPTCHA from 'react-google-recaptcha';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import axios from 'axios';
import { read_cookie, delete_cookie } from 'sfcookies';
import InputInfo from '../InputInfo';
import Select from 'react-select';

class ConnectDistributionNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agree: false,
      checkyes_1: true,
      checkyes_2: true,
      checkyes_3: true,
      checkyes_4: false,
      checkyes_5: true,
      checkyes_6: true,
      checkyes_7: true,
      check_sixMonth: false,
      check_multiHouse: false,
      houseQuantity: '',
      fullname: '',
      personal: '',
      phone_1: '',
      phone_2: '',
      email_1: '',
      email_2: '',
      custN: '',
      address: '',
      comment: '',
      counterNumber: '',
      addressto_building: '',
      addressto_entrance: '',
      addressto_floor: '',
      addressto_apartament: '',
      district_Id: '',
      street_Id: '',
      street_Name: '',
      cadastral: '',
      bank_name: '',
      bank_code: '',
      account_number: '',
      addressto: '',
      checked: true,
      customer_firstname: '',
      customer_lastname: '',
      customer_personal: '',
      customer_phone_1: '',
      customer_phone_2: '',
      customer_email_1: '',
      customer_email_2: '',
      reference_allStreets: {},
      file_1: null,
      file_3: null,
      file_4: null,
      file_5: null,
      file_6: null,
      file_7: null,
      recaptchaCode: '',
      recaptchaCodeerror: '',
      reference_FieldsDescriptions: [],
      reference_districts: [],
      reference_streets: [],
      reference_activestreest: [],
      errors: [],
    };
    this.handleLetterTermsModal = this.props.handleLetterTermsModal;
    this.letterFormValidator = this.props.letterFormValidator;
    this.renderCustomersOptions = this.props.renderCustomersOptions;
    this.SendLetter = this.props.SendLetter;
    this.handleSwalPopup = this.props.handleSwalPopup;
    this.checkGeorgianLetters = this.props.checkGeorgianLetters;
  }

  UNSAFE_componentWillMount() {
    const token = read_cookie('Bearer_Token');
    const url = window.$name;

    axios
      .get(`${url}/api/Letters/GetLetterFieldDescriptions/${this.props.letterTypeId}`, {
        headers: {
          Token: token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ reference_FieldsDescriptions: res.data.descriptions });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'udnefined') {
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.replace('/login');
          }
        }
      });

    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      let phone = data.phones.filter((x) => x.typeKey === 1);
      let email = data.emails[0];
      this.setState({
        fullname: data.name + ' ' + data.lastName,
        personal: data.personalId,
        phone_1: phone ? phone[0].info : '',
        email_1: email ? email.info : '',
        address: data.registrationAddress,
      });
    }
  }

  componentDidMount() {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    axios
      .get(`${name}/api/Letters/GetReferences?letterTypeId=50`, { headers: { Token: token } })
      .then((res) => {
        if (res.data.success) {
          let data = res.data.references;
          let districts = [];
          let activedistricts = [];
          let streets = [];
          let devices = [];
          let active_streets = [];
          let RefCapacitySteps = [];
          let tools = [];
          let priceNames = [];
          let allStreets = [];
          let allActiveStreets = [];
          //ერთეულოვანისთვის "RefCapacitySteps":  1-თვითმმართველი ,2-არათვითმმართველი 3და 4 სიმძლავრის გაზრდაა
          //console.log(data);
          data.forEach((element) => {
            switch (element.key) {
              case 'RefDevices':
                devices.push(element);
                let obj1 = { value: element.id, label: element.value };
                tools.push(obj1);
                break;
              case 'RefDistricts':
                let obj = { value: element.id, label: element.value };
                activedistricts.push(obj);
                districts.push(element);
                break;
              case 'RefStreets':
                streets.push(element);
                allStreets.push(element);
                break;
              case 'RefCapacitySteps':
                RefCapacitySteps.push(element);
                //console.log(element)
                break;
              case 'RefPriceNames':
                priceNames.push(element);
                //console.log(element)
                break;
              default:
                break;
            }
          });
          //console.log(devices);
          let val = districts[0].id;
          streets.forEach((element) => {
            if (element.parentId === val) {
              active_streets.push(element);
            }
          });

          //console.log(active_streets);

          allStreets.forEach((element) => {
            if (element.id !== 12797 && element.id !== 14610) {
              let distName = districts.find((c) => c.id == element.parentId)?.value
                ? districts.find((c) => c.id == element.parentId)?.value
                : ' ';
              element.labelText = element.value + ' (' + distName + ')';
              element.districtName = distName;
              let lObj = {
                value: element.id,
                label: element.labelText,
                districtId: element.parentId,
                districtName: distName,
              };
              allActiveStreets.push(lObj);
            }
          });
          //let tmp = allStreets.slice(50,100);
          //console.log(tmp)
          //active_streets = allStreets.slice(100,150);

          //console.log(active_streets);
          this.setState({
            reference_devices: devices,
            reference_streets: streets,
            reference_districts: districts,
            reference_activestreest: active_streets,
            reference_activedistricts: activedistricts,
            reference_RefPriceNames: priceNames,
            reference_RefCapacitySteps: RefCapacitySteps,
            reference_tools: tools,
            reference_allStreets: allActiveStreets,
          });
          //console.log(this.state.reference_allStreets);

          //this.setActiveSteps(1, RefCapacitySteps);
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
        //alert("შეცდომა ?");
      });
  }

  selectRef = null;

  clearValue = () => {
    this.selectRef.select.clearValue();
  };

  selectRef1 = null;

  clearValue1 = () => {
    this.selectRef1.select.clearValue();
  };

  handleDistrictChange = (selectedOption) => {
    //console.log(selectedOption);
    // if (selectedOption !== null) {
    //   let activestreets = [];
    //   this.state.reference_streets.forEach((element) => {
    //     if (element.parentId == selectedOption.value) {
    //       //console.log([element,selectedOption.value])
    //       let obj = { value: element.id, label: element.value };
    //       activestreets.push(obj);
    //     }
    //   });
    //   this.setState({
    //     district_Id: selectedOption.value,
    //     reference_activestreets_1: activestreets,
    //   });
    //   //this.clearValue();
    // } else {
    //   this.setState({ district_Id: '', street_Id: '', reference_activestreets_1: [] });
    // }
    // this.clearValue1();
  };

  handleStreetChange = (selectedOption) => {
    //console.log(selectedOption);
    if (selectedOption !== null)
      this.setState({
        street_Id: selectedOption !== null ? selectedOption.value : '',
        street_Name: selectedOption !== null ? selectedOption.label : '',
        district_Id: selectedOption != null ? selectedOption.districtId : '',
      });
    else {
      this.setState({ street_Id: '', street_Name: '', district_Id: '' });
    }
  };

  handleAddressChange = (value) => {
    //console.log(value);
    let streets = [];
    this.state.reference_streets.forEach((element) => {
      if (element.parentId == value) streets.push(element);
      //console.log([value,element.parentId]);
    });
    this.setState({
      reference_activestreest: streets,
      district_Id: value,
      street_Id: streets[0].id,
    });
  };

  handleCheck(name) {
    this.setState({ [name]: !this.state[name] });
    if (name === 'check_multiHouse') {
      if (this.state[name]) {
        //console.log('fasss;');
        this.setState({ houseQuantity: '' });
      }
    }
  }

  handleChange = (input) => (e) => {
    let ph = '';
    if (input.includes('phone')) {
      ph = e.target.value.replace(/\D/g, '');
      if (input === 'phone_1') this.setState({ phone_1: ph });
      else if (input === 'phone_2') this.setState({ phone_2: ph });
      else if (input === 'customer_phone_1') this.setState({ customer_phone_1: ph });
      else this.setState({ customer_phone_2: ph });
    } else if (input === 'recaptchaCode') {
      //console.log(e)
      this.setState({ recaptchaCode: e });
    } else if (input === 'cadastral') {
      let val = e.target.value;
      const regex = /(\b\d{2,3}\b)|[0-9]{2,3}/g;
      let validationResult = true;
      if (val) {
        let t1 = val.replaceAll(',', '.');
        let t2 = t1.replaceAll('/', '.');

        let res = t2.match(regex);

        let splited = t2.split('.');

        if (splited.length < 3) {
          this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი 1' });
          validationResult = false;
        }

        if (
          res &&
          splited &&
          splited[splited.length - 1].length < 5 &&
          splited[splited.length - 1].length < 5
        ) {
          console.log([res, splited]);
          if (res.length != splited.length) {
            this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი 2' });
            validationResult = false;
          }
        }

        if (splited.length > 3) {
          const response = this.checkGeorgianLetters(t2);
          if (splited[splited.length - 1].length > 6) {
            this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი 3' });
            validationResult = false;
          }
          if (!response) {
            this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი 4' });
            validationResult = false;
          }
        }

        if (validationResult) {
          this.setState({ [input]: t2, cadastralerror: '' });
        }
      } else {
        this.setState({ [input]: e.target.value });
      }
    } else {
      if (!this.state.checked) {
        if (input === 'customer_firstname') {
          this.setState({ customer_lastname: e.target.value });
        }
      }
      this.setState({ [input]: e.target.value });
    }
  };

  handleSubmit = () => {
    let isValid = this.formValidate();

    let data = [];

    if (isValid) {
      for (const [key, value] of Object.entries(this.state)) {
        let obj = {};
        if (!key.includes('error') && !key.includes('refe') && !key.includes('recap')) {
          if (value !== '' && value !== null) {
            obj.key = key;
            obj.value = value;
            data.push(obj);
          }
        }
      }
      var cstn = this.state.custN;
      if (cstn === '') cstn = '0';

      this.SendLetter(cstn, this.props.letterTypeId, this.state.recaptchaCode, data);
    } else {
      //var filesError = this.state.errors.filter(x=>x.key==="filesSize"); if(filesError.length) filesError=filesError[0].value;
      if (this.state.errorFileSize) {
        this.handleSwalPopup(
          'ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      } else {
        this.handleSwalPopup(
          'შესავსებია სავალდებულო ველები',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      }
    }
  };

  handleFileChange = (input) => (event) => {
    this.setState({ [input]: event.target.files[0] });
  };

  formValidate() {
    const values = this.state;

    let data = [];

    for (const [key, value] of Object.entries(values)) {
      if (!key.includes('recap') && !key.includes('refe') && !key.includes('error')) {
        let obj = {};
        obj.name = key;
        obj.value = value;
        if (
          key === 'fullname' ||
          key === 'personal' ||
          key === 'phone_1' ||
          key === 'address' ||
          key === 'cadastral' ||
          key === 'district_Id' ||
          key === 'street_Id' ||
          key === 'addressto_building' ||
          key === 'file_1' ||
          key === 'customer_firstname' ||
          key === 'customer_personal' ||
          key === 'customer_phone_1'
        )
          obj.Required = true;
        else if (key === 'customer_lastname') {
          if (this.state.checked == true) obj.Required = true;
          else obj.Required = false;
        } else if (key === 'houseQuantity') {
          if (this.state.check_multiHouse) obj.Required = true;
          else obj.Required = false;
        } else obj.Required = false;

        data.push(obj);
      }
    }
    // var cstn = this.state.custN;
    // if(cstn==="") cstn="000000-000";
    // console.log(cstn)
    // let result = this.letterFormValidator(this.props.letterTypeId,data);
    // this.setState({errors:result.errors});
    let result = this.letterFormValidator(this.props.letterTypeId, data);
    const found = result.errors.find((x) => x.key === 'filesSize');
    //console.log(result);
    if (typeof found !== 'undefined') {
      //console.log(1234);
      this.state.errorFileSize = true;
      this.setState({ errors: result.errors });
      //this.handleSwalPopup("ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს","warning",false,"","დახურვა",false,"#262262");
    } else {
      this.state.errorFileSize = false;
      this.setState({ errors: result.errors });
    }
    return result.isValid;
  }

  handleRepeat = () => {
    if (this.state.checked) {
      if (typeof this.props.params.user !== 'undefined') {
        const data = this.props.params.user;
        this.setState({
          customer_firstname: data.name,
          customer_personal: this.state.personal,
          customer_lastname: data.lastName,
          customer_phone_1: this.state.phone_1,
          customer_phone_2: this.state.phone_2,
          customer_email_1: this.state.email_1,
          customer_email_2: this.state.email_2,
        });
      }
    } else {
      this.setState({
        customer_phone_1: this.state.phone_1,
        customer_phone_2: this.state.phone_2,
        customer_email_1: this.state.email_1,
        customer_email_2: this.state.email_2,
      });
    }
  };

  handlePhysicalSwitch = () => {
    if (this.state.checked) {
      this.setState({
        checked: !this.state.checked,
        customer_firstname: '',
        customer_lastname: '',
        customer_personal: '',
      });
    } else {
      this.setState({ checked: !this.state.checked });
    }
  };

  checkVisibility() {
    if (this.state.check_multiHouse) return 'block';
    else return 'hidden';
  }

  errorExsist = (key) => {
    let bool = this.state.errors.some((obj) => obj.key == key);
    return bool;
  };

  renderErrorText = (key) => {
    let bool = this.state.errors.find((obj) => obj.key.includes(key) === true);
    return bool.value;
  };

  render() {
    const values = this.state;
    let phone, email;
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      phone = data.phones.filter((x) => x.typeKey === 1);
      email = data.emails[0];
    }
    let l = true;
    if (values.reference_FieldsDescriptions.length === 0) l = false;
    //console.log([this.state.check_multiHouse, this.state.houseQuantity]);
    //console.log([values.district_Id, values.street_Id]);
    //console.log(values.checkyes_5);
    return (
      <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div style={{ float: 'left' }}>
                <ToLetters></ToLetters>
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>
                  <strong>{this.props.letterTitle}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>განმცხადებელი</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სახელი და გვარი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      readOnly={this.props.params.user.fullName ? true : false}
                      placeholder="სახელი გვარი/ორგანიზაციის დასახელება"
                      onChange={this.handleChange('fullname')}
                      value={values.fullname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'fullname'}
                      />
                    ) : null}
                    {this.errorExsist('fullname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('fullname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-4 col-form-label">
                    <strong>პირადი #/საიდენტიფიკაციო #:</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="personal"
                      placeholder="პირადი ნომერი"
                      readOnly={this.props.params.user.personalId ? true : false}
                      onChange={this.handleChange('personal')}
                      value={values.personal}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'personal'}
                      />
                    ) : null}
                    {this.errorExsist('personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მისამართი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      placeholder="მისამართი"
                      readOnly={this.props.params.user.registrationAddress ? true : false}
                      onChange={this.handleChange('address')}
                      value={values.address}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'address'}
                      />
                    ) : null}
                    {this.errorExsist('address') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('address')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      readOnly={
                        typeof phone[0].info !== 'undefined' && phone[0].info ? true : false
                      }
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_1"
                      onChange={this.handleChange('phone_1')}
                      value={values.phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_2"
                      onChange={this.handleChange('phone_2')}
                      value={values.phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      readOnly={typeof email !== 'undefined' ? true : false}
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_1"
                      onChange={this.handleChange('email_1')}
                      value={values.email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_2"
                      onChange={this.handleChange('email_2')}
                      value={values.email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_2'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>ინფორმაცია (აბონენტის), გაზის საფასურის გადამხდელი პირის შესახებ:</strong>
              <div className="card-header-actions">
                <button className="btn btn-primary btn-sm" onClick={this.handleRepeat}>
                  განმცხადებლის მონაცემების გადმოტანა
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <BootstrapSwitchButton
                  width={200}
                  checked={values.checked}
                  onlabel="ფიზიკური"
                  offlabel="იურიდიული"
                  onChange={this.handlePhysicalSwitch}
                ></BootstrapSwitchButton>
                <br />
                <br />
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>აბონენტის ნომერი</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput
                      className="form-control"
                      mask="111111-111"
                      list="customers"
                      placeholder="XXXXXX-XXX"
                      name="custN"
                      onChange={this.handleChange('custN')}
                      value={values.custN}
                      autoComplete="off"
                    ></MaskedInput>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'custN'}
                      />
                    ) : null}
                    <datalist id="customers">{this.renderCustomersOptions()}</datalist>
                    {this.errorExsist('custN') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('custN')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'სახელი' : 'დასახელება'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_firstname')}
                      placeholder={values.checked ? 'სახელი' : 'დასახელება'}
                      value={values.customer_firstname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_firstname'}
                      />
                    ) : null}
                    {this.errorExsist('customer_firstname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_firstname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {values.checked ? (
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გვარი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('customer_lastname')}
                        value={values.customer_lastname}
                        placeholder="გვარი"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_lastname'}
                        />
                      ) : null}
                      {this.errorExsist('customer_lastname') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_lastname')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_personal')}
                      value={values.customer_personal}
                      placeholder={values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_personal'}
                      />
                    ) : null}
                    {this.errorExsist('customer_personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ძირითადი"
                      name="customer_phone_1"
                      onChange={this.handleChange('customer_phone_1')}
                      value={values.customer_phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ალტერნატიული"
                      name="customer_phone_2"
                      onChange={this.handleChange('customer_phone_2')}
                      value={values.customer_phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ძირითადი"
                      name="customer_email_1"
                      onChange={this.handleChange('customer_email_1')}
                      value={values.customer_email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ალტერნატიული"
                      name="customer_email_2"
                      onChange={this.handleChange('customer_email_2')}
                      value={values.customer_email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_2'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>სხვა პირობები</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>უძრავი ქონების საკადასტრო კოდი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="cadastral"
                      placeholder="საკადასტრო კოდი"
                      onChange={this.handleChange('cadastral')}
                      value={values.cadastral}
                    ></input>
                    {values.cadastralerror ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {values.cadastralerror}
                      </label>
                    ) : null}
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'cadastral'}
                      />
                    ) : null}
                    {this.errorExsist('cadastral') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('cadastral')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>რაიონი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <Select
                      isClearable
                      isSearchable
                      options={this.state.reference_activedistricts}
                      onChange={this.handleDistrictChange}
                      placeholder="აირჩიეთ"
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'district_Id'}
                      />
                    ) : null}
                    {this.errorExsist('district_Id') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('district_Id')}
                      </label>
                    ) : null}
                  </div>
                </div> */}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ქუჩა</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <Select
                      ref={(ref) => {
                        this.selectRef1 = ref;
                      }}
                      isClearable
                      isSearchable
                      options={this.state.reference_allStreets}
                      onChange={this.handleStreetChange}
                      placeholder="აირჩიეთ ან აკრიფეთ"
                      noOptionsMessage={() => 'ცარიელია'}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'street_Id'}
                      />
                    ) : null}
                    {this.errorExsist('street_Id') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('street_Id')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>შენობის ნომერი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      maxLength="50"
                      className="form-control"
                      onChange={this.handleChange('addressto_building')}
                      placeholder="შენობის ნომერი"
                      value={values.addressto_building}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto_building'}
                      />
                    ) : null}
                    {this.errorExsist('addressto_building') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('addressto_building')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სადარბაზო</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      maxLength="50"
                      className="form-control"
                      onChange={this.handleChange('addressto_entrance')}
                      placeholder="სადარბაზო"
                      value={values.addressto_entrance}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto_entrance'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სართული</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      maxLength="50"
                      className="form-control"
                      onChange={this.handleChange('addressto_floor')}
                      placeholder="სართული"
                      value={values.addressto_floor}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto_floor'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ბინის ნომერი</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      maxLength="50"
                      className="form-control"
                      onChange={this.handleChange('addressto_apartament')}
                      placeholder="ბინის ნომერი"
                      value={values.addressto_apartament}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto_apartament'}
                      />
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მისაერთებელი ობიექტის მისამართი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="addressto"
                      placeholder="მისამართი"
                      onChange={this.handleChange('addressto')}
                      value={values.addressto}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto'}
                      />
                    ) : null}
                    {this.errorExsist('addressto') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('addressto')}
                      </label>
                    ) : null}
                  </div>
                </div> */}
                <div className="form-group row">
                  <label className="col-md-4 col-form-control">
                    <strong>მრიცხველის ნომერი</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      maxLength="50"
                      className="form-control"
                      onChange={this.handleChange('counterNumber')}
                      placeholder="მრიცხველის ნომერი"
                      value={values.counterNumber}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'counterNumber'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-control">
                    <strong>უძრავი ქონების ფლობის ფორმა</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_7');
                        }}
                        checked={this.state.checkyes_7}
                      ></input>
                      &nbsp;საკუთრება
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_7');
                        }}
                        checked={!this.state.checkyes_7}
                      ></input>
                      &nbsp;დროებით ფლობა
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_7'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ბუნებრივი გაზის მოხმარების მიზანი</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_6');
                        }}
                        checked={this.state.checkyes_6}
                      ></input>
                      &nbsp;საყოფაცხოვრებო
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_6');
                        }}
                        checked={!this.state.checkyes_6}
                      ></input>
                      &nbsp;არასაყოფაცხოვრებო
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_6'}
                      />
                    ) : null}
                  </div>
                </div>
                {!this.state.checkyes_6 && (
                  <>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        მოხმარებული გაზის საფასურის გადაუხდელობის შემთხვევაში, მიმწოდებელი
                        უფლებამოსილია დავალიანების დაფარვამდე ყოველ მომდევნო საანგარიშო თვეში
                        ერთხელ, მომხმარებელს დაარიცხოს პირგასამტეხლო, საანგარიშო თვეში მოხმარებული
                        გაზის საფასურის გადაუხდელი თანხის 2,1% (ორი მთელი ერთი მეათედი) ოდენობით.
                        პირგასამტეხლოს გადახდა არ ათავისუფლებს მომხმარებელს მოხმარებული ბუნებრივი
                        გაზის საფასურის გადახდის ვალდებულებისგან. მომხმარებლის მიერ პირგასამტეხლოს
                        გადახდის ვალდებულების წარმოშობის შემთხვევაში, პირველად დაიფარება
                        პირგასამტეხლო, ხოლო შემდგომ ძირითადი დავალიანება, ხოლო პირგასამტეხლოს სრული
                        ოდენობის დაფარვის შემდეგ გადასახდელი თანხა წარიმართება ძირითადი დავალიანების
                        დასაფარად, იმ პირობით, რომ პირველად დაიფარება უფრო ადრე წარმოშობილი
                        დავალიანება. მიმწოდებლის მხრიდან გაზის მიუწოდებლობისას, თუ ეს გამოწვეულია
                        მისი ბრალეული ქმედებით, მომხმარებელი უფლებამოსილია პირგასამტეხლოს სახით
                        მოითხოვოს ამ პერიოდში მიუწოდებელი გაზის საფასურის 0,07% (ნული მთელი შვიდი
                        მეასედი) გაზის მიუწოდებლობის პერიოდის ყოველ დღეზე, ხოლო ყოველდღიური
                        პირგასამტეხლო გამოითვლება წინა საანგარიშო თვის განმავლობაში
                        მომხმარებლისათვის მიწოდებული გაზის საშუალო დღიური მოცულობის შესაბამისად.
                        ამასთან, პირგასამტეხლოს გადახდა არ ათავისუფლებს მიმწოდებელს ხელშეკრულებით
                        ნაკისრი ვალდებულებების შესრულებისაგან.
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        <strong>შენიშვნის ველი</strong>
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          id="comment"
                          placeholder="შენიშვნის ველი"
                          onChange={this.handleChange('comment')}
                          value={values.comment}
                        ></input>
                      </div>
                    </div>
                  </>
                )}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>გესაჭიროებათ თუ არა საგადასახადო ანგარიშ-ფაქტურის გამოწერა</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_4');
                        }}
                        checked={this.state.checkyes_4}
                      ></input>
                      &nbsp;დიახ
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_4');
                        }}
                        checked={!this.state.checkyes_4}
                      ></input>
                      &nbsp;არა
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_4'}
                      />
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label"><strong>გსურთ თუ არა ისარგებლოთ  ქვითრის ელექტრონული სახით მიწოდების სერვისით</strong></label>
                                    <div className="col-md-8">
                                        <label><input type="radio" onChange={()=>{this.handleCheck("checkyes_1")}} checked={this.state.checkyes_1}></input>&nbsp;დიახ</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label><input type="radio" onChange={()=>{this.handleCheck("checkyes_1")}} checked={!this.state.checkyes_1}></input>&nbsp;არა</label>
                                        {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"checkyes_1"} />:null}
                                    </div>
                                </div> */}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>განაწილების ლიცენზიატის მიერ შეტყობინების გაგზავნის ფორმა:</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_5');
                        }}
                        checked={this.state.checkyes_5}
                      ></input>
                      &nbsp;ელექტრონული
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_5');
                        }}
                        checked={!this.state.checkyes_5}
                      ></input>
                      &nbsp;წერილობითი
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_5'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      ბუნებრივი გაზით მომარაგების დაწყების მოთხოვნის განაცხადის წარდგენის მომენტში
                      საცხოვრებელი ადგილის, საწარმოს ან სხვა ობიექტის ქსელზე მიერთება დასრულებულია:{' '}
                    </strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('check_sixMonth');
                        }}
                        checked={this.state.check_sixMonth}
                      ></input>
                      &nbsp;6 თვეზე ნაკლებ ვადაში;
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('check_sixMonth');
                        }}
                        checked={!this.state.check_sixMonth}
                      ></input>
                      &nbsp;6 თვეზე მეტ ვადაში;
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'check_sixMonth'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მრავალბინიანი(ორი ან ორზე მეტი)</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('check_multiHouse');
                        }}
                        checked={this.state.check_multiHouse}
                      ></input>
                      &nbsp;დიახ
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('check_multiHouse');
                        }}
                        checked={!this.state.check_multiHouse}
                      ></input>
                      &nbsp;არა
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'check_multiHouse'}
                      />
                    ) : null}
                  </div>
                </div>
                {this.state.check_multiHouse && (
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ბინების რაოდენობა</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('houseQuantity')}
                        value={values.houseQuantity}
                        placeholder="რაოდენობა"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'houseQuantity'}
                        />
                      ) : null}
                      {this.errorExsist('houseQuantity') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('houseQuantity')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>თანდართული დოკუმენტაცია (მხოლოდ pdf/jpg ფაილები, ჯამში მაქსიმუმ 14MB)</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      პირადობის მოწმობის ან პასპორტის ასლი
                      <span style={{ color: '#e55353' }}> *</span>
                    </strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_1')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_1'}
                      />
                    ) : null}
                    <br />
                    {this.errorExsist('file_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label">
                                        <strong>ამონაწერი საჯარო რესტრიდან<span style={{color:"#e55353"}}> *</span></strong>
                                    </label>
                                    <div className="col-md-4">
                                        <input type="file" accept=".pdf, .jpg, .jpeg" onChange={this.handleFileChange("file_2")}></input>
                                        <br/>
                                        {
                                            this.errorExsist("file_2") ?(
                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("file_2")}</label>
                                                ):null
                                        }
                                    </div>
                                </div> */}
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label">
                                        <strong>ამონაწერი სამეწარმეო რეესტრიდან</strong>
                                    </label>
                                    <div className="col-md-8">
                                        <input type="file" accept=".pdf, .jpg, .jpeg" onChange={this.handleFileChange("file_3")}></input>
                                        {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"file_3"} />:null}
                                        <br/>
                                        {
                                            this.errorExsist("file_3") ?(
                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("file_3")}</label>
                                                ):null
                                        }
                                    </div>
                                </div> */}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (ქონების დროებით სარგებლობის
                      შემთხვევაში)
                    </strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_4')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_4'}
                      />
                    ) : null}
                    <br />
                    {this.errorExsist('file_4') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_4')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      გადახდაზე პასუხისმგებელი ფიზიკური პირის წერილობითი თანხმობა (თუ განმცხადებელი
                      და გადახდაზე პასუხისმგებელი სხვადასხვა პირები არიან)
                    </strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_5')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_5'}
                      />
                    ) : null}
                    <br />
                    {this.errorExsist('file_5') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_5')}
                      </label>
                    ) : null}
                  </div>
                </div> */}
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label">
                                        <strong>საბანკო რეკვიზიტები</strong>
                                    </label>
                                    <div className="col-md-8">
                                        <input type="file" accept=".pdf, .jpg, .jpeg" onChange={this.handleFileChange("file_6")}></input>
                                        {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"file_6"} />:null}
                                        <br/>
                                        {
                                            this.errorExsist("file_6") ?(
                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("file_6")}</label>
                                                ):null
                                        }
                                    </div>
                                </div> */}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სხვა დოკუმენტები</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_7')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_7'}
                      />
                    ) : null}
                    <br />
                    {this.errorExsist('file_7') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_7')}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-md-12" style={{ textAlign: 'right' }}>
                  <label>
                    <input
                      type="checkbox"
                      style={{ verticalAlign: 'middle' }}
                      checked={values.agree}
                      onChange={() => this.handleCheck('agree')}
                    ></input>
                    &nbsp;ვეთანხმები
                    <span
                      style={{ color: '#262262', cursor: 'pointer' }}
                      onClick={this.handleLetterTermsModal}
                    >
                      &nbsp;<strong>წესებსა და პირობებს</strong>
                    </span>
                  </label>
                </div>
                <div className="col-md-12">
                  <ReCAPTCHA
                    style={{ float: 'right' }}
                    sitekey={this.props.params.siteKey}
                    onChange={this.handleChange('recaptchaCode')}
                    hl="ka"
                  ></ReCAPTCHA>
                </div>
                <br />
                <br />
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary"
                    style={{ float: 'right', backgroundColor: '#262262', color: 'white' }}
                    disabled={this.state.recaptchaCode && this.state.agree ? false : true}
                    onClick={this.handleSubmit}
                  >
                    გაგზავნა
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConnectDistributionNetwork;
