import React, { Component } from 'react';
import PasswordReset from './passwordreset';
import PassResetStep2 from './passresetstep2';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';

class PasswordResetForm extends Component {
  state = {
    step: 1,
    email: '',
    emailerror: '',
    code: '',
    codeerror: '',
    password: '',
    passworderror: '',
    repassword: '',
    repassworderror: '',
    info: '',
    infoerror: '',
    errors: {},
    bool: false,
    bool2: false,
    recaptchaCode: '',
    recaptchaCodeerror: '',
    siteKey: '6LeRKt0ZAAAAAK4UK8qKKhj8kUgy0Bc13izENa5g',
    recaptchaCode_1: '',
  };

  // emailCheck=()=>{

  //   this.setState({emailerror:""});

  //   const user={
  //     email:this.state.email
  //   }

  //   Axios.post("http://10.0.0.131/back/user/mailcheck",{
  //     "email":user.email
  //   })
  //   .then(res=>{
  //     //this.nextStep();
  //     console.log(res);
  //     const {step}  = this.state;
  //     this.setState({
  //       step: step + 1,
  //     });
  //   })
  //   .catch(error=>{
  //     this.setState({emailerror:"E-Mail ვერ მოიძებნა"});
  //     //console.log(error);
  //   })

  // }

  handlePassResetRequest = () => {
    this.setState({ infoerror: '' });
    this.setState({ bool: true });
    const name = window.$name;
    Axios.post(`${name}/api/Users/ResetPasswordRequest`, {
      info: this.state.info,
      recaptchaCode: this.state.recaptchaCode,
    })
      .then((res) => {
        //console.log(res);
        if (res.data.success) {
          const { step } = this.state;
          this.setState({
            step: step + 1,
          });
        } else {
          this.setState({ infoerror: 'მომხმარებელი ვერ მოიძებნა' });
        }
        //console.log(this.state.infoerror);
        this.setState({ bool: false });
      })
      .catch((error) => {
        //console.log(error);
        this.setState({ bool: false });
        this.setState({ infoerror: '???' });
      });
  };
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  passReset = () => {
    this.setState({ emailerror: '' });
    this.setState({ codeerror: '' });
    this.setState({ passworderror: '' });
    this.setState({ repassworderror: '' });
    this.setState({ bool2: true });
    const userdata = {
      info: this.state.info,
      code: this.state.code,
      password: this.state.password,
      repassword: this.state.repassword,
      recaptchaCode_1: this.state.recaptchaCode_1,
    };
    const name = window.$name;
    Axios.post(`${name}/api/Users/ResetPassword`, {
      info: userdata.info,
      code: userdata.code,
      //"password1":userdata.password,
      password: userdata.repassword,
      recaptchaCode: userdata.recaptchaCode_1,
    })
      .then((res) => {
        //console.log(res);
        if (res.data.success) {
          const { step } = this.state;
          this.setState({
            step: step + 1,
          });
        } else {
          if (res.data.code === -3) {
            this.setState({ codeerror: 'არასწორი კოდი' });
          }
          if (res.data.code === -50) {
            this.setState({ codeerror: 'კოდი ვალიდურია 3 წუთის განმავლობაში' });
          }
        }
        this.setState({ bool2: false });
      })
      .catch((error) => {
        this.setState({ bool2: true });
        //console.log(error);
      });
  };

  formValidate = (props, step) => {
    let formIsValid = true;
    let values = props.values;
    // let errors = {};

    // if(!values["email"]){
    //   formIsValid = false;
    //   this.setState({emailerror:"E-Mail ველი აუცილებელია"});
    //   //errors["email"]="შეიყვანეთ Email";
    // }
    if (step === 1 && !values['info']) {
      formIsValid = false;
      this.setState({ infoerror: 'ველის შევსება აუცილებელია' });
    }

    if (step === 2 && !values['code']) {
      formIsValid = false;
      this.setState({ codeerror: 'კოდის შეყვანა აუცილებელია' });
    }

    if (step === 2 && values['code'].length != 4) {
      formIsValid = false;
      this.setState({ codeerror: 'ერთჯერადი კოდი უნდა იყოს 4 ნიშნა' });
    }

    // if(step===2&&!values["password"]){
    //   formIsValid=false;
    //   this.setState({passworderror:"პაროლის შეყვანა აუცილებელია"});
    // }

    if (step === 2 && values['repassword'] !== values['password']) {
      formIsValid = false;
      this.setState({ repassworderror: 'პაროლები არ ემთხვევა ერთმანეთს' });
    }

    if (step === 2 && !values['repassword']) {
      formIsValid = false;
      this.setState({ repassworderror: 'განმეორებითი პაროლის შეყვანა აუცილებელია' });
    }
    //ჯერ გადავცემ სერვეერს მეილს, თუ მეილი არ არებობს დავაბრუნებინებ შეცდომას

    return formIsValid;
  };

  passwordInputValidate(password) {
    let formIsValid = true;
    this.state.passworderror = '';
    if (!password) {
      formIsValid = false;
      this.state.passworderror = 'აუცილებელია პაროლის შეყვანა';
      this.setState({ disabled: true });
    }

    if (password) {
      if (!password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/) || password.length < 6) {
        formIsValid = false;
        this.state.disabled = true;
        this.state.passworderror =
          'პაროლი უნდა შეიცავდეს ლათინური ანბანის პატარა და დიდ სიმბოლოებს, ციფრს და უნდა იყოს მინიმუმ 6 სიმბოლო';
      } else {
        this.state.disabled = false;
      }
    }
    return formIsValid;
  }

  submitPass = () => {
    //სერვისს გადავცემ პაროლებს და პასუხს რასაც დამიბრუნებს იმის მიხედვით ვიმოქმედებ
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  nextStep_1 = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };
  //go to previous
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  handleChange = (input) => (e) => {
    //console.log(input);
    if (input === 'password') {
      this.passwordInputValidate(e.target.value);
    }
    if (input === 'recaptchaCode') {
      this.setState({ [input]: e });
    } else if (input === 'recaptchaCode_1') {
      this.setState({ [input]: e });
    } else {
      this.setState({ [input]: e.target.value });
    }
  };

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { step } = this.state;
    const {
      email,
      emailerror,
      password,
      passworderror,
      code,
      codeerror,
      repassword,
      repassworderror,
      info,
      infoerror,
      bool,
      bool2,
      recaptchaCode,
      recaptchaCodeerror,
      siteKey,
      recaptchaCode_1,
    } = this.state;
    const values = {
      email,
      emailerror,
      code,
      codeerror,
      password,
      passworderror,
      repassword,
      repassworderror,
      info,
      infoerror,
      bool,
      bool2,
      recaptchaCode,
      recaptchaCodeerror,
      siteKey,
      recaptchaCode_1,
    };
    //console.log(values);

    switch (step) {
      case 1:
        return (
          <PasswordReset
            //  aaa="dwadaw"
            emailCheck={this.emailCheck}
            handlePassResetRequest={this.handlePassResetRequest}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            formValidate={this.formValidate}
            values={values}
          ></PasswordReset>
        );
      case 2:
        return (
          <PassResetStep2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            formValidate={this.formValidate}
            submitPass={this.submitPass}
            passReset={this.passReset}
            values={values}
          ></PassResetStep2>
          // <Mobileverif
          // // bll={values.firstname}
          //    nextStep={this.nextStep}
          //    nextStep_1={this.nextStep_1}
          //    prevStep={this.prevStep}
          //    formValidate={this.formValidate}
          //    handleChange={this.handleChange}
          //    values={values}>
          // </Mobileverif>
        );
      case 3:
        return <Redirect to={'/login'}></Redirect>;
      default:
        return <h1>succ</h1>;
    }
  }
}

export default PasswordResetForm;
