import React, { Component } from 'react';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import MaskedInput from 'react-maskedinput';
import { Link } from 'react-router-dom';
import Collapsible from 'react-collapsible';
//import swal from 'sweetalert';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui.min.js';
import Helmet from 'react-helmet';
import swal from 'sweetalert2';
// import {Button,Modal} from 'react-bootstrap';
// import Background from '../../mainlogo.png';

class Abonents extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      custN: '',
      toadd: '',
      abonentN: [],
      showPopup: false,
      history: [],
      filteredhistory: [],
      firstdate: '',
      lastdate: '',
      showIcon: false,
      ShowAddForm: false,
      index: '',
      isOpen: false,
      isValid: true,
      isValiderror: '',
      customerOrder: [],
      customerReordered: [],
      customers: {},
      letterModal: false,
      dismiss: false,
      toletter: false,
    };
    this.handleSwalPopup = props.handleSwalPopup;
    this.handleLetterSwal = props.handleLetterSwal;
  }

  handlePopup = (e) => {
    e.preventDefault();
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  handleCust = (e, CUST_NUMBER) => {
    e.preventDefault();
    if (CUST_NUMBER !== this.state.custN) {
      this.setState({ custN: CUST_NUMBER });
    }
  };

  handleAddForm = () => {
    this.setState({ ShowAddForm: !this.state.ShowAddForm });
  };

  componentDidMount() {
    this.handleLoad();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleLoad = (customers) => {
    //console.log(this.props);
    // const values = this.props.values.customers;
    // console.log(this.props.values.customers);
    if (customers) {
      let array = [];
      customers.map((customer, index) => {
        //console.log(customer);
        array.push(customer.custNumber);
      });
      this.state.customers = this.props.values.customers;
      //this.setState({customers:this.props.values.customers});
      this.state.customerOrder = array;
      //this.state.customerReordered=array;
    }
  };

  formControl() {
    let isValid = true;
    if (this.state.toadd === '' || this.state.toadd === null) {
      isValid = false;
      this.setState({ isValid: false, isValiderror: 'აბონენტის ნომრის შეყვანა აუცილებელია' });
    }
    return isValid;
  }

  handleAdd = () => {
    let isValid = this.formControl();

    if (isValid) {
      const token = read_cookie('Bearer_Token');

      const cst = this.state.toadd;
      const name = window.$name;
      Axios.post(
        `${name}/api/Customer/CustomerAdd`,
        {
          customerNumber: cst,
        },
        {
          headers: {
            Token: token,
          },
        }
      )
        .then((res) => {
          //console.log(res);
          if (res.data.success) {
            if (res.data.letterSuggestion) {
              this.handleLetterSwal(cst);
            } else {
              this.handleSwalPopup(
                'აბონენტი დამატებულია',
                'success',
                false,
                '',
                'კარგი',
                true,
                '#262262'
              );
            }
          } else {
            switch (res.data.code) {
              case -5:
                this.handleSwalPopup(
                  'აბონენტი ვერ მოიძებნა',
                  'warning',
                  false,
                  '',
                  'კარგი',
                  false,
                  '#757575'
                );
                break;
              case -6:
                this.handleSwalPopup(
                  `აღნიშნულ აბონენტზე თქვენი მობილურის ნომერი არ ფიქსირდება. \n
                  აბონენტის ნომერზე მობილური ნომრის დასამატებლად საჭიროა მოგვმართოთ განცხადებით 
                  <a href="/dashboard/Write/AbonentRegister">
                        აბონენტის რეგისტრაციის მონაცემების ცვლილება
                      </a>`,
                  'warning',
                  false,
                  '',
                  'კარგი',
                  false,
                  '#757575'
                );
                break;
              default:
                this.handleSwalPopup(
                  'აბონენტის ნომერი არასწორია',
                  'warning',
                  false,
                  '',
                  'კარგი',
                  false,
                  '#757575'
                );
            }
            // this.handleSwalPopup("აბონენტის დამატება ვერ მოხერხდა","warning",false,"","კარგი",false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.href = '/login';
          } else {
            this.handleSwalPopup(
              'აბონენტის დამატება ვერ მოხერხდა',
              'danger',
              false,
              '',
              'კარგი',
              false,
              '#757575'
            );
          }
        });
    } else {
      this.setState({ isValid: false, isValiderror: 'აბონენტის ნომრის შეყვანა აუცილებელია' });
    }
  };

  handleRemove = (cust_number) => {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    Axios.get(`${name}/api/Customer/CustomerDelete/${cust_number}`, {
      headers: {
        Token: token,
      },
    })
      .then((res) => {
        //console.log(res);
        if (res.data.success) {
          this.handleSwalPopup(
            'აბონენტი ამოშლილია',
            'success',
            false,
            '',
            'კარგი',
            true,
            '#262262'
          );
        } else {
          this.handleSwalPopup(
            'აბონენტის ამოშლა ვერ მოხერხდა',
            'warning',
            false,
            '',
            'კარგი',
            false,
            '#757575'
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.href = '/login';
        } else {
          this.handleSwalPopup(
            'აბონენტის ამოშლა ვერ მოხერხდა',
            'danger',
            false,
            '',
            'კარგი',
            false,
            '#757575'
          );
        }
      });
  };

  // handleSwalPopup=(title,icon,showCancelButton,cancelButtonText,confirmButtonText,needReload,confirmButtonColor)=>{
  //     swal.fire({title:title,icon:icon,showCancelButton:showCancelButton,cancelButtonText:cancelButtonText,confirmButtonText:confirmButtonText,confirmButtonColor:confirmButtonColor})
  //     .then(()=>{
  //         if(needReload){
  //             window.location.reload();
  //         }
  //     })
  // }

  // handleSuccessSwal=()=>{
  //     swal.fire({title:"აბონენტი დამატებულია",icon:'success',button:"კარგი"})
  //     .then(()=>{
  //         window.location.reload();
  //     });
  // }

  // handleRemoveSwal=()=>{
  //     swal.fire({title:"აბონენტი ამოშლილია",icon:'success',button:"კარგი"})
  //     .then(()=>{
  //         window.location.reload();
  //     });
  // }

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleMouseMove = () => {
    if (this.state.showIcon !== true) {
      this.setState({ showIcon: true });
    }
  };

  handleMouseLeave = () => {
    this.setState({ showIcon: false });
  };

  handleIndexChagne = (index) => {
    this.setState({ index: index });
  };

  renderTable = (history, ID) => {
    return (
      <tr key={ID}>
        <td>{moment(history.OPER_DATE).format('yyyy-MM-DD')}</td>
        <td>{history.OPER_ID}</td>
        <td>{history.CONSUMPTION}</td>
        <td>{history.AMOUNT}</td>
        <td>{history.BALANCE}</td>
      </tr>
    );
  };

  handleSortChange = (order) => {
    const token = read_cookie('Bearer_Token');
    //console.log(this.state.customerReordered);
    //this.setState({customerOrder:order});
    const name = window.$name;
    Axios.post(`${name}/api/Customer/SortCustomers`, order, {
      headers: {
        Token: token,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          alert('გადაადგილება ვერ მოხერხდა');
        } else {
        }
        //console.log(res);
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
      });
  };

  renderSwitch(param) {
    switch (param) {
      case 'აქტიური':
        return <p className="badge ">{param}</p>;
      case 'ახალი':
        return <p className="badge ">{param}</p>;
      case 'დაბლოკილი':
        return <p className="badge ">{param}</p>;
      default:
        return <p className="badge ">{param}</p>;
    }
  }

  handleLetterModal = () => this.setState({ letterModal: !this.state.letterModal });

  onChangefirst = (date) => this.setState({ firstdate: date });

  onChangelast = (date) => this.setState({ lastdate: date });

  handleFilter = () => {
    const array = this.state.history;
    let filteredarray = array;
    let newDate = moment(this.state.firstdate).format('yyyy-MM-DD');
    let lastDate = moment(this.state.lastdate).format('yyyy-MM-DD');

    if (newDate === 'Invalid date' && lastDate !== 'Invalid date') {
      filteredarray = array.filter(
        (date) => new Date(moment(date.OPER_DATE).format('yyyy-MM-DD')) <= new Date(lastDate)
      );
      this.setState({ filteredhistory: filteredarray });
    } else if (newDate !== 'Invalid date' && lastDate === 'Invalid date') {
      filteredarray = array.filter(
        (date) => new Date(moment(date.OPER_DATE).format('yyyy-MM-DD')) >= new Date(newDate)
      );
      this.setState({ filteredhistory: filteredarray });
    } else if (newDate !== 'Invalid date' && lastDate !== 'Invalid date') {
      filteredarray = array.filter(
        (date) =>
          new Date(moment(date.OPER_DATE).format('yyyy-MM-DD')) >= new Date(newDate) &&
          new Date(moment(date.OPER_DATE).format('yyyy-MM-DD')) <= new Date(lastDate)
      );
      this.setState({ filteredhistory: filteredarray });
    } else if (newDate === 'Invalid date' && lastDate === 'Invalid date') {
      let history = this.state.history;
      this.setState({ filteredhistory: history });
    }
  };

  exportToPdf = () => {
    const docDefinition = {
      content: [
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 5,
            widths: ['*', 'auto', 100, '*'],

            body: [
              ['პირველი', 'Second', 'Third', 'The last one'],
              ['Value 1', 'Value 2', 'Value 3', 'Value 4'],
              [{ text: 'Bold value', bold: true }, 'Val 2', 'Val 3', 'Val 4'],
            ],
          },
        },
      ],
    };

    pdfMake.createPdf(docDefinition).download();
    // const unit = "pt";
    // const size = "A4";
    // const orientation = "portrait";

    // const marginLeft = 40;
    // const doc = new jsPDF(orientation,unit,size);

    // doc.setFontSize(15);

    // const title = "ტრანზაქციების ისტორია";
    // const headers=[["თარიღი","დასახელება","მოხმარება","თანხა","ბალანსი"]];

    // const data = this.state.filteredhistory.map(dt => [dt.OPER_DATE, dt.OPER_ID, dt.CONSUMPTION, dt.AMOUNT, dt.BALANCE]);

    // let content = {
    //     startY : 50,
    //     head : headers,
    //     body : data
    // };

    // //doc.addFont('sylfaen', 'sylfaen', 'normal');
    // //doc.setFont('sylfaen');
    // doc.text(title,marginLeft,40);
    // doc.setLanguage("ka");
    // doc.autoTable(content);
    // doc.save("history.pdf");
  };

  triggerElement() {
    return (
      <div className="card-header" style={{ cursor: 'pointer', color: '#262262' }}>
        <span>
          <i className="fas fa-plus-circle"></i> აბონენტის დამატება
        </span>
      </div>
    );
  }

  render() {
    var _ = this;
    //console.log(this.state.dismiss);
    if (
      this.props.values.customers === null ||
      typeof this.props.values.customers === 'undefined' ||
      this.props.values.customers.length === 0
    ) {
      //console.log(1);
      return (
        <div className="row justify-content-center">
          <Helmet>
            <title>ჩემი აბონენტები</title>
          </Helmet>
          <div className="col-lg-6 col-md-8 col-sm-12" style={{ fontFamily: 'BPGCond' }}>
            <div className="card">
              <Collapsible trigger={_.triggerElement()}>
                <div className="card-body">
                  <label>აბონენტის ნომერი : </label>
                  <div className="form-group row">
                    <div className="col-md-7 col-lg-8">
                      <MaskedInput
                        className={`form-control ${_.state.isValid ? '' : 'is-invalid'}`}
                        mask="111111-111"
                        placeholderChar="X"
                        placeholder="XXXXXX-XXX"
                        name="toadd"
                        onChange={_.handleChange('toadd')}
                      ></MaskedInput>
                      &nbsp;&nbsp;&nbsp;
                      <div className="invalid-feedback">
                        {_.state.isValiderror ? _.state.isValiderror : null}
                      </div>
                    </div>
                    <div className="col-md-5 col-lg-4">
                      <button
                        className="btn btn-sm btn-primary"
                        type="submit"
                        onClick={_.handleAdd}
                        style={{ backgroundColor: '#262262' }}
                      >
                        დამატება
                      </button>
                    </div>
                  </div>
                </div>
              </Collapsible>
            </div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6" />
        </div>
      );
    }
    // if(this.props.values.customers.length===0){
    //     return (<label/>);
    // }

    const customers = this.props.values.customers;
    //console.log(customers);
    customers.sort(function (a, b) {
      return a.SortOrder - b.SortOrder;
    });
    //console.log(customers);
    _.handleLoad(customers);
    //this.state.customerReordered=this.state.customerOrder;
    //console.log(_.state.customerOrder);

    $(function () {
      $('#sortable').sortable({
        start: function (event, ui) {
          ui.placeholder.css('visibility', 'visible');
          ui.placeholder.css('border', '2px dashed gray');
          ui.placeholder.css('margin-bottom', '2rem');
        },
        stop: function (event, ui) {
          var order = [];
          //console.log(ui.item.closest('ul').children('li'))
          ui.item
            .closest('ul')
            .children('li')
            .each(function () {
              order.push($(this)[0].id);
            });

          for (var i = 0; i < order.length; i++) {
            var cs = _.props.values.customers.find((c) => c.custNumber === order[i]);
            cs.SortOrder = i;
            //console.log(cs);
          }

          _.setState({ customerReordered: order });
          _.handleSortChange(order);

          //console.log(_.state.customerOrder);
        },
      });
      $('#sortable').disableSelection();
    });
    let array = [];

    if (_.state.customerReordered.length === 0) {
      //console.log()
      array = _.state.customerOrder;
    } else {
      array = _.state.customerReordered;
    }
    let cust = [];
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < customers.length; j++) {
        if (array[i] === customers[j].custNumber) {
          cust.push(customers[j]);
          break;
        }
      }
    }
    //this.handleLetterSwal();
    // console.log(_.state.customerReordered);
    //console.log(customers)
    return (
      <React.Fragment>
        {/* <Modal show={this.state.letterModal} centered style={{fontFamily:"BPGCond"}}>
                    <Modal.Header closeButton style={{fontFamily:"BPGCaps"}}>
                        <strong>შეტყობინება</strong>
                    </Modal.Header>
                    <Modal.Body>
                        <label className="col-md-12 col-form-label" style={{fontWeight:"bold", fontSize:"20px",fontFamily:"BPGCaps",textAlign:"center"}}>
                            თქვენი პირადი მონაცემები არ ემტხევა აბონენტზე მითითებულ მონაცემებს!
                            <br/>
                            გსურთ მონაცემების ცვლილება?
                        </label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary">დიახ</Button>
                        <Button className="btn btn-danger" onClick={this.handleLetterModal}>არა</Button>
                    </Modal.Footer>
                </Modal> */}

        <div className="row justify-content-center">
          <Helmet>
            <title>ჩემი აბონენტები</title>
          </Helmet>
          <div className="col-lg-6 col-md-8 col-sm-12" style={{ fontFamily: 'BPGCond' }}>
            <div className="card">
              <Collapsible trigger={_.triggerElement()}>
                <div className="card-body">
                  <label>აბონენტის ნომერი : </label>
                  <div className="form-group row">
                    <div className="col-md-7 col-lg-8">
                      <MaskedInput
                        className={`form-control ${_.state.isValid ? '' : 'is-invalid'}`}
                        mask="111111-111"
                        placeholderChar="X"
                        placeholder="XXXXXX-XXX"
                        name="toadd"
                        onChange={_.handleChange('toadd')}
                      ></MaskedInput>
                      &nbsp;&nbsp;&nbsp;
                      <div className="invalid-feedback">
                        {_.state.isValiderror ? _.state.isValiderror : null}
                      </div>
                    </div>
                    <div className="col-md-5 col-lg-4">
                      <button
                        className="btn btn-sm btn-primary"
                        type="submit"
                        onClick={_.handleAdd}
                        style={{ backgroundColor: '#262262' }}
                      >
                        დამატება
                      </button>
                    </div>
                  </div>
                </div>
              </Collapsible>
            </div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6" />
          <ul className="col-lg-12 col-md-12" style={{ listStyleType: 'none' }} id="sortable">
            {customers.map((customer, index) => {
              return (
                <li key={customer.custNumber} index={customer.custNumber} id={customer.custNumber}>
                  <div
                    className="card"
                    style={{
                      fontFamily: 'BPGCond',
                      borderColor:
                        _.state.showIcon && index === _.state.index ? '#262262' : 'white',
                    }}
                  >
                    <div className="card-header">
                      <label>
                        აბონენტი #{' '}
                        <strong>{customer.custNumber + '-' + customer.controlNumber}</strong>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {customer.letterSuggestion ? (
                          <span
                            onClick={() =>
                              this.handleLetterSwal(customer.custNumber, customer.controlNumber)
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <i className="fas fa-envelope fa-lg" style={{ color: '#f9b115' }}></i>
                          </span>
                        ) : null}
                      </label>
                      <div className="card-header-actions">
                        {customer.suspendReason.exists ? (
                          <label
                            style={{ cursor: 'pointer', hover: 'underline', color: '#e55353' }}
                            onClick={() => {
                              swal.fire({
                                title: customer.suspendReason.name,
                                text: customer.suspendReason.message,
                                icon: 'warning',
                                confirmButtonText: 'დახურვა',
                                confirmButtonColor: '#262262',
                              });
                            }}
                          >
                            <i className="fas fa-exclamation-triangle"></i>&nbsp;
                            {customer.suspendReason.name}
                          </label>
                        ) : null}
                        &nbsp; &nbsp;
                        <i
                          className="fas fa-arrows-alt"
                          style={{ color: '#262262', cursor: 'grab' }}
                          title="გადაადგილება"
                        ></i>
                        &nbsp; &nbsp;
                        <i
                          className="fas fa-trash-alt"
                          title="წაშლა"
                          onClick={() => {
                            swal
                              .fire({
                                title: 'ნამდვილად გსურთ აბონენტის ამოშლა?',
                                icon: 'question',
                                confirmButtonText: 'დიახ',
                                confirmButtonColor: '#262262',
                                showCancelButton: true,
                                cancelButtonText: 'არა',
                              })
                              .then((willdelete) => {
                                if (willdelete.isConfirmed) {
                                  _.handleRemove(
                                    customer.custNumber + '-' + customer.controlNumber
                                  );
                                }
                              });
                          }}
                          style={{ cursor: 'pointer', color: '#262262' }}
                        ></i>
                      </div>
                    </div>
                    {/* ,backgroundImage: `url("${Background}")` */}
                    <Link
                      className="card-body"
                      id="customerCard"
                      to={{
                        pathname: `/dashboard/customer/${
                          customer.custNumber + '-' + customer.controlNumber
                        }`,
                        state: { letterSuggestion: customer.letterSuggestion },
                      }}
                      style={{ cursor: 'pointer', textDecoration: 'none', color: '#262262' }}
                      onMouseMove={(e) => {
                        _.handleMouseMove();
                        _.handleIndexChagne(index);
                        _.handleCust(e, customer.custNumber + '-' + customer.controlNumber);
                      }}
                      onMouseLeave={(e) => {
                        _.handleMouseLeave();
                        _.handleIndexChagne('');
                      }}
                    >
                      <form className="form-horizontal">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="col-md-12 col-sm-12 col-lg-12">
                              <div className="row">
                                <label
                                  className="col-md-5 col-lg-3 col-sm-6 col-form-label"
                                  style={{ fontFamily: 'BPGCond' }}
                                >
                                  <strong>დასახელება</strong>
                                </label>
                                <div className="col-md-7 col-lg-9 col-sm-6">
                                  <label className="col-form-label">{customer.customerName}</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12">
                              <div className="row">
                                <label
                                  className="col-md-5 col-lg-3 col-sm-6 col-form-label"
                                  style={{ fontFamily: 'BPGCond', whiteSpace: 'nowrap' }}
                                >
                                  <strong>მისამართი</strong>
                                </label>
                                <div className="col-md-7 col-lg-9 col-sm-6">
                                  <label className="col-form-label">{customer.address}</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12">
                              <div className="row">
                                <label
                                  className="col-md-5 col-lg-3 col-sm-6 col-form-label"
                                  style={{ fontFamily: 'BPGCond' }}
                                >
                                  <strong>საკადასტრო კოდი</strong>
                                </label>
                                <div className="col-md-7 col-lg-9 col-sm-6">
                                  <label className="col-form-label">
                                    {customer.realEstateCode}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12">
                              <div className="row">
                                <label
                                  className="col-md-5 col-lg-3 col-sm-6 col-form-label"
                                  style={{ fontFamily: 'BPGCond', whiteSpace: 'nowrap' }}
                                >
                                  <strong>კატეგორია</strong>
                                </label>
                                <div className="col-md-7 col-lg-9 col-sm-6">
                                  <label className="col-form-label">{customer.categoryName}</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12">
                              <div className="row">
                                <label
                                  className="col-md-5 col-lg-3 col-sm-6 col-form-label"
                                  style={{ fontFamily: 'BPGCond', whiteSpace: 'nowrap' }}
                                >
                                  <strong>ტარიფი</strong>
                                </label>
                                <div className="col-md-7 col-lg-9 col-sm-6">
                                  <label className="col-form-label">{customer.tariffValue}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="col-md-12" style={{ alignItems: 'left' }}>
                              <div className="form-group row ">
                                <label
                                  className="col-md-6 col-form-label col-sm-6 col-lg-4"
                                  style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
                                >
                                  <b>სტატუსი</b>
                                </label>
                                <div className="col-form-label col-md-6 col-sm-6 col-lg-8">
                                  {_.renderSwitch(customer.statusText)}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  className="col-md-6 col-sm-4 col-lg-4 col-form-label"
                                  style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
                                >
                                  <b>ბალანსი</b>
                                </label>
                                <div className="col-md-6 col-sm-8 col-lg-8 col-form-label">
                                  <div
                                    className="row"
                                    style={{ display: 'flex', textAlign: 'left' }}
                                  >
                                    <label
                                      className="col-md-13 col-sm-3 col-lg-3"
                                      style={{ whiteSpace: 'nowrap' }}
                                    >
                                      <strong>{customer.balance}₾</strong>
                                    </label>
                                    <div className="col-md-9 col-sm-9 col-lg-9">
                                      <Link
                                        id="except"
                                        className="btn btn-primary"
                                        to={`/dashboard/Pay/${
                                          customer.custNumber + '-' + customer.controlNumber
                                        }`}
                                        style={{ backgroundColor: '#262262', color: 'white' }}
                                      >
                                        <i className="fas fa-credit-card"></i>&nbsp;&nbsp;გადახდა
                                      </Link>
                                    </div>
                                    {/* &nbsp; */}
                                    {/* <Link
                                      id="except"
                                      className="btn btn-primary"
                                      to={`/dashboard/Pay/${
                                        customer.custNumber + '-' + customer.controlNumber
                                      }`}
                                      style={{ backgroundColor: '#262262', color: 'white' }}
                                    >
                                      <i className="fas fa-credit-card"></i>&nbsp;&nbsp;გადახდა
                                    </Link> */}
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  className="col-md-6 col-sm-4 col-lg-4 col-form-label"
                                  style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
                                >
                                  <b>ვადა</b>
                                </label>
                                <div className="col-md-6 col-sm-8 col-lg-8 col-form-label">
                                  <div
                                    className="row"
                                    style={{ display: 'flex', textAlign: 'left' }}
                                  >
                                    <label
                                      className="col-md-12 col-sm-12 col-lg-12"
                                      style={{ whiteSpace: 'nowrap' }}
                                    >
                                      <strong>{customer.dueDateString}</strong>
                                    </label>
                                    {/* &nbsp;
                                    <Link
                                      id="except"
                                      className="btn btn-primary"
                                      to={`/dashboard/Pay/${
                                        customer.custNumber + '-' + customer.controlNumber
                                      }`}
                                      style={{ backgroundColor: '#262262', color: 'white' }}
                                    >
                                      <i className="fas fa-credit-card"></i>&nbsp;&nbsp;გადახდა
                                    </Link> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="col-md-2 col-sm-12" style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', bottom: '0px', right: '10px' }}>
                              <span
                                style={{
                                  cursor: 'pointer',
                                  textDecoration:
                                    _.state.showIcon && index === _.state.index
                                      ? 'underline'
                                      : null,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <b>
                                  <span>
                                    დაწვრილებით&nbsp;
                                    <i
                                      className="fas fa-chevron-right"
                                      style={{ color: '#262262' }}
                                    ></i>
                                  </span>
                                </b>
                              </span>
                            </div>
                          </div>
                          {/* <div style={this.state.showIcon&&index===this.state.index?conditionalStyle:null}>      
                                                    {
                                                        this.state.showIcon && index===this.state.index?                                                       
                                                            <i className="fas fa-chevron-right col-sm-1" style={{fontSize:"2em",color:"#262262",top:"50%"}}></i>
                                                        :null
                                                    }                                        
                                                    </div>          */}
                        </div>
                      </form>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>{' '}
        </div>
      </React.Fragment>
    );
  }
}
//style={{position:"absolute",right:"0",top:"0",height:"100%",borderLeft:"0.5px solid #262262",backgroundColor:this.state.showIcon?"#ebedef":null}}
//,position:"absolute",top:"50%",left:"50%",float:"right"
//style={this.state.showIcon&&index===this.state.index?conditionalStyle:null}

export default Abonents;
