import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class ToLetters extends Component {
    state = {  }
    render() { 
        return (
            <React.Fragment>
                <Link to="/dashboard/WriteLetter"><span><i className="fas fa-arrow-circle-left" title="უკან" style={{cursor:"pointer",color:"#262262"}}></i></span></Link>
            </React.Fragment>
        );
    }
}
 
export default ToLetters;
