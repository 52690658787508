import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
import ToLetters from '../../ToLetters';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import MaskedInput1 from 'react-text-mask';
import TextMask from 'react-text-mask';
import emailMask from 'text-mask-addons/src/emailMask';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import InputInfo from '../InputInfo';
import { translate_number } from '../../reflactor';
import $ from 'jquery';

class PowerIncrease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      personal: '',
      address: '',
      phone_1: '',
      phone_2: '',
      email_1: '',
      email_2: '',
      bankdetails: '',
      custN: '',
      addressto: '',
      cadastral: '',
      permisiblepower: '',
      requestedpower: '',
      requestedpowertext: '',
      totalfeeN: '',
      totalfeeT: '',
      maximum: '',
      minimal: '',
      telasi: '',
      gwp: '',
      tools: [{ tool_Id: '-1' }],
      checked: true,
      checkyes_1: 'yes',
      checkyes_2: false,
      checkyes_3: false,
      checkyes_4: false,
      checkyes_5: false,
      checkyes_6: false,
      checkyes_13: true,
      checkyes_16: true,
      checkyes_17: true,
      checkyes_18: false,
      checkyes_19: false,
      checkyes_20: false,
      checkyes_24: false,
      checkyes_45: true,
      checkyes_46: true,
      checkyes_48: true,
      agree: false,
      file_1: '',
      file_8: '',
      file_9: '',
      file_10: '',
      amount: '',
      amountN: '',
      otherdocumentsfiles: [],
      reference_devices: [],
      reference_districts: [],
      reference_streets: [],
      reference_activestreest: [],
      reference_allStreets: {},
      addressto_building: '',
      addressto_entrance: '',
      addressto_floor: '',
      addressto_appartament: '',
      district_Id: '',
      street_Id: '',
      street_Name: '',
      recaptchaCode: '',
      recaptchaCodeerror: '',
      customer_firstname: '',
      customer_lastname: '',
      customer_personal: '',
      customer_phone_1: '',
      customer_phone_2: '',
      customer_email_1: '',
      customer_email_2: '',
      reference_FieldsDescriptions: [],
      errors: [],
    };
    this.handleLetterTermsModal = this.props.handleLetterTermsModal;
    this.renderCustomersOptions = this.props.renderCustomersOptions;
    this.letterFormValidator = this.props.letterFormValidator;
    this.SendLetter = this.props.SendLetter;
    this.handleSwalPopup = this.props.handleSwalPopup;
    this.checkGeorgianLetters = this.props.checkGeorgianLetters;
  }

  reverse = (s) => {
    return s.split('').reverse().join('');
  };

  UNSAFE_componentWillMount() {
    const token = read_cookie('Bearer_Token');
    const url = window.$name;

    Axios.get(`${url}/api/Letters/GetLetterFieldDescriptions/${this.props.letterTypeId}`, {
      headers: {
        Token: token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          this.setState({ reference_FieldsDescriptions: res.data.descriptions });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'udnefined') {
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.replace('/login');
          }
        }
      });

    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      //console.log(data);
      let phone = data.phones.filter((x) => x.typeKey === 1);
      let email = data.emails[0];
      //console.log(phone)
      this.setState({
        fullname: data.name + ' ' + data.lastName,
        personal: data.personalId,
        phone_1: phone ? phone[0].info : '',
        email_1: email ? email.info : '',
        address: data.registrationAddress,
      });
    }
  }

  componentDidMount() {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    Axios.get(`${name}/api/Letters/GetReferences?letterTypeId=50`, { headers: { Token: token } })
      .then((res) => {
        if (res.data.success) {
          let data = res.data.references;
          let districts = [];
          let activedistricts = [];
          let streets = [];
          let devices = [];
          let active_streets = [];
          let RefCapacitySteps = [];
          let tools = [];
          let priceNames = [];
          let allStreets = [];
          let allActiveStreets = [];
          //ერთეულოვანისთვის "RefCapacitySteps":  1-თვითმმართველი ,2-არათვითმმართველი 3და 4 სიმძლავრის გაზრდაა
          //console.log(data);
          data.forEach((element) => {
            switch (element.key) {
              case 'RefDevices':
                devices.push(element);
                let obj1 = { value: element.id, label: element.value };
                tools.push(obj1);
                break;
              case 'RefDistricts':
                let obj = { value: element.id, label: element.value };
                activedistricts.push(obj);
                districts.push(element);
                break;
              case 'RefStreets':
                streets.push(element);
                allStreets.push(element);
                break;
              case 'RefCapacitySteps':
                RefCapacitySteps.push(element);
                //console.log(element);
                break;
              case 'RefPriceNames':
                priceNames.push(element);
                //console.log(element)
                break;
              default:
                break;
            }
          });
          //console.log(devices);
          let val = districts[0].id;
          streets.forEach((element) => {
            if (element.parentId === val) {
              active_streets.push(element);
            }
          });
          //console.log(RefCapacitySteps);
          //console.log(allStreets);

          allStreets.forEach((element) => {
            if (element.id !== 12797 && element.id !== 14610) {
              let distName = districts.find((c) => c.id == element.parentId)?.value
                ? districts.find((c) => c.id == element.parentId)?.value
                : ' ';
              element.labelText = element.value + ' (' + distName + ')';
              element.districtName = distName;
              let lObj = {
                value: element.id,
                label: element.labelText,
                districtId: element.parentId,
                districtName: distName,
              };
              allActiveStreets.push(lObj);
            }
          });
          //let tmp = allStreets.slice(50,100);
          //console.log(tmp)
          //active_streets = allStreets.slice(100,150);

          //console.log(active_streets);
          this.setState({
            reference_devices: devices,
            reference_streets: streets,
            reference_districts: districts,
            reference_activestreest: active_streets,
            reference_activedistricts: activedistricts,
            reference_RefPriceNames: priceNames,
            reference_RefCapacitySteps: RefCapacitySteps,
            reference_tools: tools,
            reference_allStreets: allActiveStreets,
          });
          //console.log(this.state.reference_allStreets);

          this.setActiveSteps(3, RefCapacitySteps);
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
        //alert("შეცდომა ?");
      });
  }
  //აქტიური წნევის საფეხურის select-ის data
  setActiveSteps = (parent, RefCapacitySteps) => {
    //console.log([parent,RefCapacitySteps])
    // 1 რეგულირებული თბილისი 50.00
    // 2 რეგულირებული რეგიონები 25.00
    // 3 სიმძლავრის გაზრდა დანართი 3 0.00
    // 4 სიმძლავრის გაზრდა დანართი 4 0.00
    let activeSteps = [];
    RefCapacitySteps.forEach((element) => {
      if (element.parentId == parent) activeSteps.push({ value: element.id, label: element.value });
    });
    this.setState({ reference_activeSteps: activeSteps });
    //console.log(activeSteps);
  };

  handleAddressChange = (value) => {
    //console.log(value);
    let streets = [];
    this.state.reference_streets.forEach((element) => {
      if (element.parentId == value) streets.push(element);
      //console.log([value,element.parentId]);
    });
    this.setState({
      reference_activestreest: streets,
      district_Id: value,
      street_Id: streets[0].id,
    });
  };

  selectRef = null;

  clearValue = () => {
    this.selectRef.select.clearValue();
  };

  selectRef1 = null;

  clearValue1 = () => {
    this.selectRef1.select.clearValue();
  };
  //რაიონის select-ის ცვლილება
  handleDistrictChange = (selectedOption) => {
    //console.log(selectedOption);
    // if (selectedOption !== null) {
    //   let activestreets = [];
    //   this.state.reference_streets.forEach((element) => {
    //     if (element.parentId == selectedOption.value) {
    //       //console.log([element,selectedOption.value])
    //       let obj = { value: element.id, label: element.value };
    //       activestreets.push(obj);
    //     }
    //   });
    //   this.setState({
    //     district_Id: selectedOption.value,
    //     reference_activestreets_1: activestreets,
    //   });
    //   //this.clearValue();
    // } else {
    //   this.setState({ district_Id: '', street_Id: '', reference_activestreets_1: [] });
    // }
    // this.clearValue();
  };
  //ქუჩის select-ის ცვლილება
  handleStreetChange = (selectedOption) => {
    //console.log(selectedOption);
    if (selectedOption !== null)
      this.setState({
        street_Id: selectedOption !== null ? selectedOption.value : '',
        street_Name: selectedOption !== null ? selectedOption.label : '',
        district_Id: selectedOption != null ? selectedOption.districtId : '',
      });
    else {
      this.setState({ street_Id: '', street_Name: '', district_Id: '' });
    }
  };
  //დანადგარების select-ის ცვლილება
  handleToolChange = (e, index) => {
    //console.log([e,index]);
    const { value, label } = e;
    //console.log([value,label]);
    const list = [...this.state.tools];
    list[index]['tool_Id'] = value;
    this.setState({ tools: list });
    //console.log(list)
  };
  //requestedpower select-ის ცვლილება
  handlePowerChange = (selectedOption) => {
    // if(selectedOption!==null) this.setState({requestedpower:selectedOption.value});
    // else this.setState({requestedpower:""});
    //console.log()
    let totalfeeN = '';
    let requestedpower = '';
    var requestedpowertext = '';
    if (selectedOption !== null) {
      requestedpowertext = selectedOption.label;
      var tmp = selectedOption.label.length - 1;
      for (let i = selectedOption.label.length - 1; i > 0; i--) {
        if (selectedOption.label[i] === '/') {
          tmp = i;
          break;
        } else totalfeeN += selectedOption.label[i];
      }
      requestedpowertext = requestedpowertext.substr(0, tmp);
      totalfeeN = this.reverse(totalfeeN);
      requestedpower = selectedOption.value;
      this.setState({
        requestedpower: requestedpower,
        totalfeeN: totalfeeN,
        requestedpowertext: requestedpowertext,
      });
    } else {
      this.setState({
        requestedpower: '',
        totalfeeN: '',
        requestedpowertext: '',
        amount: '',
        amountN: '',
      });
    }
  };

  handleCheck(name) {
    if (name === 'checkyes_48') {
      if (this.state.checkyes_48) this.setActiveSteps(4, this.state.reference_RefCapacitySteps);
      else this.setActiveSteps(3, this.state.reference_RefCapacitySteps);

      this.clearValue1();
    }

    if (name === 'checkyes_17') {
      this.setState({ checkyes_17: !this.state.checkyes_17 }, function () {
        if (this.state.checkyes_17) this.setState({ checkyes_4: false });
      });
    } else {
      this.setState({ [name]: !this.state[name] });
    }
    if (name === 'checkyes_20') {
      var t = parseInt(this.state.totalfeeN);
      //console.log(t);
      if (!this.state.checkyes_20) {
        //console.log(1);
        t = t / 2;
      }
      var translation = translate_number(t, false);
      //console.log(t);
      if (this.state.totalfeeT !== '' && this.state.totalfeeN !== '')
        this.setState({ amountN: t, amount: translation });
    }
    if (name === 'checkyes_13') {
      this.setState({ checkyes_13: !this.state.checkyes_13 });

      if (this.state.checkyes_13 && !this.state.checkyes_48) {
        this.setActiveSteps(4, this.state.reference_RefCapacitySteps);
      } else if (this.state.checkyes_13 && this.state.checkyes_48) {
        this.setActiveSteps(3, this.state.reference_RefCapacitySteps);
      } else if (!this.state.checkyes_13 && this.state.checkyes_48) {
        this.setActiveSteps(3, this.state.reference_RefCapacitySteps);
      } else if (!this.state.checkyes_13 && !this.state.checkyes_48) {
        this.setActiveSteps(4, this.state.reference_RefCapacitySteps);
      }

      // if (this.state.checkyes_13) this.setActiveSteps(2, this.state.reference_RefCapacitySteps);
      // else this.setActiveSteps(1, this.state.reference_RefCapacitySteps);
      //this.clearValue();
    }
  }

  handleThree = (name) => {
    switch (name) {
      case 'yes':
        this.setState({ checkyes_1: 'yes' });
        break;
      case 'no':
        this.setState({ checkyes_1: 'no' });
        break;
      case 'already':
        this.setState({ checkyes_1: 'already' });
        break;
      default:
        break;
    }
  };

  handleAddClick = () => {
    this.setState({ tools: [...this.state.tools, { tool_Id: '-1' }] });
  };

  handleRemoveClick = (index) => {
    this.state.tools.splice(index, 1);
    this.setState({ tools: this.state.tools });
  };

  handleInputChange = (e, index) => {
    //console.log(e.target);
    const { name, value } = e.target;
    const list = [...this.state.tools];
    list[index][name] = value;
    this.setState({ tools: list });
  };

  handleChange = (input) => (e) => {
    let ph = '';
    if (input.includes('phone')) {
      ph = e.target.value.replace(/\D/g, '');
      if (input === 'phone_1') this.setState({ phone_1: ph });
      else if (input === 'phone_2') this.setState({ phone_2: ph });
      else if (input === 'customer_phone_1') this.setState({ customer_phone_1: ph });
      else this.setState({ customer_phone_2: ph });
    } else if (input === 'cadastral') {
      let val = e.target.value;
      const regex = /(\b\d{2,3}\b)|[0-9]{2,3}/g;
      let validationResult = true;
      if (val) {
        let t1 = val.replaceAll(',', '.');
        let t2 = t1.replaceAll('/', '.');

        let res = t2.match(regex);

        let splited = t2.split('.');

        if (splited.length < 3) {
          this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
          validationResult = false;
        }

        if (res && splited && splited[splited.length - 1].length < 5) {
          if (res.length != splited.length) {
            this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
            validationResult = false;
          }
        }

        if (splited.length > 3) {
          const response = this.checkGeorgianLetters(t2);
          if (splited[splited.length - 1].length > 6) {
            this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
            validationResult = false;
          }
          if (!response) {
            this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
            validationResult = false;
          }
        }

        if (validationResult) {
          this.setState({ [input]: t2, cadastralerror: '' });
        }
      } else {
        this.setState({ [input]: e.target.value });
      }
    } else if (input === 'recaptchaCode') {
      //console.log(e)
      this.setState({ recaptchaCode: e });
    } else {
      if (!this.state.checked) {
        if (input === 'customer_firstname') {
          this.setState({ customer_lastname: e.target.value });
        }
      }
      this.setState({ [input]: e.target.value });
    }
  };

  handleFileChange = (input) => (event) => {
    if (input === 'otherdocumentsfiles') {
      if (event.target.files.length > 3) {
        this.handleSwalPopup(
          'ფაილების მაქსიმალური რაოდენობა არის 3',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
        document.getElementById('otherdocumentsfiles').value = '';
        ////this.setState({[input]:[]});
      } else {
        this.setState({ [input]: event.target.files });
      }
    } else {
      this.setState({ [input]: event.target.files[0] });
    }
  };

  handleSubmit = () => {
    let isValid = this.formValidate();

    let data = [];

    if (isValid) {
      for (const [key, value] of Object.entries(this.state)) {
        let obj = {};
        if (!key.includes('error') && !key.includes('refe') && !key.includes('recap')) {
          if (value !== '' && value !== null) {
            obj.key = key;
            obj.value = value;
            data.push(obj);
          }
        }
      }
      this.SendLetter(this.state.custN, this.props.letterTypeId, this.state.recaptchaCode, data);
    } else {
      //var filesError = this.state.errors.filter(x=>x.key==="filesSize"); if(filesError.length) filesError=filesError[0].value;
      if (this.state.errorFileSize) {
        this.handleSwalPopup(
          'ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      } else {
        this.handleSwalPopup(
          'შესავსებია სავალდებულო ველები',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      }
    }
  };

  formValidate() {
    const values = this.state;

    let data = [];

    for (const [key, value] of Object.entries(values)) {
      if (!key.includes('recap') && !key.includes('refe') && !key.includes('error')) {
        let obj = {};
        obj.name = key;
        obj.value = value;
        if (
          key === 'fullname' ||
          key === 'personal' ||
          key === 'phone_1' ||
          key === 'amount' ||
          key === 'requestedpower' ||
          key === 'file_1' ||
          key === 'address' ||
          key === 'cadastral' ||
          key === 'district_Id' ||
          key === 'street_Id' ||
          key === 'addressto_building' ||
          key === 'custN' ||
          key === 'permisiblepower' ||
          key === 'customer_firstname' ||
          key === 'customer_personal' ||
          key === 'customer_phone_1'
        )
          obj.Required = true;
        // else if(key==="telasi"||key==="gwp"){
        //     if(values.checkyes_18) obj.Required=true;
        // }
        else if (key === 'customer_lastname') {
          if (this.state.checked == true) obj.Required = true;
          else obj.Required = false;
        } else obj.Required = false;

        data.push(obj);
      }
    }

    let result = this.letterFormValidator(this.props.letterTypeId, data);
    //console.log(result)
    const found = result.errors.find((x) => x.key === 'filesSize');
    if (typeof found !== 'undefined') {
      //console.log(1234);
      this.state.errorFileSize = true;
      this.setState({ errors: result.errors });
      //this.handleSwalPopup("ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს","warning",false,"","დახურვა",false,"#262262");
    } else {
      this.state.errorFileSize = false;
      this.setState({ errors: result.errors });
    }

    return result.isValid;
  }

  handleRepeat = () => {
    if (this.state.checked) {
      if (typeof this.props.params.user !== 'undefined') {
        const data = this.props.params.user;
        this.setState({
          customer_firstname: data.name,
          customer_personal: this.state.personal,
          customer_lastname: data.lastName,
          customer_phone_1: this.state.phone_1,
          customer_phone_2: this.state.phone_2,
          customer_email_1: this.state.email_1,
          customer_email_2: this.state.email_2,
        });
      }
    } else {
      this.setState({
        customer_phone_1: this.state.phone_1,
        customer_phone_2: this.state.phone_2,
        customer_email_1: this.state.email_1,
        customer_email_2: this.state.email_2,
      });
    }
  };

  handlePhysicalSwitch = () => {
    if (this.state.checked) {
      this.setState({
        checked: !this.state.checked,
        customer_firstname: '',
        customer_lastname: '',
        customer_personal: '',
      });
    } else {
      this.setState({ checked: !this.state.checked });
    }
  };

  errorExsist = (key) => {
    let bool = this.state.errors.some((obj) => obj.key == key);
    return bool;
  };

  renderErrorText = (key) => {
    let bool = this.state.errors.find((obj) => obj.key.includes(key) === true);
    return bool.value;
  };

  render() {
    const values = this.state;
    let phone, email;
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      phone = data.phones.filter((x) => x.typeKey === 1);
      email = data.emails[0];
    }
    let l = true;
    if (values.reference_FieldsDescriptions.length === 0) l = false;
    var _this = this;
    //console.log(_this.state.amountN)
    $('#amountN').on(updateData);
    function updateData() {
      var number = $('#amountN').val();
      var translation = translate_number(number, false);

      if (_this.state.amount !== translation) _this.setState({ amount: translation });
    }

    var number = _this.state.totalfeeN;
    var translation = translate_number(number, false);

    var number1 = _this.state.amountN;
    var translation1 = translate_number(number1, false);
    this.state.amount = translation1;

    if (_this.state.totalfeeT !== translation && _this.state.totalfeeN !== '')
      _this.setState({ totalfeeT: translation });
    if (_this.state.totalfeeN) {
      //console.log(_this.state.checkyes_20);
      var t = parseInt(_this.state.totalfeeN);
      if (values.checkyes_20) {
        //console.log(1);
        t = t / 2;
      }
      //console.log(t);
      if (_this.state.totalfeeT !== translation && _this.state.totalfeeN !== '')
        _this.setState({ amountN: t });
    }
    //console.log(this.state.requestedpower);
    return (
      <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <div style={{ float: 'left' }}>
                <ToLetters></ToLetters>
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>
                  <strong>{this.props.letterTitle}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <strong>განმცხადებელი</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სახელი და გვარი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      readOnly={this.props.params.user.fullName ? true : false}
                      placeholder="სახელი გვარი/ორგანიზაციის დასახელება"
                      onChange={this.handleChange('fullname')}
                      value={values.fullname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'fullname'}
                      />
                    ) : null}
                    {this.errorExsist('fullname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('fullname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-4 col-form-label">
                    <strong>პირადი #/საიდენტიფიკაციო #:</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="personal"
                      placeholder="პირადი ნომერი"
                      readOnly={this.props.params.user.personalId ? true : false}
                      onChange={this.handleChange('personal')}
                      value={values.personal}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'personal'}
                      />
                    ) : null}
                    {this.errorExsist('personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მისამართი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      placeholder="მისამართი"
                      readOnly={this.props.params.user.registrationAddress ? true : false}
                      onChange={this.handleChange('address')}
                      value={values.address}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'address'}
                      />
                    ) : null}
                    {this.errorExsist('address') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('address')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      readOnly={
                        typeof phone[0].info !== 'undefined' && phone[0].info ? true : false
                      }
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_1"
                      onChange={this.handleChange('phone_1')}
                      value={values.phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_2"
                      onChange={this.handleChange('phone_2')}
                      value={values.phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      readOnly={typeof email !== 'undefined' ? true : false}
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_1"
                      onChange={this.handleChange('email_1')}
                      value={values.email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_2"
                      onChange={this.handleChange('email_2')}
                      value={values.email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_2'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>ინფორმაცია (აბონენტის), გაზის საფასურის გადამხდელი პირის შესახებ:</strong>
              <div className="card-header-actions">
                <button className="btn btn-primary btn-sm" onClick={this.handleRepeat}>
                  განმცხადებლის მონაცემების გადმოტანა
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <BootstrapSwitchButton
                  width={200}
                  checked={values.checked}
                  onlabel="ფიზიკური"
                  offlabel="იურიდიული"
                  onChange={this.handlePhysicalSwitch}
                ></BootstrapSwitchButton>
                <br />
                <br />
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'სახელი' : 'დასახელება'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_firstname')}
                      placeholder={values.checked ? 'სახელი' : 'დასახელება'}
                      value={values.customer_firstname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_firstname'}
                      />
                    ) : null}
                    {this.errorExsist('customer_firstname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_firstname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {values.checked ? (
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გვარი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('customer_lastname')}
                        value={values.customer_lastname}
                        placeholder="გვარი"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_lastname'}
                        />
                      ) : null}
                      {this.errorExsist('customer_lastname') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_lastname')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_personal')}
                      value={values.customer_personal}
                      placeholder={values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_personal'}
                      />
                    ) : null}
                    {this.errorExsist('customer_personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ძირითადი"
                      name="customer_phone_1"
                      onChange={this.handleChange('customer_phone_1')}
                      value={values.customer_phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ალტერნატიული"
                      name="customer_phone_2"
                      onChange={this.handleChange('customer_phone_2')}
                      value={values.customer_phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ძირითადი"
                      name="customer_email_1"
                      onChange={this.handleChange('customer_email_1')}
                      value={values.customer_email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ალტერნატიული"
                      name="customer_email_2"
                      onChange={this.handleChange('customer_email_2')}
                      value={values.customer_email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_2'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      აბონენტის ნომერი, სადაც უნდა მოხდეს ბუნებრივი გაზის მოხმარების მოცულობის
                      გაზრდა
                    </strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput
                      className="form-control"
                      mask="111111-111"
                      list="customers"
                      placeholder="XXXXXX-XXX"
                      name="custN"
                      onChange={this.handleChange('custN')}
                      value={values.custN}
                    ></MaskedInput>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'custN'}
                      />
                    ) : null}
                    <datalist id="customers">{this.renderCustomersOptions()}</datalist>
                    {this.errorExsist('custN') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('custN')}
                      </label>
                    ) : null}
                    <span style={{ color: '#e55353' }}>
                      თუ ჩამონათვალში არ იძებნება ის აბონენტის ნომერი, სადაც გსურთ ბუნებრივი გაზის
                      მოხმარების მოცულობის გაზრდა, გთხოვთ პირად კაბინეტში დაამატოთ შესაბამისი
                      აბონენტის ნომერი და შემდგომ მოახდინოთ განაცხადის რეგისტრაცია.
                      <a href="/dashboard/Customers">აბონენტის დამატება</a>
                      {/* <a href="/dashboard/Write/AbonentRegister">
                        აბონენტის რეგისტრაციის მონაცემების ცვლილება
                      </a> */}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>განმცხადებლის საბანკო რეკვიზიტები</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('bankdetails')}
                      placeholder="განმცხადებლის საბანკო რეკვიზიტები"
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'bankdetails'}
                      />
                    ) : null}
                    {this.errorExsist('bankdetails') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('bankdetails')}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <strong>ინფორმაცია ობიექტის შესახებ</strong>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    ობიექტი სადაც უნდა მოხდეს სიმძლავრის გაზრდა მდებარეობს თვითმმართველ ქალაქში
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_13')}
                      checked={values.checkyes_13}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_13')}
                      checked={!values.checkyes_13}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_13'}
                    />
                  ) : null}
                </div>
              </div>
              {/* <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>რაიონი</strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <Select
                    isClearable
                    isSearchable
                    options={this.state.reference_activedistricts}
                    onChange={this.handleDistrictChange}
                    placeholder="აირჩიეთ"
                    noOptionsMessage={() => 'ცარიელია'}
                  />
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'district_Id'}
                    />
                  ) : null}
                  {this.errorExsist('district_Id') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('district_Id')}
                    </label>
                  ) : null}
                </div>
              </div> */}
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>ქუჩა</strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <Select
                    ref={(ref) => {
                      this.selectRef1 = ref;
                    }}
                    isClearable
                    isSearchable
                    options={this.state.reference_allStreets}
                    onChange={this.handleStreetChange}
                    placeholder="აირჩიეთ ან აკრიფეთ"
                    noOptionsMessage={() => 'ცარიელია'}
                  />
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'street_Id'}
                    />
                  ) : null}
                  {this.errorExsist('street_Id') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('street_Id')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>შენობის ნომერი</strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    onChange={this.handleChange('addressto_building')}
                    placeholder="შენობის ნომერი"
                    value={values.addressto_building}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'addressto_building'}
                    />
                  ) : null}
                  {this.errorExsist('addressto_building') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('addressto_building')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>სადარბაზო</strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    onChange={this.handleChange('addressto_entrance')}
                    placeholder="სადარბაზო"
                    value={values.addressto_entrance}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'addressto_entrance'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>სართული</strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    onChange={this.handleChange('addressto_floor')}
                    placeholder="სართული"
                    value={values.addressto_floor}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'addressto_floor'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>ბინის ნომერი</strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    onChange={this.handleChange('addressto_apartament')}
                    placeholder="ბინის ნომერი"
                    value={values.addressto_apartament}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'addressto_apartament'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    უძრავი ქონების საკადასტრო კოდი (სადაც უნდა მოხდეს ბუნებრივი გაზის მოხმარების
                    მოცულობის გაზრდა)
                  </strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    onChange={this.handleChange('cadastral')}
                    placeholder="საკადასტრო კოდი"
                    value={values.cadastral}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'cadastral'}
                    />
                  ) : null}
                  {values.cadastralerror ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {values.cadastralerror}
                    </label>
                  ) : null}
                  {this.errorExsist('cadastral') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('cadastral')}
                    </label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <strong>დანადგარები</strong>
            </div>
            <div className="card-body">
              {values.tools.map((val, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        {index === 0 ? <strong>დანადგარები</strong> : null}
                      </label>
                      <div className="col-md-4">
                        <Select
                          isSearchable
                          options={values.reference_tools}
                          onChange={(e) => this.handleToolChange(e, index)}
                          placeholder="აირჩიეთ"
                        ></Select>
                      </div>
                      <div className="btn-box col-md-2">
                        {values.tools.length !== 1 && (
                          <button
                            className=""
                            type="button"
                            onClick={(e) => this.handleRemoveClick(index)}
                          >
                            წაშლა
                          </button>
                        )}
                        &nbsp;
                        {values.tools.length - 1 === index && (
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={this.handleAddClick}
                          >
                            დამატება
                          </button>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    ბუნებრივი გაზის დანადგარების (ბუნებრივი გაზის მოხმარების მოცულობის გაზრდის
                    შემთხვევაში) ჯამური, საათობრივი ხარჯი
                    <br />
                    მაქსიმალური ხარჯი{' '}
                  </strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="number"
                    min="0"
                    onChange={this.handleChange('maximum')}
                    placeholder="მ&sup3;/სთ"
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'maximum'}
                    />
                  ) : null}
                  {this.errorExsist('maximum') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('maximum')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>მინიმალური ხარჯი </strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="number"
                    min="0"
                    onChange={this.handleChange('minimal')}
                    placeholder="მ&sup3;/სთ"
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'minimal'}
                    />
                  ) : null}
                  {this.errorExsist('minimal') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('minimal')}
                    </label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <strong>სხვა პირობები</strong>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>ბუნებრივი გაზის მოხმარების მიზანი</strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_17')}
                      checked={values.checkyes_17}
                    ></input>
                    &nbsp;საყოფაცხოვრებო
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_17')}
                      checked={!values.checkyes_17}
                    ></input>
                    &nbsp;არასაყოფაცხოვრებო
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_17'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    აღრიცხვის კვანძის განთავსების საფეხური.
                    <br /> არსებული მდებარეობა
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_45')}
                      checked={values.checkyes_45}
                    ></input>
                    &nbsp;დაბალი წნევა
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_45')}
                      checked={!values.checkyes_45}
                    ></input>
                    &nbsp;საშუალო წნევა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_45'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>მოთხოვნილი მდებარეობა</strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_46')}
                      checked={values.checkyes_46}
                    ></input>
                    &nbsp;დაბალი წნევა
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_46')}
                      checked={!values.checkyes_46}
                    ></input>
                    &nbsp;საშუალო წნევა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_46'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>არსებული მდგომარეობით დასაშვები მოცულობა (სიმძლავრე)</strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="number"
                    min="0"
                    onChange={this.handleChange('permisiblepower')}
                    placeholder="მ&sup3;/სთ"
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'permisiblepower'}
                    />
                  ) : null}
                  {this.errorExsist('permisiblepower') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('permisiblepower')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>ივსება</strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_48')}
                      checked={values.checkyes_48}
                    ></input>
                    &nbsp;დანართი 3
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_48')}
                      checked={!values.checkyes_48}
                    ></input>
                    &nbsp;დანართი 4
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_48'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>მოთხოვნილი გაზრდილი მოცულობა (სიმძლავრე)</strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <Select
                    ref={(ref) => {
                      this.selectRef1 = ref;
                    }}
                    options={values.reference_activeSteps}
                    onChange={this.handlePowerChange}
                    isClearable
                    placeholder="აირჩიეთ"
                  />
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'requestedpower'}
                    />
                  ) : null}
                  {this.errorExsist('requestedpower') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('requestedpower')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    ბუნებრივი გაზის მოხმარების დასაშვები მოცულობის გაზრდის საფასური ჯამურად(ლარი)
                  </strong>
                  (ივსება სიმძლავრის გაზრდის საფასურის გავრცელების შემთხვევაში)
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    min="0"
                    onChange={this.handleChange('totalfeeN')}
                    id="totalfeeN"
                    placeholder="ციფრებში"
                    type="number"
                    value={values.totalfeeN}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'totalfeeN'}
                    />
                  ) : null}
                  {this.errorExsist('totalfeeN') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('totalfeeN')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>თანხა სიტყვიერად</strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    onChange={this.handleChange('totalfeeT')}
                    id="totalfeeT"
                    placeholder="თანხა სიტყვიერად"
                    value={values.totalfeeT}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'totalfeeT'}
                    />
                  ) : null}
                  {this.errorExsist('totalfeeT') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('totalfeeT')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>გესაჭიროებათ საგადასახადო ანგარიშ-ფაქტურა</strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      disabled={this.state.checkyes_17 ? true : false}
                      onChange={() => this.handleCheck('checkyes_4')}
                      checked={values.checkyes_4}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      disabled={this.state.checkyes_17 ? true : false}
                      onChange={() => this.handleCheck('checkyes_4')}
                      checked={!values.checkyes_4}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'fullname'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    განაცხადში მოცემული ჩემი პირადი ნომერი, ტელეფონის ნომერი და ელ. ფოსტის მისამართი
                    გამოყენებულ იქნეს გაზმომარაგებასთან დაკავშირებული ინფორმაციის შეტყობინების
                    მიზნით
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_16')}
                      checked={values.checkyes_16}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_16')}
                      checked={!values.checkyes_16}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_16'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    მიეწოდოს ელექტროენერგიისა და წყალმომარაგების სექტორში მომსახურე საწარმოებს,
                    სადაც, განაცხადში მოცემულ მისამართზე, რეგისტრირებული ვარ მომხმარებლად და
                    გამაჩნია აბონენტის ნომერი
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_18')}
                      checked={values.checkyes_18}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_18')}
                      checked={!values.checkyes_18}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_18'}
                    />
                  ) : null}
                  <br />
                  {/* {
                                        values.checkyes_18?
                                            <React.Fragment>
                                                <input className="form-control" name="telasi" onChange={this.handleChange("telasi")}></input>
                                                {
                                                    this.errorExsist("telasi") ?(
                                                        <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("telasi")}</label>
                                                        ):null
                                                }
                                                <br></br>
                                                <input className="form-control" name="gwp" onChange={this.handleChange("gwp")}></input>
                                                {
                                                    this.errorExsist("gwp") ?(
                                                        <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("gwp")}</label>
                                                        ):null
                                                }
                                            </React.Fragment>
                                        :
                                        null
                                    } */}
                </div>
              </div>
              {/* <div className="form-group row">
                                <label className="col-md-4 col-form-label"><strong>თანახმა ვარ, ქვითარი მივიღო ელექტრონული ფორმით რეგისტრაციის დროს მითითებული მონაცემების  შესაბამისად:</strong></label>
                                <div className="col-md-8">
                                    <label><input type="radio" onChange={()=>this.handleThree("yes")} checked={values.checkyes_1==="yes"?true:false}></input>&nbsp;დიახ</label>&nbsp;
                                    <label><input type="radio" onChange={()=>this.handleThree("no")} checked={values.checkyes_1==="no"?true:false}></input>&nbsp;არა</label>&nbsp;
                                    <label><input type="radio" onChange={()=>this.handleThree("already")} checked={values.checkyes_1==="already"?true:false}></input>&nbsp;უკვე ვსარგებლობ ამ მომსახურებით</label>
                                    {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"checkyes_1"} />:null}
                                </div>
                            </div> */}
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>სიმძლავრის გაზრდის საფასურის გავრცელების შემთხვევაში საფასურის</strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_20')}
                      checked={values.checkyes_20}
                    ></input>
                    &nbsp;50%
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_20')}
                      checked={!values.checkyes_20}
                    ></input>
                    &nbsp;100%
                  </label>
                  &nbsp;
                  {/* <label><input type="radio" onClick={()=>this.handleCheck("checkyes_19")} onChange={()=>this.handleCheck("checkyes_19")} checked={values.checkyes_19}></input>&nbsp;გადახდის დამადასტურებელი საბუთი</label> */}
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'amount'}
                    />
                  ) : null}
                </div>
                <div className="col-md-4" />
                <div className="col-md-3">
                  <input
                    className="form-control"
                    onChange={this.handleChange('amountN')}
                    id="amountN"
                    readOnly
                    placeholder="გადახდილი თანხა ციფრებში"
                    value={values.amountN}
                  ></input>
                </div>
                <div className="col-md-5">
                  <input
                    className="form-control"
                    onChange={this.handleChange('amount')}
                    id="amount"
                    readOnly
                    placeholder="გადახდილი თანხა სიტყვიერად"
                    value={values.amount}
                  ></input>
                </div>
              </div>
              {/* <div className="form-group row">
                                <label className="col-md-4 col-form-label"><strong>შეთანხმება ქვითრის ელექტრონული ფორმით მიღებაზე</strong></label>
                                <div className="col-md-8">
                                    <label><input type="radio" onClick={()=>this.handleCheck("checkyes_26")} onChange={()=>this.handleCheck("checkyes_26")} checked={values.checkyes_26}></input>&nbsp;დიახ</label>&nbsp;
                                    <label><input type="radio" onClick={()=>this.handleCheck("checkyes_26")} onChange={()=>this.handleCheck("checkyes_26")} checked={!values.checkyes_26}></input>&nbsp;არა</label>&nbsp;
                                    
                                    {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"checkyes_26"} />:null}
                                </div>
                            </div>             */}
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    თანდართულია მისაერთებელი ობიექტის შიდა ქსელის გაზმომარაგების მზადყოფნის
                    დამადასტურებელი დოკუმენტი
                    <br />
                  </strong>
                  <b>გაფრთხილება:</b> მიერთების დასრულებამდე აღნიშნული დოკუმენტის
                  განაწილებისლიცენზიატისთვის წარუდგენლობის შემთხვევაში გაზმომარაგება არ დაიწყება
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_24')}
                      checked={values.checkyes_24}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_24')}
                      checked={!values.checkyes_24}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_24'}
                    />
                  ) : null}
                  <br></br>
                  {values.checkyes_24 ? (
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_8')}
                    ></input>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <strong>თანდართული დოკუმენტაცია (მხოლოდ pdf/jpg ფაილები, ჯამში მაქსიმუმ 14MB)</strong>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    პირადობის მოწმობის ან პასპორტის ასლი
                    <span style={{ color: '#e55353' }}>*</span>
                  </strong>
                </label>
                <div className="col-md-8">
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg"
                    onChange={this.handleFileChange('file_1')}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'file_1'}
                    />
                  ) : null}
                  {this.errorExsist('file_1') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('file_1')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>გადახდის დამადასტურებელი საბუთი</strong>
                </label>
                <div className="col-md-8">
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg"
                    onChange={this.handleFileChange('file_9')}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>ფართის აზომვითი ნახაზი/პროექტი</strong>
                </label>
                <div className="col-md-8">
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg"
                    onChange={this.handleFileChange('file_10')}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'file_10'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>სხვა დოკუმენტები</strong>
                </label>
                <div className="col-md-8">
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg"
                    id="otherdocumentsfiles"
                    onChange={this.handleFileChange('otherdocumentsfiles')}
                    multiple
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'otherdocumentsfiles'}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-md-12" style={{ textAlign: 'right' }}>
                  <label>
                    <input
                      type="checkbox"
                      style={{ verticalAlign: 'middle' }}
                      checked={values.agree}
                      onChange={() => this.handleCheck('agree')}
                    ></input>
                    &nbsp;ვეთანხმები
                    <span
                      style={{ color: '#262262', cursor: 'pointer' }}
                      onClick={this.handleLetterTermsModal}
                    >
                      &nbsp;<strong>წესებსა და პირობებს</strong>
                    </span>
                  </label>
                </div>
                <br />
                <br />
                <div className="col-md-12">
                  <ReCAPTCHA
                    style={{ float: 'right' }}
                    sitekey={this.props.params.siteKey}
                    onChange={this.handleChange('recaptchaCode')}
                    hl="ka"
                  ></ReCAPTCHA>
                </div>
                <br />
                <br />
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary"
                    style={{ float: 'right', backgroundColor: '#262262', color: 'white' }}
                    disabled={this.state.recaptchaCode && this.state.agree ? false : true}
                    onClick={this.handleSubmit}
                  >
                    გაგზავნა
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PowerIncrease;
