import React, { Component } from 'react';

class NotFound extends Component {
    state = {  }
    render() { 
        return (
            <React.Fragment>
                <div className="c-app flex-row align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="clearfix">
                                    <h1 className="float-left display-3 mr-4">404</h1>
                                    <h4 className="pt-3">Oops!</h4>
                                    <p className="text-muted">The page you are looking for was not found.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default NotFound;