import React, { Component } from "react";
import Loader from './loader';
import { delete_cookie } from "sfcookies";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';


class Mobileverif extends Component{

    constructor(props){
        super(props);
        this.state={
          disabled:true,
          duration:0,
          key:0
        }
        //this.handleChange = this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSmsClick=this.handleSmsClick.bind(this);
    }

    handleSmsClick(){   
      this.setState({disabled:true,key:this.state.key+1,duration:180}); 
      

      this.props.stepAgain();
    }

    handleKeyDown(event){
      if(event.keyCode===13){
        this.handleClick();
      }
    }

    handleButton(){
      //console.log(1);
      //const cookie = read_cookie("Bearer_Token");
      delete_cookie("Bearer_Token");
      window.location.replace("/login");
    }

    renderTime = ({ remainingTime }) => {
      
      if (remainingTime === 0) {
        return <div ></div>;
      }
      
      var minutes = Math.floor(remainingTime/60);
      var secondes = remainingTime%60;
  
      return (
        <div className="timer">
          <div>{minutes}:{secondes}</div>
        </div>
      );
    };

    handleClick() {
        //e.preventDefault();
        //console.log(this.props.firstname);
        let formIsValid = this.props.formValidate(this.props,2);
        //console.log(formIsValid);
        if(formIsValid){
          this.props.nextStep_1();
        }
        else{
          //console.log(false);
        }   
      };
    
      back = (e) => {
        e.preventDefault();
        this.props.prevStep();
      };


      render(){
        const {values,handleChange}=this.props;

          return(
            <React.Fragment>
              <div className="c-app flex-row align-items-center" onKeyDown={(event)=>{this.handleKeyDown(event)}}>
              <div className="container">
              {/* <div style={{textAlign:"right"}}>
                    
                  </div> */}
                  <div className="row justify-content-center" style={{fontFamily:"BPGCond"}}>
                    <div className="col-md-6">
                    <div className="card-group" >
                      {
                        values.mobileloader?  
                        <Loader></Loader>
                        :null
                      }
                      </div>
                      <div className="card-group">
                        <div className="card p-4">
                          <div className="card-header">
                            <div className="card-header-actions">
                            <button className="btn btn-sm btn-primary" type="button" onClick={this.handleButton} style={{fontFamily:"BPGCond"}}>გამოსვლა</button>
                            </div>
                          </div>
                          <div className="card-body">
                            <h1>ერთჯერადი კოდით ვერიფიკაცია</h1>
                            <div className="input-group mb-3">                          
                              <input className="form-control" type="text" placeholder="ერთჯერადი კოდი" name="code" onChange={handleChange("code")} value={values.code}/>
                            </div>
                            {
                              values.codeerror?(
                                <label style={{fontSize: 12,color:"#e55353"}}>{values.codeerror}</label>
                              ):null
                            }
                            <div className="row justify-content-center">
                              <div className="col-6">
                                <button className="btn btn-block btn-primary" type="button" onClick={this.handleClick}>შემდეგი</button>
                              </div>
                              <div className="col-6 text-right">
                                <div className="row" style={{float:"right"}}>
                                  <button className="btn btn-link px-0" disabled={!this.state.disabled||this.state.key===0?false:true} type="button" onClick={this.handleSmsClick}>თავიდან გამოგზავნა</button>
                                  &nbsp;&nbsp;
                                  <div style={{visibility:!this.state.disabled?"hidden":"visible"}}>
                                    <CountdownCircleTimer isPlaying={this.state.disabled?true:false} duration={this.state.duration} size={35} strokeWidth={2.5}
                                      colors={[["#262262"]]}
                                      key={this.state.key}
                                      onComplete={() => this.setState({disabled:false})}
                                      
                                    >
                                      {this.renderTime}
                                    </CountdownCircleTimer>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                            </div>
                            </div>
                            </div>
            </React.Fragment>
          );
      }
}

export default Mobileverif;