import React, { Component } from 'react';

class CurrentWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onIframeLoad = (e) => {
    //console.log(e);
    var iframe = document.getElementById('myIframe');
    //iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
  };

  render() {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card">
              <div
                className="card-header"
                style={{ textAlign: 'left', fontFamily: 'BPGCaps', fontSize: '16px' }}
              >
                <span>
                  <strong>მიმდინარე სამუშაოები</strong>
                </span>
              </div>
              <div
                className="card-body"
                style={{
                  textAlign: 'left',
                  fontFamily: 'BPGCond',
                  color: '#262262',
                  height: '100%',
                }}
              >
                <iframe
                  src="https://te.ge/publicmap/"
                  style={{ display: 'block', width: '100%', height: '650px' }}
                  onLoad={(e) => this.onIframeLoad(e)}
                  id="myIframe"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CurrentWorks;
