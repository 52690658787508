import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Fail extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="card">
                        <div className="card-header" style={{fontFamily:"BPGCaps",textAlign:"center"}}>
                            <h1 style={{color:"#a94442"}}>გადახდა არ განხორციელდა, სცადეთ მოგვიანებით</h1>
                        </div>
                        <div className="card-body" style={{fontFamily:"BPGCond",textAlign:"center"}}>
                            <Link className="btn btn-secondary btn-lg" to={"/dashboard/Payment"}><i className="fas fa-arrow-circle-left"></i>&nbsp;უკან დაბრუნება</Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Fail;