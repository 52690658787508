import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import jsPDF from 'jspdf';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import Loader from '../../loader';
import { read_cookie, delete_cookie } from 'sfcookies';
import moment from 'moment';
import Axios from 'axios';

class SmartMeterHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ucId: props.ucId,
      dailyLogs: [],
      loader: false,
    };
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = () => {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    if (this.state.ucId) {
      //console.log(this.state.ucId);
      this.setState({ loader: true });
      Axios.get(
        `${name}/api/Customer/GetDailyLogs/${this.state.ucId}`,
        
        {
          headers: {
            "Token": token,
          },
        }
      )
        .then((res) => {
          //console.log(res.data);
          if (res.data.success) {
            this.setState({ dailyLogs: res.data.dailyLogs });
            this.setState({ loader: false });
          } else {
            this.setState({ loader: false });
            //alert("Email-ების წაკითხვა ვერ მოხერხდა");
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.href = '/login';
          }
          //alert("შეცდომა");
        });
      //this.setState({ loader: false });
    }
  };

  getToPdf=()=>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFont('BPG_Square_37_Cond');
    doc.setFontSize(15);

    const title = "ჭკვიანი მრიცხველის ჩვენება";
    const headers = [["მრიცხველის ნომერი","თარიღი", "ჩვენება მ³","ხარჯი მ³"]];
    //console.log(headers.styles);
    headers.fillColor="#262262";

    const data = this.state.dailyLogs.map(element=>[element.logicalDeviceName,element.dateTime,element.volumeDecimalString,element.volumeDelta]);

    let content = {
        startY: 50,
        headStyles:{fillColor: [38,34,98], fontStyle: 'normal',font:'BPG_Square_37_Cond_Caps'},
        bodyStyles:{font:'BPG_Square_37_Cond'},
        //columnStyles:{0:{font:'BPG_Square_37_Cond'},1:{font:'BPG_Square_37_Cond'}},
        head: headers,
        body: data,
    };
  
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
}

getToExcel=()=>{
    let dt = moment(new Date()).format("DD.MM.YYYY");
    let array = this.state.dailyLogs.map((element)=>({'მრიცხველის ნომერი':element.logicalDeviceName,'თარიღი':element.dateTime,'ჩვენება მ³':element.volumeDecimalString,'ხარჯი მ³':element.volumeDelta}));
    //console.log(array);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName= `${this.props.values+"_"+dt}`;
    let ws = XLSX.utils.json_to_sheet(array);
    //console.log(ws);
    ws['A1'].s={fill:{patternType: "none",fgColor: {rgb: "FF000000"},bgColor: {rgb: "00000000"}}}
    // ws['A1'].s={fill:{patternType: "none", // none / solid
// fgColor: {rgb: "FF000000"},
// bgColor: {rgb: "FFFFFFFF"}},border: {
    //     top: {style: "thin", color: {auto: 1}},
    //     right: {style: "thin", color: {auto: 1}},
    //     bottom: {style: "thin", color: {auto: 1}},
    //     left: {style: "thin", color: {auto: 1}}
    //     }}
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}

  _exporter;
  export = () => {
      this.save(this._exporter);
  }

  save = (component) => {
      const options = component.workbookOptions();
      const rows = options.sheets[0].rows;

      rows.forEach((row) => {
          if (row.type === 'header') {
              row.height=35;
          }
      });

      component.save(options);
  };

  render() {
    const { loader, dailyLogs, ucId } = this.state;

    const columns = [
      {
        name: 'მრიცხველის ნომერი',
        selector: 'logicalDeviceName',
        wrap: true,
        sortable: true,
        //center:true
      },
      {
        name: 'თარიღი',
        selector: 'dateTime',
        wrap: true,
        sortable: true,
        //center:true,
        cell: (row) => moment(row.dateTime).format('DD.MM.YYYY'),
      },
      {
        name: 'ჩვენება მ³',
        selector: 'volumeDecimalString',
        wrap: true,
        sortable: true,
        //center:true
      },
      {
        name: 'ხარჯი მ³',
        selector: 'volumeDelta',
        wrap: true,
        sortable: true,
        //center:true
      },
      
    ];

    const actions = [
      <i className="fas fa-file-pdf" key="pdfbutton" title="Pdf-ში კონვერტირება" style={{color:"#262262",cursor:"pointer"}} onClick={this.getToPdf}></i>,
      <i className="fas fa-file-excel" key="excelbutton" title="Excel-ში კონვერტირება" style={{color:"#262262",cursor:"pointer"}} onClick={this.export}></i>
    ]

    let dt = moment(new Date()).format("DD.MM.YYYY");
    const fileName= `${this.props.values+"_"+dt}.xlsx`;
    const headerCellOptions={background: '#262262',textAlign:'center',fontSize:15};

    //
    return (
      <React.Fragment>
        <div className="row justify-content-center">
        <ExcelExport
                        data={dailyLogs}                  
                        fileName={fileName}
                        headerPaddingCellOptions={{ background: '#ff0000' }}
                        ref={(exporter) => { this._exporter = exporter; }}                  
                        >
                        <ExcelExportColumn field="logicalDeviceName" title="მრიცხველის ნომერი" width={150} height="10em" headerCellOptions={headerCellOptions} />
                        <ExcelExportColumn field="dateTime" title="თარიღი" width={150} headerCellOptions={headerCellOptions}/>  
                        <ExcelExportColumn field="volumeDecimalString" title="ჩვენება მ³" width={150} headerCellOptions={headerCellOptions}/>
                        <ExcelExportColumn field="volumeDelta" title="ხარჯი" width={150} headerCellOptions={headerCellOptions}/>  
                    </ExcelExport>
          <div className="col-lg-12" style={{ fontFamily: 'BPGCond' }}>
            <div className="card-group">
              {loader && <Loader />}
              <DataTable
                title={<div style={{ float: 'left' }}></div>}
                columns={columns}
                data={dailyLogs}
                pagination
                responsive
                striped
                actions={actions}
                noDataComponent="მონაცემები ცარიელია"
                style={{ fontFamily: 'BPGCond' }}
              ></DataTable>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SmartMeterHistory;
