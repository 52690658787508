import React, { Component } from 'react';
import '../css/style.css';
import '../css/fontawesome-free-5.13.0-web/css/all.css';
import '../fonts/stylesheet.css';
import Loader from './loader';
import {Link} from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

class PasswordReset extends Component{

    constructor(props){
      super(props);
      //console.log(this);
      this.handleClick=this.handleClick.bind(this);
      this.state={
        isValid:true
      }  
    }
    
    handleClick() {
      let formIsValid = this.props.formValidate(this.props,1);
      this.setState({isValid:formIsValid});
      if(formIsValid){
        this.props.handlePassResetRequest();
      }
      else{
        //console.log(this.props.values);
      }    
    };

    handleKeyDown(event){
      if(event.keyCode===13){
        this.handleClick();
      } 
    }
    render(){
        const {values,handleChange}=this.props;

        let styleObj = {
          display: 'flex',
          alignItems: 'center',
          width: '0.75rem',
          height: '0.75rem',
          //fontSize: '1rem',
          flexDirection: 'row',
          //textAlign: 'center',
          whiteSpace: 'nowrap',
          color: 'inherit',
          //verticalAlign:'middle',
          /* align-items: center; */  
          fill: 'currentColor',
          backgroundColor: '#ebedef'
      };

        return(
              <React.Fragment>
                  <div className="c-app flex-row align-items-center" onKeyDown={(event)=>{this.handleKeyDown(event)}} style={{fontFamily:"BPGCond"}}>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <div className="card-group" style={{textAlign:"center"}}>
                            {
                              values.bool?
                              <Loader></Loader>
                              :null
                            }
                            <div className="card p-4">
                              <div className="card-header"><h1 style={{fontFamily:"BPGCaps"}}>პაროლის აღდგენა</h1>      </div>
                              <div className="card-body">                                                     
                                <div className="input-group mb-3">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <div style={styleObj}>
                                        <i className="far fa-user-circle"></i>
                                      </div>    
                                    </span>
                                  </div>
                                  <input className={`form-control ${this.state.isValid?"":"is-invalid"}`} type="text" placeholder="მომხმარებლის სახელი ან პირადი ნომერი" onChange={handleChange("info") } value={values.info}/>
                                  {/* <div className="invalid-feedback">{values.infoerror?values.infoerror:null}</div> */}
                                  
                                </div> 
                                
                                {
                                    values.infoerror ?(
                                      <label style={{fontSize: 12,color:"#e55353"}}>{values.infoerror}</label>
                                    ):null
                                  }                             
                                <div>
                                <div className="row justify-content-center">
                                <div >
                                <div className="col-md-8">
                                <ReCAPTCHA
                                sitekey={values.siteKey}
                                onChange={handleChange("recaptchaCode")}
                                hl="ka"
                              >

                            </ReCAPTCHA>
                              </div>
                                  <Link className="btn btn-secondary px-4" to={"/login"}> უკან</Link>&nbsp;&nbsp;
                                  <button className="btn btn-primary px-4" type="button" disabled={values.recaptchaCode?false:true} onClick={this.handleClick} style={{backgroundColor:"#262262",borderColor:"#262262",fontFamily:"BPGCond"}}>შემდეგი</button>
                                  
                                </div>
                                </div>
                                </div>
                              </div>
                        </div>                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
        )
    }
}

export default PasswordReset;