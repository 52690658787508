import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
// import { read_cookie, delete_cookie,bake_cookie } from '../../node_modules/sfcookies';

class GooGleAuth extends Component {
    state = { 
        accessToken:"",
        isLogined:"",
        clientId:"675512812624-no3lfsijt2ekglsm8gf0j3ht9jm7g67v.apps.googleusercontent.com",
        profileObject:""
        //clientId:"335705365419-54d5dec9j5t2ds212370i8rilsqkdkc7.apps.googleusercontent.com"
        //clientId:"436723983770-5v6kac304p0g2rt1htdkos6qr3kbrnas.apps.googleusercontent.com"
     }

     responseGoogle=(response)=>{
         //alert(1);
        //console.log([1,response.getAuthResponse().id_token])
        //bake_cookie("test",response.accessToken);
        if(response.accessToken){
            this.setState({
              isLogined: true,
              accessToken: response.accessToken,
              profileObject:response.profileObj
            });
        console.log(response.accessToken);
        }
        console.log(response);
    }

    render() { 
        //console.log(this.state);
        return ( 
            <React.Fragment>
                <div className="col-md-12">
                    <div className="col-md-6">
                        <GoogleLogin 
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            clientId={this.state.clientId}
                            cookiePolicy={'single_host_origin'}
                        >
                        </GoogleLogin>
                    </div>
                    <div className="col-md-6"/>
                </div>
            </React.Fragment>
        );
    }
}
 
export default GooGleAuth;