import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";

class InputInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show:false,
            descriptions:[],
            active:{
                name:"",
                description:""
            }
        }
    }

    UNSAFE_componentWillMount(){
        if(typeof(this.props.descriptions)!=="undefined"){
            this.setState({descriptions:this.props.descriptions});
        }
    }

    getTooltipContent=()=>{
        //console.log(this.state.descriptions);
        if(typeof(this.props.letterTypeId)!=="undefined"){
            let active = this.state.descriptions.find(x=>x.key===this.props.inputName);
            if(typeof(active)!=="undefined"){
                this.setState({active:{name:active.key,description:active.value}});
            }
        }
    }

    render() { 
        return (
            <React.Fragment>
                <ReactTooltip id="inputTyp" effect="solid" place="top">
                    {this.state.active.description}
                </ReactTooltip>
                <span className="input_info"  
                    onMouseEnter={()=>{this.setState({show:true});this.getTooltipContent()}} 
                    onMouseLeave={()=>this.setState({show:false,active:{name:"",description:""}})}
                ><i className="fas fa-info-circle" data-tip="" data-for="inputTyp" /></span>
            </React.Fragment>
        );
    }
}
 
export default InputInfo;