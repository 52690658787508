import React, { Component } from 'react';
import Login from './login';
import axios from '../../node_modules/axios';
//import UserPanel from './userpanel';
import { bake_cookie,read_cookie} from '../../node_modules/sfcookies';
import Mobileverif from './mobileverif';
import { Redirect } from 'react-router-dom';

class UserLogin extends Component {

    state = {
        step: 1,
        email:"",
        username:"",
        usernameerror:"",
        emailerror:"",
        password:"",
        passworderror:"",
        code:"",
        codeerror:"",
        token: "",
        bool:"",
        firstname:"",
        lastname:"",
        personalnumber:"",
        phonenumber:"",
        recaptchaCode:"",
        recaptchaCodeerror:"",
        siteKey:"6LeRKt0ZAAAAAK4UK8qKKhj8kUgy0Bc13izENa5g",
        recaptchaRequested:false,
        //errors:{}
      };


      componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    UNSAFE_componentWillMount(){
      const token = read_cookie("Bearer_Token");
      var querystring = window.location.search;
        var params = new URLSearchParams(querystring);
        let platform = params.get("platform");
        let app = params.get("app");
        if(params!==null&&app!==null){
          bake_cookie("platform",platform);
          bake_cookie("app",app);
        }
      if(typeof(token)!=="undefined"&&token.length>1){
        //console.log(token);
        this.setState({token:token});
      }
    }

      formValidate = (props,step) => {       
        //console.log(props);
        let values = props.values;
        //let errors={};
        let formIsValid = true;

        this.setState({usernameerror:""});
        this.setState({passworderror:""});

        if(!values["username"]){
          formIsValid=false;
          this.setState({usernameerror:"მომხმარებლის სახელი აუცილებელია"});
          //errors["email"]="არ უნდა იყოს ცარიელი";
          //console.log("formIsValid");
        }

        // if(typeof values["personalnumber"]!=="undefined"){
        //   if(!values["personalnumber"].match(/^[0-9]+$/)){
        //     formIsValid=false;
        //     errors["personalnumber"]="მხოლოდ ციფრები";
        //     alert("დასაშვებია მხოლოდ ციფრები")
        //   }
        // }

        if(!values["password"]){
          formIsValid=false;
          this.setState({passworderror:"პაროლის შეყვანა აუცილებელია"});
          //errors["password"]="შეიყვანეთ პაროლი";
        }

        if(typeof values["password"]!=="undefined"){
          //აქ ჩაჯდება პაროლის დამთხვევა როცა აპი დაიწერება
        }

        if(step===2&&!values["code"]){
          formIsValid=false;
          this.setState({codeerror:"აუცილებელია კოდის შეყვანა"});
        }

       //this.setState({errors: errors});
       return formIsValid;

      }

      submithandler(){
        const name = window.$name;
        this.setState({emailerror:""});
        this.setState({passworderror:""});
        // const tk = read_cookie("Bearer_Token");
        // delete_cookie("Bearer_Token");
        var querystring = window.location.search;
        var params = new URLSearchParams(querystring);
        let platform = params.get("platform");
        let app = params.get("app");
        // if(params!==null&&app!==null){
        //   bake_cookie("platform",platform);
        //   bake_cookie("app",app);
        // }
        if(platform===null) platform="web";
        if(app===null) app="0.1.0";
        //delete_cookie("platform"); delete_cookie("app")
        if(read_cookie("platform").length>0&&read_cookie("platform")!=="web"){
          platform=read_cookie("platform");
          if(read_cookie("app").length>0){
            app=read_cookie("app");
          }
        }
        
        //console.log(read_cookie("platform"),read_cookie("app"));
        const user ={
          email: this.state.email,
          userName:this.state.username,
          password: this.state.password,
          recaptchaCode:this.state.recaptchaCode
        }
        //console.log(this.state.username);
        this.setState({bool:true});
        //console.log(read_cookie(platform),read_cookie(app));
        //console.log(user.email);
        //console.log(user.password);
     
        axios.post(`${name}/api/Users/Authenticate`,{
          "userName":user.userName,
          "password":user.password,
          "recaptchaCode":user.recaptchaCode,
          "platform":platform,
          "app":app
        })
        .then(res=>{
          //console.log(res.data);
          if(res.data.success)
          {
          
          //set cockies
          const cockie_key = "Bearer_Token";
          bake_cookie(cockie_key,res.data.token);
   
          const { step } = this.state;
          this.setState({
            step: step + 2,
          });
          this.setState({bool:false});
          
          }
          else if(res.data.code===-51&&!res.data.success){
            //this.resetRecaptcha();
            this.setState({recaptchaRequested:res.data.recaptchaRequested});
          }
          else{
            //console.log(res.data)
            //this.resetRecaptcha();
            //window.grecaptcha.reset();
            this.setState({passworderror:"მომხმარებლის სახელი ან პაროლი არასწორია",password:"",recaptchaRequested:res.data.recaptchaRequested,recaptchaCode:""});
            window.grecaptcha.reset();
          }
          //console.log(this.state);
          this.setState({bool:false});
          //alert(read_cookie("Bearer_Token"));
        })
        .catch(error=>{
          //console.log(error);
          this.setState({passworderror:"მომხმარებლის სახელი ან პაროლი არასწორია"});
          this.setState({bool:false});
          // const cockie_key = "Bearer_Token";
          // bake_cookie(cockie_key,error.response.data.token);
          if(error.respone&&error.response.status===403){
            const { step } = this.state;
            this.setState({
              step: step + 1,
              bool:false
            });
          }
        })
      };

      submitsmshandler(){      
        let Btoken = read_cookie("Bearer_Token");
        const name = window.$name;
        axios.post(`${name}/api/Users/VerifyPhone`,{
          mobilePhone:this.state.phonenumber,
          smsCode:this.state.code,
         
        },
        {
          headers:{
            "Token":Btoken
          }
        })
        .then(res=>{
          //console.log(res);
          const {step}  = this.state;
          this.setState({
            step: step + 1,
          })
        })
        .catch(error=>{
          //console.log(error);
          alert("არასწორი კოდი");
        })
       
      };

      submitSmsAgain(){
        //console.log("შემოვიდა");
        let cockie_key = "Bearer_Token";
        // const validation ={
        //   token:this.state.token,
        //   code:this.state.code
        // }
        const name = window.$name;
        let token = read_cookie(cockie_key);

        //console.log("Bearer " + validation.token);
        //console.log(token);
        axios.post(`${name}/api/Users/ReSendSmsCode`,
        {
          mobilePhone:this.state.phonenumber
        },
        {headers:
            {
              "Token":token
            }    
        }
      ).then(res=>{
        //set token
        //bake_cookie(cockie_key,res.data.token);
        alert("კოდი გამოგზავნილია");
          
      })
        .catch(error=>{
          //console.log(this.state.code);
          alert("არასწორი კოდი");
          
          //console.log(error);
        })
      }

      nextStep = () => {
        this.submithandler();     
      };

      nextStep_1=()=>{
        this.submitsmshandler();
      }
   
      stepAgain=()=>{
        this.submitSmsAgain();
      }
      //go to previous
      prevStep = () => {
        const { step } = this.state;
        this.setState({
          step: step - 1,
        });
      };

      handleChange = (input) => (e) => {
        //console.log(e)
        if(input==="recaptchaCode"){
          //console.log(e)
          this.setState({recaptchaCode:e});
        }
        else{
          this.setState({ [input]: e.target.value });
        }
      };

      continue = (e) => {
        e.preventDefault();
        this.props.nextStep();
      };

      handleGoogleLoginSuccess=(response)=>{
        console.log(response);
      }

      handleGoogleLoginFailure=(response)=>{
        console.log(response);
      }

      // setCaptchaRef = (ref) => {
      //   if (ref) {
      //     return captcha = ref;
      //   }
      // };

      render(){
        const { step } = this.state;
        const { email,emailerror, password ,code,codeerror,passworderror,bool,token,firstname,lastname,phonenumber,personalnumber,username,usernameerror,recaptchaCode,recaptchaCodeerror,siteKey,recaptchaRequested} = this.state;
        const values = { email,emailerror, password,code,codeerror,passworderror,bool,token,firstname,lastname,phonenumber,personalnumber,username,usernameerror,recaptchaCode,recaptchaCodeerror,siteKey,recaptchaRequested};
        //var tk = read_cookie("Bearer_Token");
        // var querystring = window.location.search;
        // var params = new URLSearchParams(querystring);
        // let platform = params.get("platform");
        // let app = params.get("app");
        // console.log([platform,app])
        switch(step){
            case 1: 
            return(
                !this.state.token?
                <Login
                // aaa="dwadaw"
                   nextStep={this.nextStep}
                   handleChange={this.handleChange}
                   formValidate={this.formValidate}
                   values={values}
                   handleGoogleLoginSuccess={this.handleGoogleLoginSuccess}
                   handleGoogleLoginFailure={this.handleGoogleLoginFailure}
                   >
                </Login>
                :
                <Redirect to={"/dashboard"}></Redirect>
            )
            case 2:
                return(
                  // <UserPanel>

                  // </UserPanel>
                  //<h1>Success</h1>
                    <Mobileverif
                       nextStep={this.nextStep}
                       nextStep_1={this.nextStep_1}
                       prevStep={this.prevStep}
                       handleChange={this.handleChange}
                       formValidate={this.formValidate}
                       submitsmshandler={this.submitsmshandler}
                       stepAgain={this.stepAgain}
                       submitSmsAgain={this.submitSmsAgain}
                       values={values}>
                    </Mobileverif>
                )
                default:
                  return(<Redirect to={"/dashboard"}></Redirect>)
        }
      }
}

export default UserLogin;