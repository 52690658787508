import React, { Component } from 'react';
import ToLetters from '../../ToLetters';
import axios from 'axios';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import MaskedInput1 from 'react-text-mask';
import TextMask from 'react-text-mask';
import emailMask from 'text-mask-addons/src/emailMask';
import ReCAPTCHA from 'react-google-recaptcha';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Select from 'react-select';
import InputInfo from '../InputInfo';

class ConnectToNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      //head:"",
      personal: '',
      phone_1: '',
      phone_2: '',
      email_1: '',
      email_2: '',
      custN: '',
      address: '',
      customer_firstname: '',
      customer_lastname: '',
      customer_personal: '',
      customer_phone_1: '',
      customer_phone_2: '',
      customer_email_1: '',
      customer_email_2: '',
      techtermsNumber: '',
      date1: '',
      date2: '',
      addressto: '',
      responsible: '',
      responsible_personal: '',
      check_onecustomer: false,
      check_multicustomer: false,
      count: '',
      customerscount: '',
      smartmetercount: '',
      sumpower: '',
      tablescount: '',
      pagescount: '',
      tables: '',
      techterms: '',
      techtermscomp: '',
      pages: '',
      otherdocumentsfiles: [],
      recaptchaCode: '',
      recaptchaCodeerror: '',
      reference_FieldsDescriptions: [],
      errors: [],
      agree: false,
      checked: true,
      onetomulti: true,
      smartmeter: false,
      checkyes_4: false,
      checkyes_16: true,
      checkyes_17: true,
      checkyes_18: true,
      checkyes_21: true,
      danarti1_1: '',
      checkyes_5: false,
      checkyes_24: false,
      checkyes_25: false,
      checkyes_221: false,
      checktables: true,
      tablescount: '',
      pagescount: '',
      techterms: '',
      techtermsfile: '',
      techtermscomp: '',
      techtermscompfile: '',
      pages: '',
      file_1: '',
      file_8: '',
      file_agree: '',
      checkyes_23: false,
      reference_activedistricts: {},
      reference_activestreets_1: {},
      reference_RefCapacitySteps: [],
      reference_activeSteps: {},
      reference_tools: [],
      requestedpower: '',
      requestedpowerN: '',
      requestedpowertext: '',
    };
    this.handleLetterTermsModal = this.props.handleLetterTermsModal;
    this.letterFormValidator = this.props.letterFormValidator;
    this.renderCustomersOptions = this.props.renderCustomersOptions;
    this.SendLetter = this.props.SendLetter;
    this.handleSwalPopup = this.props.handleSwalPopup;
    this.checkGeorgianLetters = this.props.checkGeorgianLetters;
  }

  UNSAFE_componentWillMount() {
    const token = read_cookie('Bearer_Token');
    const url = window.$name;

    axios
      .get(`${url}/api/Letters/GetLetterFieldDescriptions/${this.props.letterTypeId}`, {
        headers: {
          Token: token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ reference_FieldsDescriptions: res.data.descriptions });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'udnefined') {
          if (typeof error.response.status !== 'undefined') {
            if (error.response.status === 401) {
              delete_cookie('Bearer_Token');
              window.location.replace('/login');
            }
          }
        }
      });

    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      let phone = data.phones.filter((x) => x.typeKey === 1);
      let email = data.emails[0];
      this.setState({
        fullname: data.name + ' ' + data.lastName,
        personal: data.personalId,
        phone_1: phone ? phone[0].info : '',
        email_1: email ? email.info : '',
        address: data.registrationAddress,
      });
    }
  }

  componentDidMount() {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    Axios.get(`${name}/api/Letters/GetReferences?letterTypeId=74`, { headers: { Token: token } })
      .then((res) => {
        if (res.data.success) {
          let data = res.data.references;
          let districts = [];
          let activedistricts = [];
          let streets = [];
          let devices = [];
          let active_streets = [];
          let RefCapacitySteps = [];
          let tools = [];

          //ერთეულოვანისთვის "RefCapacitySteps":  1-თვითმმართველი ,2-არათვითმმართველი 3 და 4 სიმძლავრის გაზრდაა
          //console.log(data);
          data.forEach((element) => {
            switch (element.key) {
              case 'RefDevices':
                devices.push(element);
                let obj1 = { value: element.id, label: element.value };
                tools.push(obj1);
                break;
              case 'RefDistricts':
                let obj = { value: element.id, label: element.value };
                activedistricts.push(obj);
                districts.push(element);
                break;
              case 'RefStreets':
                streets.push(element);
                break;
              case 'RefCapacitySteps':
                //console.log(element);
                RefCapacitySteps.push(element);
                break;
              default:
                break;
            }
          });
          //console.log(devices);
          let val = districts[0].id;
          streets.forEach((element) => {
            if (element.parentId === val) {
              active_streets.push(element);
            }
          });

          //console.log(streets);
          this.setState({
            reference_devices: devices,
            reference_streets: streets,
            reference_districts: districts,
            reference_activestreest: active_streets,
            reference_activedistricts: activedistricts,
            reference_RefCapacitySteps: RefCapacitySteps,
            reference_tools: tools,
          });
          //console.log(RefCapacitySteps);
          this.setActiveSteps(4, RefCapacitySteps, this.state.checkyes_21);
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
        //alert("შეცდომა ?");
      });
  }

  setActiveSteps = (parent, RefCapacitySteps, presure) => {
    //console.log([parent, RefCapacitySteps, presure]);
    let activeSteps = [];
    RefCapacitySteps.forEach((element) => {
      if (element.parentId == parent) {
        if (presure === true || presure === 'true') {
          if (element.days <= 10) activeSteps.push({ value: element.id, label: element.value });
        } else {
          if (element.days > 10) activeSteps.push({ value: element.id, label: element.value });
        }
      }
    });
    this.setState({ reference_activeSteps: activeSteps });
  };

  selectRef = null;

  clearValue = () => {
    this.selectRef.select.clearValue();
  };

  handlePowerChange = (selectedOption) => {
    let requestedpowerN = '';
    let amountN = '';
    if (selectedOption !== null) {
      switch (selectedOption.value) {
        case 69:
          requestedpowerN = 5.9;
          break;
        case 70:
          requestedpowerN = 9.9;
          break;
        case 71:
          requestedpowerN = 15.9;
          break;
        case 72:
          requestedpowerN = 24.9;
          break;
        case 73:
          requestedpowerN = 39.9;
          break;
        case 74:
          requestedpowerN = 64.9;
          break;
        case 75:
        case 76:
          requestedpowerN = 99.9;
          break;
        case 77:
          requestedpowerN = 159.9;
          break;
        case 78:
          requestedpowerN = 249.9;
          break;
        case 79:
          requestedpowerN = 399.9;
          break;
        case 80:
          requestedpowerN = 649.9;
          break;
        case 81:
          requestedpowerN = 999.9;
          break;
        case 82:
          requestedpowerN = 1599.9;
          break;
        case 83:
          requestedpowerN = 2599.9;
          break;
        case 84:
          requestedpowerN = 3999.9;
          break;
        case 85:
          requestedpowerN = 6399.9;
          break;
        default:
          requestedpowerN = '';
          break;
      }
      var lowpowertext = selectedOption.label;
      var tmp = selectedOption.label.length - 1;
      //console.log(selectedOption.label);
      for (let i = selectedOption.label.length - 1; i > 0; i--) {
        if (selectedOption.label[i] === '/') {
          tmp = i;
          break;
        } else amountN += selectedOption.label[i];
      }
      let tmp1 = selectedOption.label.length - 1;
      for (let j = 0; j < selectedOption.label.length - 1; j++) {
        if (selectedOption.label[j] == ' ') {
          tmp1 = j;
          break;
        }
      }

      lowpowertext = lowpowertext.substr(0, tmp1);
      // lowpowertext=lowpowertext.replace(amountN,'');
      //console.log(lowpowertext)

      amountN = this.reverse(amountN);
      //console.log([amountN,lowpowertext])
      //console.log(amountN);
      this.setState({
        requestedpower: selectedOption.value,
        amountN: amountN,
        requestedpowerN: requestedpowerN,
        requestedpowertext: lowpowertext,
      });
    } else
      this.setState({
        requestedpower: '',
        amountN: '',
        requestedpowerN: requestedpowerN,
        requestedpowertext: '',
      });
  };

  reverse = (s) => {
    return s.split('').reverse().join('');
  };

  handleChange = (input) => (e) => {
    let ph = '';
    if (input.includes('phone')) {
      ph = e.target.value.replace(/\D/g, '');
      if (input === 'phone_1') this.setState({ phone_1: ph });
      else if (input === 'phone_2') this.setState({ phone_2: ph });
      else if (input === 'customer_phone_1') this.setState({ customer_phone_1: ph });
      else this.setState({ customer_phone_2: ph });
    } else if (input === 'recaptchaCode') {
      //console.log(e)
      this.setState({ recaptchaCode: e });
    } else {
      if (!this.state.checked) {
        if (input === 'customer_firstname') {
          this.setState({ customer_lastname: e.target.value });
        }
      }
      this.setState({ [input]: e.target.value });
    }
  };

  handleFileChange = (input) => (event) => {
    if (input === 'otherdocumentsfiles') {
      if (event.target.files.length > 3) {
        this.handleSwalPopup(
          'ფაილების მაქსიმალური რაოდენობა არის 3',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
        document.getElementById('otherdocumentsfiles').value = '';
        //this.setState({[input]:[]});
      } else {
        this.setState({ [input]: event.target.files });
      }
    } else {
      this.setState({ [input]: event.target.files[0] });
    }
  };

  handleRepeat = () => {
    if (this.state.checked) {
      if (typeof this.props.params.user !== 'undefined') {
        const data = this.props.params.user;
        this.setState({
          customer_firstname: data.name,
          customer_personal: this.state.personal,
          customer_lastname: data.lastName,
          customer_phone_1: this.state.phone_1,
          customer_phone_2: this.state.phone_2,
          customer_email_1: this.state.email_1,
          customer_email_2: this.state.email_2,
        });
      }
    } else {
      this.setState({
        customer_phone_1: this.state.phone_1,
        customer_phone_2: this.state.phone_2,
        customer_email_1: this.state.email_1,
        customer_email_2: this.state.email_2,
      });
    }
  };

  handleCheck_1 = (name) => {
    switch (name) {
      case 'checkyes_24':
        this.setState({ checkyes_24: !this.state.checkyes_24 });
        break;
      case 'checkyes_4':
        this.setState({ checkyes_4: !this.state.checkyes_4 });
        break;
      case 'checkyes_5':
        this.setState({ checkyes_5: !this.state.checkyes_5 });
        break;
      case 'checkyes_25':
        this.setState({ checkyes_25: !this.state.checkyes_25 });
        break;
      case 'checkyes_23':
        this.setState({ checkyes_23: !this.state.checkyes_23 });
        break;
      case 'techterms':
        this.setState({ techterms: !this.state.techterms });
        break;
      case 'techtermscomp':
        this.setState({ techtermscomp: !this.state.techtermscomp });
        break;
      case 'checkyes_21':
        this.setState({ checkyes_21: !this.state.checkyes_21 });
        break;
      case 'checkyes_221':
        this.setState({ checkyes_221: !this.state.checkyes_221 });
        break;
      case 'agree':
        this.setState({ agree: !this.state.agree });
        break;
      default:
        break;
    }
  };

  handleCheck = (e) => {
    //console.log(e.target.value)

    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'checkyes_21') {
      this.setActiveSteps(4, this.state.reference_RefCapacitySteps, e.target.value);
      this.setState({ requestedpowerN: '' });
    }
  };

  handlediv = () => {
    this.setState({ onetomulti: !this.state.onetomulti });
  };

  handleSubmit = () => {
    //document.getElementById("SendButton").disabled=true;
    let isValid = this.formValidate();

    let data = [];

    if (isValid) {
      for (const [key, value] of Object.entries(this.state)) {
        let obj = {};
        if (!key.includes('error') && !key.includes('refe') && !key.includes('recap')) {
          if (value !== '' && value !== null) {
            obj.key = key;
            obj.value = value;
            data.push(obj);
          }
        }
      }
      this.SendLetter(this.state.custN, this.props.letterTypeId, this.state.recaptchaCode, data);
    } else {
      //var filesError = this.state.errors.filter(x=>x.key==="filesSize"); if(filesError.length) filesError=filesError[0].value;
      if (this.state.errorFileSize) {
        this.handleSwalPopup(
          'ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      } else {
        this.handleSwalPopup(
          'შესავსებია სავალდებულო ველები',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      }
    }
    //document.getElementById("SendButton").disabled=false;;
  };

  formValidate() {
    const values = this.state;
    //document.getElementById('SendButton').disabled=true;
    let data = [];

    for (const [key, value] of Object.entries(values)) {
      if (!key.includes('recap') && !key.includes('refe') && !key.includes('error')) {
        let obj = {};
        obj.name = key;
        obj.value = value;
        if (
          key === 'fullname' ||
          key === 'personal' ||
          key === 'phone_1' ||
          key === 'file_1' ||
          key === 'address' ||
          key === 'customer_firstname' ||
          key === 'customer_personal' ||
          key === 'customer_phone_1' ||
          key === 'cadastral' ||
          key === 'requestedpower' ||
          key === 'amountN' ||
          key === 'requestedpowerN'
        )
          obj.Required = true;
        else if (key === 'customer_lastname') {
          if (this.state.checked == true) obj.Required = true;
          else obj.Required = false;
        } else if (key === 'file_agree') {
          if (values.checkyes_23) obj.Required = true;
        } else if (key === 'file_8') {
          if (values.checkyes_24) obj.Required = true;
        } else if (
          key === 'addressto' ||
          key === 'cadastral' ||
          key === 'responsible' ||
          key === 'responsible_personal'
        ) {
          if (values.onetomulti) obj.Required = true;
        } else if (
          key === 'count' ||
          key === 'customerscount' ||
          key === 'smartmeterscount' ||
          key === 'household' ||
          key === 'nonhousehold' ||
          key === 'otherhousehold' ||
          key === 'sumpower' ||
          key === 'danarti1_1'
        ) {
          if (!values.onetomulti) obj.Required = true;
        } else obj.Required = false;

        data.push(obj);
      }
    }

    let result = this.letterFormValidator(this.props.letterTypeId, data);
    const found = result.errors.find((x) => x.key === 'filesSize');
    if (typeof found !== 'undefined') {
      //console.log(1234);
      this.state.errorFileSize = true;
      this.setState({ errors: result.errors });
      //this.handleSwalPopup("ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს","warning",false,"","დახურვა",false,"#262262");
    } else {
      this.state.errorFileSize = false;
      this.setState({ errors: result.errors });
    }
    //document.getElementById('SendButton').disabled=false;
    return result.isValid;
  }

  handlePhysicalSwitch = () => {
    if (this.state.checked) {
      this.setState({
        checked: !this.state.checked,
        customer_firstname: '',
        customer_lastname: '',
        customer_personal: '',
      });
    } else {
      this.setState({ checked: !this.state.checked });
    }
  };

  errorExsist = (key) => {
    let bool = this.state.errors.some((obj) => obj.key == key);
    return bool;
  };

  renderErrorText = (key) => {
    let bool = this.state.errors.find((obj) => obj.key.includes(key) === true);
    return bool.value;
  };

  render() {
    const values = this.state;
    let phone, email;
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      phone = data.phones.filter((x) => x.typeKey === 1);
      email = data.emails[0];
    }
    let l = true;
    if (values.reference_FieldsDescriptions.length === 0) l = false;
    if (values.lowpower !== 1) {
      this.state.checkyes_15 = false;
    }
    //console.log(values.reference_activeSteps)
    return (
      <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div style={{ float: 'left' }}>
                <ToLetters></ToLetters>
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>
                  <strong>{this.props.letterTitle}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>განმცხადებელი</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სახელი და გვარი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      readOnly={this.props.params.user.fullName ? true : false}
                      placeholder="სახელი გვარი/ორგანიზაციის დასახელება"
                      onChange={this.handleChange('fullname')}
                      value={values.fullname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'fullname'}
                      />
                    ) : null}
                    {this.errorExsist('fullname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('fullname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-4 col-form-label">
                    <strong>პირადი #/საიდენტიფიკაციო #:</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="personal"
                      placeholder="პირადი ნომერი"
                      readOnly={this.props.params.user.personalId ? true : false}
                      onChange={this.handleChange('personal')}
                      value={values.personal}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'personal'}
                      />
                    ) : null}
                    {this.errorExsist('personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მისამართი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      placeholder="მისამართი"
                      readOnly={this.props.params.user.registrationAddress ? true : false}
                      onChange={this.handleChange('address')}
                      value={values.address}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'address'}
                      />
                    ) : null}
                    {this.errorExsist('address') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('address')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      readOnly={
                        typeof phone[0].info !== 'undefined' && phone[0].info ? true : false
                      }
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_1"
                      onChange={this.handleChange('phone_1')}
                      value={values.phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_2"
                      onChange={this.handleChange('phone_2')}
                      value={values.phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      readOnly={typeof email !== 'undefined' ? true : false}
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_1"
                      onChange={this.handleChange('email_1')}
                      value={values.email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_2"
                      onChange={this.handleChange('email_2')}
                      value={values.email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_2'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>ინფორმაცია აბონენტის შესახებ</strong>
              <div className="card-header-actions">
                <button className="btn btn-primary btn-sm" onClick={this.handleRepeat}>
                  განმცხადებლის მონაცემების გადმოტანა
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <BootstrapSwitchButton
                  width={200}
                  checked={values.checked}
                  onlabel="ფიზიკური"
                  offlabel="იურიდიული"
                  onChange={this.handlePhysicalSwitch}
                ></BootstrapSwitchButton>
                <br />
                <br />
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'სახელი' : 'დასახელება'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_firstname')}
                      placeholder={values.checked ? 'სახელი' : 'დასახელება'}
                      value={values.customer_firstname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_firstname'}
                      />
                    ) : null}
                    {this.errorExsist('customer_firstname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_firstname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {values.checked ? (
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გვარი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('customer_lastname')}
                        value={values.customer_lastname}
                        placeholder="გვარი"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_lastname'}
                        />
                      ) : null}
                      {this.errorExsist('customer_lastname') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_lastname')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_personal')}
                      value={values.customer_personal}
                      placeholder={values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_personal'}
                      />
                    ) : null}
                    {this.errorExsist('customer_personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ძირითადი"
                      name="customer_phone_1"
                      onChange={this.handleChange('customer_phone_1')}
                      value={values.customer_phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ალტერნატიული"
                      name="customer_phone_2"
                      onChange={this.handleChange('customer_phone_2')}
                      value={values.customer_phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ძირითადი"
                      name="customer_email_1"
                      onChange={this.handleChange('customer_email_1')}
                      value={values.customer_email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ალტერნატიული"
                      name="customer_email_2"
                      onChange={this.handleChange('customer_email_2')}
                      value={values.customer_email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_2'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>საბანკო რეკვიზიტები</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('bankdetails')}
                      placeholder="აბონენტის საბანკო რეკვიზიტები"
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'bankdetails'}
                      />
                    ) : null}
                    {this.errorExsist('bankdetails') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('bankdetails')}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>ძირითადი ინფორმაცია</strong>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>გესაჭიროებათ საგადასახადო ანგარიშ-ფაქტურა</strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck_1('checkyes_4');
                      }}
                      checked={this.state.checkyes_4}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck_1('checkyes_4');
                      }}
                      checked={!this.state.checkyes_4}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_4'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    განაწილების ლიცენზიატის მიერ ამ განაცხადზე შეტყობინების გაგზავნის ფორმა
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck_1('checkyes_5');
                      }}
                      checked={this.state.checkyes_5}
                    ></input>
                    &nbsp;წერილობითი
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck_1('checkyes_5');
                      }}
                      checked={!this.state.checkyes_5}
                    ></input>
                    &nbsp;ელექტრონული
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_5'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-12 col-form-label">
                  <strong>
                    ახალი მომხმარებლის გამანაწილებელ ქსელზე მიერთების ტექნიკური პირობის
                  </strong>
                </label>
                <label className="col-md-4 col-form-label">
                  <strong>N</strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    onChange={() => this.handleChange('techtermsNumber')}
                    placeholder="ტექნიკური პირობის N"
                  ></input>
                </div>
                <br></br>
                <br></br>
                <label className="col-md-4 col-form-label">
                  <strong>გაცემის თარიღი</strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="date"
                    id="date1"
                    placeholder="საიდან"
                    onChange={this.handleChange('date1')}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'date1'}
                    />
                  ) : null}
                  {this.errorExsist('date1') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('date1')}
                    </label>
                  ) : null}
                </div>
                <br></br>
                <br></br>
                <label className="col-md-4 col-form-label">
                  <strong>მოქმედების ვადა</strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="date"
                    id="date2"
                    placeholder="საიდან"
                    onChange={this.handleChange('date2')}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'date2'}
                    />
                  ) : null}
                  {this.errorExsist('date2') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('date2')}
                    </label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>განაცხადი</strong>
            </div>
            <div className="card-body">
              <input
                type="radio"
                id="onecustomer"
                name="onetomulti"
                value={true}
                defaultChecked
                onChange={() => this.handlediv()}
              ></input>
              <label htmlFor="onecustomer">
                &nbsp;შეჭრის სამუშაოების ერთი აბონენტის მიერ მოთხოვნის შემთხვევაში
              </label>
              <br />
              <input
                type="radio"
                id="multicustomer"
                name="onetomulti"
                value={false}
                onChange={() => this.handlediv()}
              ></input>
              <label htmlFor="multicustomer">
                &nbsp;ორი ან ორზე მეტი ობიექტის (მრავალბინიანი საცხოვრებელი სახლის ან სხვა
                დანიშნულების ობიექტის) მიერთების შემთხვევაში{' '}
              </label>
              <br />
              <br />
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>მოთხოვნილი წნევის საფეხური</strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      name="checkyes_21"
                      value={true}
                      defaultChecked
                      onChange={(e) => {
                        this.handleCheck(e);
                      }}
                    ></input>
                    &nbsp;დაბალი
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      type="radio"
                      name="checkyes_21"
                      value={false}
                      onChange={(e) => {
                        this.handleCheck(e);
                      }}
                    ></input>
                    &nbsp;საშუალო
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_21'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>მოთხოვნილი პაკეტი</strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <Select
                    ref={(ref) => {
                      this.selectRef = ref;
                    }}
                    options={values.reference_activeSteps}
                    placeholder="აირჩიეთ"
                    onChange={this.handlePowerChange}
                  />
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'requestedpower'}
                    />
                  ) : null}
                  {this.errorExsist('requestedpower') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('requestedpower')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>მოთხოვნილი სიმძლავრე </strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    onChange={this.handleChange('requestedpowerN')}
                    value={values.requestedpowerN}
                    type="number"
                    min="0"
                    step="0.1"
                    placeholder="მოთხოვნილი სიმძლავრე"
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'requestedpowerN'}
                    />
                  ) : null}
                  {this.errorExsist('requestedpowerN') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('requestedpowerN')}
                    </label>
                  ) : null}
                </div>
              </div>

              {values.onetomulti === true ? (
                <div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>დაზუსტებული მისამართი, სადაც უნდა მოხდეს გაზმომარაგება:</strong>
                      <span style={{ color: '#e55353' }}>*</span>{' '}
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('addressto')}
                        placeholder="მისამართი"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'addressto'}
                        />
                      ) : null}
                      {this.errorExsist('addressto') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('addressto')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        უძრავი ქონების საკადასტრო კოდი, სადაც უნდა მოხდეს გაზმომარაგება:
                      </strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('cadastral')}
                        placeholder="საკადასტრო კოდი"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'cadastral'}
                        />
                      ) : null}
                      {this.errorExsist('cadastral') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('cadastral')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        აბონენტის (მოხმარებული ბ. გაზის საფასურის გადახდაზე პასუხისმგებელი პირის)
                        სახელი, გვარი ან იურიდიული პირის სახელი:
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('responsible')}
                        placeholder="სახელი, გვარი ან  იურიდიული პირის სახელი"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'responsible'}
                        />
                      ) : null}
                      {this.errorExsist('responsible') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('responsible')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>პირადი ნომერი/საიდენტ. კოდი: </strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('responsible_personal')}
                        placeholder="პირადი ნომერი/საიდენტ. კოდი: "
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'responsible_personal'}
                        />
                      ) : null}
                      {this.errorExsist('responsible_personal') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('responsible_personal')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ბუნებრივი გაზის მოხმარების მიზანი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          name="checkyes_17"
                          value={true}
                          defaultChecked
                          onChange={(e) => {
                            this.handleCheck(e);
                          }}
                        ></input>
                        &nbsp;საყოფაცხოვრებო
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          name="checkyes_17"
                          value={false}
                          onChange={(e) => {
                            this.handleCheck(e);
                          }}
                        ></input>
                        &nbsp;არასაყოფაცხოვრებო
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_17'}
                        />
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        {' '}
                        0-6 კუბ.მ/სთ სიმძლავრით მიერთების შემთხვევაში, გსურთ თუ არა ჭკვიანი
                        მრიცხველის მოწყობა:
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          disabled
                          value={false}
                          defaultChecked
                          onChange={() => {
                            this.handleCheck('smartmeter');
                          }}
                        ></input>
                        &nbsp;არა
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          disabled
                          value={true}
                          onChange={() => {
                            this.handleCheck('smartmeter');
                          }}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'smartmeter'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        მშენებლობის ტექნიკური ზედამხედველობის, აღრიცხვის კვანძის მოწყობისა და შეჭრის
                        საფასური (შეთავაზებული პაკეტის მიხედვით)
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('amountN')}
                        type="number"
                        step="0.1"
                        min={0}
                        value={values.amountN}
                        placeholder="საფასური"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'amountN'}
                        />
                      ) : null}
                      {this.errorExsist('amountN') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('amountN')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-12 col-form-label">
                      <strong>
                        განაცხადში მოცემული ჩემი პირადი ნომერი, ტელეფონის ნომერი და ელ. ფოსტის
                        მისამართი :{' '}
                      </strong>
                    </label>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ა)</strong>გამოყენებულ იქნეს გაზმომარაგებასთან დაკავშირებული
                      ინფორმაციის შეტყობინების მიზნით
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          name="checkyes_16"
                          value={true}
                          onChange={(e) => {
                            this.handleCheck(e);
                          }}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          name="checkyes_16"
                          value={false}
                          defaultChecked
                          onChange={(e) => {
                            this.handleCheck(e);
                          }}
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_16'}
                        />
                      ) : null}
                    </div>
                    <br />
                    <label className="col-md-4 col-form-group">
                      <strong>ბ)</strong>მიეწოდოს ელექტროენერგიისა და წყალმომარაგების სექტორში
                      მომსახურე საწარმოებს, სადაც, განაცხადში მოცემულ მისამართზე, რეგისტრირებული ვარ
                      მომხმარებლად და გამაჩნია აბონენტის ნომერი
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          name="checkyes_18"
                          value={true}
                          onChange={(e) => {
                            this.handleCheck(e);
                          }}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          name="checkyes_18"
                          value={false}
                          defaultChecked
                          onChange={(e) => {
                            this.handleCheck(e);
                          }}
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_18'}
                        />
                      ) : null}
                      <br></br>
                      {/* {
                                            this.state.checkyes_18 ?
                                            (
                                                <React.Fragment>
                                                    <input className="form-control" name="telasi" onChange={this.handleChange("telasi")}></input>
                                                    {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"telasi"} />:null}
                                                    {
                                                        this.errorExsist("telasi") ?(
                                                            <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("telasi")}</label>
                                                            ):null
                                                    }
                                                    <br></br>
                                                    <input className="form-control" name="gwp" onChange={this.handleChange("gwp")}></input>
                                                    {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"gwp"} />:null}
                                                    {
                                                        this.errorExsist("gwp") ?(
                                                            <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("gwp")}</label>
                                                            ):null
                                                    }
                                                </React.Fragment>
                                            )
                                            :null
                                        } */}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>დამოუკიდებელი ობიექტების რაოდენობა</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('count')}
                        placeholder="რაოდენობა"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'count'}
                        />
                      ) : null}
                      {this.errorExsist('count') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('count')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        განაცხადით მოთხოვნილი აბონენტების (ინდ. აღრიცხვა) საერთო რაოდენობა
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('customerscount')}
                        placeholder="რაოდენობა"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customerscount'}
                        />
                      ) : null}
                      {this.errorExsist('customerscount') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customerscount')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        მათ შორის, 0-6 კუბ.მ/სთ სიმძლავრის ჭკვიანი მრიცხველის მოთხოვნის შემთხვევაში,
                        მოთხოვნილი ჭკვიანი მრიცხველების რაოდენობა
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('smartmetercount')}
                        placeholder="რაოდენობა"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'smartmetercount'}
                        />
                      ) : null}
                      {this.errorExsist('smartmetercount') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('smartmetercount')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-12 col-form-label">
                      <strong>საყოფაცხოვრებო და არასაყოფაცხოვრებო აბონენტთა რაოდენობა:</strong>
                    </label>
                    <label className="col-md-4 col-form-label">
                      <strong>საყოფაცხოვრებო</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('household')}
                        placeholder="რაოდენობა"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'household'}
                        />
                      ) : null}
                      {this.errorExsist('household') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('household')}
                        </label>
                      ) : null}
                    </div>
                    <br />
                    <br />
                    <label className="col-md-4 col-form-label">
                      <strong>არასაყოფაცხოვრებო</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('nonhousehold')}
                        placeholder="რაოდენობა"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'nonhousehold'}
                        />
                      ) : null}
                      {this.errorExsist('nonhousehold') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('nonhousehold')}
                        </label>
                      ) : null}
                    </div>
                    <br />
                    <br />
                    <label className="col-md-4 col-form-label">
                      <strong>განცალკევებული აღრიცხვა</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('otherhousehold')}
                        placeholder="რაოდენობა"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'otherhousehold'}
                        />
                      ) : null}
                      {this.errorExsist('otherhousehold') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('otherhousehold')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        საცხოვრებელი სახლების, საწარმოების ან სხვა სახის ობიექტების ჯამური
                        მისაერთებელი სიმძლავრე
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('sumpower')}
                        placeholder="სიმძლავრე"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'sumpower'}
                        />
                      ) : null}
                      {this.errorExsist('sumpower') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('sumpower')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        დამოუკიდებელი შენობა-ნაგებობების დეტალური მონაცემები მოცემულია დანართის
                        სახით (დანართი1.1)
                      </strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg"
                        onChange={this.handleFileChange('danarti1_1')}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'danarti1_1'}
                        />
                      ) : null}
                      {this.errorExsist('danarti1_1') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('danarti1_1')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-12 col-form-label">
                      <strong>
                        მისაერთებელი ობიექტის დეტალური მონაცემები და მოთხოვნები დანართი 1.1-ის
                        მიხედვით
                      </strong>
                    </label>
                    <label className="col-md-4 col-form-label">
                      <strong> თანდართული ცხრილების რაოდენობა</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('tablescount')}
                        placeholder="რაოდენობა"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'tablescount'}
                        />
                      ) : null}
                      {this.errorExsist('tablescount') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('tablescount')}
                        </label>
                      ) : null}
                    </div>
                    <br></br>
                    <br></br>
                    <label className="col-md-4 col-form-label">
                      <strong> ცხრილების გვერდების ჯამური რაოდენობა</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('pagescount')}
                        placeholder="რაოდენობა"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'pagescount'}
                        />
                      ) : null}
                      {this.errorExsist('pagescount') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('pagescount')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    უძრავი ქონების მესაკუთრის წერილობითი თანხმობა (მონიშნეთ ობიექტის დროებით ფლობის
                    შემთხვევაში)
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('checkyes_23')}
                      checked={values.checkyes_23}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('checkyes_23')}
                      checked={!values.checkyes_23}
                    ></input>
                    &nbsp;არა
                  </label>
                  &nbsp;
                  {values.checkyes_23 ? (
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_agree')}
                    ></input>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    აღრიცხვის კვანძის მოწყობის საფასურის გადახდის დამადასტურებელი საბუთი (მონიშნეთ
                    წინასწარ გადახდილი საფასურის ოდენობა)
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('checkyes_221')}
                      checked={values.checkyes_221}
                    ></input>
                    &nbsp;50%
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('checkyes_221')}
                      checked={!values.checkyes_221}
                    ></input>
                    &nbsp;100%
                  </label>
                  &nbsp;
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg"
                    onChange={this.handleFileChange('file_9')}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    ახალი მომხმარებლის გამანაწილებელ ქსელზე მიერთების ტექნიკური პირობა
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('techterms')}
                      checked={values.techterms}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('techterms')}
                      checked={!values.techterms}
                    ></input>
                    &nbsp;არა
                  </label>
                  &nbsp;
                  {values.techterms && (
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('techtermsfile')}
                    ></input>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    ობიექტის მიერთების გამართულობისა და ტექნიკური პირობასთან შესაბამისობის
                    დამადასტურებელი დოკუმენტები
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('techtermscomp')}
                      checked={values.techtermscomp}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('techtermscomp')}
                      checked={!values.techtermscomp}
                    ></input>
                    &nbsp;არა
                  </label>
                  &nbsp;
                  {values.techtermscomp && (
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('techtermscompfile')}
                    ></input>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    თანდართულია მისაერთებელი ობიექტის შიდა ქსელის გაზმომარაგების მზადყოფნის
                    დამადასტურებელი დოკუმენტი
                    <br />
                    გაფრთხილება: მიერთების დასრულებამდე აღნიშნული დოკუმენტის განაწილების
                    ლიცენზიატისთვის წარუდგენლობის შემთხვევაში გაზმომარაგება არ დაიწყება
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('checkyes_24')}
                      checked={values.checkyes_24}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck_1('checkyes_24')}
                      checked={!values.checkyes_24}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_24'}
                    />
                  ) : null}
                  <br />
                  {values.checkyes_24 ? (
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_8')}
                    ></input>
                  ) : null}
                  {this.errorExsist('file_8') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('file_8')}
                    </label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>თანდართული დოკუმენტაცია (მხოლოდ pdf/jpg ფაილები, ჯამში მაქსიმუმ 14MB)</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>
                      პირადობის მოწმობის ან პასპორტის ასლი
                      <span style={{ color: '#e55353' }}> *</span>
                    </strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_1')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_1'}
                      />
                    ) : null}
                    {this.errorExsist('file_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სხვა დოკუმენტები</strong>
                  </label>
                  <div className="col-md-8">
                    {/* <input className="form-control" onChange={this.handleChange("otherdocuments")} placeholder="სხვა დოკუმენტები"></input>
                     */}
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      id="otherdocumentsfiles"
                      onChange={this.handleFileChange('otherdocumentsfiles')}
                      multiple
                    ></input>

                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'otherdocumentsfiles'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-md-12" style={{ textAlign: 'right' }}>
                  <label>
                    <input
                      type="checkbox"
                      style={{ verticalAlign: 'middle' }}
                      checked={values.agree}
                      onChange={() => this.handleCheck_1('agree')}
                    ></input>
                    &nbsp;ვეთანხმები
                    <span
                      style={{ color: '#262262', cursor: 'pointer' }}
                      onClick={this.handleLetterTermsModal}
                    >
                      &nbsp;<strong>წესებსა და პირობებს</strong>
                    </span>
                  </label>
                </div>
                <br />
                <br />
                <div className="col-md-12 col-sm-12">
                  <ReCAPTCHA
                    style={{ float: 'right' }}
                    sitekey={this.props.params.siteKey}
                    onChange={this.handleChange('recaptchaCode')}
                    hl="ka"
                  ></ReCAPTCHA>
                </div>
                <br />
                <br />
                <div className="col-md-9 col-sm-12"></div>
                <div className="col-md-3 col-sm-12">
                  <button
                    className="btn btn-primary"
                    id="SendButton"
                    style={{ float: 'right', backgroundColor: '#262262', color: 'white' }}
                    disabled={this.state.recaptchaCode && this.state.agree ? false : true}
                    onClick={this.handleSubmit}
                  >
                    გაგზავნა
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConnectToNetwork;
