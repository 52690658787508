import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import Profile from './Profile';
import Home from './Home';
import Letters from './Letters';
import Abonents from './Abonents';
import TeLogo from '../../logo.png';
import Notifications from './Notifications';
import Sms from './Detailed/Sms';
import Email from './Detailed/Emails';
import MyLetters from './MyLetters';
import AbonentDetailed from './AbonentDetailed';
import ReactNotification from 'react-notifications-component';
import Payment from './Payment';
import PaymentCustomer from './PaymentCustomer';
import '../../fonts/stylesheet.css';
import Fail from './payment/PayFail';
import Success from './payment/PaySuccess';
import AbonentRegister from './Letters/AbonentRegister';
import OldAbonentRegister from './Letters/OldAbonentRegister';
import ConnectDistributionNetwork from './Letters/ConnectDistributionNetwork';
//import AddPoint from './Letters/AddPoint';
import ConnectNewCustomer from './Letters/ConnectNewCustomer';
import ConnectNewCustomers from './Letters/ConnectNewCustomers';
import AbonentInfo from './Letters/AbonentInfo';
import AccountingUpdate from './Letters/AccountingUpdate';
import CheckingCounter from './Letters/CheckingCounter';
import ConnectAbonentFromDivide from './Letters/ConnectAbonentFromDivide';
import DebtRedistribution from './Letters/DebtRedistribution';
import DetectorMontage from './Letters/DetectorMontage';
import InternalNetworkInstallation from './Letters/InternalNetworkInstallation';
import InternalNetworkUpdate from './Letters/InternalNetworkUpdate';
import MoveCounterTube from './Letters/MoveCounterTube';
import MovingAmount from './Letters/MovingAmount';
import PowerIncrease from './Letters/PowerIncrease';
import ProjectCorrecting from './Letters/ProjectCorrecting';
import TemporaryCustomer from './Letters/TemporaryCustomer';
import TerminationRecovery from './Letters/TerminationRecovery';
import AmountBack from './Letters/AmountBack';
import AmountBackFromInstall from './Letters/AmountBackFromInstall';
import AmountCorrecting from './Letters/AmountCorrecting';
import MovingAmountToNumber from './Letters/MovingAmountToNumber';
import AmountDeWrite from './Letters/AmountDeWrite';
import NotFound from './NotFound';
import Help from './Help';
import BottomLogo from '../../../src/bottomleftimg.png';
import BookVisit from './BookVisit';
import CancelAppliccation from './Letters/CancelAppliccation';
import CancelAbonentInfo from './Letters/CancelAbonentInfo';
import SmartMeter from './Letters/SmartMeter';
import swal from 'sweetalert2';
import { Button, Modal } from 'react-bootstrap';
import renderHTML from 'react-render-html';
import TechTerms from './Letters/TechTerms';
import chatLogo from '../../chat.png';
import $ from 'jquery';
import jsStringEscape from 'js-string-escape';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { read_cookie, delete_cookie } from 'sfcookies';
import LetterTerms from './LetterTerms';
import TeChat from './TEchat';
import MobilePayment from './PaymentMobile';
import ScrollToTop from '../ScrollToTop';
import Lightbox from 'react-image-lightbox';
import ConnectToNetwork from './Letters/ConnectToNetwork';
import OtherLetter from './Letters/OtherLetter';
import CurrentWorks from './MenuPages/CurrentWorks';
import ClarificationOfCommunications from './Letters/ClarificationOfCommunications';
import { is } from 'date-fns/locale';
// import SlideMenu from 'react-slide-menu';
// import Helmet from "react-helmet";
// import DocumetnPrint from './DocumentPrint';
// import MessengerCustomerChat from 'react-messenger-customer-chat';

class Navrbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      didmount: false,
      letterGroups: {},
      lettertypes: {},
      showNavbar: true,
      mobileShowNavbar: false,
      active: '',
      bool: false,
      showModal: false,
      currentModalHtml: '',
      currentModalHeader: '',
      priceModal: false,
      currentPriceModalHtml: '',
      toGrid: false,
      letterTerms: false,
      redirect: false,
      payredirect: false,
      confirmationModal: false,
      confirmationCode0: '',
      confirmationCode1: '',
      confirmationCode2: '',
      confirmationCode3: '',
      confirmationCodeerror: '',
      lightBox: false,
      photoIndex: 0,
      dt: [],
      customerNumber: '',
      letTypeId: '',
      recap: '',
    };
  }

  componentDidMount() {
    //const script = document.createElement("script"); script.src = "https://some-scripturl.js";
  }

  handleTypingChange = (input) => (e) => {
    //console.log([input,e])
    this.setState({ [input]: e.target.value });
  };

  chechGeorgianLetters = (s) => {
    s = s.toLowerCase();
    const letters = [
      'q',
      'w',
      'e',
      'r',
      't',
      'y',
      'u',
      'i',
      'o',
      'p',
      'a',
      's',
      'd',
      'f',
      'g',
      'h',
      'j',
      'k',
      'l',
      'z',
      'x',
      'c',
      'v',
      'b',
      'n',
      'm',
    ];

    let count = 0;
    letters.forEach((element) => {
      count += s.split(element).length - 1;
    });

    if (count > 0) return false;

    if (count === 1) {
      if (!letters.includes(s[s.length - 1])) return false;
    }

    return true;
  };

  isHTML = (str) => {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
  };

  renderListitems = (id) => {
    const list = this.props.values.lettertypes.filter((x) => x.id === id);

    const details = list[0];
    //var temp = details.descDueDate;
    //console.log(temp.includes("null"));
    details.descTarget !== null
      ? (details.descTarget = `<p>${details.descTarget}</p>`)
      : (details.descTarget = '<p></p>');
    details.descProcedure !== null
      ? (details.descProcedure = `<p>${details.descProcedure}</p>`)
      : (details.descProcedure = '<p></p>');
    details.descDueDate !== null
      ? (details.descDueDate = `<p>${details.descDueDate}</p>`)
      : (details.descDueDate = '<p></p>');
    // if(id===70){
    //     return(
    //         <React.Fragment>
    //         </React.Fragment>
    //     )
    // }
    return (
      <React.Fragment>
        <li style={{ textAlign: 'left' }}>
          <strong style={{ fontFamily: 'BPGCaps' }}>მიზნობრიობა</strong>
          <br />
          <label>{renderHTML(details.descTarget)}</label>
        </li>
        <li style={{ textAlign: 'left' }}>
          <strong style={{ fontFamily: 'BPGCaps' }}>პროცედურა</strong>
          <br />
          <label>{renderHTML(details.descProcedure)}</label>
        </li>
        <li style={{ textAlign: 'left' }}>
          {id === 41 || id === 44 || id === 50 || id === 60 ? (
            <React.Fragment>
              <strong style={{ fontFamily: 'BPGCaps' }}>შესრულების ვადა</strong>
              <br />
              {/* <button className="btn btn-primary btn-pill" onClick={()=>this.handleModal(id)} >ცხრილი</button> */}
              <a
                style={{ cursor: 'pointer', color: '#262262', textDecoration: 'underline' }}
                title="ნახვა"
                onClick={(event) => {
                  event.preventDefault();
                  this.handleModal(id, true);
                }}
                data-target="#myModal"
                data-section="section-1"
              >
                ცხრილი
              </a>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <strong style={{ fontFamily: 'BPGCaps' }}>შესრულების ვადა</strong>
              <br />
              <label>{renderHTML(details.descDueDate)}</label>
            </React.Fragment>
          )}
        </li>
        <br />
        <li style={{ textAlign: 'left' }}>
          <strong style={{ fontFamily: 'BPGCaps' }}>ღირებულება</strong>
          <br />
          {details.descPrice.length > 100 ? (
            id === 41 || id === 44 || id === 50 || id === 60 ? (
              <React.Fragment>
                {/* <strong style={{fontFamily:"BPGCaps"}}>შესრულების ვადა</strong> */}
                {/* <br/> */}
                {/* <button className="btn btn-primary btn-pill" onClick={()=>this.handleModal(id)} >ცხრილი</button> */}
                <a
                  style={{ cursor: 'pointer', color: '#262262', textDecoration: 'underline' }}
                  title="ნახვა"
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleModal(id, true);
                  }}
                  data-target="#myModal"
                  data-section="section-1"
                >
                  ცხრილი
                </a>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* <strong style={{fontFamily:"BPGCaps"}}>შესრულების ვადა</strong> */}
                {/* <br/> */}
                <label>{renderHTML(details.descPrice)}</label>
              </React.Fragment>
            )
          ) : (
            // <button className="btn btn-primary btn-pill" onClick={()=>this.handlePriceModal(id)}>ნახვა</button>
            <label>{renderHTML(details.descPrice)}</label>
          )}
          {/* <label>{renderHTML(details.descPrice)}</label> */}
        </li>
        <br />
        {id == 60 && (
          <>
            <li style={{ textAlign: 'left' }}>
              <strong style={{ fontFamily: 'BPGCaps' }}>დანართი</strong>
              <br />
              <a
                href="https://my.te.ge/uploads/დანართი1.docx"
                style={{ cursor: 'pointer', color: '#262262', textDecoration: 'underline' }}
              >
                დანართი 1
              </a>
              <br />
              <a
                href="https://my.te.ge/uploads/დანართი1Excel.xlsx"
                style={{ cursor: 'pointer', color: '#262262', textDecoration: 'underline' }}
              >
                დანართი 1 Excel
              </a>
            </li>
            <br />
          </>
        )}
      </React.Fragment>
    );
  };

  handleModal = (id, toGrid) => {
    //console.log(this.props.values.lettertypes)
    //console.log(id)
    let data = '';
    if (id) {
      data = this.props.values.lettertypes.find((x) => x.id === id);
    }
    //console.log(data);
    this.setState({
      showModal: !this.state.showModal,
      currentModalHtml: id ? data.description : '',
      currentModalHeader: id ? data.name : '',
      toGrid: toGrid ? true : false,
    });
  };

  handleLightBox = (id) => {
    //e.preventDefault();
    this.setState({ lightBox: !this.state.lightBox });
  };

  handlePriceModal = (id) => {
    let data = '';
    if (id) {
      data = this.props.values.lettertypes.find((x) => x.id === id);
    }
    this.setState({
      priceModal: !this.state.priceModal,
      currentPriceModalHtml: id ? `<p>${data.descPrice}</p>` : '',
    });
  };

  handleConfirmationModal = () => {
    this.setState({ confirmationModal: !this.state.confirmationModal });
  };

  //განაცხადზე წესების და პირობების მოდალი
  handleLetterTermsModal = () => {
    this.setState({ letterTerms: !this.state.letterTerms });
  };

  //ინპუტებზე აბონენტის ნომრის სია
  renderCustomersOptions = () => {
    const { values } = this.props;
    let customers = [];
    for (var i = 0; i < values.customers.length; i++) {
      customers.push(values.customers[i].custNumber + '-' + values.customers[i].controlNumber);
    }
    return customers.length > 0 ? (
      <React.Fragment>
        {customers.map((val, index) => {
          return <option key={index}>{val}</option>;
        })}
      </React.Fragment>
    ) : null;
  };

  //განაცხადის გაგზავნა
  SendLetter = (custN, letterTypeId, recaptchaCode, data, dismiss) => {
    const token = read_cookie('Bearer_Token');
    let formData = new FormData();
    const url = window.$name;

    //console.log([custN, letterTypeId, recaptchaCode, data]);
    if (typeof custN !== 'undefined') {
      if (custN !== null && custN !== '') custN = custN.replace('undefined', '');
    }

    if (this.state.dt.length === 0) {
      //console.log(1);

      this.setState({ customerNumber: custN });
    }
    this.state.customerNumber = custN;
    this.state.letTypeId = letterTypeId;
    this.state.dt = data;
    this.state.confirmationCodeerror = '';
    this.state.recap = recaptchaCode;
    //console.log([this.state.customerNumber,this.state.letTypeId,this.state.recap,this.state.dt])
    if (typeof this.state.customerNumber !== 'undefined')
      formData.append('CustomerNumber', this.state.customerNumber);
    if (typeof this.state.confirmationCode !== '')
      formData.append(
        'SmsCode',
        this.state.confirmationCode0 +
          this.state.confirmationCode1 +
          this.state.confirmationCode2 +
          this.state.confirmationCode3
      );
    formData.append('RefLetterTypeId', this.state.letTypeId);
    formData.append('RecaptchaCode', this.state.recap);

    this.state.dt.forEach((element) => {
      if (element.key.includes('file') && element.key !== 'otherdocumentsfiles') {
        formData.append(element.key, element.value);
      } else if (element.key === 'otherdocumentsfiles') {
        let cnt = 0;
        for (const [name, val] of Object.entries(element.value)) {
          if (name !== '' && val !== '') {
            formData.append('other_' + cnt, val);
            cnt++;
          }
        }
      } else if (element.key === 'otherdocuments') {
        for (let i = 0; i < element.value.length; i++) {
          formData.append('other_' + i, element.value[i]);
        }
      } else if (element.key === 'tools') {
        let val = JSON.stringify(element.value);
        val = jsStringEscape(val);
        formData.append('Fields[]', '{key:"' + element.key + '",value:"' + val + '"}');
      } else if (element.key === 'customersList') {
        let val = JSON.stringify(element.value);
        val = jsStringEscape(val);
        //console.log(val);
        formData.append('Fields[]', '{key:"' + element.key + '",value:"' + val + '"}');
      } else if (element.key === 'specified') {
        for (var i = 0; i < element.value.length; i++) {
          var data = [];
          var obj = {};
          for (const [name, val] of Object.entries(element.value[i])) {
            if (name === 'preparedfile') formData.append(name + '_' + i, val);
            else obj[name] = val;
          }
          data.push(obj);
          var val1 = JSON.stringify(data);
          val1 = jsStringEscape(val1);
          formData.append('Fields[]', '{key:"' + 'specified_' + i + '",value:"' + val1 + '"}');
        }
      } else {
        let val = element.value;
        //if(element.key==="custN") val=val.replace('undefined','');
        val = jsStringEscape(val);
        formData.append('Fields[]', '{key:"' + element.key + '",value:"' + val + '"}');
      }
    });

    axios
      .post(`${url}/api/Letters/AddLetterDocument`, formData, {
        headers: {
          Token: token,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          if (this.state.confirmationModal === true) {
            this.setState({ confirmationModal: false });
          }
          if (letterTypeId === 39) {
            let c = custN.substr(0, 6);
            if (dismiss) this.handleDismiss(c, false);
          }
          swal
            .fire({
              icon: 'success',
              title: `<span style="font-family:BPGCond;">განაცხადი დარეგისტრირებულია!</span>`,
              html: `<span style="font-family:BPGCond;">რამდენიმე წუთში მოხდება განაცხადის მიღება რის შესახებაც შეტყობინებას მიიღებთ SMS ის სახით, ასევე განაცხადის პროგრესს შეგიძლიათ თვალყური ადევნოთ განაცხადების სიაში.</span>`,
              confirmButtonText: `<span style="font-family:BPGCond;">კარგი</span>`,
              confirmButtonColor: '#262262',
              onClose: () => {
                window.location.replace('/dashboard/MyLetters');
              },
            })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.replace('/dashboard/MyLetters');
              }
            });
          // window.location.replace("/dashboard/MyLetters");
        } else {
          if (res.data.code === 1) {
            this.handleConfirmationModal();
          } else if (res.data.code === -13) {
            this.setState({ confirmationCodeerror: 'არასწორი კოდი' });
          } else if (res.data.code === -7) {
            store.addNotification({
              title: 'შეცდომა',
              message: 'ფაილის არასწორი ფორმატი',
              type: 'warning',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            window.grecaptcha.reset();
          } else if (res.data.code === -14) {
          } else {
            store.addNotification({
              title: 'შეცდომა',
              message: res.data.message,
              type: 'warning',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            window.grecaptcha.reset();
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
        window.grecaptcha.reset();
      });
  };

  sendData = () => {
    if (
      this.state.confirmationCode0 === '' ||
      this.state.confirmationCode1 === '' ||
      this.state.confirmationCode2 === '' ||
      this.state.confirmationCode3 === ''
    ) {
      this.setState({ confirmationCodeerror: 'შეავსეთ ველები' });
    } else {
      this.SendLetter(
        this.state.customerNumber,
        this.state.letTypeId,
        this.state.recap,
        this.state.dt
      );
    }
  };

  //განაცხადების სვიტჩი
  renderSwitch = (eventKey, props, id, title) => {
    //console.log(this.props)
    const { values } = this.props;

    let customers = [];
    for (var i = 0; i < values.customers.length; i++) {
      customers.push(values.customers[i].custNumber + '-' + values.customers[i].controlNumber);
    }

    const infoStyle = {
      fontFamily: 'BPGCond',
      padding: '0',
    };

    const infoParStyle = {
      paddingTop: '15px',
      paddingRight: '30px',
      textAlign: 'justify',
    };

    // const labelColor={
    //     color:"#e55353"
    // }

    const ulStyle = {
      wordBreak: 'break-all',
    };

    switch (id) {
      //AbonentRegister
      case 39:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <AbonentRegister
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                        {/* <li>
                                                    <button className="btn btn-pill btn-primary" type="button" onClick={()=>this.handleLightBox(id)}><i className="fas fa-binoculars"></i>&nbsp;მაგალითი</button>
                                                </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //ConnectDistributionNetwork
      case 40:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <ConnectDistributionNetwork
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //ConnectNewCustomer
      case 41:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <ConnectNewCustomer
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                        <br />
                        {/* <li style={{listStyleType:"none"}}>
                                                    <button className="btn btn-pill btn-primary" type="button" onClick={() => this.setState({ lightBox: true })}><i className="fas fa-binoculars"></i>&nbsp;მაგალითი</button>
                                                </li> */}
                      </ul>
                    </div>
                    {/* <button className="btn btn-pill btn-primary" type="button" onClick={()=>this.handleModal(id,false)}><i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა</button> */}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //AbonentInfo
      case 42:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <AbonentInfo
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //AccountingUpdate
      case 43:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <AccountingUpdate
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //ConnectAbonentFromDivide
      case 44:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <ConnectAbonentFromDivide
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //DebtRedistribution
      case 45:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <DebtRedistribution
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //InternalNetworkInstallation
      case 46:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <InternalNetworkInstallation
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //InternalNetworkUpdate
      case 47:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <InternalNetworkUpdate
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //MoveCounterTube
      case 48:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <MoveCounterTube
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //MovingAmount
      case 49:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <MovingAmount
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //PowerIncrease
      case 50:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <PowerIncrease
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //TemporaryCustomer
      case 51:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <TemporaryCustomer
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //TerminationRecovery
      case 52:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <TerminationRecovery
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //AmountBack
      case 53:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <AmountBack
                  {...props}
                  params={values}
                  customers={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //AmountBackFromInstall
      case 54:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <AmountBackFromInstall
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //AmountCorrecting
      case 55:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <AmountCorrecting
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //MovingAmountToNumber
      case 56:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <MovingAmountToNumber
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //AmountDeWrite
      case 57:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <AmountDeWrite
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //AddPoint +ProjectCorrecting
      case 58:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <ProjectCorrecting
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //CheckingCounter
      case 59:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <CheckingCounter
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //ConnectNewCustomers
      case 60:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <ConnectNewCustomers
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //DetectorMontage
      case 61:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <DetectorMontage
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //OldAbonentRegister
      case 62:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <OldAbonentRegister
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //ProjectCorrecting
      case 69:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <ProjectCorrecting
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //CancelAppliccation
      case 70:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <CancelAppliccation
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //TechTerms
      case 71:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <TechTerms
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //CancelAbonentInfo
      case 72:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <CancelAbonentInfo
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //SmartMeter
      case 73:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <SmartMeter
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //ConnectToNetwork
      case 74:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <ConnectToNetwork
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  checkGeorgianLetters={this.chechGeorgianLetters}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      //OtherLetter
      case 75:
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-8">
                <OtherLetter
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );

      case 76:
        return (
          <>
            <div className="row">
              <div className="col-lg-8">
                <ClarificationOfCommunications
                  {...props}
                  params={values}
                  values={customers}
                  letterTitle={title}
                  handleLetterTermsModal={this.handleLetterTermsModal}
                  renderCustomersOptions={this.renderCustomersOptions}
                  letterFormValidator={this.letterFormValidator}
                  SendLetter={this.SendLetter}
                  handleSwalPopup={this.handleSwalPopup}
                  letterTypeId={id}
                />
              </div>
              <div className="col-lg-4">
                <div className="col-md-12">
                  <div className="card" style={infoStyle}>
                    <div style={infoParStyle}>
                      <ul style={ulStyle}>
                        {this.renderListitems(id)}
                        <li style={{ listStyleType: 'none' }}>
                          <button
                            className="btn btn-pill btn-primary"
                            type="button"
                            onClick={() => this.handleModal(id, false)}
                          >
                            <i className="fas fa-info"></i>&nbsp;სრული განმარტების ნახვა
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  handleSideBar = () => {
    var t = this.state.showNavbar;
    //console.log(["mobile:",this.state.mobileShowNavbar,"resp",this.state.showNavbar]);
    this.setState({ showNavbar: !t, mobileShowNavbar: false });
    if (t) {
      document.getElementById('sidebar').classList.remove('c-sidebar-lg-show');
      document.getElementById('sidebar').classList.remove('c-sidebar-show');
    } else {
      document.getElementById('sidebar').classList.add('c-sidebar-lg-show');
      document.getElementById('sidebar').classList.remove('c-sidebar-show');
    }
  };

  handleMobileSideBar = () => {
    var t = this.state.mobileShowNavbar;
    //console.log(["mobile:",this.state.mobileShowNavbar,"resp",this.state.showNavbar]);
    this.setState({ mobileShowNavbar: !t });
    if (t) {
      document.getElementById('sidebar').classList.remove('c-sidebar-show');
      document.getElementById('sidebar').classList.add('c-sidebar-lg-show');
    } else {
      document.getElementById('sidebar').classList.add('c-sidebar-show');
      //             document.getElementById("sidebar").classList.add("c-sidebar-lg-show");
    }
  };

  fopenNav = () => {
    document.getElementById('mySidebar').style.width = '250px';
    //document.getElementById("main").style.marginLeft = "250px";
  };

  closeNav = () => {
    document.getElementById('mySidebar').style.width = '0';
    //document.getElementById("main").style.marginLeft= "0";
  };

  handleActive = (name) => {
    this.setState({ active: name });
  };
  //განაცხადის ფორმების ვალიდატორი
  letterFormValidator = (letterTypeId, data) => {
    let result = {};
    let errors = [];
    let isValid = true;
    let bool1 = false;
    let bool2 = false;

    //console.log(data);

    data.forEach((element) => {
      let errorObject = {};
      //console.log(element);
      if (element.Required) {
        if (element.name === 'checkyes_14') {
          //console.log(element);
          if (element.value === false) bool1 = true;
        } else if (element.name === 'customersList') {
          //console.log(element.name);
          element.value.forEach((el) => {
            for (const [k, v] of Object.entries(el)) {
              if (v === '' || v === null) {
                //console.log(element);
                isValid = false;
                errorObject.key = element.name;
                errorObject.value = 'აუცილებელია ყველა ველის შევსება';
                //console.log(errorObject);
                break;
              }
            }
          });
        } else if (element.name === 'checkyes_14_1') {
          if (element.value === false) bool2 = true;
          //console.log(element);
        } else if (element.name === 'warranty_deadline') {
          var o = data.find((x) => x.name === 'check_warranty');
          if (o.value === true) {
            if (element.value === '' || element.value === null) {
              isValid = false;
              errorObject.key = element.name;
              errorObject.value = 'აუცილებელია';
            }

            let date = new Date(element.value);
            let today = new Date();
            if (date < today) {
              isValid = false;
              errorObject.key = element.name;
              errorObject.value = 'არასწორი თარიღი';
            }
          }
          //console.log(o);
        } else if (element.name === 'specified') {
          //console.log(element.value);
          if (Array.isArray(element.value) && element.value.length > 0) {
            var midValid = element.value.every((item) => {
              return (
                typeof item === 'object' &&
                Object.values(item).every((value) => {
                  return value !== null && value !== '' && value !== undefined;
                })
              );
            });

            if (!midValid) {
              isValid = false;
              errorObject.key = element.name;
              errorObject.value = 'აუცილებელია ყველა ველის შევსება';
            }
          } else {
            isValid = false;
            errorObject.key = element.name;
            errorObject.value = 'აუცილებელია შევსება';
          }
        } else if (
          element.value !== '' &&
          element.value !== null &&
          typeof element.value !== 'undefined'
        ) {
          if (element.name.includes('phone')) {
            if (!element.value.match('^[0-9]*$')) {
              isValid = false;
              errorObject.key = element.name;
              errorObject.value = 'დასაშვებია მხოლოდ ციფრები';
            }
          } else if (element.name === 'peakpower') {
            var double = parseFloat(element.value);
            //console.log(element.value);
            if (double <= 0 || element.value == 'NaN') {
              //console.log(element)
              isValid = false;
              errorObject.key = element.name;
              errorObject.value = 'შეიყვანეთ 0-ზე მეტი სიმძლავრე';
            }
          } else if (element.name === 'houseQuantity') {
            let cnt = parseInt(element.value);
            if (cnt <= 1 || element.value == 'NaN') {
              //console.log(element)
              isValid = false;
              errorObject.key = element.name;
              errorObject.value = 'შეიყვანეთ 1-ზე მეტი ციფრი';
            }
          } else if (element.name === 'tools' && letterTypeId === 60) {
            //console.log(11111)
            element.value.forEach((el) => {
              if (el.tool_Id === '' || el.quantity === '') {
                isValid = false;
                errorObject.key = element.name;
                errorObject.value = 'აუცილებელია შევსება';
              }
              //console.log(el)
            });
          } else if (element.name === 'custN') {
            //console.log(element)
            if (element.value.length === 0) {
              isValid = false;
              errorObject.key = element.name;
              errorObject.value = 'აუცილებელია';
            } else {
              //let cnt=0;
              //var count = (element.value.match(/\d/g) || []).length;
              let regex = /^(\d{6}-\d{3})$/;
              let ans = element.value.match(regex);
              if (ans === null) {
                isValid = false;
                errorObject.key = element.name;
                errorObject.value = 'აბონენტის ნომერი უნდა იყოს 9 ნიშნა';
              }

              //console.log([count, errorObject]);
            }
          } else if (element.name === 'cadastral') {
            if (element.value.length === 0) {
              isValid = false;
              errorObject.key = element.name;
              errorObject.value = 'აუცილებელია';
            }

            let val = element.value;
            const regex = /(\b\d{2,3}\b)|[0-9]{2,3}/g;
            let validationResult = true;
            if (val) {
              let t1 = val.replaceAll(',', '.');
              let t2 = t1.replaceAll('/', '.');

              let res = t2.match(regex);

              let splited = t2.split('.');

              if (splited.length < 3) {
                isValid = false;
                errorObject.key = element.name;
                errorObject.value = 'არასწორი საკადასტრო კოდი';
                //this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
                validationResult = false;
              }

              if (res && splited && splited[splited.length - 1].length < 5) {
                if (res.length != splited.length) {
                  isValid = false;
                  errorObject.key = element.name;
                  errorObject.value = 'არასწორი საკადასტრო კოდი';
                  //this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
                  validationResult = false;
                  validationResult = false;
                }
              }

              if (splited.length > 3) {
                const response = this.chechGeorgianLetters(t2);
                //console.log(response);
                if (splited[splited.length - 1].length > 6) {
                  isValid = false;
                  errorObject.key = element.name;
                  errorObject.value = 'არასწორი საკადასტრო კოდი';
                  //this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
                  validationResult = false;
                  validationResult = false;
                }
                if (!response) {
                  isValid = false;
                  errorObject.key = element.name;
                  errorObject.value = 'არასწორი საკადასტრო კოდი';
                  //this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
                  validationResult = false;
                  validationResult = false;
                }
              }
            }
          }
        } else {
          isValid = false;
          errorObject.key = element.name;
          errorObject.value = 'აუცილებელია';
        }
      } else {
        if (element.value !== '' || element.value !== null) {
          if (element.name === 'phone_2') {
            if (!element.value.match('^[0-9]*$')) {
              isValid = false;
              errorObject.key = element.name;
              errorObject.value = 'დასაშვებია მხოლოდ ციფრები';
            }
          }
        }
      }
      if (Object.keys(errorObject).length > 0) errors.push(errorObject);
    });
    let files = [];
    data.forEach((element) => {
      if (element.name === 'otherdocumentsfiles') {
        //console.log(element);
        if (element.value.length > 0) {
          var l = element.value.length;
          var temp = element.value;
          for (var i = 0; i < l; i++) {
            files.push(temp[i]);
          }
        }
      } else if (element.name !== 'otherdocumentsfiles' && element.name.includes('file')) {
        //console.log(element);
        if (element.value !== '' && element.value !== null && typeof element.value !== 'undefined')
          files.push(element);
      }
    });
    var cnt = 0;
    for (var i = 0; i < files.length; i++) {
      var temp = this.ValidateSize(files[i]);
      //console.log([typeof(temp),temp,files[i]])
      if (typeof temp !== 'undefined') cnt += temp;
      //console.log(this.ValidateSize(files[i]));
    }
    //console.log(cnt);
    if (cnt > 14) {
      let errorObject = {};
      isValid = false;
      errorObject.key = 'filesSize';
      errorObject.value = 'ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს';
      errors.push(errorObject);
      //console.log(11);
    }
    //console.log(files);
    if (bool1 && bool2) {
      isValid = false;
      let obj = { key: 'checkyes_14', value: 'აუცილებელია' };
      errors.push(obj);
    }
    result.errors = errors;
    result.isValid = isValid;
    //console.log(errors);

    return result;
  };

  ValidateSize = (file) => {
    //console.log(typeof(file))
    var FileSize = 0;

    if (typeof file.size !== 'undefined') {
      FileSize = file.size / 1024 / 1024;
      //console.log(file)
    } else {
      //console.log(file);
      FileSize = file.value.size / 1024 / 1024;
      //console.log(file.value.size)
    }
    // in MiB
    return FileSize;
  };

  mobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  checkFileExtension = (file) => {
    const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(file)) {
      return false;
    }
  };

  handleNew = () => {
    let checker = this.mobileCheck();
    if (!checker) {
      //'https://chat.te.ge/client.html?ref=https%3A//te.ge/ka/27/static/distanciuri-momsaxureba.html&hash=4fcb029a4265f214818a29be75dd256e703bb187&username=%E1%83%A1%E1%83%A2%E1%83%A3%E1%83%9B%E1%83%90%E1%83%A0%E1%83%98',
      window.open(
        'https://chat.te.ge/?76118c78-b05a-11ee-b87b-0050568e27d7',
        '',
        'width=400,height=600'
      );
    } else {
      this.setRedirect();
    }
  };

  handleNewPayment = () => {
    let checker = this.mobileCheck();
    if (!checker) {
    } else {
      this.setPayRedirect();
    }
  };

  handleSwalPopup = (
    title,
    icon,
    showCancelButton,
    cancelButtonText,
    confirmButtonText,
    needReload,
    confirmButtonColor
  ) => {
    swal
      .fire({
        html: `<span style="font-family:BPGCond;">${title}</span>`,
        icon: icon,
        showCancelButton: showCancelButton,
        cancelButtonText: `<span style="font-family:BPGCond;">${cancelButtonText}</span>`,
        confirmButtonText: `<span style="font-family:BPGCond;">${confirmButtonText}</span>`,
        confirmButtonColor: confirmButtonColor,
      })
      .then(() => {
        if (needReload) {
          window.location.reload();
        }
      });
  };

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      // window.location.replace("/dashboard/chat")
      return <Redirect to="/dashboard/chat" />;
    }
  };

  reverseString(str) {
    return str.split('').reverse().join('');
  }

  errorExsist = (key) => {
    let bool = this.state.errors.some((obj) => obj.key == key);
    return bool;
  };

  renderErrorText = (key) => {
    let bool = this.state.errors.find((obj) => obj.key.includes(key) === true);
    return bool.value;
  };

  handleDismiss = (custN, dismiss) => {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;

    axios
      .get(`${name}/api/Customer/DismissLetterSuggestion/${custN}`, {
        headers: {
          Token: token,
        },
      })
      .then((res) => {
        if (dismiss) {
          window.location.reload();
        }
      })
      .catch((error) => {});
  };

  callsomething = (custN, controlNumber) => {
    this.handleDismiss(custN, true);

    window.location.href = `/dashboard/Write/AbonentRegister?${custN + controlNumber}`;
  };

  handleLetterSwal = (custN, controlNumber) => {
    var _ = this;
    swal
      .fire({
        title: `<span style="font-family:BPGCaps;"><strong>შეტყობინება</strong></span>`,
        html: `<label style="font-weight:bold;font-size:20px;font-family:BPGCaps;text-align:center;">
                თქვენი პირადი მონაცემები (სახელი, გვარი, დასახელება) არ ემთხევა სააბონენტო ბარათზე (${
                  custN ? custN : '' + controlNumber ? '-' + controlNumber : ''
                }) მითითებულ მონაცემებს.
                <br/>
                <span style="font-weight:bolder;">თუ თქვენ ხართ უძრავი ქონების მესაკუთრე, გთხოვთ, შეავსეთ განაცხადი
                <br/>
                <a href="#" id="toletter">
                აბონენტის რეგისტრაციის მონაცემების ცვლილება (აბონენტად რეგისტრირებული პირის ან რეგისტრირებული სხვა მონაცემების ცვლილება)
                </a>.</span>
                </label>
                <br/>
                <label style="font-family:BPGCond;font-size:14px;"><input type="checkbox" id="ckdismiss"></input>&nbsp; აღარ მაჩვენო ამ აბონენტზე</label>
                `,
        icon: 'question',
        confirmButtonColor: '#262262',
        confirmButtonText: `<span style="font-family:BPGCond;">დიახ</span>`,
        confirmButtonAriaLabel: '#262262',
        //showCancelButton:true,
        showDenyButton: true,
        denyButtonColor: '#262262',
        denyButtonText: `<span style="font-family:BPGCond;">არა</span>`,
        cancelButtonText: `<span style="font-family:BPGCond;">არა</span>`,
        didOpen: function () {
          $('#toletter').on('click', function () {
            _.handleDismiss(custN, true);

            window.location.href = `/dashboard/Write/AbonentRegister?${custN + controlNumber}`;
          });
        },
      })
      .then((result) => {
        //call api for dismiss
        let dismiss = $('#ckdismiss').is(':checked');
        //console.log(result)
        if (result.isConfirmed) {
          if (dismiss) this.handleDismiss(custN, result.isDenied);

          window.location.href = `/dashboard/Write/AbonentRegister?${custN + controlNumber}`;
        } else if (result.isDenied) {
          if (dismiss) this.handleDismiss(custN, result.isDenied);
          //window.location.reload();
        }
      });
  };

  render() {
    const {
      values,
      logouthandler,
      handleChange,
      handleDataReset,
      handleDataUpdate,
      handlePasswordReset,
      handlePassUndo,
      handleCount,
    } = this.props;
    const letters = values.letterGroups;
    const lettertypes = values.lettertypes;

    //const jsScript = React.lazy(()=>import('./fullstory'));

    const currentStyle = {
      height: '60px',
      width: '80px',
      border: 'none',
      transition: 'all 300ms',
      position: 'fixed',
      bottom: '60px',
      cursor: 'pointer',
      right: '40px',
      Zindex: '999999999',
    };

    if (this.state.showModal && this.state.toGrid) {
      $(function (event) {
        // reset the scroll to top
        $('#myModal .modal-body').scrollTop(0);
        // get the section using data
        //var section = $(event.relatedTarget).data('section');
        // get the top of the section
        var sectionOffset = $('#section-1').offset();
        //scroll the container
        var $target = $('#section-1');
        $target.addClass('hilight');
        $('#myModal .modal-body').animate(
          {
            scrollTop: sectionOffset.top - 30,
          },
          {
            duration: 1000,
            // complete:function(){
            //   var $target = $('#section-1');
            //   $target.addClass('hilight');
            // }
          }
        );
      });
    }

    if (Object.keys(letters).length === 0) {
      return <label></label>;
    }
    //console.log([this.state.showNavbar,this.state.mobileShowNavbar])

    let temp = window.location.href;
    let path = '';
    for (let i = temp.length - 1; i > 0; i--) {
      if (temp[i] == '/') break;
      path += temp[i];
    }

    path = this.reverseString(path);
    if (path === 'chat') {
      this.state.redirect = false;
    }
    const { photoIndex, lightBox } = this.state;
    const images = [''];

    //console.log(read_cookie("platform"),read_cookie("app"));
    //this.pathSwitch(path);
    // console.log(values)
    //console.log(path);
    //const show = this.state.showNavbar;
    //const data={letters,lettertypes};
    //console.log(this.props);
    //{this.state.showNavbar?"c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show":"c-sidebar c-sidebar-dark c-sidebar-fixed"}
    return (
      <React.Fragment>
        <ReactNotification />
        <Router>
          <ScrollToTop />
          <div className="c-app">
            <div
              className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show"
              id="sidebar"
            >
              <div className="c-sidebar-brand d-md-down-none">
                <Link
                  to="/dashboard/Profile"
                  style={{ fontFamily: 'BPGCond', color: 'white', textDecoration: 'none' }}
                >
                  <span onClick={() => this.handleActive('Profile')}>{values.user.fullName}</span>
                </Link>
              </div>
              <ul className="c-sidebar-nav ps ps--active-y">
                <li
                  className="c-sidebar-nav-item"
                  onClick={() => {
                    this.handleActive('Home');
                    this.handleMobileSideBar();
                  }}
                >
                  <Link
                    className="c-sidebar-nav-link navbarhover"
                    to="/dashboard"
                    style={{
                      fontFamily: 'BPGCaps',
                      backgroundColor: this.state.active === 'Home' ? 'rgba(255,255,255,.05)' : '',
                    }}
                  >
                    <div className="c-sidebar-nav-icon">
                      <i className="fas fa-home"></i>
                    </div>
                    <div className="c-sidebar-nav-icon">მთავარი</div>
                  </Link>
                </li>

                <li
                  className="c-sidebar-nav-item"
                  onClick={() => {
                    this.handleActive('Customers');
                    this.handleMobileSideBar();
                  }}
                >
                  <Link
                    className="c-sidebar-nav-link navbarhover"
                    to="/dashboard/Customers"
                    style={{
                      fontFamily: 'BPGCaps',
                      backgroundColor:
                        this.state.active === 'Customers' ? 'rgba(255,255,255,.05)' : '',
                    }}
                  >
                    <div className="c-sidebar-nav-icon">
                      <i className="fas fa-list-alt"></i>
                    </div>
                    <div className="c-sidebar-nav-icon">ჩემი აბონენტები</div>
                  </Link>
                </li>
                <li
                  className="c-sidebar-nav-item"
                  onClick={() => {
                    this.handleActive('Letters');
                    this.handleMobileSideBar();
                  }}
                >
                  <Link
                    className="c-sidebar-nav-link navbarhover"
                    to="/dashboard/Myletters"
                    style={{
                      fontFamily: 'BPGCaps',
                      backgroundColor:
                        this.state.active === 'Letters' ? 'rgba(255,255,255,.05)' : '',
                    }}
                  >
                    <div className="c-sidebar-nav-icon">
                      <i className="fas fa-scroll"></i>
                    </div>
                    <div className="c-sidebar-nav-icon">ჩემი განცხადებები</div>
                  </Link>
                </li>
                {/* <li className="c-sidebar-nav-item" onClick={()=>{this.handleActive("BookVisit");this.handleMobileSideBar();}}>
                                <Link className="c-sidebar-nav-link navbarhover" to="/dashboard/BookVisit" style={{fontFamily:"BPGCaps",backgroundColor:this.state.active==="BookVisit"?"rgba(255,255,255,.05)":""}}>
                                    <div className="c-sidebar-nav-icon"><i className="fas fa-building"></i></div>
                                    <div className="c-sidebar-nav-icon">ვიზიტის დაჯავშნა</div>
                                </Link>
                            </li> */}
                <li
                  className="c-sidebar-nav-item"
                  onClick={() => {
                    this.handleActive('Notifications');
                    this.handleMobileSideBar();
                  }}
                >
                  <Link
                    className="c-sidebar-nav-link navbarhover"
                    to="/dashboard/Notifications"
                    style={{
                      fontFamily: 'BPGCaps',
                      backgroundColor:
                        this.state.active === 'Notifications' ? 'rgba(255,255,255,.05)' : '',
                    }}
                  >
                    <div className="c-sidebar-nav-icon">
                      <i className="fas fa-bell"></i>
                    </div>
                    <div className="c-sidebar-nav-icon">შეტყობინებები</div>
                  </Link>
                </li>
                <li
                  className="c-sidebar-nav-item"
                  onClick={() => {
                    this.handleActive('Profile');
                    this.handleMobileSideBar();
                  }}
                >
                  <Link
                    className="c-sidebar-nav-link navbarhover"
                    to="/dashboard/Profile"
                    style={{
                      fontFamily: 'BPGCaps',
                      backgroundColor:
                        this.state.active === 'Profile' ? 'rgba(255,255,255,.05)' : '',
                    }}
                  >
                    <div className="c-sidebar-nav-icon">
                      <i className="fas fa-user-circle"></i>
                    </div>
                    <div className="c-sidebar-nav-icon">პროფილი</div>
                  </Link>
                </li>
                <li
                  className="c-sidebar-nav-item"
                  onClick={() => {
                    this.handleActive('Help');
                    this.handleMobileSideBar();
                  }}
                >
                  <Link
                    className="c-sidebar-nav-link navbarhover"
                    to="/dashboard/Help"
                    style={{
                      fontFamily: 'BPGCaps',
                      backgroundColor: this.state.active === 'Help' ? 'rgba(255,255,255,.05)' : '',
                    }}
                  >
                    <div className="c-sidebar-nav-icon">
                      <i className="fas fa-question-circle"></i>
                    </div>
                    <div className="c-sidebar-nav-icon">დახმარება</div>
                  </Link>
                </li>
              </ul>

              <img
                src={BottomLogo}
                style={{ width: '80%', position: 'absolute', left: '0', bottom: '0' }}
                alt="BottomLogo"
              ></img>
            </div>
            <div className="c-wrapper c-fixed-components">
              <header className="c-header c-header-light c-header-fixed">
                <button
                  className="c-header-toggler c-class-toggler d-lg-none mfe-auto"
                  type="button"
                  data-class="c-sidebar-show"
                  data-target="#sidebar"
                  onClick={() => this.handleMobileSideBar()}
                >
                  <i className="fas fa-bars" alt="Menu" title="Menu"></i>
                </button>
                <button
                  className="c-header-toggler c-class-toggler mfs-3 d-md-down-none"
                  type="button"
                  data-target="#sidebar"
                  data-class="c-sidebar-lg-show"
                  responsive="true"
                  onClick={() => this.handleSideBar()}
                >
                  <i className="fas fa-bars" alt="Menu" title="Menu"></i>
                </button>
                <ul className="c-header-nav d-md-down-none" style={{ fontFamily: 'BPGCaps' }}>
                  <li className="c-header-nav-item px-3">
                    <Link to="/dashboard" className="c-header-nav-link">
                      <img src={TeLogo} style={{ width: '136px' }} alt="TE Logo"></img>
                    </Link>
                  </li>
                  <li className="nav-item dropdown"></li>
                  <li className="c-header-nav-item px-3">
                    <a
                      className="c-header-nav-link"
                      href="https://te.ge/ka/bills.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      დოკუმენტები
                    </a>
                  </li>
                  <li className="c-header-nav-item px-3">
                    <a
                      className="c-header-nav-link"
                      href="https://te.ge/ka/businesscenters.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      მომსახურების ცენტრი
                    </a>
                  </li>
                  <li className="c-header-nav-item px-3">
                    <a
                      className="c-header-nav-link"
                      href="https://te.ge/ka/blog.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      სიახლეები
                    </a>
                  </li>
                  <li className="c-header-nav-item px-3">
                    <a
                      className="c-header-nav-link"
                      href="https://te.ge/ka/contact.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      კონტაქტი
                    </a>
                  </li>
                  <li className="c-header-nav-item px-3">
                    <Link to="/dashboard/CurrentWorks" className="c-header-nav-link">
                      მიმდინარე სამუშაოები
                    </Link>
                  </li>
                </ul>
                {/* <ul className="c-header-nav mfs-auto"></ul> */}
                <ul className="c-header-nav mfs-auto">
                  <li className="c-header-nav-item dropdown mx-2">
                    <Link className="c-header-nav-link" to="/dashboard/Notifications">
                      <div className="c-avatar">
                        <i className="far fa-bell bdg" nbr={10}></i>
                        <span
                          className={
                            values.notificationCount !== 0
                              ? 'badge badge-pill badge-danger'
                              : 'badge badge-pill badge-energy'
                          }
                        >
                          {values.notificationCount}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="c-header-nav-item dropdown mx-2">
                    <div className="c-header-nav-link">
                      <div className="c-avatar" onClick={() => this.handleActive('other')}>
                        <Link to="/dashboard/Payment">
                          <i
                            className="fas fa-credit-card"
                            title="Pay"
                            style={{ color: '#262262' }}
                          ></i>
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li className="c-header-nav-item dropdown mx-2" onClick={logouthandler}>
                    <div className="c-header-nav-link">
                      <div className="c-avatar">
                        <i
                          className="fas fa-sign-out-alt"
                          alt="Sign Out"
                          title="Sign Out"
                          style={{ cursor: 'pointer' }}
                        ></i>
                      </div>
                    </div>
                  </li>
                </ul>
              </header>
              <div className="c-body">
                {lightBox && (
                  <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ lightBox: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                      })
                    }
                    enableZoom
                    clickOutsideToClose
                    imageLoadErrorMessage="ფოტოს ჩატვირთვა ვერ მოხერხდა"
                  ></Lightbox>
                )}

                <Modal
                  show={this.state.showModal}
                  onHide={this.handleModal}
                  centered
                  dialogClassName="modal-90w"
                  id="myModal"
                  scrollable={true}
                >
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontFamily: 'BPGCaps' }}>
                      <strong>{this.state.currentModalHeader}</strong>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{renderHTML(this.state.currentModalHtml)}</Modal.Body>
                </Modal>

                <Modal
                  show={this.state.priceModal}
                  onHide={this.handlePriceModal}
                  centered
                  dialogClassName="modal-90w"
                >
                  <Modal.Header closeButton>
                    <label
                      style={{ textAlign: 'center', fontFamily: 'BPGCaps', fontSize: 'medium' }}
                    >
                      <strong>ღირებულება</strong>
                    </label>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ alignItems: 'center' }}>
                      <div className="col-md-12">
                        {renderHTML(this.state.currentPriceModalHtml)}
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={this.state.letterTerms}
                  onHide={this.handleLetterTermsModal}
                  centered
                  dialogClassName="modal-90w"
                >
                  <Modal.Header closeButton>
                    <label
                      style={{ textAlign: 'center', fontFamily: 'BPGCaps', fontSize: 'medium' }}
                    >
                      <strong>წესები და პირობები</strong>
                    </label>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ alignItems: 'center' }}>
                      <div className="col-md-12">
                        <LetterTerms />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={this.state.confirmationModal}
                  centered
                  style={{ fontFamily: 'BPGCond' }}
                  onHide={this.handleConfirmationModal}
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    <label
                      style={{ textAlign: 'center', fontFamily: 'BPGCaps', fontSize: 'medium' }}
                    >
                      <strong>დადასტურება</strong>
                    </label>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-horizontal" style={{ textAlign: 'center' }}>
                      <div className="form-group row">
                        <label
                          className="col-md-12 col-form-label"
                          style={{ fontWeight: 'bold', fontSize: '18px', fontFamily: 'BPGCaps' }}
                        >
                          თქვენ გაქვთ ამ ტიპის მიმდინარე განაცხადი. ნამდვილად გსურთ ახლის დაწერა?
                        </label>
                        <label className="col-md-12 col-form-label" style={{ fontWeight: 'bold' }}>
                          დაადასტურეთ SMS კოდით
                        </label>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-12 col-form-label">SMS კოდი: </label>
                        <div
                          className="col-md-12"
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <div className="col-md-2">
                            <input
                              className="form-control"
                              style={{ textAlign: 'center' }}
                              onChange={this.handleTypingChange('confirmationCode0')}
                              value={this.state.confirmationCode0}
                              id="confirmationCode"
                              maxLength="1"
                            ></input>
                          </div>
                          <div className="col-md-2">
                            <input
                              className="form-control"
                              style={{ textAlign: 'center' }}
                              onChange={this.handleTypingChange('confirmationCode1')}
                              value={this.state.confirmationCode1}
                              id="confirmationCode"
                              maxLength="1"
                            ></input>
                          </div>
                          <div className="col-md-2">
                            <input
                              className="form-control"
                              style={{ textAlign: 'center' }}
                              onChange={this.handleTypingChange('confirmationCode2')}
                              value={this.state.confirmationCode2}
                              id="confirmationCode"
                              maxLength="1"
                            ></input>
                          </div>
                          <div className="col-md-2">
                            <input
                              className="form-control"
                              style={{ textAlign: 'center' }}
                              onChange={this.handleTypingChange('confirmationCode3')}
                              value={this.state.confirmationCode3}
                              id="confirmationCode"
                              maxLength="1"
                            ></input>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="col-md-12">
                          {this.state.confirmationCodeerror != '' && (
                            <label style={{ fontSize: 18, color: '#e55353' }}>
                              {this.state.confirmationCodeerror}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="btn btn-primary" onClick={() => this.sendData()}>
                      დადასტურება
                    </Button>
                    {/* <Button className="btn btn-danger" >გაუქმება</Button> */}
                  </Modal.Footer>
                </Modal>

                {this.renderRedirect()}

                <main className="c-main">
                  <div className="container-fluid">
                    <div className="fade-in">
                      <Switch>
                        <Route path="/dashboard/chat">
                          <TeChat />
                        </Route>
                        <Route
                          path="/dashboard/MobilePay"
                          component={(props) => <MobilePayment {...props} />}
                        ></Route>

                        <Route exact path="/dashboard">
                          <Home values={values}></Home>
                        </Route>
                        <Route path="/dashboard/Profile">
                          <Profile
                            values={values}
                            handleChange={handleChange}
                            handleDataReset={handleDataReset}
                            handleDataUpdate={handleDataUpdate}
                            handlePasswordReset={handlePasswordReset}
                            handlePassUndo={handlePassUndo}
                            handleSwalPopup={this.handleSwalPopup}
                          ></Profile>
                        </Route>
                        <Route path="/dashboard/CurrentWorks">
                          <CurrentWorks></CurrentWorks>
                        </Route>
                        {/* <Route path="/dashboard/PaymentHistory">
                                                <PaymentHistory 
                                                    values={values}
                                                >
                                                </PaymentHistory>
                                            </Route> */}
                        <Route
                          path="/dashboard/MyLetters"
                          component={(props) => <MyLetters {...props} values={values} />}
                        ></Route>
                        <Route path="/dashboard/Customers">
                          <Abonents
                            values={values}
                            handleSwalPopup={this.handleSwalPopup}
                            handleLetterSwal={this.handleLetterSwal}
                            handleDismiss={this.handleDismiss}
                          ></Abonents>
                        </Route>
                        <Route
                          path="/dashboard/customer/:customerid"
                          component={(props) => (
                            <AbonentDetailed
                              {...props}
                              values={values}
                              handleLetterSwal={this.handleLetterSwal}
                              handleDismiss={this.handleDismiss}
                            />
                          )}
                        ></Route>
                        <Route path="/dashboard/Notifications">
                          <Notifications values={values} handleCount={handleCount}></Notifications>
                        </Route>
                        <Route path="/dashboard/Sms">
                          <Sms values={values}></Sms>
                        </Route>
                        <Route path="/dashboard/Emails">
                          <Email values={values}></Email>
                        </Route>
                        <Route path="/dashboard/Payment">
                          <Payment />
                        </Route>
                        <Route
                          path="/dashboard/WriteLetter"
                          component={(props) => (
                            <Letters {...props} values={values} params={values} />
                          )}
                        ></Route>
                        <Route path="/dashboard/fail">
                          <Fail></Fail>
                        </Route>
                        <Route path="/dashboard/success">
                          <Success></Success>
                        </Route>
                        <Route path="/dashboard/BookVisit">
                          <BookVisit values={values}></BookVisit>
                        </Route>
                        <Route path="/dashboard/Help">
                          <Help values={values}></Help>
                        </Route>
                        <Route
                          path="/dashboard/Pay/:customerId"
                          component={(props) => <PaymentCustomer {...props} />}
                        ></Route>
                        {lettertypes.map((letter, index) => {
                          let name = letter.componentName;
                          let title = letter.name;
                          let id = letter.id;
                          return (
                            <Route
                              path={`/dashboard/Write/${name}`}
                              key={index}
                              component={(props) => this.renderSwitch(name, props, id, title)}
                            ></Route>
                          );
                        })}
                        <Route component={NotFound}></Route>
                      </Switch>
                    </div>
                  </div>
                </main>
                <div style={currentStyle}>
                  {/* <MessengerCustomerChat
                                    pageId="115615530290991"
                                    appId="786851985408765"                                           
                                    > 
                                </MessengerCustomerChat> */}
                  <div style={{ position: 'relative', left: '20px' }}>
                    <button className="btn-chat" onClick={this.handleNew}>
                      <img src={chatLogo} width="24px" height="24px" alt="chat"></img>
                      {/* <svg width="24" height="24" viewBox="0 0 24 24" style={{fill:"#fff"}}>
                                            <path  d="M12 3c5.514 0 10 3.592 10 8.007 
                                            0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 
                                            1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415
                                            4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023
                                            4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 
                                            11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm0 14h-5v-1h5v1zm5-3h-10v-1h10v1zm0-3h-10v-1h10v1z"/>
                                        </svg>                                   */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.mobileShowNavbar ? (
            <div
              className="c-sidebar-backdrop c-fade c-show"
              onClick={() => this.handleMobileSideBar()}
            ></div>
          ) : null}
        </Router>
      </React.Fragment>
    );
  }
}

export default Navrbar;
