import React, { Component } from 'react';

class MobilePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            src:""
        }
    }
    UNSAFE_componentWillMount(){
        //console.log(this.props.history.location.state.src);
        if(typeof(this.props.src)!=="undefined"){
            this.setState({src:this.props.history.location.state.src})
        }
    }

    render() { 
        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <iframe id="scaled-frame" width="100%" height="480" title="Mobile Payment"
                            src={this.props.history.location.state.src}>
                        </iframe>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default MobilePayment;