import React, { Component } from 'react';
import SearchField from 'react-search-field';
import moment from 'moment';

class TableSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyToFind: '',
      history: [],
      filteredHistrory: [],
    };
    this.handleSearchedHistory = this.props.handleSearchedHistory;
  }

  UNSAFE_componentWillMount() {
    this.setState({ history: this.props.data });
  }

  reverseString = (str) => {
    return str.split('.').reverse().join('.');
  };

  onChange = (input) => (e) => {
    try {
      this.setState({ [input]: e });
      var history = this.props.data;
      var filteredHistrory = [];
      if (e === '') {
        filteredHistrory = this.props.data;
      } else {
        history.forEach((element) => {
          for (const [key, value] of Object.entries(element)) {
            if (key.includes('Date') || key === 'rd') {
              let res = moment(value).format('DD.MM.YYYY');
              if (res.includes(e)) {
                filteredHistrory.push(element);
                break;
              }
            } else {
              let res = String(value);
              if (res.includes(e)) {
                filteredHistrory.push(element);
                break;
              }
            }
          }
        });
      }
      this.handleSearchedHistory(filteredHistrory);
    } catch (err) {
      //console.log(err)
    }
  };

  render() {
    return (
      <React.Fragment>
        <SearchField placeholder="ძებნა..." onChange={this.onChange('keyToFind')} />
      </React.Fragment>
    );
  }
}

export default TableSearch;
