import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
import ToLetters from '../../ToLetters';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import MaskedInput1 from 'react-text-mask';
import TextMask from 'react-text-mask';
import emailMask from 'text-mask-addons/src/emailMask';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import InputInfo from '../InputInfo';

class InternalNetworkUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkyes_4: false,
      checkyes_5: false,
      checkyes_6: true,
      checkyes_35: false,
      checkyes_36: false,
      agree: false,
      fullname: '',
      phone_1: '',
      phone_2: '',
      email_1: '',
      email_2: '',
      personal: '',
      custN: '',
      address: '',
      addressto: '',
      comment: '',
      cadastral: '',
      recaptchaCode: '',
      recaptchaCodeerror: '',
      file_1: '',
      //file_2:"",
      tools: [{ tool_Id: '', quantity: '', mcube: '' }],
      reference_FieldsDescriptions: [],
      toolserror: '',
      reference_tools: [],
      checked: true,
      customer_firstname: '',
      customer_lastname: '',
      customer_personal: '',
      customer_phone_1: '',
      customer_phone_2: '',
      customer_email_1: '',
      customer_email_2: '',
      otherdocumentsfiles: [],
      errors: [],
    };
    this.handleLetterTermsModal = this.props.handleLetterTermsModal;
    this.letterFormValidator = this.props.letterFormValidator;
    this.renderCustomersOptions = this.props.renderCustomersOptions;
    this.SendLetter = this.props.SendLetter;
    this.handleSwalPopup = this.props.handleSwalPopup;
  }

  UNSAFE_componentWillMount() {
    const token = read_cookie('Bearer_Token');
    const url = window.$name;

    Axios.get(`${url}/api/Letters/GetLetterFieldDescriptions/${this.props.letterTypeId}`, {
      headers: {
        Token: token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          this.setState({ reference_FieldsDescriptions: res.data.descriptions });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'udnefined') {
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.replace('/login');
          }
        }
      });

    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      let phone = data.phones.filter((x) => x.typeKey === 1);
      let email = data.emails[0];
      this.setState({
        fullname: data.name + ' ' + data.lastName,
        personal: data.personalId,
        phone_1: phone ? phone[0].info : '',
        email_1: email ? email.info : '',
        address: data.registrationAddress,
      });
    }
  }

  componentDidMount() {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    Axios.get(`${name}/api/Letters/GetReferences?letterTypeId=50`, { headers: { Token: token } })
      .then((res) => {
        let data = res.data.references;
        //console.log(data);
        let tools = [];
        data.forEach((element) => {
          switch (element.key) {
            case 'RefDevices':
              let obj1 = { value: element.id, label: element.value, m3: element.parentId };
              tools.push(obj1);
              break;
            default:
              break;
          }
        });
        this.setState({ reference_tools: tools });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
      });
  }

  handleAddClick = () => {
    this.setState({ tools: [...this.state.tools, { tool_Id: '', quantity: '', mcube: '' }] });
  };

  handleRemoveClick = (index) => {
    let list = this.state.tools.splice(index, 1);
    this.setState({ tools: list });
  };

  handleToolChange = (index, e) => {
    const { value, label, m3 } = e;
    const list = [...this.state.tools];
    list[index]['mcube'] = m3;
    list[index]['tool_Id'] = value;
    this.setState({ tools: list });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.tools];
    list[index][name] = value;
    this.setState({ tools: list });
  };

  handleCheck(name) {
    this.setState({ [name]: !this.state[name] });
  }

  handleFileChange = (input) => (event) => {
    if (input === 'otherdocumentsfiles') {
      if (event.target.files.length > 3) {
        this.handleSwalPopup(
          'ფაილების მაქსიმალური რაოდენობა არის 3',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
        document.getElementById('otherdocumentsfiles').value = '';
        //this.setState({[input]:[]});
      } else {
        this.setState({ [input]: event.target.files });
      }
    } else {
      this.setState({ [input]: event.target.files[0] });
    }
  };

  handleChange = (input) => (e) => {
    let ph = '';
    if (input.includes('phone')) {
      ph = e.target.value.replace(/\D/g, '');
      if (input === 'phone_1') this.setState({ phone_1: ph });
      else if (input === 'phone_2') this.setState({ phone_2: ph });
      else if (input === 'customer_phone_1') this.setState({ customer_phone_1: ph });
      else this.setState({ customer_phone_2: ph });
    } else if (input === 'recaptchaCode') {
      //console.log(e)
      this.setState({ recaptchaCode: e });
    } else {
      this.setState({ [input]: e.target.value });
    }
  };

  handleSubmit = () => {
    let isValid = this.formValidate();
    let data = [];
    if (isValid) {
      for (const [key, value] of Object.entries(this.state)) {
        let obj = {};
        if (!key.includes('error') && !key.includes('refe') && !key.includes('recap')) {
          if (value !== '' && value !== null) {
            obj.key = key;
            obj.value = value;
            data.push(obj);
          }
        }
      }
      this.SendLetter(this.state.custN, this.props.letterTypeId, this.state.recaptchaCode, data);
    } else {
      //var filesError = this.state.errors.filter(x=>x.key==="filesSize"); if(filesError.length) filesError=filesError[0].value;
      if (this.state.errorFileSize) {
        this.handleSwalPopup(
          'ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      } else {
        this.handleSwalPopup(
          'შესავსებია სავალდებულო ველები',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      }
    }
  };

  formValidate() {
    const values = this.state;

    let data = [];

    for (const [key, value] of Object.entries(values)) {
      if (!key.includes('recap') && !key.includes('refe') && !key.includes('error')) {
        let obj = {};
        obj.name = key;
        obj.value = value;
        if (
          key === 'fullname' ||
          key === 'custN' ||
          key === 'personal' ||
          key === 'phone_1' ||
          key === 'address' ||
          key === 'file_1' ||
          key === 'addressto' ||
          key === 'cadastral' ||
          key === 'customer_firstname' ||
          key === 'customer_personal' ||
          key === 'customer_phone_1'
        ) {
          obj.Required = true;
        } else if (key === 'email_1') {
          if (this.state.checkyes_5 === false) obj.Required = true;
          else obj.Required = false;
        } else if (key === 'customer_lastname') {
          if (this.state.checked == true) obj.Required = true;
          else obj.Required = false;
        } else obj.Required = false;

        data.push(obj);
      }
    }

    let result = this.letterFormValidator(this.props.letterTypeId, data);
    const found = result.errors.find((x) => x.key === 'filesSize');
    if (typeof found !== 'undefined') {
      //console.log(1234);
      this.state.errorFileSize = true;
      this.setState({ errors: result.errors });
      //this.handleSwalPopup("ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს","warning",false,"","დახურვა",false,"#262262");
    } else {
      this.state.errorFileSize = false;
      this.setState({ errors: result.errors });
    }

    return result.isValid;
  }

  handleRepeat = () => {
    if (this.state.checked) {
      if (typeof this.props.params.user !== 'undefined') {
        const data = this.props.params.user;
        this.setState({
          customer_firstname: data.name,
          customer_personal: this.state.personal,
          customer_lastname: data.lastName,
          customer_phone_1: this.state.phone_1,
          customer_phone_2: this.state.phone_2,
          customer_email_1: this.state.email_1,
          customer_email_2: this.state.email_2,
        });
      }
    } else {
      this.setState({
        customer_phone_1: this.state.phone_1,
        customer_phone_2: this.state.phone_2,
        customer_email_1: this.state.email_1,
        customer_email_2: this.state.email_2,
      });
    }
  };

  handlePhysicalSwitch = () => {
    if (this.state.checked) {
      this.setState({
        checked: !this.state.checked,
        customer_firstname: '',
        customer_lastname: '',
        customer_personal: '',
      });
    } else {
      this.setState({ checked: !this.state.checked });
    }
  };

  errorExsist = (key) => {
    let bool = this.state.errors.some((obj) => obj.key == key);
    return bool;
  };

  renderErrorText = (key) => {
    let bool = this.state.errors.find((obj) => obj.key.includes(key) === true);
    return bool.value;
  };

  render() {
    const values = this.state;
    let phone, email;
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      phone = data.phones.filter((x) => x.typeKey === 1);
      email = data.emails[0];
    }
    let l = true;
    if (values.reference_FieldsDescriptions.length === 0) l = false;
    console.log(this.state.errors);
    return (
      <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div style={{ float: 'left' }}>
                <ToLetters></ToLetters>
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>
                  <strong>{this.props.letterTitle}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>განმცხადებელი</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სახელი და გვარი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      readOnly={this.props.params.user.fullName ? true : false}
                      placeholder="სახელი გვარი/ორგანიზაციის დასახელება"
                      onChange={this.handleChange('fullname')}
                      value={values.fullname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'fullname'}
                      />
                    ) : null}
                    {this.errorExsist('fullname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('fullname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-4 col-form-label">
                    <strong>პირადი #/საიდენტიფიკაციო #</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="personal"
                      readOnly={this.props.params.user.personalId ? true : false}
                      placeholder="პირადი ნომერი"
                      onChange={this.handleChange('personal')}
                      value={values.personal}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'personal'}
                      />
                    ) : null}
                    {this.errorExsist('personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მისამართი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      placeholder="მისამართი"
                      readOnly={this.props.params.user.registrationAddress ? true : false}
                      onChange={this.handleChange('address')}
                      value={values.address}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'address'}
                      />
                    ) : null}
                    {this.errorExsist('address') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('address')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      readOnly={
                        typeof phone[0].info !== 'undefined' && phone[0].info ? true : false
                      }
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_1"
                      onChange={this.handleChange('phone_1')}
                      value={values.phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_2"
                      onChange={this.handleChange('phone_2')}
                      value={values.phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ძირითადი)</strong>
                    {values.checkyes_5 === false ? (
                      <span style={{ color: '#e55353' }}>*</span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      readOnly={typeof email !== 'undefined' ? true : false}
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_1"
                      onChange={this.handleChange('email_1')}
                      value={values.email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_1'}
                      />
                    ) : null}
                    {this.errorExsist('email_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('email_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_2"
                      onChange={this.handleChange('email_2')}
                      value={values.email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_2'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>ინფორმაცია (აბონენტის), გაზის საფასურის გადამხდელი პირის შესახებ:</strong>
              <div className="card-header-actions">
                <button className="btn btn-primary btn-sm" onClick={this.handleRepeat}>
                  განმცხადებლის მონაცემების გადმოტანა
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <BootstrapSwitchButton
                  width={200}
                  checked={values.checked}
                  onlabel="ფიზიკური"
                  offlabel="იურიდიული"
                  onChange={this.handlePhysicalSwitch}
                ></BootstrapSwitchButton>
                <br />
                <br />
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>აბონენტის ნომერი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput
                      className="form-control"
                      mask="111111-111"
                      list="customers"
                      placeholder="XXXXXX-XXX"
                      name="custN"
                      onChange={this.handleChange('custN')}
                      value={values.custN}
                    ></MaskedInput>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'custN'}
                      />
                    ) : null}
                    <datalist id="customers">{this.renderCustomersOptions()}</datalist>
                    {this.errorExsist('custN') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('custN')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'სახელი' : 'დასახელება'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_firstname')}
                      placeholder={values.checked ? 'სახელი' : 'დასახელება'}
                      value={values.customer_firstname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_firstname'}
                      />
                    ) : null}
                    {this.errorExsist('customer_firstname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_firstname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {values.checked ? (
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გვარი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('customer_lastname')}
                        value={values.customer_lastname}
                        placeholder="გვარი"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_lastname'}
                        />
                      ) : null}
                      {this.errorExsist('customer_lastname') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_lastname')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_personal')}
                      value={values.customer_personal}
                      placeholder={values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_personal'}
                      />
                    ) : null}
                    {this.errorExsist('customer_personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ძირითადი"
                      name="customer_phone_1"
                      onChange={this.handleChange('customer_phone_1')}
                      value={values.customer_phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ალტერნატიული"
                      name="customer_phone_2"
                      onChange={this.handleChange('customer_phone_2')}
                      value={values.customer_phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ძირითადი"
                      name="customer_email_1"
                      onChange={this.handleChange('customer_email_1')}
                      value={values.customer_email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ალტერნატიული"
                      name="customer_email_2"
                      onChange={this.handleChange('customer_email_2')}
                      value={values.customer_email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_2'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>სხვა პირობები</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ობიექტის მისამართი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="addressto"
                      placeholder="მისამართი"
                      onChange={this.handleChange('addressto')}
                      value={values.addressto}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto'}
                      />
                    ) : null}
                    {this.errorExsist('addressto') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('addressto')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>უძრავი ქონების საკადასტრო კოდი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="cadastral"
                      placeholder="საკადასტრო კოდი"
                      onChange={this.handleChange('cadastral')}
                      value={values.cadastral}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'cadastral'}
                      />
                    ) : null}
                    {this.errorExsist('cadastral') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('cadastral')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მრიცხველის გადაადგილება</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_35');
                        }}
                        checked={this.state.checkyes_35}
                      ></input>
                      &nbsp;დიახ
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_35');
                        }}
                        checked={!this.state.checkyes_35}
                      ></input>
                      &nbsp;არა
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_35'}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მილის გადაადგილება</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_36');
                        }}
                        checked={this.state.checkyes_36}
                      ></input>
                      &nbsp;დიახ
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_36');
                        }}
                        checked={!this.state.checkyes_36}
                      ></input>
                      &nbsp;არა
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_36'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>პასუხის გაგზავნის ფორმა</strong>
                  </label>
                  <div className="col-md-8">
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_5');
                        }}
                        checked={this.state.checkyes_5}
                      ></input>
                      &nbsp;წერილობითი
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        onChange={() => {
                          this.handleCheck('checkyes_5');
                        }}
                        checked={!this.state.checkyes_5}
                      ></input>
                      &nbsp;ელექტრონული
                    </label>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'checkyes_5'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>შენიშვნა</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="comment"
                      placeholder="შენიშვნა"
                      onChange={this.handleChange('comment')}
                      value={values.comment}
                    ></input>
                    {/* <span className="redAsterisk">*</span> */}
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'comment'}
                      />
                    ) : null}
                    {values.commenterror ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {values.commenterror}
                      </label>
                    ) : null}
                  </div>
                </div>
                {this.state.tools.map((tool, index) => {
                  //console.log(tool);
                  return (
                    <React.Fragment key={index}>
                      <div className="form-group row">
                        <br></br>
                        <label className="col-md-4 col-form-label">
                          {index === 0 ? (
                            <strong>გაზის დანადგარების და ხელსაწყოების ჩამონათვალი </strong>
                          ) : null}
                        </label>
                        <div className="col-md-4">
                          {/* <input className="form-control" name="Tname" placeholder="დანადგარის დასახელება" value={tool.Tname} onChange={e => this.handleInputChange(e, index)}></input> */}
                          <Select
                            isSearchable
                            options={values.reference_tools}
                            onChange={(e) => this.handleToolChange(index, e)}
                            placeholder="აირჩიეთ"
                          />
                        </div>
                        <div className="col-md-2">
                          <input
                            className="form-control"
                            name="quantity"
                            type="number"
                            placeholder="რაოდ."
                            value={tool.quantity}
                            onChange={(e) => this.handleInputChange(e, index)}
                          ></input>
                        </div>
                        <div className="col-md-2">
                          <input
                            className="form-control"
                            name="mcube"
                            type="number"
                            placeholder="მ&sup3;"
                            value={tool.mcube}
                            onChange={(e) => this.handleInputChange(e, index)}
                          ></input>
                        </div>
                        {/* <div className="col-md-1"/> */}
                      </div>
                      <div className="form-group row">
                        <div className="col-md-4" />
                        <div className="col-md-6" />
                        <div className="btn-box col-md-2">
                          {this.state.tools.length !== 1 && (
                            <button className="" type="button" onClick={this.handleRemoveClick}>
                              წაშლა
                            </button>
                          )}
                          &nbsp;
                          {this.state.tools.length - 1 === index && (
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={this.handleAddClick}
                            >
                              დამატება
                            </button>
                          )}
                        </div>
                        {/* <div className="col-md-1"/> */}
                      </div>
                    </React.Fragment>
                  );
                })}
                {values.toolserror ? (
                  <div className="form-group row">
                    <div className="col-md-4"></div>
                    <label style={{ fontSize: 10, color: '#e55353' }}>{values.toolserror}</label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>თანდართული დოკუმენტაცია (მხოლოდ pdf/jpg ფაილები, ჯამში მაქსიმუმ 14MB)</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label ">
                    <strong>პირადობის მოწმობის ან პასპორტის ასლი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_1')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_1'}
                      />
                    ) : null}
                    <br></br>
                    {this.errorExsist('file_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-group row">
                                    <label className="col-md-4 col-form-label ">
                                        <strong>ამონაწერი საჯარო რეესტრიდან</strong><span style={{color:"#e55353"}}>*</span>
                                    </label>
                                    <div className="col-md-8">
                                        <input type="file" accept=".pdf, .jpg, .jpeg" onChange={this.handleFileChange("file_2")}></input>
                                        {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"file_2"} />:null}
                                        <br></br>
                                        {
                                            this.errorExsist("file_2") ?(
                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("file_2")}</label>
                                                ):null
                                        }
                                    </div>
                                </div> */}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სხვა დოკუმენტები</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      id="otherdocumentsfiles"
                      onChange={this.handleFileChange('otherdocumentsfiles')}
                      multiple
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'otherdocumentsfiles'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-md-12" style={{ textAlign: 'right' }}>
                  <label>
                    <input
                      type="checkbox"
                      style={{ verticalAlign: 'middle' }}
                      checked={values.agree}
                      onChange={() => this.handleCheck('agree')}
                    ></input>
                    &nbsp;ვეთანხმები
                    <span
                      style={{ color: '#262262', cursor: 'pointer' }}
                      onClick={this.handleLetterTermsModal}
                    >
                      &nbsp;<strong>წესებსა და პირობებს</strong>
                    </span>
                  </label>
                </div>
                <div className="col-md-12">
                  <ReCAPTCHA
                    style={{ float: 'right' }}
                    sitekey={this.props.params.siteKey}
                    onChange={this.handleChange('recaptchaCode')}
                    hl="ka"
                  ></ReCAPTCHA>
                </div>
                <br />
                <br />
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary"
                    style={{ float: 'right', backgroundColor: '#262262', color: 'white' }}
                    disabled={this.state.recaptchaCode && this.state.agree ? false : true}
                    onClick={this.handleSubmit}
                  >
                    გაგზავნა
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InternalNetworkUpdate;
