import React, { Component } from 'react';
import ToLetters from '../../ToLetters';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import MaskedInput1 from 'react-text-mask';
import TextMask from 'react-text-mask';
import emailMask from 'text-mask-addons/src/emailMask';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import InputInfo from '../InputInfo';
import { translate_number } from '../../reflactor';
import $ from 'jquery';
import * as XLSX from 'xlsx';

class ConnectNewCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkyes_1: 'yes',
      checkyes_4: false,
      checkyes_13: true,
      checkyes_16: true,
      checkyes_18: false,
      checkyes_19: false,
      checkyes_26: false,
      checkyes_47: true,
      cd_dvd: false,
      agree: false,
      fullname: '',
      personal: '',
      address: '',
      phone_1: '',
      phone_2: '',
      email_1: '',
      email_2: '',
      addressto: '',
      cadastral: '',
      power: '',
      amount: '',
      quantity: '',
      household: '',
      nonhousehold: '',
      bankdetails: '',
      otheramount: 0,
      money: '',
      telasi: '',
      gwp: '',
      check_warranty: false,
      warranty: '',
      warranty_deadline: '',
      otherdocumentsfiles: [],
      customer_firstname: '',
      customer_lastname: '',
      customer_personal: '',
      customer_phone_1: '',
      customer_phone_2: '',
      customer_email_1: '',
      customer_email_2: '',
      specified: [],
      smartmetercount: '',
      tools: [{ tool_Id: '-1', quantity: '' }],
      reference_devices: [],
      reference_districts: [],
      reference_streets: [],
      reference_activestreest: [],
      reference_CapacitySteps: [],
      reference_activedistricts: {},
      reference_activestreets_1: {},
      reference_RefCapacitySteps: [],
      reference_FieldsDescriptions: [],
      reference_activeSteps: {},
      reference_allStreets: {},
      addressto_building: '',
      district_Id: '',
      street_Id: '',
      file_9: '',
      file_1: '',
      recaptchaCode: '',
      recaptchaCodeerror: '',
      checked: true,
      errors: [],
      file_15: '',
    };
    this.handleLetterTermsModal = this.props.handleLetterTermsModal;
    this.letterFormValidator = this.props.letterFormValidator;
    this.SendLetter = this.props.SendLetter;
    this.handleSwalPopup = this.props.handleSwalPopup;
    this.checkGeorgianLetters = this.props.checkGeorgianLetters;
    this.fileInputRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    const token = read_cookie('Bearer_Token');
    const url = window.$name;

    Axios.get(`${url}/api/Letters/GetLetterFieldDescriptions/${this.props.letterTypeId}`, {
      headers: {
        Token: token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          this.setState({ reference_FieldsDescriptions: res.data.descriptions });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'udnefined') {
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.replace('/login');
          }
        }
      });

    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      //console.log(data);
      let phone = data.phones.filter((x) => x.typeKey === 1);
      let email = data.emails[0];
      //console.log(phone)
      this.setState({
        fullname: data.name + ' ' + data.lastName,
        personal: data.personalId,
        phone_1: phone ? phone[0].info : '',
        email_1: email ? email.info : '',
        address: data.registrationAddress,
      });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidMount() {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    Axios.get(`${name}/api/Letters/GetReferences?letterTypeId=50`, { headers: { Token: token } })
      .then((res) => {
        // if (res.data.success) {
        //   let data = res.data.references;
        //   let districts = [];
        //   let activedistricts = [];
        //   let streets = [];
        //   let devices = [];
        //   let active_streets = [];
        //   let RefCapacitySteps = [];
        //   let tools = [];

        //   //ერთეულოვანისთვის "RefCapacitySteps":  1-თვითმმართველი ,2-არათვითმმართველი 3და 4 სიმძლავრის გაზრდაა
        //   //console.log(data);
        //   data.forEach((element) => {
        //     switch (element.key) {
        //       case 'RefDevices':
        //         devices.push(element);
        //         let obj1 = { value: element.id, label: element.value };
        //         tools.push(obj1);
        //         break;
        //       case 'RefDistricts':
        //         let obj = { value: element.id, label: element.value };
        //         activedistricts.push(obj);
        //         districts.push(element);
        //         break;
        //       case 'RefStreets':
        //         streets.push(element);
        //         break;
        //       case 'RefCapacitySteps':
        //         RefCapacitySteps.push(element);
        //         break;
        //       default:
        //         break;
        //     }
        //   });
        //   //console.log(RefCapacitySteps);
        //   let val = districts[0].id;
        //   streets.forEach((element) => {
        //     if (element.parentId === val) {
        //       active_streets.push(element);
        //     }
        //   });

        //   //console.log(streets);
        //   this.setState({
        //     reference_devices: devices,
        //     reference_streets: streets,
        //     reference_districts: districts,
        //     reference_activestreest: active_streets,
        //     reference_activedistricts: activedistricts,
        //     reference_RefCapacitySteps: RefCapacitySteps,
        //     reference_tools: tools,
        //   });

        //   this.setActiveSteps(1, RefCapacitySteps);
        // }
        if (res.data.success) {
          let data = res.data.references;
          let districts = [];
          let activedistricts = [];
          let streets = [];
          let devices = [];
          let active_streets = [];
          let RefCapacitySteps = [];
          let tools = [];
          let priceNames = [];
          let allStreets = [];
          let allActiveStreets = [];
          //ერთეულოვანისთვის "RefCapacitySteps":  1-თვითმმართველი ,2-არათვითმმართველი 3და 4 სიმძლავრის გაზრდაა
          //console.log(data);
          data.forEach((element) => {
            switch (element.key) {
              case 'RefDevices':
                devices.push(element);
                let obj1 = { value: element.id, label: element.value };
                tools.push(obj1);
                break;
              case 'RefDistricts':
                let obj = { value: element.id, label: element.value };
                activedistricts.push(obj);
                districts.push(element);
                break;
              case 'RefStreets':
                streets.push(element);
                allStreets.push(element);
                break;
              case 'RefCapacitySteps':
                RefCapacitySteps.push(element);
                //console.log(element)
                break;
              case 'RefPriceNames':
                priceNames.push(element);
                //console.log(element)
                break;
              default:
                break;
            }
          });
          //console.log(devices);
          let val = districts[0].id;
          streets.forEach((element) => {
            if (element.parentId === val) {
              active_streets.push(element);
            }
          });

          //console.log(active_streets);

          allStreets.forEach((element) => {
            if (element.id !== 12797 && element.id !== 14610) {
              let distName = districts.find((c) => c.id == element.parentId)?.value
                ? districts.find((c) => c.id == element.parentId)?.value
                : ' ';
              element.labelText = element.value + ' (' + distName + ')';
              element.districtName = distName;
              let lObj = {
                value: element.id,
                label: element.labelText,
                districtId: element.parentId,
                districtName: distName,
              };
              allActiveStreets.push(lObj);
            }
          });
          //let tmp = allStreets.slice(50,100);
          //console.log(tmp)
          //active_streets = allStreets.slice(100,150);

          //console.log(active_streets);
          this.setState({
            reference_devices: devices,
            reference_streets: streets,
            reference_districts: districts,
            reference_activestreest: active_streets,
            reference_activedistricts: activedistricts,
            reference_RefPriceNames: priceNames,
            reference_RefCapacitySteps: RefCapacitySteps,
            reference_tools: tools,
            reference_allStreets: allActiveStreets,
          });
          //console.log(this.state.reference_allStreets);

          this.setActiveSteps(1, RefCapacitySteps);
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
        //alert("შეცდომა ?");
      });
  }

  selectRef = null;

  clearValue = () => {
    this.selectRef.select.clearValue();
  };

  handleDistrictChange = (selectedOption) => {
    //console.log(selectedOption);
    // if (selectedOption !== null) {
    //   let activestreets = [];
    //   this.state.reference_streets.forEach((element) => {
    //     if (element.parentId == selectedOption.value) {
    //       //console.log([element,selectedOption.value])
    //       let obj = { value: element.id, label: element.value };
    //       activestreets.push(obj);
    //     }
    //   });
    //   this.setState({
    //     district_Id: selectedOption.value,
    //     reference_activestreets_1: activestreets,
    //   });
    //   //this.clearValue();
    // } else {
    //   this.setState({ district_Id: '', street_Id: '', reference_activestreets_1: [] });
    // }
    // this.clearValue();
  };

  handleStreetChange = (selectedOption) => {
    //console.log(selectedOption);
    if (selectedOption !== null)
      this.setState({
        street_Id: selectedOption !== null ? selectedOption.value : '',
        street_Name: selectedOption !== null ? selectedOption.label : '',
        district_Id: selectedOption != null ? selectedOption.districtId : '',
      });
    else {
      this.setState({ street_Id: '', street_Name: '', district_Id: '' });
    }
  };

  setActiveSteps = (parent, RefCapacitySteps) => {
    //console.log([parent,RefCapacitySteps])
    let activeSteps = [];
    RefCapacitySteps.forEach((element) => {
      if (element.parentId == parent) activeSteps.push({ value: element.id, label: element.value });
    });
    this.setState({ reference_activeSteps: activeSteps });
  };

  handleCheck(name) {
    if (name === 'checkyes_47') {
      this.handleSpecifiedAdd(this.state.quantity);
    }
    // else if(name==='check_warranty') {
    //     this.setState({ check_warranty: !this.state.check_warranty });
    // }
    this.setState({ [name]: !this.state[name] });
  }

  handleInputChange = (e, index) => {
    let name = '';
    let value = '';
    //console.log(e.target.name);
    if (e.target.type === 'file') {
      name = e.target.name;
      value = e.target.files[0];
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    const list = [...this.state.documents];
    list[index][name] = value;
    //console.log(list);
    this.setState({ documents: list });
  };

  handleSpecifiedInputChange = (e, index) => {
    let name,
      value = '';
    //console.log(e.target);
    if (e.target.type === 'file') {
      name = e.target.name;
      value = e.target.files[0];
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    const list = [...this.state.specified];
    list[index][name] = value;
    this.setState({ specified: list });
  };

  handleChange = (input) => (e) => {
    let ph = '';
    if (input.includes('phone')) {
      ph = e.target.value.replace(/\D/g, '');
      if (input === 'phone_1') this.setState({ phone_1: ph });
      else if (input === 'phone_2') this.setState({ phone_2: ph });
      else if (input === 'customer_phone_1') this.setState({ customer_phone_1: ph });
      else this.setState({ customer_phone_2: ph });
    } else if (input === 'recaptchaCode') {
      //console.log(e)
      this.setState({ recaptchaCode: e });
    } else if (input === 'household') {
      let t = this.state.quantity;
      if (t !== '') {
        if (!isNaN(parseInt(e.target.value, 10))) {
          t = parseInt(t, 10);
          let a = parseInt(e.target.value, 10);
          let b = 0;
          if (t >= a) {
            b = t - a;
          }
          this.setState({ household: a, nonhousehold: b });
        } else {
          this.setState({ household: e.target.value });
        }
        //console.log(b);
      }
    } else if (input === 'nonhousehold') {
      let t = this.state.quantity;
      if (t !== '') {
        if (!isNaN(parseInt(e.target.value, 10))) {
          t = parseInt(t, 10);
          let a = parseInt(e.target.value, 10);
          let b = 0;
          if (t >= a) {
            b = t - a;
          }
          this.setState({ household: b, nonhousehold: a });
        } else {
          this.setState({ nonhousehold: e.target.value });
        }
        //console.log(b);
      }
    } else if (input === 'quantity') {
      // if (e.target.value === '0' || e.target.value === '1') {
      //   alert('ბინების რაოდენობა უნდა იყოს 1ზე მეტი');
      //   let inp = document.getElementById('quantityInput');
      //   inp.value = '';
      // } else {
      this.setState({ [input]: e.target.value });
      this.handleSpecifiedAdd(e.target.value);
      //   this.setState({ [input]: e.target.value });
      // }
    } else {
      if (!this.state.checked) {
        if (input === 'customer_firstname') {
          this.setState({ customer_lastname: e.target.value });
        }
      }
      this.setState({ [input]: e.target.value });
    }
  };

  isValidNumber = (text, min, max) => {
    const number = parseFloat(text);
    return !isNaN(number) && number >= min && number <= max;
  };

  handleExcelFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // Check if the file type is xlsx
    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.setState({ error: 'ატვირთეთ მხოლოდ .xlsx გაფართოების ფაილი.' });
      return;
    }

    const reader = new FileReader();
    let cnt = 0;
    let household = 0;
    let nonhousehold = 0;
    try {
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = await XLSX.read(data, { type: 'array' });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        //this.handleSpecifiedAdd(e.target.value);

        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const dataRows = [];

        for (const [index, key] of Object.keys(rows).entries()) {
          if (rows[key].length > 0 && index !== 0) {
            cnt++;
            let obj = {
              id: rows[key][0],
              address: rows[key][1],
              fullname: rows[key][2],
              personal: rows[key][3],
              cadastral: rows[key][4],
              power: rows[key][5],
              amount: rows[key][6],
              reason: rows[key][7],
              smartmeter: rows[key][8],
              prepared: rows[key][9],
              will: true,
            };

            if (
              obj.address === '' ||
              obj.fullname === '' ||
              obj.personal === '' ||
              obj.cadastral === ''
            ) {
              alert('არასწორი ინფორმაცია (ცარიელი ველები)');
            }

            if (!this.isValidNumber(obj.power, 0.1, 99.9)) {
              alert('არასწორი ინფორმაცია (სიმძლავრე უნდა იყოს შუალედში 0.1-99.9)');
              return;
            }

            // if (!this.isValidNumber(obj.amount,0.1, 1000000)) {
            //   alert('არასწორი ინფორმაცია (საფასური უნდა იყოს შუალედში 0-100000)');
            //   return;
            // }

            if (obj.reason != 'საყოფაცხოვრებო' && obj.reason != 'არასაყოფაცხოვრებო') {
              //console.log(obj);
              alert(
                'არასწორი ინფორმაცია (მოხმარების მიზანი - დასაშვებია მხოლოდ საყოფაცხოვრებო/არასაყოფაცხოვრებო)'
              );
              return;
            }

            if (obj.smartmeter != 'დიახ' && obj.smartmeter != 'არა') {
              alert('არასწორი ინფორმაცია (ჭკვიანი მრიცხველი - დასაშვებია მხოლოდ დიახ/არა)');
              return;
            }

            if (obj.prepared != 'დიახ' && obj.prepared != 'არა') {
              alert('არასწორი ინფორმაცია (მზადყოფნის დოკუმენტი - დასაშვებია მხოლოდ დიახ/არა)');
              return;
            }

            if (obj.reason === 'საყოფაცხოვრებო') {
              obj.reason = true;
              household++;
            } else {
              obj.reason = false;
              nonhousehold++;
            }

            if (obj.prepared === 'დიახ') obj.prepared = true;
            else obj.prepared = false;

            if (obj.smartmeter === 'დიახ') obj.smartmeter = true;
            else obj.smartmeter = false;

            dataRows.push(obj);
          }
        }

        this.handleSpecifiedAdd(cnt);
        this.setState({ quantity: cnt, household: household, nonhousehold: nonhousehold });
        // for (let i = 0; i < dataRows.length; i++) {
        //   console.log(this.state.specified[i]);
        // }
        this.setState({ specified: dataRows });
        //this.state.quantity = cnt;
      };

      //console.log(this.state);
      reader.readAsArrayBuffer(file);
    } catch (ex) {
      alert('არასწორი ფაილი');
      console.log(ex);
      return;
    }
  };

  handleAddClick = () => {
    this.setState({ documents: [...this.state.documents, { name: '', file: '' }] });
  };

  handleSpecifiedAdd = (quantity) => {
    //console.log(quantity)
    if (quantity !== '' && quantity !== null && typeof quantity !== 'undefined') {
      this.setState({ specified: [] });

      var t = [];
      for (var i = 0; i < quantity; i++) {
        t.push({
          address: '',
          fullname: '',
          personal: '',
          cadastral: '',
          power: '',
          amount: '',
          reason: true,
          prepared: false,
          will: true,
          smartmeter: false,
        });
        // this.setState({specified:[...this.state.specified,{address:"",fullname:"",personal:"",cadastral:"",power:"",amount:"",reason:false,prepared:false,will:true}]});
      }
      this.setState({ specified: t });
    } else {
      this.setState({ specified: [] });
    }
  };

  handleSpecifiedRemove = (index) => {
    this.state.specified.splice(index, 1);
    this.setState({ specified: this.state.specified });
  };

  handleSpecifiedBool = (e, index, name) => {
    //console.log([e,index,name]);
    const list = [...this.state.specified];
    switch (name) {
      case 'reason':
        var a = list[index]['reason'];
        list[index]['reason'] = !a;
        //console.log([index,list[index]["reason"]]);
        break;
      case 'prepared':
        var b = list[index]['prepared'];
        list[index]['prepared'] = !b;
        break;
      case 'will':
        var c = list[index]['will'];
        list[index]['will'] = !c;
        break;
      case 'smartmeter':
        var c = list[index]['smartmeter'];
        list[index]['smartmeter'] = !c;
        break;
      default:
        break;
    }
    //console.log([list[index][name],name]);
    this.setState({ specified: list });
  };

  handleRemoveClick = (index) => {
    this.state.documents.splice(index, 1);
    this.setState({ documents: this.state.documents });
  };

  handleFileChange = (input) => (event) => {
    if (input === 'otherdocumentsfiles') {
      if (event.target.files.length > 3) {
        this.handleSwalPopup(
          'ფაილების მაქსიმალური რაოდენობა არის 3',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
        document.getElementById('otherdocumentsfiles').value = '';
        //this.setState({[input]:[]});
      } else {
        this.setState({ [input]: event.target.files });
      }
    } else {
      this.setState({ [input]: event.target.files[0] });
    }
  };

  handleThree = (name) => {
    switch (name) {
      case 'yes':
        this.setState({ checkyes_1: 'yes' });
        break;
      case 'no':
        this.setState({ checkyes_1: 'no' });
        break;
      case 'already':
        this.setState({ checkyes_1: 'already' });
        break;
      default:
        break;
    }
  };

  handleSubmit = () => {
    let isValid = this.formValidate();

    let data = [];

    if (isValid) {
      for (const [key, value] of Object.entries(this.state)) {
        let obj = {};
        if (!key.includes('error') && !key.includes('refe') && !key.includes('recap')) {
          if (value !== '' && value !== null) {
            obj.key = key;
            obj.value = value;
            data.push(obj);
          }
        }
      }
      this.SendLetter(this.state.custN, this.props.letterTypeId, this.state.recaptchaCode, data);
    } else {
      //var filesError = this.state.errors.filter(x=>x.key==="filesSize"); if(filesError.length) filesError=filesError[0].value;
      if (this.state.errorFileSize) {
        this.handleSwalPopup(
          'ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      } else {
        this.handleSwalPopup(
          'შესავსებია სავალდებულო ველები',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      }
    }
  };

  handleToolAddClick = () => {
    this.setState({ tools: [...this.state.tools, { tool_Id: '3', quantity: '' }] });
  };

  handleToolRemoveClick = (index) => {
    this.state.tools.splice(index, 1);
    this.setState({ tools: this.state.tools });
  };

  handleToolInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.tools];
    list[index][name] = value;
    this.setState({ tools: list });
  };

  handleAddressChange = (value) => {
    //console.log(value);
    let streets = [];
    this.state.reference_streets.forEach((element) => {
      if (element.parentId == value) streets.push(element);
      //console.log([value,element.parentId]);
    });
    this.setState({
      reference_activestreest: streets,
      district_Id: value,
      street_Id: streets[0].id,
    });
  };

  handleToolChange = (e, index) => {
    //console.log([e,index]);
    const { value, label } = e;
    //console.log([value,label]);
    const list = [...this.state.tools];
    list[index]['tool_Id'] = value;
    this.setState({ tools: list });
    //console.log(list)
  };

  handleQuantityBlur = () => {
    //console.log(this.state.quantity);
    if (this.state.quantity <= 1) {
      let inp = document.getElementById('quantityInput');
      inp.value = '';
      this.setState({ quantity: '' });
      alert('ბინების რაოდენობა უნდა იყოს 1ზე მეტი');
    }
  };

  formValidate() {
    const values = this.state;

    let data = [];

    for (const [key, value] of Object.entries(values)) {
      if (!key.includes('recap') && !key.includes('refe') && !key.includes('error')) {
        let obj = {};
        obj.name = key;
        obj.value = value;
        if (
          key === 'fullname' ||
          key === 'personal' ||
          key === 'phone_1' ||
          key === 'amount' ||
          key === 'power' ||
          key === 'address' ||
          key === 'cadastral' ||
          key === 'district_Id' ||
          key === 'street_Id' ||
          key === 'addressto_building' ||
          key === 'quantity' ||
          key === 'custN' ||
          key === 'amount' ||
          key === 'household' ||
          key === 'nonhousehold' ||
          key === 'money' ||
          key === 'file_1' ||
          key === 'file_9' ||
          key === 'customer_firstname' ||
          key === 'customer_personal' ||
          key === 'customer_phone_1' ||
          key === 'tools' ||
          key === 'specified' ||
          key === 'file_15'
        )
          obj.Required = true;
        // else if(key==="telasi"||key==="gwp"){
        //     if(values.checkyes_18) obj.Required=true;
        // }
        else if (key === 'warranty') {
          if (values.check_warranty) obj.Required = true;
        } else if (key === 'warranty_deadline') {
          if (values.check_warranty) obj.Required = true;
        } else if (key === 'customer_lastname') {
          if (this.state.checked == true) obj.Required = true;
          else obj.Required = false;
        } else obj.Required = false;

        data.push(obj);
      }
    }

    let result = this.letterFormValidator(this.props.letterTypeId, data);
    const found = result.errors.find((x) => x.key === 'filesSize');
    if (typeof found !== 'undefined') {
      //console.log(1234);
      this.state.errorFileSize = true;
      this.setState({ errors: result.errors });
      //this.handleSwalPopup("ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს","warning",false,"","დახურვა",false,"#262262");
    } else {
      this.state.errorFileSize = false;
      this.setState({ errors: result.errors });
    }

    return result.isValid;
  }

  handleRepeat = () => {
    if (this.state.checked) {
      if (typeof this.props.params.user !== 'undefined') {
        const data = this.props.params.user;
        this.setState({
          customer_firstname: data.name,
          customer_personal: this.state.personal,
          customer_lastname: data.lastName,
          customer_phone_1: this.state.phone_1,
          customer_phone_2: this.state.phone_2,
          customer_email_1: this.state.email_1,
          customer_email_2: this.state.email_2,
        });
      }
    } else {
      this.setState({
        customer_phone_1: this.state.phone_1,
        customer_phone_2: this.state.phone_2,
        customer_email_1: this.state.email_1,
        customer_email_2: this.state.email_2,
      });
    }
  };

  handlePhysicalSwitch = () => {
    if (this.state.checked) {
      this.setState({
        checked: !this.state.checked,
        customer_firstname: '',
        customer_lastname: '',
        customer_personal: '',
      });
    } else {
      this.setState({ checked: !this.state.checked });
    }
  };

  errorExsist = (key) => {
    let bool = this.state.errors.some((obj) => obj.key == key);
    return bool;
  };

  renderErrorText = (key) => {
    let bool = this.state.errors.find((obj) => obj.key.includes(key) === true);
    return bool.value;
  };

  render() {
    const values = this.state;

    let phone, email;
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      phone = data.phones.filter((x) => x.typeKey === 1);
      email = data.emails[0];
    }
    let l = true;
    var _this = this;
    if (values.reference_FieldsDescriptions.length === 0) l = false;
    $('#amount').on(updateData).keyup(updateData);
    function updateData() {
      var number = $('#amount').val();
      var translation = translate_number(number, false);
      if (_this.state.money !== translation) _this.setState({ money: translation });
    }
    //console.log(this.state.specified);
    // var number = _this.state.amount;
    // var translation = translate_number(number, false);
    // if(_this.state.totalfeeT!==translation&&_this.state.totalfeeN!=="") _this.setState({totalfeeT:translation});

    //console.log(values.specified);

    return (
      <React.Fragment>
        <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div style={{ float: 'left' }}>
                  <ToLetters></ToLetters>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span>
                    <strong>{this.props.letterTitle}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>განმცხადებელი</strong>
              </div>
              <div className="card-body">
                <div className="form-horizontal">
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>განმცხადებლი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('fullname')}
                        readOnly={this.props.params.user.fullName ? true : false}
                        placeholder="სახელი და გვარი, ან იურიდიული პირის შემთხვევაში - სახელწოდება"
                        name="fullname"
                        value={values.fullname}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'fullname'}
                        />
                      ) : null}
                      {this.errorExsist('fullname') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('fullname')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>პირადი ნომერი/საიდენტიფიკაციო კოდი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('personal')}
                        readOnly={this.props.params.user.personalId ? true : false}
                        placeholder="პირადი ნომერი/საიდენტიფიკაციო კოდი"
                        value={values.personal}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'personal'}
                        />
                      ) : null}
                      {this.errorExsist('personal') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('personal')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მისამართი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('address')}
                        readOnly={this.props.params.user.registrationAddress ? true : false}
                        placeholder="საცხოვრებელი მისამართი"
                        value={values.address}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'address'}
                        />
                      ) : null}
                      {this.errorExsist('address') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('address')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მობილური (ძირითადი)</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <MaskedInput1
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={true}
                        readOnly={
                          typeof phone[0].info !== 'undefined' && phone[0].info ? true : false
                        }
                        className="form-control"
                        type="text"
                        placeholder="ძირითადი"
                        name="phone_1"
                        onChange={this.handleChange('phone_1')}
                        value={values.phone_1}
                      ></MaskedInput1>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'phone_1'}
                        />
                      ) : null}
                      {this.errorExsist('phone_1') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('phone_1')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მობილური (ალტერნატიული)</strong>
                    </label>
                    <div className="col-md-8">
                      <MaskedInput1
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={true}
                        className="form-control"
                        type="text"
                        placeholder="ალტერნატიული"
                        name="phone_2"
                        onChange={this.handleChange('phone_2')}
                        value={values.phone_2}
                      ></MaskedInput1>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'phone_2'}
                        />
                      ) : null}
                      {this.errorExsist('phone_2') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('phone_2')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ელ. ფოსტა (ძირითადი)</strong>
                    </label>
                    <div className="col-md-8">
                      <TextMask
                        className="form-control"
                        placeholder="ძირითადი"
                        readOnly={typeof email !== 'undefined' ? true : false}
                        name="email_1"
                        onChange={this.handleChange('email_1')}
                        value={values.email_1}
                        mask={emailMask.mask}
                        pipe={emailMask.pipe}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'email_1'}
                        />
                      ) : null}
                      {this.errorExsist('email_1') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('email_1')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ელ. ფოსტა (ალტერნატიული)</strong>
                    </label>
                    <div className="col-md-8">
                      <TextMask
                        className="form-control"
                        placeholder="ალტერნატიული"
                        name="email_2"
                        onChange={this.handleChange('email_2')}
                        value={values.email_2}
                        mask={emailMask.mask}
                        pipe={emailMask.pipe}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'email_2'}
                        />
                      ) : null}
                      {this.errorExsist('email_2') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('email_2')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>ინფორმაცია მისაერთებელი ობიექტის შესახებ</strong>
                <div className="card-header-actions">
                  <button className="btn btn-primary btn-sm" onClick={this.handleRepeat}>
                    განმცხადებლის მონაცემების გადმოტანა
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="form-horizontal">
                  <BootstrapSwitchButton
                    width={200}
                    checked={values.checked}
                    onlabel="ფიზიკური"
                    offlabel="იურიდიული"
                    onChange={this.handlePhysicalSwitch}
                  ></BootstrapSwitchButton>
                  <br />
                  <br />
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>{values.checked ? 'სახელი' : 'დასახელება'}</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('customer_firstname')}
                        placeholder={values.checked ? 'სახელი' : 'დასახელება'}
                        value={values.customer_firstname}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_firstname'}
                        />
                      ) : null}
                      {this.errorExsist('customer_firstname') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_firstname')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  {values.checked ? (
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        <strong>გვარი</strong>
                        <span style={{ color: '#e55353' }}>*</span>
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          onChange={this.handleChange('customer_lastname')}
                          value={values.customer_lastname}
                          placeholder="გვარი"
                        ></input>
                        {l ? (
                          <InputInfo
                            descriptions={values.reference_FieldsDescriptions}
                            letterTypeId={this.props.letterTypeId}
                            inputName={'customer_lastname'}
                          />
                        ) : null}
                        {this.errorExsist('customer_lastname') ? (
                          <label style={{ fontSize: 10, color: '#e55353' }}>
                            {this.renderErrorText('customer_lastname')}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        {values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}
                      </strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('customer_personal')}
                        value={values.customer_personal}
                        placeholder={values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_personal'}
                        />
                      ) : null}
                      {this.errorExsist('customer_personal') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_personal')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მობილურის ნომერი(ძირითადი)</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <MaskedInput1
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={true}
                        className="form-control"
                        type="text"
                        placeholder="ძირითადი"
                        name="customer_phone_1"
                        onChange={this.handleChange('customer_phone_1')}
                        value={values.customer_phone_1}
                      ></MaskedInput1>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_phone_1'}
                        />
                      ) : null}
                      {this.errorExsist('customer_phone_1') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_phone_1')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მობილურის ნომერი(ალტერნატიული)</strong>
                    </label>
                    <div className="col-md-8">
                      <MaskedInput1
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={true}
                        className="form-control"
                        type="text"
                        placeholder="ალტერნატიული"
                        name="customer_phone_2"
                        onChange={this.handleChange('customer_phone_2')}
                        value={values.customer_phone_2}
                      ></MaskedInput1>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_phone_2'}
                        />
                      ) : null}
                      {this.errorExsist('customer_phone_2') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_phone_2')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ელ. ფოსტა(ძირითადი)</strong>
                    </label>
                    <div className="col-md-8">
                      <TextMask
                        className="form-control"
                        placeholder="ძირითადი"
                        name="customer_email_1"
                        onChange={this.handleChange('customer_email_1')}
                        value={values.customer_email_1}
                        mask={emailMask.mask}
                        pipe={emailMask.pipe}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_email_1'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ელ. ფოსტა(ალტერნატიული)</strong>
                    </label>
                    <div className="col-md-8">
                      <TextMask
                        className="form-control"
                        placeholder="ალტერნატიული"
                        name="customer_email_2"
                        onChange={this.handleChange('customer_email_2')}
                        value={values.customer_email_2}
                        mask={emailMask.mask}
                        pipe={emailMask.pipe}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_email_2'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მიერთების მსურველის (განმცხადებლის) საბანკო რეკვიზიტები</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('bankdetails')}
                        placeholder="მიერთების  მსურველის  (განმცხადებლის) საბანკო  რეკვიზიტები"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'bankdetails'}
                        />
                      ) : null}
                      {this.errorExsist('bankdetails') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('bankdetails')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მისაერთებელი ობიექტი მდებარეობს თვითმმართველ ქალაქში</strong>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_13')}
                          onClick={() => this.handleCheck('checkyes_13')}
                          checked={values.checkyes_13}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_13')}
                          onClick={() => this.handleCheck('checkyes_13')}
                          checked={!values.checkyes_13}
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_13'}
                        />
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>რაიონი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <Select
                        isClearable
                        isSearchable
                        options={this.state.reference_activedistricts}
                        onChange={this.handleDistrictChange}
                        placeholder="აირჩიეთ"
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'district_Id'}
                        />
                      ) : null}
                      {this.errorExsist('district_Id') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('district_Id')}
                        </label>
                      ) : null}
                    </div>
                  </div> */}
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ქუჩა</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <Select
                        ref={(ref) => {
                          this.selectRef1 = ref;
                        }}
                        isClearable
                        isSearchable
                        options={this.state.reference_allStreets}
                        onChange={this.handleStreetChange}
                        placeholder="აირჩიეთ ან აკრიფეთ"
                        noOptionsMessage={() => 'ცარიელია'}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'street_Id'}
                        />
                      ) : null}
                      {this.errorExsist('street_Id') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('street_Id')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>შენობის ნომერი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        maxLength="50"
                        className="form-control"
                        onChange={this.handleChange('addressto_building')}
                        placeholder="შენობის ნომერი"
                        value={values.addressto_building}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'addressto_building'}
                        />
                      ) : null}
                      {this.errorExsist('addressto_building') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('addressto_building')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>უძრავი ქონების საკადასტრო კოდი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('cadastral')}
                        placeholder="საკადასტრო კოდი"
                        value={values.cadastral}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'cadastral'}
                        />
                      ) : null}
                      {this.errorExsist('cadastral') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('cadastral')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>სხვა პირობები</strong>
              </div>
              <div className="card-body">
                <div className="form-horizontal">
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გამანაწილებელ ქსელზე მისაერთებელი ობიექტის ჯამური სიმძლავრე</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('power')}
                        placeholder="მ&sup3;"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'power'}
                        />
                      ) : null}
                      {this.errorExsist('power') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('power')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გამანაწილებელ ქსელზე მიერთების საფასური ჯამურად</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        id="amount"
                        onChange={this.handleChange('amount')}
                        placeholder="ლარი"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'amount'}
                        />
                      ) : null}
                      {this.errorExsist('amount') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('amount')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        განაცხადით მოთხოვნილ აბონენტთა (ინდ. აღრიცხვის საშუალებების) საერთო
                        რაოდენობა
                      </strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="quantityInput"
                        type="number"
                        min="0"
                        onBlur={this.handleQuantityBlur}
                        onChange={this.handleChange('quantity')}
                        placeholder="რაოდენობა"
                        value={values.quantity}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'quantity'}
                        />
                      ) : null}
                      {this.errorExsist('quantity') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('quantity')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        მათ შორის, 0-6 კუბ.მ/სთ სიმძლავრის ჭკვიანი მრიცხველის მოთხოვნის შემთხვევაში,
                        მოთხოვნილი ჭკვიანი მრიცხველების რაოდენობა
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        step="1"
                        placeholder="რაოდენობა"
                        onChange={this.handleChange('smartmetercount')}
                        value={values.smartmetercount}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'smartmetercount'}
                        />
                      ) : null}
                      {this.errorExsist('smartmetercount') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('smartmetercount')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-12 col-form-label">
                      <strong>საყოფაცხოვრებო და არასაყოფაცხოვრებო აბონენტთა რაოდენობა: </strong>
                    </label>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>საყოფაცხოვრებო</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('household')}
                        value={values.household}
                        placeholder="ციფრებში"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'household'}
                        />
                      ) : null}
                      {this.errorExsist('household') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('household')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>არასაყოფაცხოვრებო</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onChange={this.handleChange('nonhousehold')}
                        value={values.nonhousehold}
                        placeholder="ციფრებში "
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'nonhousehold'}
                        />
                      ) : null}
                      {this.errorExsist('nonhousehold') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('nonhousehold')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="form-group row">
                                        <label className="col-md-4 col-form-label"><strong>დამატებით განცალკევებული აღრიცხვა (საერთო მიზნებისთვის, გათბობის სისტემა და სხვა)</strong></label>
                                        <div className="col-md-8">
                                            <input className="form-control" type="number" min="0" onChange={this.handleChange("otheramount")} placeholder="ციფრებში"></input>
                                            {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"otheramount"} />:null}
                                            {
                                                this.errorExsist("otheramount") ?(
                                                    <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("otheramount")}</label>
                                                    ):null
                                            }
                                        </div>
                                    </div> */}

                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გესაჭიროებათ საგადასახადო ანგარიშ-ფაქტურა</strong>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_4')}
                          onClick={() => this.handleCheck('checkyes_4')}
                          checked={values.checkyes_4}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_4')}
                          onClick={() => this.handleCheck('checkyes_4')}
                          checked={!values.checkyes_4}
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_4'}
                        />
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="form-group row">
                                        <label className="col-md-4 col-form-label"><strong>ობიექტის არქიტექტურული პროექტის შესაბამისი გაზმომარაგების სქემა ელექტრონული ფორმით (ბეჭდური განაცხადის შემთხვევაში CD ან DVD ფორმატით) </strong></label>
                                        <div className="col-md-8">
                                            <label><input type="checkbox" onChange={()=>this.handleCheck("cd_dvd")} onClick={()=>this.handleCheck("cd_dvd")} checked={values.cd_dvd}></input>&nbsp;დიახ</label>
                                            {l?<InputInfo descriptions={values.reference_FieldsDescriptions} letterTypeId={this.props.letterTypeId} inputName={"cd_dvd"} />:null}
                                        </div>
                                    </div> */}
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        განაცხადში მოცემული ჩემი პირადი ნომერი, ტელეფონის ნომერი და ელ. ფოსტის
                        მისამართი: გამოყენებულ იქნეს გაზმომარაგებასთან დაკავშირებული ინფორმაციის
                        შეტყობინების მიზნით
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_18')}
                          onClick={() => this.handleCheck('checkyes_18')}
                          checked={values.checkyes_18}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_18')}
                          onClick={() => this.handleCheck('checkyes_18')}
                          checked={!values.checkyes_18}
                        ></input>
                        არა
                      </label>
                      <br />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_18'}
                        />
                      ) : null}
                      {/* {
                                                values.checkyes_18?(
                                                    <React.Fragment>
                                                        <input className="form-control" onChange={this.handleChange("telasi")} placeholder="ელ. ენერგია"></input>
                                                        {
                                                            this.errorExsist("telasi") ?(
                                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("telasi")}</label>
                                                                ):null
                                                        }
                                                        <br/>
                                                        <input className="form-control" onChange={this.handleChange("gwp")} placeholder="წყალმომარაგება"></input>
                                                        {
                                                            this.errorExsist("gwp") ?(
                                                                <label style={{fontSize: 10,color:"#e55353"}}>{this.renderErrorText("gwp")}</label>
                                                                ):null
                                                        }
                                                    </React.Fragment>
                                                )
                                                :
                                                null
                                            } */}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        თანახმა ვარ, ქვითარი მივიღო ელექტრონული ფორმით რეგისტრაციის დროს მითითებული
                        მონაცემების შესაბამისად:
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleThree('yes')}
                          onClick={() => this.handleThree('yes')}
                          checked={values.checkyes_1 === 'yes' ? true : false}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleThree('no')}
                          onClick={() => this.handleThree('no')}
                          checked={values.checkyes_1 === 'no' ? true : false}
                        ></input>
                        &nbsp;არა
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleThree('already')}
                          onClick={() => this.handleThree('already')}
                          checked={values.checkyes_1 === 'already' ? true : false}
                        ></input>
                        &nbsp;უკვე ვსარგებლობ ამ მომსახურებით
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_1'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>საგარანტიო</strong>
                    </label>
                    <div className="col-md-4">
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('check_warranty')}
                          checked={this.state.check_warranty}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('check_warranty')}
                          checked={!this.state.check_warranty}
                        ></input>
                        &nbsp;არა
                      </label>
                    </div>
                  </div>
                  {this.state.check_warranty && (
                    <>
                      <div className="form-group row">
                        <label className="col-md-4 col-form-label">
                          <strong>
                            საგარანტიოს ნომერი <span style={{ color: '#e55353' }}>*</span>
                          </strong>
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            onChange={this.handleChange('warranty')}
                            placeholder="საგარანტიოს ნომერი"
                            value={this.state.warranty}
                          ></input>
                          {l ? (
                            <InputInfo
                              descriptions={values.reference_FieldsDescriptions}
                              letterTypeId={this.props.letterTypeId}
                              inputName={'warranty'}
                            />
                          ) : null}
                          {this.errorExsist('warranty') ? (
                            <label style={{ fontSize: 10, color: '#e55353' }}>
                              {this.renderErrorText('warranty')}
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-4 col-form-label">
                          <strong>საგარანტიოს ვადა</strong>
                          <span style={{ color: '#e55353' }}>*</span>
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="date"
                            id="warranty_deadline"
                            placeholder=""
                            onChange={this.handleChange('warranty_deadline')}
                          ></input>
                          {l ? (
                            <InputInfo
                              descriptions={values.reference_FieldsDescriptions}
                              letterTypeId={this.props.letterTypeId}
                              inputName={'warranty_deadline'}
                            />
                          ) : null}
                          {this.errorExsist('warranty_deadline') ? (
                            <label style={{ fontSize: 10, color: '#e55353' }}>
                              {this.renderErrorText('warranty_deadline')}
                            </label>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        მომხმარებლების (აბონენტების) მიხედვით მისაერთებელი სიმძლავრე, გამანაწილებელ
                        ქსელზე მიერთების საფასური და ამ მომხმარებლების დაზუსტებული მისამართები
                        (ივსება დანართის 1. მიხედვით){' '}
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="checkbox"
                          readOnly
                          // onChange={() => this.handleCheck('checkyes_47')}
                          // onClick={() => this.handleCheck('checkyes_47')}
                          checked={values.checkyes_47}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_47'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გადახდილი თანხა სიტყვიერად</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="money"
                        onChange={this.handleChange('money')}
                        placeholder="გადახდილი თანხა სიტყვიერად"
                        value={values.money}
                      ></input>
                      {this.errorExsist('money') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('money')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  {values.tools.map((val, index) => {
                    return (
                      <div className="form-group row" key={index}>
                        <br></br>
                        <label className="col-md-4 col-form-label">
                          {index === 0 ? (
                            <strong>
                              დანადგარები<span style={{ color: '#e55353' }}>*</span>
                            </strong>
                          ) : null}
                        </label>
                        <div className="col-md-3">
                          <Select
                            isSearchable
                            options={values.reference_tools}
                            onChange={(e) => this.handleToolChange(e, index)}
                            placeholder="აირჩიეთ"
                          />
                        </div>
                        <div className="col-md-2">
                          <input
                            className="form-control"
                            type="number"
                            name="quantity"
                            min="0"
                            onChange={(e) => this.handleToolInputChange(e, index)}
                            placeholder="რაოდენობა"
                          ></input>
                        </div>
                        <div className="btn-box col-md-2">
                          {values.tools.length !== 1 && (
                            <button
                              className=""
                              type="button"
                              onClick={(e) => this.handleToolRemoveClick(index)}
                            >
                              წაშლა
                            </button>
                          )}
                          &nbsp;
                          {values.tools.length - 1 === index && (
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={this.handleToolAddClick}
                            >
                              დამატება
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label" />
                    <div className="col-md-8">
                      {this.errorExsist('tools') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('tools')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>
                  თანდართული დოკუმენტაცია (მხოლოდ pdf/jpg ფაილები, ჯამში მაქსიმუმ 14MB)
                </strong>
              </div>
              <div className="card-body">
                <div className="form-horizontal">
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>პირადობის მოწმობის ან პასპორტის ასლი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg"
                        onChange={this.handleFileChange('file_1')}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'file_1'}
                        />
                      ) : null}
                      {this.errorExsist('file_1') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('file_1')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მიერთების საფასურის სრულად გადახდის დამადასტურებელი საბუთი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg"
                        onChange={this.handleFileChange('file_9')}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'file_9'}
                        />
                      ) : null}
                      {this.errorExsist('file_9') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('file_9')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>დანართი 1</strong>
                      <span
                        style={{
                          color: '#e55353',
                        }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg"
                        onChange={this.handleFileChange('file_15')}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'file_15'}
                        />
                      ) : null}
                      {this.errorExsist('file_15') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('file_15')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>სხვა დოკუმენტები</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg"
                        id="otherdocumentsfiles"
                        onChange={this.handleFileChange('otherdocumentsfiles')}
                        multiple
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'otherdocumentsfiles'}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>აბონენტების მიხედვით დაზუსტებული მისამართები</strong>
                <div className="card-header-actions">
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={this.fileInputRef}
                    accept=".xlsx"
                    onChange={this.handleExcelFileUpload}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.fileInputRef.current.click()}
                  >
                    ექსელის იმპორტი
                  </button>
                </div>
              </div>
              <div className="card-body">
                {values.checkyes_47 ? (
                  <React.Fragment>
                    <div className="col-lg-12" style={{ textAlign: 'center' }}>
                      <h3>
                        <strong>აბონენტების მიხედვით დაზუსტებული მისამართები</strong>
                      </h3>
                    </div>
                    <br />
                    <div style={{ overflowX: 'auto' }}>
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '0',
                          width: 'auto',
                          border: '1px solid #ddd',
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: 'center',
                                width: '250px',
                                wordWrap: 'break-word',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              დაზუსტებული მისამართი
                            </th>
                            <th
                              style={{
                                textAlign: 'center',
                                width: '200px',
                                wordWrap: 'break-word',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              სახელი, გვარი ან იურიდიული პირის დასახელება
                            </th>
                            <th
                              style={{
                                textAlign: 'center',
                                width: '200px',
                                wordWrap: 'break-word',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              პ/ნ, ან ს/კ
                            </th>
                            <th
                              style={{
                                textAlign: 'center',
                                width: '250px',
                                wordWrap: 'break-word',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              საკადასტრო კოდი
                            </th>
                            <th
                              style={{
                                textAlign: 'center',
                                width: '100px',
                                wordWrap: 'break-word',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              სიმძლავრე
                            </th>
                            <th
                              style={{
                                textAlign: 'center',
                                width: '100px',
                                wordWrap: 'break-word',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              საფასური
                            </th>
                            <th
                              style={{
                                textAlign: 'center',
                                width: '150px',
                                wordWrap: 'break-word',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              მოხმარების მიზანი
                            </th>
                            <th
                              style={{
                                textAlign: 'center',
                                width: '100px',
                                wordWrap: 'break-word',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              ჭკვიანი მრიცხველი
                            </th>
                            <th
                              style={{
                                textAlign: 'center',
                                width: '80px',
                                wordWrap: 'break-word',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              მზადყოფნის დოკუმენტი
                            </th>
                          </tr>
                        </thead>
                        {this.state.specified.map((item, index) => {
                          return (
                            <tbody>
                              <React.Fragment>
                                <tr key={index}>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                    <input
                                      className="form-control"
                                      onChange={(e) => this.handleSpecifiedInputChange(e, index)}
                                      name="address"
                                      placeholder="მისამართი"
                                      value={item.address}
                                    ></input>
                                  </td>
                                  <td
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px',
                                    }}
                                  >
                                    <input
                                      className="form-control"
                                      onChange={(e) => this.handleSpecifiedInputChange(e, index)}
                                      name="fullname"
                                      placeholder="აბონენტის დასახელება"
                                      value={item.fullname}
                                    ></input>
                                  </td>
                                  <td
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px',
                                      width: '140px !important',
                                    }}
                                  >
                                    <input
                                      className="form-control"
                                      onChange={(e) => this.handleSpecifiedInputChange(e, index)}
                                      name="personal"
                                      placeholder="პ/ნ, ან ს/კ"
                                      style={{ width: '100px' }}
                                      value={item.personal}
                                    ></input>
                                  </td>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                    <input
                                      className="form-control"
                                      onChange={(e) => this.handleSpecifiedInputChange(e, index)}
                                      name="cadastral"
                                      placeholder="საკადასტრო კოდი"
                                      value={item.cadastral}
                                    ></input>
                                  </td>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                    <input
                                      className="form-control"
                                      type="number"
                                      min="0"
                                      onChange={(e) => this.handleSpecifiedInputChange(e, index)}
                                      name="power"
                                      placeholder="სიმძლავრე"
                                      value={item.power}
                                    ></input>
                                  </td>
                                  <td
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px',
                                      width: '140px !important',
                                    }}
                                  >
                                    <input
                                      className="form-control"
                                      type="number"
                                      onChange={(e) => this.handleSpecifiedInputChange(e, index)}
                                      name="amount"
                                      placeholder="საფასური"
                                      value={item.amount}
                                      style={{ width: '80px' }}
                                    ></input>
                                  </td>
                                  <td
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px',
                                      fontSize: '8.5px !important',
                                    }}
                                  >
                                    <BootstrapSwitchButton
                                      style="size-8-5"
                                      width={100}
                                      onlabel="საყოფაცხოვრებო"
                                      offlabel="არასაყოფაცხოვრებო"
                                      checked={item.reason}
                                      onChange={(e) => this.handleSpecifiedBool(e, index, 'reason')}
                                    ></BootstrapSwitchButton>
                                    {/* <label><input type="radio" onChange={e=>this.handleSpecifiedBool(e,index,"reason")}  checked={item.reason}></input>&nbsp;საყოფაცხოვრებო</label>&nbsp;
                                                                            <label><input type="radio" onChange={e=>this.handleSpecifiedBool(e,index,"reason")}  checked={!item.reason}></input>&nbsp;არასაყოფაცხოვრებო</label> */}
                                  </td>
                                  <td
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px',
                                      fontSize: '10.5px',
                                    }}
                                  >
                                    <label>
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          this.handleSpecifiedBool(e, index, 'smartmeter')
                                        }
                                        checked={item.smartmeter}
                                      ></input>
                                      &nbsp;დიახ
                                    </label>
                                    &nbsp;
                                    <br />
                                    <label>
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          this.handleSpecifiedBool(e, index, 'smartmeter')
                                        }
                                        checked={!item.smartmeter}
                                      ></input>
                                      &nbsp;არა
                                    </label>
                                  </td>
                                  <td
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px',
                                      fontSize: '10.5px',
                                    }}
                                  >
                                    <label>
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          this.handleSpecifiedBool(e, index, 'prepared')
                                        }
                                        checked={item.prepared}
                                      ></input>
                                      &nbsp;დიახ
                                    </label>
                                    &nbsp;
                                    <label>
                                      <input
                                        type="radio"
                                        onChange={(e) =>
                                          this.handleSpecifiedBool(e, index, 'prepared')
                                        }
                                        checked={!item.prepared}
                                      ></input>
                                      &nbsp;არა
                                    </label>
                                    &nbsp;
                                  </td>
                                </tr>
                              </React.Fragment>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                    <br />
                    {this.errorExsist('specified') ? (
                      <label style={{ fontSize: 14, color: '#e55353' }}>
                        {this.renderErrorText('specified')}
                      </label>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col-md-12" style={{ textAlign: 'right' }}>
                    <label>
                      <input
                        type="checkbox"
                        style={{ verticalAlign: 'middle' }}
                        checked={values.agree}
                        onChange={() => this.handleCheck('agree')}
                      ></input>
                      &nbsp;ვეთანხმები
                      <span
                        style={{ color: '#262262', cursor: 'pointer' }}
                        onClick={this.handleLetterTermsModal}
                      >
                        &nbsp;<strong>წესებსა და პირობებს</strong>
                      </span>
                    </label>
                  </div>
                  <br />
                  <br />
                  <div className="col-md-12">
                    <ReCAPTCHA
                      style={{ float: 'right' }}
                      sitekey={this.props.params.siteKey}
                      onChange={this.handleChange('recaptchaCode')}
                      hl="ka"
                    ></ReCAPTCHA>
                  </div>
                  <br />
                  <br />

                  <br />
                  <br />
                  <div className="col-md-9"></div>
                  <div className="col-md-3">
                    <button
                      className="btn btn-primary"
                      style={{ float: 'right', backgroundColor: '#262262', color: 'white' }}
                      disabled={this.state.recaptchaCode && this.state.agree ? false : true}
                      onClick={this.handleSubmit}
                    >
                      გაგზავნა
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ConnectNewCustomers;
