import React, { Component } from 'react';
import ToLetters from '../../ToLetters';
import axios from 'axios';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import MaskedInput1 from 'react-text-mask';
import TextMask from 'react-text-mask';
import emailMask from 'text-mask-addons/src/emailMask';
import ReCAPTCHA from 'react-google-recaptcha';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Select from 'react-select';
import InputInfo from '../InputInfo';
//import $ from 'jquery';

class ConnectNewCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkyes_1: 'yes',
      checkyes_4: false,
      checkyes_6: true,
      checkyes_13: true,
      checkyes_14: true,
      checkyes_14_1: false,
      checkyes_15: false,
      checkyes_16: true,
      checkyes_18: false,
      checkyes_20: false,
      checkyes_23: false,
      checkyes_24: false,
      agree: false,
      checked: true,
      check_warranty: false,
      warranty: '',
      warranty_deadline: '',
      fullname: '',
      personal: '',
      address: '',
      phone_1: '',
      phone_2: '',
      email_1: '',
      email_2: '',
      addressto: '',
      cadastral: '',
      customer_firstname: '',
      customer_lastname: '',
      customer_personal: '',
      customer_phone_1: '',
      customer_phone_2: '',
      customer_email_1: '',
      customer_email_2: '',
      peakpower: '',
      lowpower: '',
      lowpowertext: '',
      telasi: '',
      gwp: '',
      otherdocumentsfiles: [],
      file_1: '',
      file_8: '',
      file_9: '',
      file_10: '',
      file_agree: '',
      amountN: '',
      bankdetails: '',
      reference_devices: [],
      reference_districts: [],
      reference_streets: [],
      reference_activestreest: [],
      addressto_building: '',
      addressto_entrance: '',
      addressto_floor: '',
      addressto_apartament: '',
      district_Id: '',
      street_Id: '',
      street_Name: '',
      recaptchaCode: '',
      recaptchaCodeerror: '',
      reference_activedistricts: {},
      reference_activestreets_1: {},
      reference_RefCapacitySteps: [],
      reference_activeSteps: {},
      reference_tools: [],
      reference_allStreets: {},
      tools: [{ tool_Id: '-1' }],
      errors: [],
      reference_FieldsDescriptions: [],
      reference_RefPriceNames: {},
      sendButton: false,
      smartmeter: false,
      smartmeterprice: 0,
      referenceAgreement: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    };
    this.handleLetterTermsModal = this.props.handleLetterTermsModal;
    this.letterFormValidator = this.props.letterFormValidator;
    this.SendLetter = this.props.SendLetter;
    this.handleSwalPopup = this.props.handleSwalPopup;
    this.checkGeorgianLetters = this.props.checkGeorgianLetters;
    //this.clearValue=this.clearValue.bind(this);
  }

  handleThree = (name) => {
    switch (name) {
      case 'yes':
        this.setState({ checkyes_1: 'yes' });
        break;
      case 'no':
        this.setState({ checkyes_1: 'no' });
        break;
      case 'already':
        this.setState({ checkyes_1: 'already' });
        break;
      default:
        break;
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  UNSAFE_componentWillMount() {
    const token = read_cookie('Bearer_Token');
    const url = window.$name;

    axios
      .get(`${url}/api/Letters/GetLetterFieldDescriptions/${this.props.letterTypeId}`, {
        headers: {
          Token: token,
        },
      })
      .then((res) => {
        //console.log(res);
        if (res.data.success) {
          this.setState({ reference_FieldsDescriptions: res.data.descriptions });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'udnefined') {
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.replace('/login');
          }
        }
      });
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      //console.log(data);
      let phone = data.phones.filter((x) => x.typeKey === 1);
      let email = data.emails[0];
      //console.log(phone)
      this.setState({
        fullname: data.name + ' ' + data.lastName,
        personal: data.personalId,
        phone_1: phone ? phone[0].info : '',
        email_1: email ? email.info : '',
        address: data.registrationAddress,
      });
    }
  }

  componentDidMount() {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    Axios.get(`${name}/api/Letters/GetReferences?letterTypeId=50`, {
      headers: { Token: token },
    })
      .then((res) => {
        if (res.data.success) {
          let data = res.data.references;
          let districts = [];
          let activedistricts = [];
          let streets = [];
          let devices = [];
          let active_streets = [];
          let RefCapacitySteps = [];
          let tools = [];
          let priceNames = [];
          let allStreets = [];
          let allActiveStreets = [];
          //ერთეულოვანისთვის "RefCapacitySteps":  1-თვითმმართველი ,2-არათვითმმართველი 3და 4 სიმძლავრის გაზრდაა
          //console.log(data);
          data.forEach((element) => {
            switch (element.key) {
              case 'RefDevices':
                devices.push(element);
                let obj1 = { value: element.id, label: element.value };
                tools.push(obj1);
                break;
              case 'RefDistricts':
                let obj = { value: element.id, label: element.value };
                activedistricts.push(obj);
                districts.push(element);
                break;
              case 'RefStreets':
                streets.push(element);
                allStreets.push(element);
                break;
              case 'RefCapacitySteps':
                RefCapacitySteps.push(element);
                //console.log(element)
                break;
              case 'RefPriceNames':
                priceNames.push(element);
                //console.log(element)
                break;
              default:
                break;
            }
          });
          //console.log(devices);
          let val = districts[0].id;
          streets.forEach((element) => {
            if (element.parentId === val) {
              active_streets.push(element);
            }
          });

          //console.log(allStreets);

          allStreets.forEach((element) => {
            if (element.id !== 12797 && element.id !== 14610) {
              let distName = districts.find((c) => c.id == element.parentId)?.value
                ? districts.find((c) => c.id == element.parentId)?.value
                : ' ';
              element.labelText = element.value + ' (' + distName + ')';
              element.districtName = distName;
              let lObj = {
                value: element.id,
                label: element.labelText,
                districtId: element.parentId,
                districtName: distName,
              };
              allActiveStreets.push(lObj);
            }
          });
          //let tmp = allStreets.slice(50,100);
          //console.log(tmp)
          //active_streets = allStreets.slice(100,150);

          //console.log(active_streets);
          this.setState({
            reference_devices: devices,
            reference_streets: streets,
            reference_districts: districts,
            reference_activestreest: active_streets,
            reference_activedistricts: activedistricts,
            reference_RefPriceNames: priceNames,
            reference_RefCapacitySteps: RefCapacitySteps,
            reference_tools: tools,
            reference_allStreets: allActiveStreets,
          });
          // console.log(this.state.reference_RefCapacitySteps);

          this.setActiveSteps(1, RefCapacitySteps);
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
        //alert("შეცდომა ?");
      });
  }

  handleInputChange = (e, index) => {
    //console.log(e.target);
    const { name, value } = e.target;
    const list = [...this.state.tools];
    list[index][name] = value;
    this.setState({ tools: list });
  };

  setActiveSteps = (parent, RefCapacitySteps) => {
    //console.log([parent,RefCapacitySteps])
    let activeSteps = [];
    RefCapacitySteps.forEach((element) => {
      if (element.parentId == parent) activeSteps.push({ value: element.id, label: element.value });
    });
    this.setState({ reference_activeSteps: activeSteps });
  };

  reverse = (s) => {
    return s.split('').reverse().join('');
  };

  handlePowerChange = (selectedOption) => {
    let peakPower = '';
    let amountN = '';
    if (selectedOption !== null) {
      switch (selectedOption.value) {
        case 45:
        case 54:
          peakPower = 5.9;
          break;
        case 46:
        case 55:
          this.setState({
            checkyes_14: true,
            checkyes_14_1: false,
            smartmeter: false,
            smartmeterprice: 0,
          });
          peakPower = 9.9;
          break;
        case 47:
        case 56:
          this.setState({
            checkyes_14: true,
            checkyes_14_1: false,
            smartmeter: false,
            smartmeterprice: 0,
          });
          peakPower = 15.9;
          break;
        case 48:
        case 57:
          this.setState({
            checkyes_14: true,
            checkyes_14_1: false,
            smartmeter: false,
            smartmeterprice: 0,
          });
          peakPower = 24.9;
          break;
        case 49:
        case 58:
          this.setState({
            checkyes_14: true,
            checkyes_14_1: false,
            smartmeter: false,
            smartmeterprice: 0,
          });
          peakPower = 39.9;
          break;
        case 50:
        case 59:
          this.setState({
            checkyes_14: true,
            checkyes_14_1: false,
            smartmeter: false,
            smartmeterprice: 0,
          });
          peakPower = 64.9;
          break;
        case 51:
        case 60:
          this.setState({
            checkyes_14: true,
            checkyes_14_1: false,
            smartmeter: false,
            smartmeterprice: 0,
          });
          peakPower = 99.9;
          break;
        default:
          this.setState({
            checkyes_14: true,
            checkyes_14_1: false,
            smartmeter: false,
            smartmeterprice: 0,
          });
          peakPower = '';
          break;
      }
      var lowpowertext = selectedOption.label;
      var tmp = selectedOption.label.length - 1;
      //console.log(selectedOption.label);
      for (let i = selectedOption.label.length - 1; i > 0; i--) {
        if (selectedOption.label[i] === '/') {
          tmp = i;
          break;
        } else amountN += selectedOption.label[i];
      }
      lowpowertext = lowpowertext.substr(0, tmp);

      amountN = this.reverse(amountN);

      this.setState({
        lowpower: selectedOption.value,
        lowpowertext: lowpowertext,
        peakpower: peakPower,
        amountN: amountN,
      });
    } else this.setState({ lowpower: '', peakpower: peakPower, amountN: '' });
  };

  selectRef = null;

  clearValue = () => {
    this.selectRef.select.clearValue();
  };

  selectRef1 = null;

  clearValue1 = () => {
    this.selectRef1.select.clearValue();
  };

  handleDistrictChange = (selectedOption) => {
    // //console.log(selectedOption);
    // if (selectedOption !== null) {
    //   let activestreets = [];
    //   this.state.reference_streets.forEach((element) => {
    //     if (element.parentId == selectedOption.value) {
    //       //console.log([element,selectedOption.value])
    //       let obj = { value: element.id, label: element.value };
    //       activestreets.push(obj);
    //     }
    //   });
    //   this.setState({
    //     district_Id: selectedOption.value,
    //     reference_activestreets_1: activestreets,
    //   });
    //   this.clearValue();
    // } else {
    //   this.setState({ district_Id: '', street_Id: '', reference_activestreets_1: [] });
    // }
    // this.clearValue1();
  };

  handleStreetChange = (selectedOption) => {
    //console.log(selectedOption);
    if (selectedOption !== null)
      this.setState({
        street_Id: selectedOption !== null ? selectedOption.value : '',
        street_Name: selectedOption !== null ? selectedOption.label : '',
        district_Id: selectedOption != null ? selectedOption.districtId : '',
      });
    else {
      this.setState({ street_Id: '', street_Name: '', district_Id: '' });
    }
  };

  handleCheck(name) {
    //console.log(name)
    const allowedSmartTimeIds = [45, 46, 54, 55];
    switch (name) {
      case 'smartmeter':
        if (!this.state.smartmeter) {
          if (allowedSmartTimeIds.includes(this.state.lowpower)) {
            let smartTimePrice = this.state.reference_RefCapacitySteps.find(
              (x) => x.id === this.state.lowpower
            ).extraPrice;
            this.setState({ smartmeterprice: smartTimePrice });
          } else {
            this.setState({ smartmeterprice: 0 });
          }
        } else {
          this.setState({ smartmeterprice: 0 });
        }
        this.setState({ smartmeter: !this.state.smartmeter });
        break;
      case 'check_warranty':
        this.setState({ check_warranty: !this.state.check_warranty });
        break;
      case 'checkyes_1':
        this.setState({ checkyes_1: !this.state.checkyes_1 });
        break;
      case 'checkyes_2':
        this.setState({ checkyes_2: !this.state.checkyes_2 });
        break;
      case 'checkyes_3':
        this.setState({ checkyes_3: !this.state.checkyes_3 });
        break;
      case 'checkyes_4':
        this.setState({ checkyes_4: !this.state.checkyes_4 });
        break;
      case 'checkyes_5':
        this.setState({ checkyes_5: !this.state.checkyes_5 });
        break;
      case 'checkyes_6':
        this.setState({ checkyes_6: !this.state.checkyes_6 }, function () {
          if (this.state.checkyes_6) this.setState({ checkyes_4: false });
          if (!this.state.checkyes_6) {
            this.setState({ checkyes_14_1: false, checkyes_14: true });
          }
        });

        break;
      case 'checkyes_7':
        this.setState({ checkyes_7: !this.state.checkyes_7 });
        break;
      case 'checkyes_8':
        this.setState({ checkyes_8: !this.state.checkyes_8 });
        break;
      case 'checkyes_9':
        this.setState({ checkyes_9: !this.state.checkyes_9 });
        break;
      case 'checkyes_10':
        this.setState({ checkyes_10: !this.state.checkyes_10 });
        break;
      case 'checkyes_11':
        this.setState({ checkyes_11: !this.state.checkyes_11 });
        break;
      case 'checkyes_12':
        this.setState({ checkyes_12: !this.state.checkyes_12 });
        break;
      case 'checkyes_13':
        this.setState({ checkyes_13: !this.state.checkyes_13 });
        if (this.state.checkyes_13) this.setActiveSteps(2, this.state.reference_RefCapacitySteps);
        else this.setActiveSteps(1, this.state.reference_RefCapacitySteps);
        this.clearValue();
        break;
      case 'checkyes_14':
        this.setState({
          checkyes_14: !this.state.checkyes_14,
          checkyes_14_1: this.state.checkyes_14,
        });
        if (this.state.checkyes_13 && this.state.lowpower == 45) {
          this.state.reference_RefCapacitySteps.forEach((element) => {
            if (element['id'] == 45) {
              let amountN = '';
              for (let i = element.value.length - 1; i > 0; i--) {
                if (element.value[i] === '/') {
                  break;
                } else amountN += element.value[i];
              }
              amountN = this.reverse(amountN);
              //console.log(amountN)
              this.setState({ amountN: amountN });
            }
          });
        }
        if (!this.state.checkyes_13 && this.state.lowpower == 54) {
          this.state.reference_RefCapacitySteps.forEach((element) => {
            if (element['id'] == 54) {
              let amountN = '';
              for (let i = element.value.length - 1; i > 0; i--) {
                if (element.value[i] === '/') {
                  break;
                } else amountN += element.value[i];
              }
              amountN = this.reverse(amountN);
              //console.log(amountN)
              this.setState({ amountN: amountN });
            }
          });
        }

        break;
      case 'checkyes_14_1':
        this.setState({
          checkyes_14: !this.state.checkyes_14,
          checkyes_14_1: !this.state.checkyes_14_1,
        });
        if (this.state.checkyes_13 && this.state.lowpower == 45) {
          let temp = this.state.reference_RefPriceNames[1]['extra'];
          this.state.reference_RefCapacitySteps.forEach((element) => {
            if (element['id'] == 45) {
              let amountN = '';
              for (let i = element.value.length - 1; i > 0; i--) {
                if (element.value[i] === '/') {
                  break;
                } else amountN += element.value[i];
              }
              amountN = this.reverse(amountN);
              amountN = parseFloat(amountN) + parseFloat(temp);
              this.setState({ amountN: amountN });
            }
          });
        }
        if (!this.state.checkyes_13 && this.state.lowpower == 54) {
          let temp = this.state.reference_RefPriceNames[2]['extra'];
          this.state.reference_RefCapacitySteps.forEach((element) => {
            if (element['id'] == 54) {
              let amountN = '';
              for (let i = element.value.length - 1; i > 0; i--) {
                if (element.value[i] === '/') {
                  break;
                } else amountN += element.value[i];
              }
              amountN = this.reverse(amountN);
              amountN = parseFloat(amountN) + parseFloat(temp);
              this.setState({ amountN: amountN });
            }
          });
        }
        //if(this.state.checkyes_14===true) this.setState({checkyes_14_1:false});
        break;
      case 'checkyes_15':
        this.setState({ checkyes_15: !this.state.checkyes_15 });
        break;
      case 'checkyes_16':
        this.setState({ checkyes_16: !this.state.checkyes_16 });
        break;
      case 'checkyes_17':
        this.setState({ checkyes_17: !this.state.checkyes_17 });
        break;
      case 'checkyes_18':
        this.setState({ checkyes_18: !this.state.checkyes_18 });
        break;
      case 'checkyes_19':
        this.setState({ checkyes_19: !this.state.checkyes_19 });
        break;
      case 'checkyes_20':
        this.setState({ checkyes_20: !this.state.checkyes_20 });
        break;
      case 'checkyes_21':
        this.setState({ checkyes_21: !this.state.checkyes_21 });
        break;
      case 'checkyes_22':
        this.setState({ checkyes_22: !this.state.checkyes_22 });
        break;
      case 'checkyes_23':
        this.setState({ checkyes_23: !this.state.checkyes_23 });
        break;
      case 'checkyes_24':
        this.setState({ checkyes_24: !this.state.checkyes_24 });
        break;
      case 'checkyes_25':
        this.setState({ checkyes_25: !this.state.checkyes_25 });
        break;
      case 'checkyes_26':
        this.setState({ checkyes_26: !this.state.checkyes_26 });
        break;
      case 'agree':
        this.setState({ agree: !this.state.agree });
        break;
      case 'checked':
        this.setState({ checked: !this.state.checked });
        break;
      default:
        break;
    }
  }

  // handleAddressChange = (value) => {
  //   //console.log(value);
  //   let streets = [];
  //   this.state.reference_streets.forEach((element) => {
  //     if (element.parentId == value) streets.push(element);
  //     //console.log([value,element.parentId]);
  //   });
  //   this.setState({
  //     reference_activestreest: streets,
  //     district_Id: value,
  //     street_Id: streets[0].id,
  //   });
  // };

  //   let count = 0;
  //   letters.forEach((element) => {
  //     count += s.split(element).length - 1;
  //   });

  //   if (count > 0) return false;

  //   if (count === 1) {
  //     if (!letters.includes(s[s.length - 1])) return false;
  //   }

  //   return true;
  // };

  handleChange = (input) => (e) => {
    let ph = '';
    if (input.includes('phone')) {
      ph = e.target.value.replace(/\D/g, '');
      if (input === 'phone_1') this.setState({ phone_1: ph });
      else if (input === 'phone_2') this.setState({ phone_2: ph });
      else if (input === 'customer_phone_1') this.setState({ customer_phone_1: ph });
      else this.setState({ customer_phone_2: ph });
    } else if (input === 'recaptchaCode') {
      //console.log(e)
      this.setState({ recaptchaCode: e });
    } else if (input === 'cadastral') {
      let val = e.target.value;
      const regex = /(\b\d{2,3}\b)|[0-9]{2,3}/g;
      let validationResult = true;
      if (val) {
        let t1 = val.replaceAll(',', '.');
        let t2 = t1.replaceAll('/', '.');

        let res = t2.match(regex);

        let splited = t2.split('.');

        if (splited.length < 3) {
          this.setState({ cadastral: t2, cadastralerror: 'არასწორი საკადასტრო კოდი' });
          validationResult = false;
        }

        if (res && splited && splited[splited.length - 1].length < 5) {
          if (res.length != splited.length) {
            this.setState({
              cadastral: t2,
              cadastralerror: 'არასწორი საკადასტრო კოდი',
            });
            validationResult = false;
          }
        }

        if (splited.length > 3) {
          const response = this.checkGeorgianLetters(t2);
          //console.log(response);
          if (splited[splited.length - 1].length > 6) {
            this.setState({
              cadastral: t2,
              cadastralerror: 'არასწორი საკადასტრო კოდი',
            });
            validationResult = false;
          }
          if (!response) {
            this.setState({
              cadastral: t2,
              cadastralerror: 'არასწორი საკადასტრო კოდი',
            });
            validationResult = false;
          }
        }

        if (validationResult) {
          this.setState({ [input]: t2, cadastralerror: '' });
        }
      } else {
        this.setState({ [input]: e.target.value });
      }
    } else {
      if (!this.state.checked) {
        if (input === 'customer_firstname') {
          this.setState({ customer_lastname: e.target.value });
        }
      }
      this.setState({ [input]: e.target.value });
    }
  };

  handlePeakPower = () => {
    let lp = this.state.lowpower;
    let pp = this.state.peakpower;
    let num = parseFloat(pp);
    num = num ? num : 0;
    //console.log(num);
    if (this.state.lowpower) {
      if (num >= 0) {
        //console.log(num);
        if (lp == 45 || lp == 54) {
          if (num < 0.1) this.setState({ peakpower: 0.1 });
          else if (num > 5.9) this.setState({ peakpower: 5.9 });
          else this.setState({ peakpower: num });
        } else if (lp == 46 || lp == 55) {
          if (num < 6.1) this.setState({ peakpower: 6.1 });
          else if (num > 9.9) this.setState({ peakpower: 9.9 });
          else this.setState({ peakpower: num });
        } else if (lp == 47 || lp == 56) {
          if (num < 10.1) this.setState({ peakpower: 10.1 });
          else if (num > 15.9) this.setState({ peakpower: 15.9 });
          else this.setState({ peakpower: num });
        } else if (lp == 48 || lp == 57) {
          if (num < 16.1) this.setState({ peakpower: 16.1 });
          else if (num > 24.9) this.setState({ peakpower: 24.9 });
          else this.setState({ peakpower: num });
        } else if (lp == 49 || lp == 58) {
          if (num < 25.1) this.setState({ peakpower: 25.1 });
          else if (num > 39.9) this.setState({ peakpower: 39.9 });
          else this.setState({ peakpower: num });
        } else if (lp == 50 || lp == 59) {
          if (num < 40.1) this.setState({ peakpower: 40.1 });
          else if (num > 64.9) this.setState({ peakpower: 64.9 });
          else this.setState({ peakpower: num });
        } else if (lp == 51 || lp == 60) {
          if (num < 65.1) this.setState({ peakpower: 65.1 });
          else if (num > 99.9) this.setState({ peakpower: 99.9 });
          else this.setState({ peakpower: num });
        } else {
          if (num < 100.1) this.setState({ peakpower: 100.1 });
          else if (num > 9998.9) this.setState({ peakpower: 9998.9 });
          else this.setState({ peakpower: num });
        }
      } else {
        //console.log(2);
        this.setState({ peakpower: '' });
      }
      //console.log(this.state.peakpower);
    }
  };

  handleAddClick = () => {
    this.setState({ tools: [...this.state.tools, { tool_Id: '' }] });
  };

  handleRemoveClick = (index) => {
    this.state.tools.splice(index, 1);
    this.setState({ tools: this.state.tools });
  };

  handleToolChange = (e, index) => {
    //console.log([e,index]);
    const { value, label } = e;
    //console.log([value,label]);
    const list = [...this.state.tools];
    list[index]['tool_Id'] = value;
    this.setState({ tools: list });
    //console.log(list)
  };

  handleFileChange = (input) => (event) => {
    if (input === 'otherdocumentsfiles') {
      if (event.target.files.length > 3) {
        this.handleSwalPopup(
          'ფაილების მაქსიმალური რაოდენობა არის 3',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
        document.getElementById('otherdocumentsfiles').value = '';
        //this.setState({[input]:[]});
      } else {
        this.setState({ [input]: event.target.files });
      }
    } else {
      this.setState({ [input]: event.target.files[0] });
    }
  };

  handleSubmit = () => {
    //document.getElementById("SendButton").disabled=true;
    let isValid = this.formValidate();

    let data = [];

    if (isValid) {
      for (const [key, value] of Object.entries(this.state)) {
        let obj = {};
        if (!key.includes('error') && !key.includes('refe') && !key.includes('recap')) {
          if (value !== '' && value !== null) {
            obj.key = key;
            obj.value = value;
            data.push(obj);
          }
        }
      }
      this.SendLetter(this.state.custN, this.props.letterTypeId, this.state.recaptchaCode, data);
    } else {
      //var filesError = this.state.errors.filter(x=>x.key==="filesSize"); if(filesError.length) filesError=filesError[0].value;
      if (this.state.errorFileSize) {
        this.handleSwalPopup(
          'ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      } else {
        this.handleSwalPopup(
          'შესავსებია სავალდებულო ველები',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      }
    }
    //document.getElementById("SendButton").disabled=false;;
  };

  formValidate() {
    const values = this.state;
    //document.getElementById('SendButton').disabled=true;
    let data = [];

    for (const [key, value] of Object.entries(values)) {
      if (!key.includes('recap') && !key.includes('refe') && !key.includes('error')) {
        let obj = {};
        obj.name = key;
        obj.value = value;
        if (
          key === 'fullname' ||
          key === 'personal' ||
          key === 'phone_1' ||
          key === 'amountN' ||
          key === 'file_1' ||
          key === 'peakpower' ||
          key === 'address' ||
          key === 'cadastral' ||
          key === 'district_Id' ||
          key === 'street_Id' ||
          key === 'addressto_building' ||
          key === 'lowpower' ||
          key === 'customer_firstname' ||
          key === 'customer_personal' ||
          key === 'customer_phone_1'
        )
          obj.Required = true;
        else if (key === 'customer_lastname') {
          if (this.state.checked == true) obj.Required = true;
          else obj.Required = false;
        } else if (key === 'file_agree') {
          if (values.checkyes_23) obj.Required = true;
        } else if (key === 'warranty') {
          if (values.check_warranty) obj.Required = true;
        } else if (key === 'warranty_deadline') {
          if (values.check_warranty) obj.Required = true;
        } else if (key === 'checkyes_14' || key === 'checkyes_14_1') {
          if (values.checkyes_14_1 === false && values.checkyes_14 === false) obj.Required = true;
        } else if (key === 'file_8') {
          if (values.checkyes_24) obj.Required = true;
        } else obj.Required = false;

        data.push(obj);
      }
    }

    let result = this.letterFormValidator(this.props.letterTypeId, data);
    const found = result.errors.find((x) => x.key === 'filesSize');
    if (typeof found !== 'undefined') {
      //console.log(1234);
      this.state.errorFileSize = true;
      this.setState({ errors: result.errors });
      //this.handleSwalPopup("ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს","warning",false,"","დახურვა",false,"#262262");
    } else {
      this.state.errorFileSize = false;
      this.setState({ errors: result.errors });
    }
    //document.getElementById('SendButton').disabled=false;
    //alert(result.isValid);
    return result.isValid;
  }

  handleRepeat = () => {
    if (this.state.checked) {
      if (typeof this.props.params.user !== 'undefined') {
        const data = this.props.params.user;
        this.setState({
          customer_firstname: data.name,
          customer_personal: this.state.personal,
          customer_lastname: data.lastName,
          customer_phone_1: this.state.phone_1,
          customer_phone_2: this.state.phone_2,
          customer_email_1: this.state.email_1,
          customer_email_2: this.state.email_2,
        });
      }
    } else {
      this.setState({
        customer_phone_1: this.state.phone_1,
        customer_phone_2: this.state.phone_2,
        customer_email_1: this.state.email_1,
        customer_email_2: this.state.email_2,
      });
    }
  };

  handlePhysicalSwitch = () => {
    if (this.state.checked) {
      this.setState({
        checked: !this.state.checked,
        customer_firstname: '',
        customer_lastname: '',
        customer_personal: '',
      });
    } else {
      this.setState({ checked: !this.state.checked });
    }
  };

  errorExsist = (key) => {
    let bool = this.state.errors.some((obj) => obj.key == key);
    return bool;
  };

  renderErrorText = (key) => {
    let bool = this.state.errors.find((obj) => obj.key.includes(key) === true);
    return bool.value;
  };

  render() {
    //console.log(this.state.lowpower);
    const values = this.state;
    let phone, email;
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      phone = data.phones.filter((x) => x.typeKey === 1);
      email = data.emails[0];
    }
    let l = true;
    if (values.reference_FieldsDescriptions.length === 0) l = false;
    if (values.lowpower !== 45 && values.lowpower !== 54) {
      this.state.checkyes_15 = false;
    }

    if (
      (values.addressto_apartament.length > 0 && values.addressto_entrance.length > 0) ||
      (values.addressto_apartament.length > 0 && values.addressto_floor.length > 0) ||
      (values.addressto_apartament.lenght > 0 &&
        values.addressto_floor.length > 0 &&
        values.addressto_entrance.length > 0)
    ) {
      this.state.checkyes_15 = false;
    }

    // if (values.addressto_apartament.length > 0 || values.addressto_entrance.length > 0) {

    // }

    //console.log(values.checked);

    let v = Number(this.state.peakpower);
    if (v > 6400) this.state.peakpower = 6400;

    // document.getElementById("peak").addEventListener("change", function() {
    //     let v = parseInt(this.value);
    //     if (v < 1) this.state.peakPower = 1;
    //     if (v > 6400) this.state.peakPower = 6400;
    // });
    //console.log([this.state.district_Id, this.state.street_Id, this.state.street_Name]);
    return (
      <React.Fragment>
        <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div style={{ float: 'left' }}>
                  <ToLetters></ToLetters>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span>
                    <strong>{this.props.letterTitle}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>განმცხადებელი</strong>
              </div>
              <div className="card-body">
                <div className="form-horizontal">
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>სახელი და გვარი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        readOnly={this.props.params.user.fullName ? true : false}
                        placeholder="სახელი გვარი/ორგანიზაციის დასახელება"
                        onChange={this.handleChange('fullname')}
                        value={values.fullname}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'fullname'}
                        />
                      ) : null}
                      {this.errorExsist('fullname') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('fullname')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-4 col-form-label">
                      <strong>პირადი #/საიდენტიფიკაციო #:</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        id="personal"
                        placeholder="პირადი ნომერი"
                        readOnly={this.props.params.user.personalId ? true : false}
                        onChange={this.handleChange('personal')}
                        value={values.personal}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'personal'}
                        />
                      ) : null}
                      {this.errorExsist('personal') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('personal')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მისამართი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        id="address"
                        placeholder="მისამართი"
                        readOnly={this.props.params.user.registrationAddress ? true : false}
                        onChange={this.handleChange('address')}
                        value={values.address}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'address'}
                        />
                      ) : null}
                      {this.errorExsist('address') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('address')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მობილური (ძირითადი)</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <MaskedInput1
                        readOnly={
                          typeof phone[0].info !== 'undefined' && phone[0].info ? true : false
                        }
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={true}
                        className="form-control"
                        type="text"
                        placeholder="მობილურის ნომერი"
                        name="phone_1"
                        onChange={this.handleChange('phone_1')}
                        value={values.phone_1}
                      ></MaskedInput1>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'phone_1'}
                        />
                      ) : null}
                      {this.errorExsist('phone_1') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('phone_1')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მობილური (ალტერნატიული)</strong>
                    </label>
                    <div className="col-md-8">
                      <MaskedInput1
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={true}
                        className="form-control"
                        type="text"
                        placeholder="მობილურის ნომერი"
                        name="phone_2"
                        onChange={this.handleChange('phone_2')}
                        value={values.phone_2}
                      ></MaskedInput1>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'phone_2'}
                        />
                      ) : null}
                      {this.errorExsist('phone_2') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('phone_2')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ელ. ფოსტა (ძირითადი)</strong>
                    </label>
                    <div className="col-md-8">
                      <TextMask
                        readOnly={typeof email !== 'undefined' ? true : false}
                        className="form-control"
                        placeholder="ელ. ფოსტა"
                        name="email_1"
                        onChange={this.handleChange('email_1')}
                        value={values.email_1}
                        mask={emailMask.mask}
                        pipe={emailMask.pipe}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'email_1'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ელ. ფოსტა (ალტერნატიული)</strong>
                    </label>
                    <div className="col-md-8">
                      <TextMask
                        className="form-control"
                        placeholder="ელ. ფოსტა"
                        name="email_2"
                        onChange={this.handleChange('email_2')}
                        value={values.email_2}
                        mask={emailMask.mask}
                        pipe={emailMask.pipe}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'email_2'}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>ინფორმაცია აბონენტის შესახებ</strong>
                <div className="card-header-actions">
                  <button className="btn btn-primary btn-sm" onClick={this.handleRepeat}>
                    განმცხადებლის მონაცემების გადმოტანა
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="form-horizontal">
                  <BootstrapSwitchButton
                    width={200}
                    checked={values.checked}
                    onlabel="ფიზიკური"
                    offlabel="იურიდიული"
                    onChange={this.handlePhysicalSwitch}
                  ></BootstrapSwitchButton>
                  <br />
                  <br />
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>{values.checked ? 'სახელი' : 'დასახელება'}</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('customer_firstname')}
                        placeholder={values.checked ? 'სახელი' : 'დასახელება'}
                        value={values.customer_firstname}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_firstname'}
                        />
                      ) : null}
                      {this.errorExsist('customer_firstname') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_firstname')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  {values.checked ? (
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        <strong>გვარი</strong>
                        <span style={{ color: '#e55353' }}>*</span>
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          onChange={this.handleChange('customer_lastname')}
                          value={values.customer_lastname}
                          placeholder="გვარი"
                        ></input>
                        {l ? (
                          <InputInfo
                            descriptions={values.reference_FieldsDescriptions}
                            letterTypeId={this.props.letterTypeId}
                            inputName={'customer_lastname'}
                          />
                        ) : null}
                        {this.errorExsist('customer_lastname') ? (
                          <label style={{ fontSize: 10, color: '#e55353' }}>
                            {this.renderErrorText('customer_lastname')}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        {values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}
                      </strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('customer_personal')}
                        value={values.customer_personal}
                        placeholder={values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_personal'}
                        />
                      ) : null}
                      {this.errorExsist('customer_personal') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_personal')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მობილურის ნომერი(ძირითადი)</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <MaskedInput1
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={true}
                        className="form-control"
                        type="text"
                        placeholder="ძირითადი"
                        name="customer_phone_1"
                        onChange={this.handleChange('customer_phone_1')}
                        value={values.customer_phone_1}
                      ></MaskedInput1>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_phone_1'}
                        />
                      ) : null}
                      {this.errorExsist('customer_phone_1') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_phone_1')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მობილურის ნომერი(ალტერნატიული)</strong>
                    </label>
                    <div className="col-md-8">
                      <MaskedInput1
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={true}
                        className="form-control"
                        type="text"
                        placeholder="ალტერნატიული"
                        name="customer_phone_2"
                        onChange={this.handleChange('customer_phone_2')}
                        value={values.customer_phone_2}
                      ></MaskedInput1>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_phone_2'}
                        />
                      ) : null}
                      {this.errorExsist('customer_phone_2') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_phone_2')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ელ. ფოსტა(ძირითადი)</strong>
                    </label>
                    <div className="col-md-8">
                      <TextMask
                        className="form-control"
                        placeholder="ძირითადი"
                        name="customer_email_1"
                        onChange={this.handleChange('customer_email_1')}
                        value={values.customer_email_1}
                        mask={emailMask.mask}
                        pipe={emailMask.pipe}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_email_1'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ელ. ფოსტა(ალტერნატიული)</strong>
                    </label>
                    <div className="col-md-8">
                      <TextMask
                        className="form-control"
                        placeholder="ალტერნატიული"
                        name="customer_email_2"
                        onChange={this.handleChange('customer_email_2')}
                        value={values.customer_email_2}
                        mask={emailMask.mask}
                        pipe={emailMask.pipe}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_email_2'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>საბანკო რეკვიზიტები</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('bankdetails')}
                        placeholder="აბონენტის საბანკო რეკვიზიტები"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'bankdetails'}
                        />
                      ) : null}
                      {this.errorExsist('bankdetails') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('bankdetails')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>ინფორმაცია მისაერთებელი ობიექტის შესახებ</strong>
              </div>
              <div className="card-body">
                <div className="form-horizontal">
                  <div className="form-group row">
                    <label className="col-md-4 col-from-label">
                      <strong>
                        უძრავი ქონების საკადასტრო კოდი
                        <span style={{ color: '#e55353' }}>*</span>
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('cadastral')}
                        placeholder="უძრავი ქონების საკადასტრო კოდი"
                        value={values.cadastral}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'cadastral'}
                        />
                      ) : null}
                      {values.cadastralerror ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {values.cadastralerror}
                        </label>
                      ) : null}
                      {this.errorExsist('cadastral') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('cadastral')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>რაიონი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <Select
                      isClearable
                      isSearchable
                      options={this.state.reference_activedistricts}
                      onChange={this.handleDistrictChange}
                      placeholder="აირჩიეთ"
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'district_Id'}
                      />
                    ) : null}
                    {this.errorExsist('district_Id') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('district_Id')}
                      </label>
                    ) : null}
                  </div>
                </div> */}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ქუჩა</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <Select
                      ref={(ref) => {
                        this.selectRef1 = ref;
                      }}
                      isClearable
                      isSearchable
                      options={this.state.reference_allStreets}
                      onChange={this.handleStreetChange}
                      placeholder="აირჩიეთ ან აკრიფეთ"
                      noOptionsMessage={() => 'ცარიელია'}
                    />

                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'street_Id'}
                      />
                    ) : null}
                    {this.errorExsist('street_Id') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('street_Id')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <br />
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>შენობის ნომერი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      maxLength="50"
                      className="form-control"
                      onChange={this.handleChange('addressto_building')}
                      placeholder="შენობის ნომერი"
                      value={values.addressto_building}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto_building'}
                      />
                    ) : null}
                    {this.errorExsist('addressto_building') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('addressto_building')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სადარბაზო</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      maxLength="50"
                      className="form-control"
                      onChange={this.handleChange('addressto_entrance')}
                      placeholder="სადარბაზო"
                      value={values.addressto_entrance}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto_entrance'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სართული</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      maxLength="50"
                      className="form-control"
                      onChange={this.handleChange('addressto_floor')}
                      placeholder="სართული"
                      value={values.addressto_floor}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto_floor'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ბინის ნომერი</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      maxLength="50"
                      className="form-control"
                      onChange={this.handleChange('addressto_apartament')}
                      placeholder="ბინის ნომერი"
                      value={values.addressto_apartament}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'addressto_apartament'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>სხვა პირობები</strong>
              </div>
              <div className="card-body">
                <div className="form-horizontal">
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>მისაერთებელი ობიექტი მდებარეობს თვითმმართველ ქალაქში</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_13');
                          }}
                          checked={this.state.checkyes_13}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_13');
                          }}
                          checked={!this.state.checkyes_13}
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_13'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        გამანაწილებელი ქსელის დაბალი წნევის საფეხურზე მისაერთებელი სიმძლავრე
                      </strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <Select
                        ref={(ref) => {
                          this.selectRef = ref;
                        }}
                        options={values.reference_activeSteps}
                        placeholder="აირჩიეთ"
                        onChange={this.handlePowerChange}
                      />
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'lowpower'}
                        />
                      ) : null}
                      {this.errorExsist('lowpower') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('lowpower')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ბუნებრივი გაზის მოხმარების მიზანი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_6');
                          }}
                          checked={this.state.checkyes_6}
                        ></input>
                        &nbsp;საყოფაცხოვრებო
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_6');
                          }}
                          checked={!this.state.checkyes_6}
                        ></input>
                        &nbsp;არასაყოფაცხოვრებო
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_6'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        გამანაწილებელ ქსელზე მისაერთებელი პიკური სიმძლავრე (მოხმარების დასაშვები
                        სიმძლავრე)
                      </strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="peak"
                        type="number"
                        min="1"
                        step="0.1"
                        max="9999"
                        onChange={this.handleChange('peakpower')}
                        onBlur={this.handlePeakPower}
                        value={values.peakpower}
                        placeholder="მ&sup3;/სთ"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'peakpower'}
                        />
                      ) : null}
                      {this.errorExsist('peakpower') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('peakpower')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გამანაწილებელ ქსელზე მიერთების საფასური (ციფრებში)</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        value={values.amountN}
                        onChange={this.handleChange('amountN')}
                        placeholder="გამანაწილებელ ქსელზე მიერთების საფასური (ციფრებში)"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'amountN'}
                        />
                      ) : null}
                      {this.errorExsist('amountN') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('amountN')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-12 col-form-label">
                      <strong>
                        ივსება საყოფაცხოვრებო მომხმარებლის შემთხვევაში (0-6 მ&sup3;/სთ შეთავაზებული
                        პაკეტის მონიშვნის შემთხვევაში)
                      </strong>
                    </label>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გამანაწილებელ ქსელზე მიერთების საფასურის გადახდის პირობა</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_14');
                          }}
                          checked={this.state.checkyes_14}
                          disabled={
                            !values.checkyes_6 ||
                            (values.lowpower !== 45 &&
                              values.lowpower !== 54 &&
                              values.lowpower !== 46 &&
                              values.lowpower !== 55)
                              ? true
                              : false
                          }
                        ></input>
                        &nbsp;ერთდროულად
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_14_1');
                          }}
                          checked={this.state.checkyes_14_1}
                          disabled={
                            !values.checkyes_6 ||
                            (values.lowpower !== 45 &&
                              values.lowpower !== 54 &&
                              values.lowpower !== 46 &&
                              values.lowpower !== 55)
                              ? true
                              : false
                          }
                        ></input>
                        &nbsp;17 თვეზე განწილვადებით
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_14'}
                        />
                      ) : null}
                      {this.errorExsist('checkyes_14') ? (
                        <React.Fragment>
                          <br />
                          <label style={{ fontSize: 10, color: '#e55353' }}>
                            {this.renderErrorText('checkyes_14')}
                          </label>
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        მომხმარებლის შიდა ქსელის სტანდარტული პირობით ერთი წერტილის მოწყობა და ერთი
                        ხელსაწყოს დაერთება
                      </strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_15');
                          }}
                          checked={this.state.checkyes_15}
                          disabled={
                            (values.lowpower !== 45 &&
                              values.lowpower !== 54 &&
                              values.lowpower !== 46 &&
                              values.lowpower !== 55) ||
                            (values.addressto_apartament && values.addressto_entrance) ||
                            (values.addressto_apartament && values.addressto_floor) ||
                            (values.addressto_apartament &&
                              values.addressto_floor &&
                              values.addressto_entrance)
                              ? true
                              : false
                          }
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_15');
                          }}
                          checked={!this.state.checkyes_15}
                          disabled={
                            (values.lowpower !== 45 &&
                              values.lowpower !== 54 &&
                              values.lowpower !== 46 &&
                              values.lowpower !== 55) ||
                            (values.addressto_apartament && values.addressto_entrance) ||
                            (values.addressto_apartament && values.addressto_floor) ||
                            (values.addressto_apartament &&
                              values.addressto_floor &&
                              values.addressto_entrance)
                              ? true
                              : false
                          }
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_15'}
                        />
                      ) : null}
                    </div>
                  </div>
                  {values.lowpower === 45 ||
                  values.lowpower === 54 ||
                  values.lowpower === 46 ||
                  values.lowpower === 55 ? (
                    <React.Fragment>
                      <div className="form-group row">
                        <label className="col-md-4 col-form-label">
                          <strong>
                            0-6 კუბ.მ/სთ სიმძლავრით მიერთების შემთხვევაში, გსურთ თუ არა ჭკვიანი
                            მრიცხველის მოწყობა
                          </strong>
                        </label>
                        <div className="col-md-8">
                          <label>
                            <input
                              type="radio"
                              onChange={() => {
                                this.handleCheck('smartmeter');
                              }}
                              checked={this.state.smartmeter}
                            ></input>
                            &nbsp;დიახ
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <label>
                            <input
                              type="radio"
                              onChange={() => {
                                this.handleCheck('smartmeter');
                              }}
                              checked={!this.state.smartmeter}
                            ></input>
                            &nbsp;არა
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-4 col-form-label">
                          <strong>
                            დადებითი პასუხის შემთხვევაში, ასევე შეავსეთ ჭკვიანი მრიცხველის საფასური
                            (ციფრებში)
                          </strong>
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            value={values.smartmeterprice}
                            readOnly
                          ></input>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}

                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გესაჭიროებათ საგადასახადო ანგარიშ-ფაქტურა</strong>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          disabled={this.state.checkyes_6 ? true : false}
                          onChange={() => {
                            this.handleCheck('checkyes_4');
                          }}
                          checked={this.state.checkyes_4}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          disabled={this.state.checkyes_6 ? true : false}
                          onChange={() => {
                            this.handleCheck('checkyes_4');
                          }}
                          checked={!this.state.checkyes_4}
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_4'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-12 col-form-label">
                      <strong>
                        განაცხადში მოცემული ჩემი პირადი ნომერი, ტელეფონის ნომერი და ელ. ფოსტის
                        მისამართი :{' '}
                      </strong>
                    </label>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ა)</strong>გამოყენებულ იქნეს გაზმომარაგებასთან დაკავშირებული
                      ინფორმაციის შეტყობინების მიზნით
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_16');
                          }}
                          checked={this.state.checkyes_16}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_16');
                          }}
                          checked={!this.state.checkyes_16}
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_16'}
                        />
                      ) : null}
                    </div>
                    <br />
                    <label className="col-md-4 col-form-group">
                      <strong>ბ)</strong>მიეწოდოს ელექტროენერგიისა და წყალმომარაგების სექტორში
                      მომსახურე საწარმოებს, სადაც, განაცხადში მოცემულ მისამართზე, რეგისტრირებული ვარ
                      მომხმარებლად და გამაჩნია აბონენტის ნომერი
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_18');
                          }}
                          checked={this.state.checkyes_18}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => {
                            this.handleCheck('checkyes_18');
                          }}
                          checked={!this.state.checkyes_18}
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_18'}
                        />
                      ) : null}
                      <br></br>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-4">
                      <label className="col-form-label">
                        <strong>
                          თანახმა ვარ, ქვითარი მივიღო ელექტრონული ფორმით რეგისტრაციის დროს
                          მითითებული მონაცემების შესაბამისად:
                        </strong>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleThree('yes')}
                          onClick={() => this.handleThree('yes')}
                          checked={values.checkyes_1 === 'yes' ? true : false}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleThree('no')}
                          onClick={() => this.handleThree('no')}
                          checked={values.checkyes_1 === 'no' ? true : false}
                        ></input>
                        &nbsp;არა
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleThree('already')}
                          onClick={() => this.handleThree('already')}
                          checked={values.checkyes_1 === 'already' ? true : false}
                        ></input>
                        &nbsp;უკვე ვსარგებლობ ამ მომსახურებით
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_1'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>საგარანტიო</strong>
                    </label>
                    <div className="col-md-4">
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('check_warranty')}
                          checked={this.state.check_warranty}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('check_warranty')}
                          checked={!this.state.check_warranty}
                        ></input>
                        &nbsp;არა
                      </label>
                    </div>
                  </div>
                  {this.state.check_warranty && (
                    <>
                      <div className="form-group row">
                        <label className="col-md-4 col-form-label">
                          <strong>
                            საგარანტიოს ნომერი <span style={{ color: '#e55353' }}>*</span>
                          </strong>
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            onChange={this.handleChange('warranty')}
                            placeholder="საგარანტიოს ნომერი"
                            value={this.state.warranty}
                          ></input>
                          {l ? (
                            <InputInfo
                              descriptions={values.reference_FieldsDescriptions}
                              letterTypeId={this.props.letterTypeId}
                              inputName={'warranty'}
                            />
                          ) : null}
                          {this.errorExsist('warranty') ? (
                            <label
                              style={{
                                fontSize: 10,
                                color: '#e55353',
                              }}
                            >
                              {this.renderErrorText('warranty')}
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-4 col-form-label">
                          <strong>საგარანტიოს ვადა</strong>
                          <span style={{ color: '#e55353' }}>*</span>
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="date"
                            id="warranty_deadline"
                            placeholder=""
                            onChange={this.handleChange('warranty_deadline')}
                          ></input>
                          {l ? (
                            <InputInfo
                              descriptions={values.reference_FieldsDescriptions}
                              letterTypeId={this.props.letterTypeId}
                              inputName={'warranty_deadline'}
                            />
                          ) : null}
                          {this.errorExsist('warranty_deadline') ? (
                            <label
                              style={{
                                fontSize: 10,
                                color: '#e55353',
                              }}
                            >
                              {this.renderErrorText('warranty_deadline')}
                            </label>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        {this.state.check_warranty
                          ? 'საგარანტიოს დამდადასტურებელი საბუთი'
                          : 'მიერთების საფასურის გადახდის დამადასტურებელი საბუთი'}
                      </strong>
                    </label>
                    <div className="col-md-4">
                      <label>ასევე, გადახდილია საფასურის </label>&nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_20')}
                          checked={this.state.checkyes_20}
                        ></input>
                        &nbsp;50%
                      </label>
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_20')}
                          checked={!this.state.checkyes_20}
                        ></input>
                        100%
                      </label>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg"
                        onChange={this.handleFileChange('file_9')}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'file_9'}
                        />
                      ) : null}
                      <br></br>
                      {this.errorExsist('file_9') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('file_9')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  {values.tools.map((val, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            {index === 0 ? <strong>დანადგარები</strong> : null}
                          </label>
                          <div className="col-md-4">
                            <Select
                              isSearchable
                              options={values.reference_tools}
                              onChange={(e) => this.handleToolChange(e, index)}
                              placeholder="აირჩიეთ"
                            ></Select>
                          </div>
                          <div className="btn-box col-md-4">
                            {values.tools.length !== 1 && (
                              <button
                                className=""
                                type="button"
                                onClick={(e) => this.handleRemoveClick(index)}
                              >
                                წაშლა
                              </button>
                            )}
                            &nbsp;
                            {values.tools.length - 1 === index && (
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={this.handleAddClick}
                              >
                                დამატება
                              </button>
                            )}
                            {l && index === 0 ? (
                              <InputInfo
                                descriptions={values.reference_FieldsDescriptions}
                                letterTypeId={this.props.letterTypeId}
                                inputName={'tools'}
                              />
                            ) : null}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        განაცხადზე თანდართულია საცხოვრებელი ბინის, საწარმოს ან ობიექტის მესაკუთრის
                        წერილობითი თანხმობა(ივსება მხოლოდ იმ შემთხვევაში, თუ უძრავი ქონების
                        მესაკუთრე და განმცხადებელი სხვადასხვა პირია)
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => this.handleCheck('checkyes_23')}
                          onClick={() => this.handleCheck('checkyes_23')}
                          checked={values.checkyes_23}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_23'}
                        />
                      ) : null}
                      <br />
                      {values.checkyes_23 ? (
                        <input
                          type="file"
                          accept=".pdf, .jpg, .jpeg"
                          onChange={this.handleFileChange('file_agree')}
                        ></input>
                      ) : null}
                      {this.errorExsist('file_agree') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('file_agree')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        თანდართულია მისაერთებელი ობიექტის შიდა ქსელის გაზმომარაგების მზადყოფნის
                        დამადასტურებელი დოკუმენტი
                        <br />
                        გაფრთხილება: მიერთების დასრულებამდე აღნიშნული დოკუმენტის განაწილების
                        ლიცენზიატისთვის წარუდგენლობის შემთხვევაში გაზმომარაგება არ დაიწყება
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_24')}
                          checked={values.checkyes_24}
                        ></input>
                        &nbsp;დიახ
                      </label>
                      &nbsp;
                      <label>
                        <input
                          type="radio"
                          onChange={() => this.handleCheck('checkyes_24')}
                          checked={!values.checkyes_24}
                        ></input>
                        &nbsp;არა
                      </label>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'checkyes_24'}
                        />
                      ) : null}
                      <br />
                      {values.checkyes_24 ? (
                        <input
                          type="file"
                          accept=".pdf, .jpg, .jpeg"
                          onChange={this.handleFileChange('file_8')}
                        ></input>
                      ) : null}
                      {this.errorExsist('file_8') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('file_8')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong>
                  თანდართული დოკუმენტაცია (მხოლოდ pdf/jpg ფაილები, ჯამში მაქსიმუმ 14MB)
                </strong>
              </div>
              <div className="card-body">
                <div className="form-horizontal">
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>
                        პირადობის მოწმობის ან პასპორტის ასლი
                        <span style={{ color: '#e55353' }}> *</span>
                      </strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg"
                        onChange={this.handleFileChange('file_1')}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'file_1'}
                        />
                      ) : null}
                      {this.errorExsist('file_1') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('file_1')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="form-group row">
                                        <label className="col-md-4 col-form-label"><strong>ამონაწერი საჯარო რეესტრიდან<span style={{color:"#e55353"}}> *</span></strong></label>
                                        <div className="col-md-8">
                                            <input type="file" accept=".pdf, .jpg, .jpeg" onChange={this.handleFileChange("file_2")}></input>
                                        </div>
                                    </div> */}
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>ფართის აზომვითი ნახაზი/პროექტი</strong>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg"
                        onChange={this.handleFileChange('file_10')}
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'file_10'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>სხვა დოკუმენტები</strong>
                    </label>
                    <div className="col-md-8">
                      {/* <input className="form-control" onChange={this.handleChange("otherdocuments")} placeholder="სხვა დოკუმენტები"></input>
                       */}
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg"
                        id="otherdocumentsfiles"
                        onChange={this.handleFileChange('otherdocumentsfiles')}
                        multiple
                      ></input>

                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'otherdocumentsfiles'}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col-md-12" style={{ textAlign: 'right' }}>
                    <label>
                      <input
                        type="checkbox"
                        style={{ verticalAlign: 'middle' }}
                        checked={values.agree}
                        onChange={() => this.handleCheck('agree')}
                      ></input>
                      &nbsp;ვეთანხმები
                      <span
                        style={{ color: '#262262', cursor: 'pointer' }}
                        onClick={this.handleLetterTermsModal}
                      >
                        &nbsp;<strong>წესებსა და პირობებს</strong>
                      </span>
                    </label>
                  </div>
                  <br />
                  <br />
                  <div className="col-md-12 col-sm-12">
                    <ReCAPTCHA
                      style={{ float: 'right' }}
                      sitekey={this.props.params.siteKey}
                      onChange={this.handleChange('recaptchaCode')}
                      hl="ka"
                    ></ReCAPTCHA>
                  </div>
                  <br />
                  <br />
                  <div className="col-md-9 col-sm-12"></div>
                  <div className="col-md-3 col-sm-12">
                    <button
                      className="btn btn-primary"
                      id="SendButton"
                      style={{
                        float: 'right',
                        backgroundColor: '#262262',
                        color: 'white',
                      }}
                      disabled={this.state.recaptchaCode && this.state.agree ? false : true}
                      onClick={this.handleSubmit}
                    >
                      გაგზავნა
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ConnectNewCustomer;
