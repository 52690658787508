import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import Sms from './Detailed/Sms';
import History from './Detailed/History';
import Customer from './Detailed/Customer';
import Emails from './Detailed/Emails';
import CheckHistory from './Detailed/CheckHistory';
import Charts from './Detailed/Charts';
import SmartMeterHistory from './Detailed/SmartMeterHistory';
import { read_cookie, delete_cookie } from 'sfcookies';
import Axios from 'axios';
import swal from 'sweetalert2';

class AbonentDetailed extends Component {
  constructor(props) {
    super(props);
    //console.log(this);
    this.state = {
      eventkey: 'history',
      custN: '',
      CustomerInfo: [],
      ucId: null,
    };
    this.handleLetterSwal = props.handleLetterSwal;
  }

  // UNSAFE_componentWillMount(){
  //     //this.setState({custN:this.props.match.params.customerid});
  // }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.values.user).length !== 0) {
      this.handleLoad();
    }
  }

  handleLoad = () => {
    let token = read_cookie('Bearer_Token');
    const url = window.$name;
    Axios.post(
      `${url}/api/Customer/GetCustomerInfo`,
      {
        customerNumber: this.props.match.params.customerid,
      },
      {
        headers: {
          Token: token,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          const { customer } = res.data;
          const { ucId } = customer;
          this.setState({ CustomerInfo: res.data.customer, ucId: ucId });
          //console.log(this.state.CustomerInfo);
        } else {
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.href = '/login';
        }
        //alert(error.status);
      });
  };

  handleSelect = (eventKey) => {
    this.setState({ eventKey: eventKey });
  };

  renderSwitch(eventKey) {
    //const values = this.state.CustomerInfo.custNumber+"-"+this.state.CustomerInfo.controlNumber;
    const values = this.props.match.params.customerid;
    //console.log(vals);
    if (Object.keys(this.props.values.user).length !== 0) {
      switch (eventKey) {
        case 'sms':
          return (
            <React.Fragment>
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <Sms values={values}></Sms>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        case 'email':
          return (
            <React.Fragment>
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <Emails values={values}></Emails>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        case 'charts':
          return (
            <React.Fragment>
              <div className="card-body">
                <Charts values={values} />
              </div>
            </React.Fragment>
          );
        case 'checkhistory':
          return (
            <React.Fragment>
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <CheckHistory values={values}></CheckHistory>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        case 'smartmeterhistory':
          return (
            <>
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <SmartMeterHistory values={values} ucId={this.state.ucId}></SmartMeterHistory>
                  </div>
                </div>
              </div>
            </>
          );
        default:
          return (
            <React.Fragment>
              <div className="card-body">
                <History values={values}></History>
              </div>
            </React.Fragment>
          );
      }
    }
  }

  handleSwalPopup = (
    title,
    icon,
    showCancelButton,
    cancelButtonText,
    confirmButtonText,
    needReload,
    confirmButtonColor
  ) => {
    swal
      .fire({
        title: title,
        icon: icon,
        showCancelButton: showCancelButton,
        cancelButtonText: cancelButtonText,
        confirmButtonText: confirmButtonText,
        confirmButtonColor: confirmButtonColor,
      })
      .then(() => {
        if (needReload) {
          window.location.reload();
        }
      });
  };

  render() {
    const vals = this.state.CustomerInfo;
    //console.log(this.props);

    // return(<label>{this.props.match.params.customerid}</label>)
    //console.log(this.props);

    //console.log(this.props)

    if (vals.letterSuggestion) {
      this.handleLetterSwal(vals.custNumber, vals.controlNumber);
    }

    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Customer values={vals} handleSwalPopup={this.handleSwalPopup}></Customer>
              </div>
            </div>
            <br></br>
            <Nav
              fill
              variant="tabs"
              defaultActiveKey="history"
              onSelect={this.handleSelect}
              style={{ fontFamily: 'BPGCond' }}
            >
              <Nav.Item>
                <Nav.Link eventKey="history" style={{ color: '#262262' }}>
                  <i className="fas fa-file-invoice-dollar" style={{ color: '#262262' }}></i>
                  &nbsp;ტრანზაქციების ისტორია
                </Nav.Link>
              </Nav.Item>
              {this.state.ucId && (
                <Nav.Item>
                  <Nav.Link eventKey="smartmeterhistory" style={{ color: '#262262' }}>
                    <i className="far fa-calendar-check" style={{ color: '#262262' }}></i>
                    &nbsp;ჭკვიანი მრიცხველის ჩვენება
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link eventKey="sms" style={{ color: '#262262' }}>
                  <i className="fas fa-sms" style={{ color: '#262262' }}></i>&nbsp;SMS ისტორია
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="email" style={{ color: '#262262' }}>
                  <i className="fas fa-at" style={{ color: '#262262' }}></i>&nbsp;Email ისტორია
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="checkhistory" style={{ color: '#262262' }}>
                  <i className="far fa-calendar-check" style={{ color: '#262262' }}></i>
                  &nbsp;შემოწმების ისტორია
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="charts" style={{ color: '#262262' }}>
                  <i className="fas fa-chart-line" style={{ color: '#262262' }}></i>&nbsp;ანალიტიკა
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                {this.props.match.params.customerid ? this.renderSwitch(this.state.eventKey) : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AbonentDetailed;

// export default (props) =>
// {
//     // console.log(111111);
//     //  console.log(props);
//     return <AbonentDetailed {...props} />;
//     //export default props => <AbonentDetailed {...props}/>;
// };
// export default props => <AbonentDetailed {...props}/>;
