import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //hovered:false,
      id: '',
    };
  }

  handleMouseMove = () => {
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  handleIdChange = (id) => {
    this.setState({ id: id });
  };

  render() {
    //const { hovered } = this.state;
    //const style = { backgroundColor: "#F3F3F3",cursor:"pointer",color:"#3c4b64",textDecoration:"none"};

    return (
      <React.Fragment>
        <Helmet>
          <title>მთავარი</title>
        </Helmet>
        <form style={{ fontFamily: 'BPGCaps' }}>
          {/* <div className="row justify-content-center">
                        <h1 style={{textAlign:"center",color:"#262262"}}>პროგრამა მუშაობს სატესტო რეჟიმში</h1>
                    </div>
                    <br/> */}
          <div className="row justify-content-center">
            <div className="col-sm-6 col-md-4 col-lg-3">
              <Link
                className="card homecard"
                to={'/dashboard/Customers'}
                // style={this.state.id==="2"?style:{color:"#3c4b64"}} id="2"
                // onMouseMove={(e)=>{this.handleMouseMove();this.handleIdChange("2")}}
                // onMouseLeave={(e)=>{this.handleMouseLeave();this.handleIdChange("")}}
              >
                <div className="card-body text-center">
                  <div
                    className="text-muted small text-uppercase font-weight-bold"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    ჩემი აბონენტები
                  </div>
                  <div className="text-value-xl py-4">
                    <i className="fas fa-list-alt" style={{ color: '#3c4b64' }}></i>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <Link
                className="card homecard"
                to={'/dashboard/MyLetters'}
                // style={this.state.id==="3"?style:{color:"#3c4b64"}} id="3"
                // onMouseMove={(e)=>{this.handleMouseMove();this.handleIdChange("3")}}
                // onMouseLeave={(e)=>{this.handleMouseLeave();this.handleIdChange("")}}
              >
                <div className="card-body text-center">
                  <div
                    className="text-muted small text-uppercase font-weight-bold"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    ჩემი განცხადებები
                  </div>
                  <div className="text-value-xl py-4">
                    <i className="fas fa-scroll" style={{ color: '#3c4b64' }}></i>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <Link
                className="card homecard"
                to={'/dashboard/WriteLetter'}
                // style={this.state.id==="4"?style:{color:"#3c4b64"}} id="4"
                // onMouseMove={(e)=>{this.handleMouseMove();this.handleIdChange("4")}}
                // onMouseLeave={(e)=>{this.handleMouseLeave();this.handleIdChange("")}}
              >
                <div className="card-body text-center">
                  <div
                    className="text-muted small text-uppercase font-weight-bold"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    განცხადების დაწერა
                  </div>
                  <div className="text-value-xl py-4">
                    <i className="fas fa-clipboard-list" style={{ color: '#3c4b64' }}></i>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <br></br>
          <div className="row justify-content-center">
            <div className="col-sm-6 col-md-4 col-lg-3">
              <Link
                className="card homecard"
                to={'/dashboard/Notifications'}
                // style={this.state.id==="5"?style:{color:"#3c4b64"}} id="5"
                // onMouseMove={(e)=>{this.handleMouseMove();this.handleIdChange("5")}}
                // onMouseLeave={(e)=>{this.handleMouseLeave();this.handleIdChange("")}}
              >
                <div className="card-body text-center">
                  <div
                    className="text-muted small text-uppercase font-weight-bold"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    შეტყობინებები
                  </div>
                  <div className="text-value-xl py-4">
                    <i className="fas fa-bell" style={{ color: '#3c4b64' }}></i>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <Link
                className="card homecard"
                to={'/dashboard/Profile'}
                id="1"
                // style={this.state.id==="1"?style:{color:"#3c4b64"}}
                // onMouseMove={(e)=>{this.handleMouseMove();this.handleIdChange("1")}} onMouseLeave={(e)=>{this.handleMouseLeave();this.handleIdChange("")}}
              >
                <div className="card-body text-center">
                  <div
                    className="text-muted small text-uppercase font-weight-bold"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    პროფილი
                  </div>
                  <div className="text-value-xl py-4">
                    <i className="fas fa-user" style={{ color: '#3c4b64' }}></i>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <Link
                className="card homecard"
                to={'/dashboard/Help'}
                // id="6" style={this.state.id==="6"?style:{color:"#3c4b64"}}
                // onMouseMove={(e)=>{this.handleMouseMove();this.handleIdChange("6")}}
                // onMouseLeave={(e)=>{this.handleMouseLeave();this.handleIdChange("")}}
              >
                <div className="card-body text-center">
                  <div
                    className="text-muted small text-uppercase font-weight-bold"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    დახმარება
                  </div>
                  <div className="text-value-xl py-4">
                    <i className="fas fa-question-circle" style={{ color: '#3c4b64' }}></i>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default Home;
