import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
import {Redirect} from 'react-router-dom';
import { read_cookie,delete_cookie } from 'sfcookies';
import Axios from 'axios';
import Helmet from 'react-helmet';

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid:false,
            isValiderror:"",
            customerNumber:"-",
            show:false,
            abonentInfo:[]
          }
    }

    handleChange = (input) => (e) => {   
        this.setState({ [input]: e.target.value });
    };

    handleShow(){
        this.setState({show:!this.state.show});
    }

    handleSubmit=(event)=>{
        event.preventDefault();
        let isValid=this.handleSubmitCheck();
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        let custN=this.state.customerNumber
        if(isValid){
            Axios.post(`${name}/api/Payment/Check`,
            {
                customerNumber:custN
            },
            {
                headers:{
                    "Token":token
                }
            })
            .then(res=>{
                if(!res.data.success){
                    this.setState({isValid:false,isValiderror:"არასწორი აბონენტის ნომერი"});
                }
                else if(res.data.abonentInfo.error>0){
                    this.setState({isValid:false,isValiderror:"არასწორი აბონენტის ნომერი"});
                }
                else{
                    this.setState({isValid:true,abonentInfo:res.data.abonentInfo,isValiderror:""});
                }
            })
            .catch(error=>{
                this.setState({isValiderror:""});
                if(error.response.code===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                  }
            });
        }
    }

    handleSubmitCheck(){
        let isValid = true;
        this.setState({isValiderror:""});
        if(this.state.customerNumber.includes('X')){
            this.state.isValid=false;
            this.state.isValiderror="შეიყვანეთ სწორი აბონენტის ნომერი";
            isValid=false;
        }

        if(this.state.customerNumber === ""|| this.state.customerNumber === null){
            isValid = false;
            this.state.isValid=false;
            this.state.isValiderror="აბონენტის ნომრის შეყვანა აუცილებელია";
        }

        return isValid;
    }

    handleKeyDown(event){
        if(event.keyCode===13){   
            this.handleSubmit(event);
        }
    }

    render() { 
        let isValid = this.state.isValid;

        if(this.state.isValid){
            return <Redirect push to={`/dashboard/Pay/${this.state.customerNumber}`}></Redirect>
        }

        return ( 
            <React.Fragment>
                <Helmet><title>გადახდა</title></Helmet>
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                        <div className="card">
                            <div className="card-header text-center" >
                                <span style={{fontFamily:"BPGCaps",fontSize:"1.5rem",color:"#262262"}}>გადახდა</span>
                            </div>
                            <div className="card-body" style={{textAlign:"center",margin:"auto",fontFamily:"BPGCond",color:"#262262"}}>
                                <form onKeyDown={(event)=>{this.handleKeyDown(event)}}>
                                    <div className="form-group">
                                        <h3>შეიყვანეთ აბონენტის ნომერი:</h3><br/>
                                        <MaskedInput className={`form-control-lg form-control${isValid?"":" is-invalid"}`} value={this.state.customerNumber} mask="111111-111" placeholderChar='X'  placeholder="XXXXXX-XXX" name="customerNumber" onChange={this.handleChange("customerNumber")}></MaskedInput>&nbsp;&nbsp;&nbsp;
                                        <div className="invalid-feedback">{!this.state.isValid?this.state.isValiderror:null}</div><br></br>
                                        <button className="btn btn-primary btn-lg" type="button" style={{backgroundColor:"#262262",borderColor:"#262262",width:"100%",fontFamily:"BPGCaps"}} onClick={this.handleSubmit}>შემოწმება</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>    
                </div>       
            </React.Fragment>
        );
    }
}
 
export default Payment;