import React, { Component } from 'react';
import {Button,Modal} from 'react-bootstrap';
import { read_cookie ,delete_cookie} from 'sfcookies';
import Axios from 'axios';
import '../../css/style.css';
import '../../css/fontawesome-free-5.13.0-web/css/all.css';
//import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import '../../css/profile.css';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import Helmet from 'react-helmet';
//import swal from 'sweetalert';
import swal from 'sweetalert2';
//import { CountdownCircleTimer } from 'react-countdown-circle-timer';

class Profile extends Component {
    constructor(props){
        super(props);
        this.state = {
            readOnly: true,
            readOnlyPhone:true,
            showHideDemo1:true,
            showHideDemo2:false,
            showPassRes: false,
            telephones:[],
            beforechange:"",
            afterchange:"",
            afterchangeerror:"",
            showAddPhone:false,
            addPhone:"",
            addPhoneerror:"",
            showPopup:false,
            smscode:"",
            smscodeerror:"",
            toVerify:"",
            showChangePhone:false,
            showAddEmail:false,
            addEmail:false,
            addAnEmail:"",
            addAnEmailerror:"",
            emailToverify:"",
            showEmailVerify:false,
            emailCode:"",
            emailCodeerror:"",
            active:"one",
            duration:0,
            disabled:true,
            key:0
        }
        this.handleSwalPopup=props.handleSwalPopup;      
    }

    hideComponent(name) {
        //console.log(name);
        switch (name) {
          case "showHideDemo1":
            this.setState({ showHideDemo1: !this.state.showHideDemo1 });
            this.setState({showHideDemo2:!this.state.showHideDemo2});
            this.setState(prevState => ({readOnly: !prevState.readOnly}));
            break;
          case "showHideDemo2":
            this.setState({ showHideDemo2: !this.state.showHideDemo2 });
            this.setState({showHideDemo1:!this.state.showHideDemo1});
            this.setState(prevState => ({readOnly: !prevState.readOnly}));
            break;
            default:
                break;
        }
    }

    showPassForm(){
        this.setState({showPassRes:!this.state.showPassRes});
    }

    handlePhoneChange = (input) => (e) => {      
        this.setState({ [input]: e.target.value });
    };

    handleEmailChange=(input)=>(e)=>{
          this.setState({[input]:e.target.value});
    }

    handlePhoneClick = () => {
        this.setState({showChangePhone:!this.state.showChangePhone});
    }

    handlePopup = () => {
        this.setState({showPopup:!this.state.showPopup});
    }
      
    handleAdd = () => {
        this.setState({showAddPhone:!this.state.showAddPhone});
    }

    handleEmailAdd=()=>{
          this.setState({showAddEmail:!this.state.showAddEmail});
    }

    handleToVerify(phone){
        this.setState({toVerify:phone});
    }

    handlePrepare(phone){
        this.setState({beforechange:phone});
    }

    handleEmailPrepare(email){
          this.setState({emailToverify:email});
    }

    handleAddSubmit = () => {
        const token = read_cookie("Bearer_Token");
        const isValid = this.verifyFormValidate();
        const name = window.$name;
        if(isValid){
            
            
            Axios.post(`${name}/api/Users/AddPhone`,{
                info : this.state.addPhone
            },
            {
                headers:{
                    "Token": token
                }
            })
            .then(res => {
                if(res.data.success){
                    this.handlePopup();
                    this.handleSwalPopup(`ვერიფიკაციისთვის ერთჯერადი კოდი გამოგზავნილია ${this.state.addPhone} ნომერზე`,"success",false,"","დახურვა",false,"#757575");
                }
                else if(res.data.code===-2){
                    this.handleSwalPopup("ნომერი უკვე არსებობს","warning",false,"","დახურვა",false,"#757575");
                }
                else{
                    this.handleSwalPopup("ნომრის დამატება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                }
            })
            .catch(error => {
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                }
                else{
                    this.handleSwalPopup("ნომრის დამატება ვერ მოხერხდა","danger",false,"","დახურვა",false,"#757575");
                }
            });
        }
    }

    handleVerify = () => {
        const token = read_cookie("Bearer_Token");
        const isValid = this.smsFormValidate();
        const name = window.$name;
        if(isValid){
            Axios.post(`${name}/api/Users/VerifyPhone`,
            {
                mobilePhone : this.state.addPhone,
                smsCode : this.state.smscode
            },
            {
                headers:{
                    "Token" : token
                }
            })
            .then(res=>{
                if(res.data.success){
                    this.handleSwalPopup(`${this.state.addPhone} ვერიფიცირებულია`,"success",false,"","დახურვა",true,"#77575");
                }
                else{
                    this.handleSwalPopup("მობილური ნომრის ვერიფიცირება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                }
                
            })
            .catch(error=>{
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                }
                else{
                    this.handleSwalPopup("მობილური ნომრის ვერიფიცირება ვერ მოხერხდა","warning",false,"","danger",false,"#757575");
                }
            });
        }
    }

    // submitSmsAgain(){
    //     const cockie_key = "Bearer_Token";

    //     const token = read_cookie(cockie_key);
    //     Axios.post("http://10.0.0.131/back/user/Verifyadd",
    //     {

    //     },
    //     {headers:
    //         {
    //           "Authorization":"Bearer "+token
    //         }    
    //     }
    //   ).then(res=>{
    //     alert("კოდი გამოგზავნილია");
          
    //   })
    //     .catch(error=>{
    //         if(error.response.status===401){
    //             delete_cookie("Bearer_Token");
    //             window.location.href = '/login';
    //           }
    //       alert("კოდის გამოგზავნა ვერ მოხერხდა");
    //     })
    // }

    // handlePhoneEdit = () => {
    //     const token = read_cookie("Bearer_Token");

    //     const isValid = this.phoneEditValidate();
    //     const name = window.$name;
    //     if(isValid){
    //         Axios.post("http://10.0.0.131/back/user/editphone",
    //         {
    //             "PHONE_NUMBER" : this.state.afterchange,
    //             "OLD_PHONE_NUMBER" : this.state.beforechange
    //         },
    //         {
    //             headers:{
    //                 "Authorization" : "Bearer " + token
    //             }
    //         })
    //         .then(res => {
    //             //console.log(res);
    //             this.setState({showChangePhone:false});
    //             this.setState({showPopup:true});
    //             //("შეცვლილია");
    //         })
    //         .catch(error => {
    //             //console.log(error);
    //             if(error.response.status===401){
    //                 delete_cookie("Bearer_Token");
    //                 window.location.href = '/login';
    //               }
    //             alert("შეცვლა ვერ მოხერხდა");
    //         });
    //     }
    // }

    handlePhoneRemove = (phone) => {
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        Axios.post(`${name}/api/Users/DeletePhone`,
        {
            info: phone
        },
        {
            headers:{
                "Token" : token
            }
        })
        .then(res=>{
            if(res.data.success){
                this.handleSwalPopup("ნომერი წაშლილია","success",false,"","დახურვა",true,"#757575");
            }
            else{
                this.handleSwalPopup("ნომერის წაშლა ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
            }
        })
        .catch(error=>{
            if(error.response.status===401){
                delete_cookie("Bearer_Token");
                window.location.href = '/login';
              }
            else{
                this.handleSwalPopup("ნომერის წაშლა ვერ მოხერხდა","danger",false,"","დახურვა",false,"#757575");
            }
        })
    }

    verifyFormValidate = () => {
          let isValid = true;

          if(this.state.addPhone.length!==9){
              isValid = false;
              this.setState({addPhoneerror:"ნომერი უნდა იყოს ცხრანიშნა"});
          }

          if(this.state.addPhone.match(/\[0-9]/)){
              isValid = false;
              this.setState({addPhoneerror:"შეიყვანეთ მხოლოდ ციფრები"});
          }

          if(this.state.addPhone===""){
            isValid = false;
            this.setState({addPhoneerror:"აუცილებელია ტელეფონის ნომრის შეყვანა"});
          }

          return isValid;
    }

    smsFormValidate = () => {
          let isValid = true;

          if(this.state.smscode.length!==4){
            isValid = false;
            this.setState({smscodeerror:"კოდი უნდა იყოს ოთხნიშნა"});
          }

          if(this.state.smscode.match(/\/[0-9]/)){
            isValid = false;
            this.setState({smscodeerror:"შეიყვანეთ მხოლოდ ციფრები"});
          }

          if(this.state.smscode===""){
              isValid = false;
              this.setState({smscodeerror:"აუცილებელია კოდის შეყვანა"});
          }

          return isValid;
    }

    phoneEditValidate = () => {
        let isValid = true;

        if(this.state.afterchange.length!==9){
            isValid = false;
            this.setState({afterchangeerror:"კოდი უნდა იყოს ოთხნიშნა"});
          }

          if(this.state.afterchange.match(/\/[0-9]/)){
            isValid = false;
            this.setState({afterchangeerror:"შეიყვანეთ მხოლოდ ციფრები"});
          }

          if(this.state.afterchange===""){
              isValid = false;
              this.setState({afterchangeerror:"აუცილებელია კოდის შეყვანა"});
          }

        return isValid;
    }

    handleEmailRemove=(email)=>{
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        Axios.post(`${name}/api/Users/DeleteEmail`,
        {
            info:email
        },
        {
            headers:{
                "Token":token
            }
        })
        .then(res=>{
            if(res.data.success){
                this.handleSwalPopup("ელ. ფოსტა წაშლილია","success",false,"","დახურვა",true,"#757575");
            }
            else{
                this.handleSwalPopup("ელ. ფოსტის წაშლა ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
            }
        })
        .catch(error=>{
            if(error.response.status===401){
                delete_cookie("Bearer_Token");
                window.location.href = '/login';
              }
            else{
                this.handleSwalPopup("ელ. ფოსტის წაშლა ვერ მოხერხდა","danger",false,"","დახურვა",false,"#757575");
            }
        })
    }

    mailCodeFormValidate = () => {
        let isValid = true;

        if(this.state.emailCode.length!==4){
          isValid = false;
          this.setState({emailCodeerror:"კოდი უნდა იყოს ოთხნიშნა"});
        }

        if(this.state.emailCode.match(/\/[0-9]/)){
          isValid = false;
          this.setState({emailCodeerror:"შეიყვანეთ მხოლოდ ციფრები"});
        }

        if(this.state.emailCode===""){
            isValid = false;
            this.setState({emailCodeerror:"აუცილებელია კოდის შეყვანა"});
        }

        return isValid;
    }

    emailAddValidate(){
        let isValid = true;

        if(!this.state.addAnEmail||this.state.addAnEmail===""){
            isValid=false;
            this.setState({addAnEmailerror:"აუცილებელია"});
        }

        return isValid;
    }

    handleAddEmail=(email)=>{
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        Axios.post(`${name}/api/Users/AddEmail`,
        {
            info:email
        },
        {
            headers:{
                "Token":token
            }
        })
        .then(res=>{
            if(res.data.success){
                alert("E-mail დამატებულია და კოდი გამოგზავნილია");
            }
            else{
                alert(res.data.message);
            }
        })
        .catch(error=>{
            if(error.response.status===401){
                delete_cookie("Bearer_Token");
                window.location.href = '/login';
              }
            //alert(error);
        })
    
    }

    handleAddEmail1=(email)=>{
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        let isValid = this.emailAddValidate();
        if(isValid){
            this.setState({emailToverify:email})
        this.handleShowEmail();
        Axios.post(`${name}/api/Users/AddEmail`,
        {
            info:this.state.addAnEmail
        },
        {
            headers:{
                "Token":token
            }
        })
        .then(res=>{
            if(res.data.success){
                this.setState({emailToverify:this.state.addAnEmail});
                this.handleSwalPopup(`<span style="font-family:BPGCond">ერთჯერადი კოდი გამოგზავნილია ${this.state.addAnEmail}-ზე</span>`,"success",false,"","დახურვა",false,"#757575")
            }
            else{
                this.handleSwalPopup("ელ. ფოსტის დამატება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
            }
        })
        .catch(error=>{
            if(error.response.status===401){
                delete_cookie("Bearer_Token");
                window.location.href = '/login';
              }
            else{
                this.handleSwalPopup("ელ. ფოსტის დამატება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
            }
        });
        }
    }
    
    handleEmailValidate=()=>{
        const token = read_cookie("Bearer_Token");
        const name = window.$name;
        let isValid=this.mailCodeFormValidate();

        if(isValid){
            Axios.post(`${name}/api/Users/VerifyEmail`,{
                email:this.state.emailToverify,
                smsCode:this.state.emailCode
            },
            {
                headers:{
                    "Token":token
                }
            })
            .then(res=>{
                if(res.data.success){
                    this.handleSwalPopup("ელ. ფოსტა ვერიფიცირებული და დამატებულია","success",false,"","დახურვა",true,"#757575");                    
                }
                else{
                    switch(res.data.status){
                        case -3:
                            this.handleSwalPopup("ელ. ფოსტა ვერიფიცირება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                            break;
                        default:
                            this.handleSwalPopup("ელ. ფოსტა ვერიფიცირება ვერ მოხერხდა","warning",false,"","დახურვა",false,"#757575");
                            break;
                    }
                }
            })
            .catch(error=>{
                if(error.response.status===401){
                    delete_cookie("Bearer_Token");
                    window.location.href = '/login';
                }
                else{
                    this.handleSwalPopup("ელ. ფოსტა ვერიფიცირება ვერ მოხერხდა","danger",false,"","დახურვა",false,"#757575");
                }
            })
        }
    }

    handleShowEmail=()=>{
        this.setState({showEmailVerify:!this.state.showEmailVerify});
    }

    handleSuccessSwal=()=>{
        swal({title:"აბონენტის დამატება",text:"აბონენტი დამატებულია",icon:'success',button:"კარგი"})
        .then(()=>{
            window.location.reload();
        });
    }

    handleRemoveSwal=()=>{
        swal({title:"აბონენტის ამოშლა",text:"აბონენტი ამოშლილია",icon:'success',button:"კარგი"})
        .then(()=>{
            window.location.reload();
        });
    }

    getPrimaryPhone(phones){  
        let primary = "";           
        phones.map((phone,index)=>{
            if(phone.typeKey===1){
                primary=phone.info;
            }
        });
        return primary;
    }

    getAlternativePhones(phones){
        let alternatives = [];
        phones.map((phone,index)=>{
            if(phone.typeKey!==1){
                alternatives.push(phone);
            }
        });
        return alternatives;
    }

    handleSelected=(name)=>{
        switch(name){
            case "one":
                this.setState({active:"one"});
                break;
            case "two":
                this.setState({active:"two"});
                break;
            case "three":
                this.setState({active:"three"});
                break;
            case "four":
                this.setState({active:"four"});
                break;
            default:
                break;
        }
    }

    render() { 
        const {values,handleChange,handleDataUpdate,handlePasswordReset} = this.props;
        //const { showHideDemo1, showHideDemo2, showPassRes } = this.state;

        if(Object.keys(values.user).length === 0){
            return (<label/>);
        }
        //console.log(values);
        const primary = this.getPrimaryPhone(values.user.phones);
        const alternatives = this.getAlternativePhones(values.user.phones);

        return ( 
            <React.Fragment>
                <Helmet><title>პროფილი</title></Helmet>
                <div className="row justify-content-center" style={{fontFamily:"BPGCond"}}>
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">პროფილი</div>
                            <div className="card-body">
                                <Tabs defaultTab="one"
                                    vertical
                                    className="vertical-tabs">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <TabList>
                                                    <Tab tabFor="one" onClick={()=>this.handleSelected("one")} style={{backgroundColor:this.state.active==="one"?" #f4f4f4":""}}><span style={{float:"left",color:"#262262",textAlign:"left"}} ><i className="far fa-user-circle"></i>&nbsp;მომხმარებლის ინფორმაცია</span></Tab>
                                                    <Tab tabFor="two" onClick={()=>this.handleSelected("two")} style={{backgroundColor:this.state.active==="two"?" #f4f4f4":""}}><span style={{float:"left",color:"#262262",textAlign:"left"}} ><i className="fas fa-lock"></i>&nbsp;პაროლის შეცვლა</span></Tab>
                                                    <Tab tabFor="three" onClick={()=>this.handleSelected("three")} style={{backgroundColor:this.state.active==="three"?" #f4f4f4":""}}><span style={{float:"left",color:"#262262",textAlign:"left"}} ><i className="fas fa-mobile-alt"></i>&nbsp;მობილურის ნომრები(დამატება/ამოშლა)</span></Tab>
                                                    <Tab tabFor="four" onClick={()=>this.handleSelected("four")} style={{backgroundColor:this.state.active==="four"?" #f4f4f4":""}}><span style={{float:"left",color:"#262262",textAlign:"left"}} ><i className="fas fa-at"></i>&nbsp;ელ. ფოსტა(დამატება/ამოშლა)</span></Tab>
                                                </TabList>      
                                            </div>                         
                                            <div className="col-md-7" >                                   
                                                <TabPanel tabId="one">
                                                    <div className="form-horizontal">
                                                        <div className="form-group row">
                                                            <label className="col-md-12 col-form-label"><strong>მომხმარებლის სახელი</strong></label>
                                                            <div className="col-md-7">
                                                                <input readOnly className="form-control" id="usernameedit" type="text" name="usernameedit" value={values.usernameedit} onChange={handleChange("usernameedit")}></input>
                                                                {
                                                                    values.usernameediterror ?(
                                                                        <label style={{fontSize: 12,color:"#e55353"}}>{values.usernameediterror}</label>
                                                                    ):null
                                                                }    
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-12 col-form-label"><strong>სახელი</strong></label>
                                                            <div className="col-md-7">
                                                                <input className="form-control" id="firstnameedit" type="text" name="firstnameedit" value={values.firstnameedit} onChange={handleChange("firstnameedit")} ></input>
                                                                {
                                                                    values.firstnameediterror ?(
                                                                        <label style={{fontSize: 12,color:"#e55353"}}>{values.firstnameediterror}</label>
                                                                    ):null
                                                                }    
                                                            </div>
                                                      
                                                                                                           
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-12 col-form-label"><strong>გვარი</strong></label>
                                                            <div className="col-md-7">
                                                                <input className="form-control" id="lastnameedit" type="text" name="lastnameedit" value={values.lastnameedit} onChange={handleChange("lastnameedit")}></input>
                                                            
                                                                {
                                                                    values.lastnameediterror ?(
                                                                        <label style={{fontSize: 12,color:"#e55353"}}>{values.lastnameediterror}</label>
                                                                    ):null
                                                                }                                                            
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-12 col-form-label"><strong>პირადი ნომერი</strong></label>
                                                            <div className="col-md-7">
                                                                <div className="">
                                                                    <input className="form-control" id="personalnumberedit" name="personalnumberedit" type="text" value={values.personalnumberedit} onChange={handleChange("personalnumberedit")}></input>
                                                                </div>
                                                                {
                                                                    values.personalnumberediterror ?(
                                                                        <label style={{fontSize: 12,color:"#e55353"}}>{values.personalnumberediterror}</label>
                                                                    ):null
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-12 col-form-label"><strong>საკონტაქტო მისამართი</strong></label>
                                                            <div className="col-md-7">
                                                                <div className="">
                                                                    <input className="form-control" id="registrationAddressedit" name="registrationAddressedit" type="text" value={values.registrationAddressedit} onChange={handleChange("registrationAddressedit")}></input>
                                                                </div>
                                                                {
                                                                    values.registrationAddresserror ?(
                                                                        <label style={{fontSize: 12,color:"#e55353"}}>{values.registrationAddresserror}</label>
                                                                    ):null
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3"/>
                                                            <div className="col-md-7">
                                                            <div className="form-actions">
                                                                    <button className="btn btn-sm btn-primary" type="submit" style={{float:"right"}} onClick={handleDataUpdate}>დამახსოვრება</button>
                                                                </div>                              
                                                            </div>
                                                        </div>             
                                                    </div>
                                                </TabPanel>
                                                <TabPanel tabId="two">                                                                                 
                                                    <div className="form-horizontal">
                                                        <div className="form-group row">
                                                            <label className="col-md-12 col-form-label"><strong>ძველი პაროლი</strong></label>
                                                            <div className="col-md-7">
                                                                <input className="form-control" type="password" id="passold" name="passold" onChange={handleChange("passold")}></input>
                                                                {
                                                                    values.passolderror ?(
                                                                        <label style={{fontSize: 12,color:"#e55353"}}>{values.passolderror}</label>
                                                                    ):null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-12 col-form-label"><strong>ახალი პაროლი</strong></label>
                                                            <div className="col-md-7">
                                                                <input className="form-control" type="password" id="passnew" name="passnew" onChange={handleChange("passnew")}></input>
                                                                {
                                                                    values.passnewerror ?(
                                                                        <label style={{fontSize: 12,color:"#e55353"}}>{values.passnewerror}</label>
                                                                    ):null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-12 col-form-label"><strong>გაიმეორეთ პაროლი</strong></label>
                                                            <div className="col-md-7">
                                                                <input className="form-control" type="password" id="passnewre" name="passnewre" onChange={handleChange("passnewre")}></input>
                                                                {
                                                                    values.passnewreerror ?(
                                                                        <label style={{fontSize: 12,color:"#e55353"}}>{values.passnewreerror}</label>
                                                                    ):null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div className="form-group row">
                                                    <div className="col-md-3"/>
                                                        <div className="col-md-7" >
                                                            <button className="btn btn-sm btn-primary" style={{float:"right"}} type="button" onClick={handlePasswordReset}>დამახსოვრება</button>
                                                            {/* &nbsp;&nbsp;
                                                            <button className="btn btn-sm btn-danger" type="button" onClick={handlePassUndo}>გაუქმება</button> */}
                                                        </div>
                                                </div>
                                                </TabPanel>
                                                <TabPanel tabId="three">
                                                    <div className="form-horizontal">
                                                        <div className="form-group row">
                                                            <label className="col-md-12 col-form-label"><strong>ძირითადი ნომერი</strong></label>
                                                            <div className="col-md-7">
                                                                <label className="col-form-label">{primary}</label>
                                                            </div>                                        
                                                        </div>
                                                        <div className="form-group-row">
                                                            <ul  style={{listStyleType: "none",padding: "0",margin:"0"}}>
                                                                {
                                                                    alternatives.length>=1?                       
                                                                    alternatives.map((phone,index)=>{
                                                                        return(                                                      
                                                                            <li key={index}>
                                                                                <div className="form-group row">
                                                                                    <label className="col-md-12 col-form-label"><strong>ნომერი #{index+1}</strong></label>
                                                                                    <div className="col-md-6">
                                                                                        <input className="form-control" value={phone.info} readOnly={this.state.readOnlyPhone} onChange={this.handlePhoneChange("afterchange")}></input>
                                                                                    </div>
                                                                                    &nbsp;
                                                                                    <div className="col-md-1" style={{float:"left"}}>
                                                                                        <i className="fas fa-edit" onClick={()=>{
                                                                                            this.handlePhoneClick();
                                                                                            this.handlePrepare(phone.info);
                                                                                        }}></i>
                                                                                        {/* {console.log(phone)} */}
                                                                                            &nbsp;
                                                                                        <i className="fas fa-trash-alt" onClick={() => 
                                                                                            // { if (window.confirm('ნამდვილად გსურთ ნომრის ამოშლა?')) this.handlePhoneRemove(phone.info) }
                                                                                            swal.fire({html:"<span style='"+"font-family:BPGCond"+"'>ნამდვილად გსურთ ნომრის ამოშლა?</span>",confirmButtonColor:"#262262",showCancelButton:true,confirmButtonText:`<span style="font-family:BPGCond;">დიახ</span>`,cancelButtonText:`<span style="font-family:BPGCond;">არა</span>`,icon:"question"})
                                                                                            .then((value)=>{
                                                                                                if(value.isConfirmed){
                                                                                                    this.handlePhoneRemove(phone.info);
                                                                                                }
                                                                                            })
                                                                                            }></i>
                                                                                        {
                                                                                            !phone.verified?
                                                                                            (
                                                                                                <React.Fragment>
                                                                                                    {/* {this.setState({toVerify:phone.phonenumber})} */}
                                                                                                    <button className="btn btn-sm btn-success" onClick={()=>{
                                                                                                        this.handlePopup();
                                                                                                        this.handleToVerify(phone.info);
                                                                                                    }}>ვერიფიცირება</button>
                                                                                                </React.Fragment>
                                                                                                // this.setState({toVerify:phone.phonenumber})
                                                                                                // <button className="btn btn-sm btn-success" onClick={this.handlePopup}>ვერიფიცირება</button>
                                                                                            )
                                                                                            :null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        
                                                                        )
                                                                    })
                                                                    :null
                                                                    //values.phones.map(())
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                
                                                    {
                                                        this.state.showAddPhone ? 
                                                        (
                                                            <React.Fragment>
                                                                <div className="form-group row">
                                                                    <label className="col-md-12 col-form-label"><strong>ნომერი : </strong></label>
                                                                    <div className="col-md-6">
                                                                        <input className="form-control" value={this.state.addPhone} onChange={this.handlePhoneChange("addPhone")}></input>
                                                                        <br></br>
                                                                        {
                                                                        this.state.addPhoneerror ? 
                                                                        (
                                                                            <label style={{fontSize: 12,color:"#e55353"}}>{this.state.addPhoneerror}</label>
                                                                        )
                                                                        :null
                                                                    }
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="form-group row">
                                                                    
                                                                    <div className="col-md-10"> 
                                                                        <div style={{float:"right"}}>
                                                                            <button className="btn btn-sm btn-primary" onClick={this.handleAddSubmit}>შენახვა</button>
                                                                            &nbsp;
                                                                            <button className="btn btn-sm btn-secondary" onClick={this.handleAdd}>გაუქმება</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                        :null
                                                    }
                                                {
                                                    !this.state.showAddPhone?
                                                    (
                                                        <div className="form-group row">
                                                            <div className="col-md-3"></div>
                                                            <div className="col-md-6"></div>
                                                            <div className="col-md-1">
                                                                <React.Fragment>
                                                                    <button className="btn btn-sm btn-primary" style={{float:"right"}} onClick={this.handleAdd}>დამატება</button>                                       
                                                                </React.Fragment>
                                                            </div>
                                                        </div>
                                                    )
                                                    :null
                                                }
                                            </TabPanel>
                                                <TabPanel tabId="four">
                                                    <div className="form-horizontal">                                                
                                                        <div className="form-group-row">
                                                        <ul style={{listStyleType: "none",padding: "0",margin:"0"}}>
                                                            {
                                                                values.user.emails.length!==0?
                                                                values.user.emails.map((email,index)=>{
                                                                    return(
                                                                        <li key={index}>
                                                                            <div className="form-group row">
                                                                                <label className="col-md-12 col-form-label"><strong>E-Mail #{index+1}</strong></label>
                                                                                <div className="col-md-6">
                                                                                    <input className="form-control" value={email.info} readOnly={true} ></input>
                                                                                </div>
                                                                                &nbsp;
                                                                                <div className="col-md-1">
                                                                                    <i className="fas fa-trash-alt centeredIcon" title="წაშლა" onClick={() => 
                                                                                        {
                                                                                            swal.fire({title:`<span style="font-family:BPGCond;">ნამდვილად გსურთ ელ. ფოსტის წაშლა?</span>`,confirmButtonColor:"#262262",showCancelButton:true,confirmButtonText:`<span style="font-family:BPGCond;">დიახ</span>`,cancelButtonText:`<span style="font-family:BPGCond;">არა</span>`,icon:"question"})
                                                                                            .then((value)=>
                                                                                            {
                                                                                                //console.log(value)
                                                                                                if(value.isConfirmed){
                                                                                                    this.handleEmailRemove(email.info);
                                                                                                    //this.handleShowEmail();
                                                                                                }
                                                                                                // switch(value)
                                                                                                // {
                                                                                                //     case "yes":this.handleEmailRemove(email.info);break;
                                                                                                //     case "cancel":this.showEmailVerify();break;
                                                                                                //     default:break;
                                                                                                // }
                                                                                            })
                                                                                        }}                      
                                                                                    ></i>
                                                                                </div>
                                                                                {
                                                                                    !email.verified?
                                                                                        (
                                                                                            <React.Fragment>
                                                                                                {/* {this.setState({toVerify:phone.phonenumber})} */}
                                                                                                <button className="btn btn-sm btn-success" onClick={()=>{
                                                                                                    this.handleShowEmail();
                                                                                                    this.handleEmailPrepare(email.info);
                                                                                                    //this.handleAddEmail(email.info);
                                                                                                }}>ვერიფიცირება</button>
                                                                                            </React.Fragment>
                                                                                            // this.setState({toVerify:phone.phonenumber})
                                                                                            // <button className="btn btn-sm btn-success" onClick={this.handlePopup}>ვერიფიცირება</button>
                                                                                        )
                                                                                    :null
                                                                                    }
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                :null
                                                            }
                                                        </ul>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.showAddEmail ? 
                                                        (
                                                            <React.Fragment>
                                                                <div className="form-group row">
                                                                    <label className="col-md-3 col-form-label"><strong>Email: </strong></label>
                                                                    <div className="col-md-6">
                                                                        <input className="form-control" value={this.state.addAnEmail} onChange={this.handleEmailChange("addAnEmail")}></input>
                                                                        <br></br>
                                                                        {
                                                                        this.state.addAnEmailerror ? 
                                                                        (
                                                                            <label style={{fontSize: 12,color:"#e55353"}}>{this.state.addAnEmailerror}</label>
                                                                        )
                                                                        :null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <div style={{float:"right"}}>
                                                                        <button className="btn btn-sm btn-primary" onClick={this.handleAddEmail1}>შენახვა</button>
                                                                        &nbsp;
                                                                        <button className="btn btn-sm btn-danger" onClick={this.handleEmailAdd}>გაუქმება</button>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                        :null
                                                    }
                                                    {
                                                        !this.state.showAddEmail?
                                                        (
                                                            <div className="col-md-10"> 
                                                                <button className="btn btn-sm btn-primary" style={{float:"right"}} onClick={this.handleEmailAdd}>დამატება</button>                                       
                                                            </div>
                                                        )
                                                        :null
                                                    }
                                                </TabPanel>
                                            </div>     
                                        </div> 
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showPopup} onHide={this.handlePopup} centered>
                    <Modal.Header closeButton><strong>ნომრის ვერიფიკაცია</strong></Modal.Header>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">SMS Code</label>
                                <div className="col-md-9">
                                    <input className="form-control" id="smscode" name="smscode" value={this.state.smscode} onChange={this.handlePhoneChange("smscode")}></input>
                                    <br></br>
                                    {
                                        this.state.smscodeerror ?
                                        (
                                            <label style={{fontSize: 12,color:"#e55353"}}>{this.smscodeerror}</label>
                                        )
                                        :null
                                    }
                                </div>                               
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-success" onClick={this.handleVerify}>ვერიფიკაცია</Button>
                        {/* &nbsp;&nbsp;
                        <CountdownCircleTimer isPlaying={this.state.disabled?true:false} duration={this.state.duration} size={35} strokeWidth={2.5}
                            colors={[["#262262"]]}
                            key={this.state.key}
                            onComplete={() => this.setState({disabled:false})}
                            >
                            {this.renderTime}
                        </CountdownCircleTimer> */}
                        {/* <Button className="btn btn-primary">თავიდან გამოგზავნა</Button> */}
                        {/* <Button onClick={this.handlePopup}>დახურვა</Button> */}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showEmailVerify} onHide={this.handleShowEmail} centered style={{fontFamily:"BPGCond"}}> 
                    <Modal.Header closeButton><strong>E-Mail-ის ვერიფიცირება</strong></Modal.Header>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Code</label>
                                <div className="col-md-9">
                                    <input className="form-control" id="emailcode" name="emailcode" value={this.state.emailCode} onChange={this.handlePhoneChange("emailCode")}></input>
                                    <br></br>
                                    {
                                        this.state.emailCodeerror ?
                                        (
                                            <label style={{fontSize: 12,color:"#e55353"}}>{this.state.emailCodeerror}</label>
                                        )
                                        :null
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-success" onClick={this.handleEmailValidate}>ვერიფიკაცია</Button>
                        <Button className="btn btn-primary" onClick={this.handleAddEmail}>თავიდან გამოგზავნა</Button>
                        {/* <Button onClick={this.handlePopup}>დახურვა</Button> */}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showChangePhone}>
                    <Modal.Header><strong>მობილური ნომრის ცვლილება</strong></Modal.Header>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">{this.state.beforechange}</label>
                                <div className="col-md-9">
                                    <input className="form-control" id="afterchange" name="afterchange" onChange={this.handlePhoneChange("afterchange")}></input>
                                    <br></br>
                                    {
                                        this.state.afterchangeerror ? 
                                        (
                                            <label style={{fontSize:12,color:"#e55353"}}>{this.afterchangeerror}</label>
                                        )
                                        :null
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary" onClick={this.handlePhoneEdit}>შეცვლა</Button>
                        <Button className="btn btn-danger" onClick={this.handlePhoneClick}>გაუქმება</Button>
                    </Modal.Footer>
                </Modal>

               
            </React.Fragment>
            
         );
    }
}
 
export default Profile;