import React, { Component } from 'react';
import ToLetters from '../../ToLetters';
import MaskedInput from 'react-maskedinput';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import MaskedInput1 from 'react-text-mask';
import ReCAPTCHA from 'react-google-recaptcha';
import TextMask from 'react-text-mask';
import emailMask from 'text-mask-addons/src/emailMask';
import axios from 'axios';
import { read_cookie, delete_cookie } from 'sfcookies';
import InputInfo from '../InputInfo';
import Select from 'react-select';
import Axios from 'axios';

class SmartMeter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      address: '',
      personal: '',
      phone_1: '',
      phone_2: '',
      email_1: '',
      email_2: '',
      bankdetails: '',
      custN: '',
      addressto: '',
      cadastral: '',
      recaptchaCode: '',
      file_1: '',
      customer_firstname: '',
      customer_lastname: '',
      customer_personal: '',
      customer_phone_1: '',
      customer_phone_2: '',
      customer_email_1: '',
      customer_email_2: '',
      reference_FieldsDescriptions: [],
      lowpower: '',
      errors: [],
      amountN: '',
      telasi: '',
      smartmeterprice: '',
      checkyes_4: false,
      checkyes_5: false,
      checkyes_16: true,
      checkyes_18: false,
      checkyes_221: false,
      checked: true,
      gwp: '',
      agree: false,
      file_16: '',
      otherdocumentsfiles: [],
      reference_CapacitySteps: [],
      reference_activedistricts: {},
      reference_activestreets_1: {},
      reference_RefCapacitySteps: [],
      reference_FieldsDescriptions: [],
      reference_activeSteps: {},
      reference_allStreets: {},
      reference_tools: [],
      district_Id: '',
      street_Id: '',
      street_Name: '',
      addressto_building: '',
      addressto_entrance: '',
      addressto_floor: '',
      addressto_appartament: '',
    };
    this.handleLetterTermsModal = this.props.handleLetterTermsModal;
    this.letterFormValidator = this.props.letterFormValidator;
    this.renderCustomersOptions = this.props.renderCustomersOptions;
    this.SendLetter = this.props.SendLetter;
    this.handleSwalPopup = this.props.handleSwalPopup;
    this.checkGeorgianLetters = this.props.checkGeorgianLetters;
  }

  UNSAFE_componentWillMount() {
    const token = read_cookie('Bearer_Token');
    const url = window.$name;

    axios
      .get(`${url}/api/Letters/GetLetterFieldDescriptions/${this.props.letterTypeId}`, {
        headers: {
          Token: token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ reference_FieldsDescriptions: res.data.descriptions });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'udnefined') {
          if (error.response.status === 401) {
            delete_cookie('Bearer_Token');
            window.location.replace('/login');
          }
        }
      });

    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      let phone = data.phones.filter((x) => x.typeKey === 1);
      let email = data.emails[0];
      this.setState({
        fullname: data.name + ' ' + data.lastName,
        personal: data.personalId,
        phone_1: phone ? phone[0].info : '',
        email_1: email ? email.info : '',
        address: data.registrationAddress,
      });
    }
  }

  componentDidMount() {
    const token = read_cookie('Bearer_Token');
    const name = window.$name;
    Axios.get(`${name}/api/Letters/GetReferences?letterTypeId=50`, { headers: { Token: token } })
      .then((res) => {
        if (res.data.success) {
          let data = res.data.references;
          let districts = [];
          let activedistricts = [];
          let streets = [];
          let devices = [];
          let active_streets = [];
          let RefCapacitySteps = [];
          let tools = [];
          let priceNames = [];
          let allStreets = [];
          let allActiveStreets = [];
          //ერთეულოვანისთვის "RefCapacitySteps":  1-თვითმმართველი ,2-არათვითმმართველი 3და 4 სიმძლავრის გაზრდაა
          //console.log(data);
          data.forEach((element) => {
            switch (element.key) {
              case 'RefDevices':
                devices.push(element);
                let obj1 = { value: element.id, label: element.value };
                tools.push(obj1);
                break;
              case 'RefDistricts':
                let obj = { value: element.id, label: element.value };
                activedistricts.push(obj);
                districts.push(element);
                break;
              case 'RefStreets':
                streets.push(element);
                allStreets.push(element);
                break;
              case 'RefCapacitySteps':
                RefCapacitySteps.push(element);
                //console.log(element)
                break;
              case 'RefPriceNames':
                priceNames.push(element);
                //console.log(element)
                break;
              default:
                break;
            }
          });
          //console.log(devices);
          let val = districts[0].id;
          streets.forEach((element) => {
            if (element.parentId === val) {
              active_streets.push(element);
            }
          });

          //console.log(active_streets);

          allStreets.forEach((element) => {
            if (element.id !== 12797 && element.id !== 14610) {
              let distName = districts.find((c) => c.id == element.parentId)?.value
                ? districts.find((c) => c.id == element.parentId)?.value
                : ' ';
              element.labelText = element.value + ' (' + distName + ')';
              element.districtName = distName;
              let lObj = {
                value: element.id,
                label: element.labelText,
                districtId: element.parentId,
                districtName: distName,
              };
              allActiveStreets.push(lObj);
            }
          });
          //let tmp = allStreets.slice(50,100);
          //console.log(tmp)
          //active_streets = allStreets.slice(100,150);

          //console.log(active_streets);
          this.setState({
            reference_devices: devices,
            reference_streets: streets,
            reference_districts: districts,
            reference_activestreest: active_streets,
            reference_activedistricts: activedistricts,
            reference_RefPriceNames: priceNames,
            reference_RefCapacitySteps: RefCapacitySteps,
            reference_tools: tools,
            reference_allStreets: allActiveStreets,
          });
          //console.log(this.state.reference_RefCapacitySteps);

          this.setActiveSteps(1, RefCapacitySteps);
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          delete_cookie('Bearer_Token');
          window.location.replace('/login');
        }
        //alert("შეცდომა ?");
      });
  }

  handlePowerChange = (selectedOption) => {
    if (selectedOption !== null) {
      let smartTimePrice = this.state.reference_RefCapacitySteps.find(
        (x) => x.id === selectedOption.value
      ).extraPrice;
      this.setState({ lowpower: selectedOption.value, smartmeterprice: smartTimePrice });
    }
  };

  setActiveSteps = (parent, RefCapacitySteps) => {
    const Ids = [45, 46];
    let activeSteps = [];
    RefCapacitySteps.forEach((element) => {
      if (element.parentId == parent) {
        if (Ids.includes(element.id)) {
          activeSteps.push({ value: element.id, label: element.value });
        }
      }
    });
    this.setState({ reference_activeSteps: activeSteps });
  };

  //select-ების გასუფთავება
  selectRef = null;

  clearValue = () => {
    this.selectRef.select.clearValue();
  };

  selectRef1 = null;

  clearValue1 = () => {
    this.selectRef1.select.clearValue();
  };

  handleDistrictChange = (selectedOption) => {
    //console.log(selectedOption);
    // if (selectedOption !== null) {
    //   let activestreets = [];
    //   this.state.reference_streets.forEach((element) => {
    //     if (element.parentId == selectedOption.value) {
    //       //console.log([element,selectedOption.value])
    //       let obj = { value: element.id, label: element.value };
    //       activestreets.push(obj);
    //     }
    //   });
    //   this.setState({
    //     district_Id: selectedOption.value,
    //     reference_activestreets_1: activestreets,
    //   });
    //   //this.clearValue();
    // } else {
    //   this.setState({ district_Id: '', street_Id: '', reference_activestreets_1: [] });
    // }
    // this.clearValue1();
  };

  handleStreetChange = (selectedOption) => {
    //console.log(selectedOption);
    if (selectedOption !== null)
      this.setState({
        street_Id: selectedOption !== null ? selectedOption.value : '',
        street_Name: selectedOption !== null ? selectedOption.label : '',
        district_Id: selectedOption != null ? selectedOption.districtId : '',
      });
    else {
      this.setState({ street_Id: '', street_Name: '', district_Id: '' });
    }
  };

  handleChange = (input) => (e) => {
    let ph = '';
    if (input.includes('phone')) {
      ph = e.target.value.replace(/\D/g, '');
      if (input === 'phone_1') this.setState({ phone_1: ph });
      else this.setState({ phone_2: ph });
    } else if (input === 'recaptchaCode') {
      //console.log(e)
      this.setState({ recaptchaCode: e });
    } else {
      this.setState({ [input]: e.target.value });
    }
  };

  handleFileChange = (input) => (event) => {
    if (input === 'otherdocumentsfiles') {
      if (event.target.files.length > 3) {
        this.handleSwalPopup(
          'ფაილების მაქსიმალური რაოდენობა არის 3',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
        document.getElementById('otherdocumentsfiles').value = '';
        //this.setState({[input]:[]});
      } else {
        this.setState({ [input]: event.target.files });
      }
    } else {
      if (input === 'file_8') {
        this.setState({ [input]: event.target.files[0], checkyes_19: true });
      } else this.setState({ [input]: event.target.files[0] });
    }
  };

  handleCheck(name) {
    this.setState({ [name]: !this.state[name] });
  }

  formValidate() {
    const values = this.state;
    //document.getElementById('SendButton').disabled=true;
    let data = [];

    for (const [key, value] of Object.entries(values)) {
      if (!key.includes('recap') && !key.includes('refe') && !key.includes('error')) {
        let obj = {};
        obj.name = key;
        obj.value = value;
        if (
          key === 'fullname' ||
          key === 'personal' ||
          key === 'phone_1' ||
          key === 'file_1' ||
          key === 'address' ||
          key === 'cadastral' ||
          key === 'district_Id' ||
          key === 'street_Id' ||
          key === 'addressto_building' ||
          key === 'customer_firstname' ||
          key === 'customer_personal' ||
          key === 'customer_phone_1' ||
          key === 'custN' ||
          key === 'lowpower' ||
          key === 'smartmeterprice'
        )
          obj.Required = true;
        else if (key === 'customer_lastname') {
          if (this.state.checked == true) obj.Required = true;
          else obj.Required = false;
        } else obj.Required = false;

        data.push(obj);
      }
    }

    let result = this.letterFormValidator(this.props.letterTypeId, data);
    const found = result.errors.find((x) => x.key === 'filesSize');
    if (typeof found !== 'undefined') {
      //console.log(1234);
      this.state.errorFileSize = true;
      this.setState({ errors: result.errors });
      //this.handleSwalPopup("ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს","warning",false,"","დახურვა",false,"#262262");
    } else {
      this.state.errorFileSize = false;
      this.setState({ errors: result.errors });
    }
    //document.getElementById('SendButton').disabled=false;
    return result.isValid;
  }

  handleSubmit = () => {
    let isValid = this.formValidate();

    let data = [];

    if (isValid) {
      for (const [key, value] of Object.entries(this.state)) {
        let obj = {};
        if (!key.includes('error') && !key.includes('refe') && !key.includes('recap')) {
          if (value !== '' && value !== null) {
            obj.key = key;
            obj.value = value;
            data.push(obj);
          }
        }
      }
      this.SendLetter(this.state.custN, this.props.letterTypeId, this.state.recaptchaCode, data);
    } else {
      //var filesError = this.state.errors.filter(x=>x.key==="filesSize"); if(filesError.length) filesError=filesError[0].value;
      if (this.state.errorFileSize) {
        this.handleSwalPopup(
          'ფაილების ჯამური ზომა არ უნდა აღემატებოდეს 14MB-ს',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      } else {
        this.handleSwalPopup(
          'შესავსებია სავალდებულო ველები',
          'warning',
          false,
          '',
          'დახურვა',
          false,
          '#262262'
        );
      }
    }
  };

  handleRepeat = () => {
    if (this.state.checked) {
      if (typeof this.props.params.user !== 'undefined') {
        const data = this.props.params.user;
        this.setState({
          customer_firstname: data.name,
          customer_personal: this.state.personal,
          customer_lastname: data.lastName,
          customer_phone_1: this.state.phone_1,
          customer_phone_2: this.state.phone_2,
          customer_email_1: this.state.email_1,
          customer_email_2: this.state.email_2,
        });
      }
    } else {
      this.setState({
        customer_phone_1: this.state.phone_1,
        customer_phone_2: this.state.phone_2,
        customer_email_1: this.state.email_1,
        customer_email_2: this.state.email_2,
      });
    }
  };

  handlePhysicalSwitch = () => {
    if (this.state.checked) {
      this.setState({
        checked: !this.state.checked,
        customer_firstname: '',
        customer_lastname: '',
        customer_personal: '',
      });
    } else {
      this.setState({ checked: !this.state.checked });
    }
  };

  errorExsist = (key) => {
    let bool = this.state.errors.some((obj) => obj.key == key);
    return bool;
  };

  renderErrorText = (key) => {
    let bool = this.state.errors.find((obj) => obj.key.includes(key) === true);
    return bool.value;
  };

  render() {
    const values = this.state;
    let phone, email;
    if (typeof this.props.params.user !== 'undefined') {
      const data = this.props.params.user;
      phone = data.phones.filter((x) => x.typeKey === 1);
      email = data.emails[0];
    }
    let l = true;
    if (values.reference_FieldsDescriptions.length === 0) l = false;
    //console.log(values)
    return (
      <div className="row justify-content-center" style={{ fontFamily: 'BPGCond' }}>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div style={{ float: 'left' }}>
                <ToLetters></ToLetters>
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>
                  <strong>{this.props.letterTitle}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>განმცხადებელი</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სახელი და გვარი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      readOnly={this.props.params.user.fullName ? true : false}
                      placeholder="სახელი გვარი/ორგანიზაციის დასახელება"
                      onChange={this.handleChange('fullname')}
                      value={values.fullname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'fullname'}
                      />
                    ) : null}
                    {this.errorExsist('fullname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('fullname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-4 col-form-label">
                    <strong>პირადი #/საიდენტიფიკაციო #:</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="personal"
                      placeholder="პირადი ნომერი"
                      readOnly={this.props.params.user.personalId ? true : false}
                      onChange={this.handleChange('personal')}
                      value={values.personal}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'personal'}
                      />
                    ) : null}
                    {this.errorExsist('personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მისამართი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      placeholder="მისამართი"
                      readOnly={this.props.params.user.registrationAddress ? true : false}
                      onChange={this.handleChange('address')}
                      value={values.address}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'address'}
                      />
                    ) : null}
                    {this.errorExsist('address') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('address')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      readOnly={
                        typeof phone[0].info !== 'undefined' && phone[0].info ? true : false
                      }
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_1"
                      onChange={this.handleChange('phone_1')}
                      value={values.phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილური (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="მობილურის ნომერი"
                      name="phone_2"
                      onChange={this.handleChange('phone_2')}
                      value={values.phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      readOnly={typeof email !== 'undefined' ? true : false}
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_1"
                      onChange={this.handleChange('email_1')}
                      value={values.email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა (ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ელ. ფოსტა"
                      name="email_2"
                      onChange={this.handleChange('email_2')}
                      value={values.email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'email_2'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>ინფორმაცია (აბონენტის), გაზის საფასურის გადამხდელი პირის შესახებ:</strong>
              <div className="card-header-actions">
                <button className="btn btn-primary btn-sm" onClick={this.handleRepeat}>
                  განმცხადებლის მონაცემების გადმოტანა
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <BootstrapSwitchButton
                  width={200}
                  checked={values.checked}
                  onlabel="ფიზიკური"
                  offlabel="იურიდიული"
                  onChange={this.handlePhysicalSwitch}
                ></BootstrapSwitchButton>
                <br />
                <br />
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'სახელი' : 'დასახელება'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_firstname')}
                      placeholder={values.checked ? 'სახელი' : 'დასახელება'}
                      value={values.customer_firstname}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_firstname'}
                      />
                    ) : null}
                    {this.errorExsist('customer_firstname') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_firstname')}
                      </label>
                    ) : null}
                  </div>
                </div>
                {values.checked ? (
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      <strong>გვარი</strong>
                      <span style={{ color: '#e55353' }}>*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        onChange={this.handleChange('customer_lastname')}
                        value={values.customer_lastname}
                        placeholder="გვარი"
                      ></input>
                      {l ? (
                        <InputInfo
                          descriptions={values.reference_FieldsDescriptions}
                          letterTypeId={this.props.letterTypeId}
                          inputName={'customer_lastname'}
                        />
                      ) : null}
                      {this.errorExsist('customer_lastname') ? (
                        <label style={{ fontSize: 10, color: '#e55353' }}>
                          {this.renderErrorText('customer_lastname')}
                        </label>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>{values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('customer_personal')}
                      value={values.customer_personal}
                      placeholder={values.checked ? 'პირადი ნომერი' : 'საინდენტიფიკაციო ნომერი'}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_personal'}
                      />
                    ) : null}
                    {this.errorExsist('customer_personal') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_personal')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ძირითადი)</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ძირითადი"
                      name="customer_phone_1"
                      onChange={this.handleChange('customer_phone_1')}
                      value={values.customer_phone_1}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_1'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>მობილურის ნომერი(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput1
                      mask={[
                        '(',
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      className="form-control"
                      type="text"
                      placeholder="ალტერნატიული"
                      name="customer_phone_2"
                      onChange={this.handleChange('customer_phone_2')}
                      value={values.customer_phone_2}
                    ></MaskedInput1>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_phone_2'}
                      />
                    ) : null}
                    {this.errorExsist('customer_phone_2') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('customer_phone_2')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ძირითადი)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ძირითადი"
                      name="customer_email_1"
                      onChange={this.handleChange('customer_email_1')}
                      value={values.customer_email_1}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_1'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>ელ. ფოსტა(ალტერნატიული)</strong>
                  </label>
                  <div className="col-md-8">
                    <TextMask
                      className="form-control"
                      placeholder="ალტერნატიული"
                      name="customer_email_2"
                      onChange={this.handleChange('customer_email_2')}
                      value={values.customer_email_2}
                      mask={emailMask.mask}
                      pipe={emailMask.pipe}
                    />
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'customer_email_2'}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>აბონენტის N</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <MaskedInput
                      className="form-control"
                      mask="111111-111"
                      list="customers"
                      placeholder="XXXXXX-XXX"
                      name="custN"
                      onChange={this.handleChange('custN')}
                      value={values.custN}
                    ></MaskedInput>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'custN'}
                      />
                    ) : null}
                    <datalist id="customers">{this.renderCustomersOptions()}</datalist>
                    {this.errorExsist('custN') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('custN')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>განმცხადებლის საბანკო რეკვიზიტები</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('bankdetails')}
                      placeholder="განმცხადებლის საბანკო რეკვიზიტები"
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'bankdetails'}
                      />
                    ) : null}
                    {this.errorExsist('bankdetails') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('bankdetails')}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>ინფორმაცია ობიექტის შესახებ</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-from-label">
                    <strong>უძრავი ქონების საკადასტრო კოდი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={this.handleChange('cadastral')}
                      placeholder="უძრავი ქონების საკადასტრო კოდი"
                      value={values.cadastral}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'cadastral'}
                      />
                    ) : null}
                    {this.errorExsist('cadastral') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('cadastral')}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>რაიონი</strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <Select
                    isClearable
                    isSearchable
                    options={this.state.reference_activedistricts}
                    onChange={this.handleDistrictChange}
                    placeholder="აირჩიეთ"
                  />
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'district_Id'}
                    />
                  ) : null}
                  {this.errorExsist('district_Id') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('district_Id')}
                    </label>
                  ) : null}
                </div>
              </div> */}
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>ქუჩა</strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <Select
                    ref={(ref) => {
                      this.selectRef1 = ref;
                    }}
                    isClearable
                    isSearchable
                    options={this.state.reference_allStreets}
                    onChange={this.handleStreetChange}
                    placeholder="აირჩიეთ ან აკრიფეთ"
                    noOptionsMessage={() => 'ცარიელია'}
                  />
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'street_Id'}
                    />
                  ) : null}
                  {this.errorExsist('street_Id') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('street_Id')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>შენობის ნომერი</strong>
                  <span style={{ color: '#e55353' }}>*</span>
                </label>
                <div className="col-md-8">
                  <input
                    maxLength="50"
                    className="form-control"
                    onChange={this.handleChange('addressto_building')}
                    placeholder="შენობის ნომერი"
                    value={values.addressto_building}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'addressto_building'}
                    />
                  ) : null}
                  {this.errorExsist('addressto_building') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('addressto_building')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>სადარბაზო</strong>
                </label>
                <div className="col-md-8">
                  <input
                    maxLength="50"
                    className="form-control"
                    onChange={this.handleChange('addressto_entrance')}
                    placeholder="სადარბაზო"
                    value={values.addressto_entrance}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'addressto_entrance'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>სართული</strong>
                </label>
                <div className="col-md-8">
                  <input
                    maxLength="50"
                    className="form-control"
                    onChange={this.handleChange('addressto_floor')}
                    placeholder="სართული"
                    value={values.addressto_floor}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'addressto_floor'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>ბინის ნომერი</strong>
                </label>
                <div className="col-md-8">
                  <input
                    maxLength="50"
                    className="form-control"
                    onChange={this.handleChange('addressto_apartament')}
                    placeholder="ბინის ნომერი"
                    value={values.addressto_apartament}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'addressto_apartament'}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>სხვა პირობები</strong>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>გესაჭიროებათ საგადასახადო ანგარიშ-ფაქტურა</strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck('checkyes_4');
                      }}
                      checked={this.state.checkyes_4}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck('checkyes_4');
                      }}
                      checked={!this.state.checkyes_4}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_4'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    განაწილების ლიცენზიატის მიერ ამ განაცხადზე შეტყობინების გაგზავნის ფორმა
                  </strong>
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck('checkyes_5');
                      }}
                      checked={this.state.checkyes_5}
                    ></input>
                    &nbsp;მატერიალური
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck('checkyes_5');
                      }}
                      checked={!this.state.checkyes_5}
                    ></input>
                    &nbsp;ელექტრონული
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_5'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-4 col-form-label">
                  <strong>
                    არსებული სიმძლავრე<span style={{ color: '#e55353' }}>*</span>
                  </strong>
                </div>
                <div className="col-md-8">
                  <Select
                    ref={(ref) => {
                      this.selectRef = ref;
                    }}
                    options={values.reference_activeSteps}
                    placeholder="აირჩიეთ"
                    onChange={this.handlePowerChange}
                  />
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'lowpower'}
                    />
                  ) : null}
                  {this.errorExsist('lowpower') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('lowpower')}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>
                    ჭკვიანი მრიცხველის მოწყობის საფასური<span style={{ color: '#e55353' }}>*</span>
                  </strong>
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    value={values.smartmeterprice}
                    readOnly
                    type="number"
                    min="0"
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>ა)</strong>გამოყენებულ იქნეს გაზმომარაგებასთან დაკავშირებული ინფორმაციის
                  შეტყობინების მიზნით
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck('checkyes_16');
                      }}
                      checked={this.state.checkyes_16}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck('checkyes_16');
                      }}
                      checked={!this.state.checkyes_16}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_16'}
                    />
                  ) : null}
                </div>
                <br />
                <label className="col-md-4 col-form-group">
                  <strong>ბ)</strong>მიეწოდოს ელექტროენერგიისა და წყალმომარაგების სექტორში მომსახურე
                  საწარმოებს, სადაც, განაცხადში მოცემულ მისამართზე, რეგისტრირებული ვარ მომხმარებლად
                  და გამაჩნია აბონენტის ნომერი
                </label>
                <div className="col-md-8">
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck('checkyes_18');
                      }}
                      checked={this.state.checkyes_18}
                    ></input>
                    &nbsp;დიახ
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => {
                        this.handleCheck('checkyes_18');
                      }}
                      checked={!this.state.checkyes_18}
                    ></input>
                    &nbsp;არა
                  </label>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'checkyes_18'}
                    />
                  ) : null}
                  <br></br>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-4 col-form-label">
                  <strong>მიერთების საფასურის გადახდის დამადასტურებელი საბუთი</strong>
                </label>
                <div className="col-md-4">
                  <label>ასევე, გადახდილია საფასურის </label>&nbsp;
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_221')}
                      checked={this.state.checkyes_221}
                    ></input>
                    &nbsp;50%
                  </label>
                  <label>
                    <input
                      type="radio"
                      onChange={() => this.handleCheck('checkyes_221')}
                      checked={!this.state.checkyes_221}
                    ></input>
                    100%
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg"
                    onChange={this.handleFileChange('file_16')}
                  ></input>
                  {l ? (
                    <InputInfo
                      descriptions={values.reference_FieldsDescriptions}
                      letterTypeId={this.props.letterTypeId}
                      inputName={'file_16'}
                    />
                  ) : null}
                  <br></br>
                  {this.errorExsist('file_16') ? (
                    <label style={{ fontSize: 10, color: '#e55353' }}>
                      {this.renderErrorText('file_16')}
                    </label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>თანდართული დოკუმენტაცია (მხოლოდ pdf/jpg ფაილები, ჯამში მაქსიმუმ 14MB)</strong>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-md-4 col-form-label ">
                    <strong>პირადობის მოწმობის ან პასპორტის ასლი</strong>
                    <span style={{ color: '#e55353' }}>*</span>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      onChange={this.handleFileChange('file_1')}
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'file_1'}
                      />
                    ) : null}
                    <br></br>
                    {this.errorExsist('file_1') ? (
                      <label style={{ fontSize: 10, color: '#e55353' }}>
                        {this.renderErrorText('file_1')}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label">
                    <strong>სხვა თანდართული დოკუმენტაცია (სურვილის შემთხვევაში)</strong>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      id="otherdocumentsfiles"
                      onChange={this.handleFileChange('otherdocumentsfiles')}
                      multiple
                    ></input>
                    {l ? (
                      <InputInfo
                        descriptions={values.reference_FieldsDescriptions}
                        letterTypeId={this.props.letterTypeId}
                        inputName={'otherdocumentsfiles'}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-md-12" style={{ textAlign: 'right' }}>
                  <label>
                    <input
                      type="checkbox"
                      style={{ verticalAlign: 'middle' }}
                      checked={values.agree}
                      onChange={() => this.handleCheck('agree')}
                    ></input>
                    &nbsp;ვეთანხმები
                    <span
                      style={{ color: '#262262', cursor: 'pointer' }}
                      onClick={this.handleLetterTermsModal}
                    >
                      &nbsp;<strong>წესებსა და პირობებს</strong>
                    </span>
                  </label>
                </div>
                <div className="col-md-12">
                  <ReCAPTCHA
                    style={{ float: 'right' }}
                    sitekey={this.props.params.siteKey}
                    onChange={this.handleChange('recaptchaCode')}
                    hl="ka"
                  ></ReCAPTCHA>
                </div>
                <br />
                <br />
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary"
                    id="sendButton"
                    style={{ float: 'right', backgroundColor: '#262262', color: 'white' }}
                    disabled={this.state.recaptchaCode && this.state.agree ? false : true}
                    onClick={this.handleSubmit}
                    data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> იგზავნება"
                  >
                    გაგზავნა
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SmartMeter;
